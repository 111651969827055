/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import { updatePayment, storeCurrentPage } from '../../../actions/index';

class PayNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: ''
    };

    this.amountChange = this.amountChange.bind(this);
  }

  amountChange(e) {
    const value = parseFloat(e.target.value);
    this.setState({
      amount: value
    });
    this.props.updatePayment(value);
  }

  componentDidMount() {
    if (this.props.latest_payment_amount) {
      this.setState({
        amount: this.props.latest_payment_amount
      });
    }
  }

  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
        this.props.history.push(this.props.currentLoadingPage);
        this.props.storeCurrentPage('');
    }
  }

  render() {
    const currentUser = this.props.currentUser ? this.props.currentUser : null;
    const { t } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
          <Jumbotron className="col-lg-10 col-md-10 col-sm-10 col-xs-10 jumbotron_usage_summary">
              <div className="row">
                <div className="pay_now_heading1">
                  {t('payNow.welcome')} {currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : null}
                </div>
              </div>
          <Jumbotron className="jumbotron_pay_now_panel">
                  <div className="col-lg-12 col-md-12 col-sm-12 sol-xs-12">
                    <div className="wrapper summary-card-main">
                      <div className="pay_now_cards">
                        <div>
                        <p>{t('payNow.statment1_paynow')}</p>
                        </div>
                        <div>
                        <p>{t('payNow.statment2_paynow')}</p>
                        </div>
                        <div></div>
                      </div>
                      <div className="pay_now_cards">
                        <div>
                        <span>{t('payNow.paynow_heading1')}</span>
                        <div className="pyment_paynow_info_panel">
                          <span className="due_date_lbl">{t('payNow.paynow_label1')}</span>
                          <div className="amount_input_panel">
                          <div className="payment_amt_lbl">{t('payNow.paynow_label2')}</div>&nbsp;
                          <div>
                            <input type="number"
                            className="form-control amount-paynow-input"
                            id="paynow_amount"
                            name="paynow_amount"
                            autoComplete="off"
                            value={this.state.amount}
                            onChange={this.amountChange}/>
                          </div>
                          </div>
                        </div>
                        </div>
                        <div></div>
                        <div className="payment-submit-button">
                          <button className='btn pay_now_cont_btn' type='button' disabled={!this.state.amount}>{this.state.amount ? <Link className="pay_buttons" to='/selectCard' amount={this.state.amount}>{t('payNow.paynow_continue_btn')}</Link> : t('payNow.paynow_continue_btn') }</button>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </Jumbotron>
          </Jumbotron>
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  latest_payment_amount: state.crud.latest_payment_amount,
  currentLoadingPage: state.crud.currentLoadingPage,
  currentUser: state.crud.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  updatePayment: (details) => dispatch(updatePayment(details)),
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(PayNow));
