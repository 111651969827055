/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import TMXLogo from '../../../assets/img/TMX-Analytics-Logo.png';

class Header extends Component {
    state = {

    }

    render() {
      const { t } = this.props;
      /* const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
      }; */
      return (
        <>
        <div className='header-style-bar navbar-font'>
        <Navbar collapseOnSelect expand="lg" variant="dark" className="navbar_color navbar-font sticky-top">
          <Navbar.Brand href='/signup'><img src={TMXLogo} height="50" width="240"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link target="_blank" eventKey={t('header.analytics_link')} href={t('header.analytics_link')} className='link-hover-style'>{t('header.home')}</Nav.Link>
              <Nav.Link target="_blank" eventKey={t('header.products_pricing')} href={t('header.products_pricing_link')} className='link-hover-style'>{t('header.products_pricing')}</Nav.Link>
              <Nav.Link target="_blank" eventKey={t('header.solutions')} href={t('header.solutions_link')} className='link-hover-style'>{t('header.solutions')}</Nav.Link>
            </Nav>
            <Nav>
              {/* <Nav.Link eventKey={t('header.login')} onClick={() => changeLanguage(t('header.language_code'))}>{t('header.current_language')}</Nav.Link> */}
              <Nav.Link eventKey={t('header.login')} href="/login">{t('header.login')}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        </div>
        </>
      );
    }
}

export default withNamespaces()(Header);
