/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Iframe from 'react-iframe';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
import BannerImage from '../common/bannerImage';
import Pagination from '../common/pagination';
import { store } from '../../../utils/httpUtil';
import apiUrl from '../../../utils/apiUrl';
import { saveSignUpMonerisDetails, savesignUpDetails } from '../../../actions/index';
import ErrorMessage from '../common/errorMessage';

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iFrameUrl: '',
      iFrameSubmitUrl: '',
      dataKey: null,
      monerisError: false,
      errors: {
        moneris: 'moneris_err_msg'
      },
      resultMessage: '',
      isAlertNotRequired: true,
      isValid: false,
      isSkipPaymentDisabled: true,
      showLoader: false,
      showCreditCardForm: false,
      paymentType: 'invoice'
    };
    this.skipPayment = this.skipPayment.bind(this);
    this.saveInitiator = this.saveInitiator.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.saveDetails = this.saveDetails.bind(this);
    if (this.props.signUpDetailsSaved && !this.props.signUpDetailsSaved.first_name) {
      this.props.history.push(this.props.defaultPage);
    }
    if(this.props.billingDetails && this.props.billingDetails.selectedCountry) {
    this.getIframeDetails(this.props.billingDetails.selectedCountry);
    }
  }

  getIframeDetails(countrySelected) {
    trackPromise(store(apiUrl.iFrameUrl, { selectedCountry: countrySelected }).then((iFrameDetails) => {
      if (iFrameDetails && iFrameDetails.data && iFrameDetails.data.data) {
        this.setState({
          iFrameUrl: iFrameDetails.data.data.url,
          iFrameSubmitUrl: iFrameDetails.data.data.submit
        });
      }
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

  showLoader() {
      return (
        <div
        style={{
          width: '100%',
          display: 'flex',
          top: '5%',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          zIndex: '100',
          height: '100vh',
          opacity: '0.5',
        }}
        >
        <Loader type="Circles" color="#16fffb" height="100" width="100" />
        </div>
    );
  }

  saveInitiator() {
    if (this.state.showCreditCardForm) {
      this.setState({
        showLoader: true
      });
      const ele = document.getElementById('monerisFrame');
      const monFrame = ele.contentWindow;
      monFrame.postMessage('', this.state.iFrameSubmitUrl);
      if (window.addEventListener) {
        window.addEventListener('message', this.responseMessage.bind(this), false);
      } else if (window.attachEvent) {
        window.attachEvent('onmessage', this.responseMessage.bind(this));
      }
    } else {
      this.skipPayment();
      this.saveDetails();
      this.props.history.push('/signup-summary');
    }
  }

  responseMessage(e) {
    if (JSON.parse(e.data).responseCode.indexOf('001') === -1) {
      this.setState({
        monerisError: true,
        isValid: false
      });
    } else {
      this.setState({
        monerisError: false,
        dataKey: JSON.parse(e.data).dataKey,
        skipMonerisFlow: false,
        isValid: true
      });
      this.props.saveSignUpMonerisDetails({ dataKey: JSON.parse(e.data).dataKey, skipMonerisFlow: false });
      this.saveDetails();
      this.props.history.push('/signup-summary');
    }
    this.setState({
      showLoader: false
    });
  }

  skipPayment() {
    this.setState({
      skipMonerisFlow: true
    });
    this.props.saveSignUpMonerisDetails({ skipMonerisFlow: true });
  }

  handlePaymentChange(event) {
    const { value } = event.target;
    const paymentType = value === 'payByCreditCard' ? 'credit_card' : 'invoice';

    this.setState({
      showCreditCardForm: value === 'payByCreditCard',
      paymentType
    });
  }

  componentDidMount() {
   if (this.props.signUpDetailsSaved.is_individual) {
      this.setState({
        isSkipPaymentDisabled: false,
        showCreditCardForm: true
      });
   } 
  }

  saveDetails() {
    const propsNew = this.props.signUpDetailsSaved;
    if (propsNew.is_individual) {
      propsNew.team_name = `${propsNew.first_name} ${propsNew.last_name}`;
    } else {
      propsNew.team_name = propsNew.client_name;
    }
    propsNew.payment_type = this.state.paymentType;
    this.props.savesignUpDetails(propsNew);
  }

  render() {
    const { t } = this.props;
    return (<>
      {this.state.showLoader ? this.showLoader() : ''}
      <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
      <div className="container-fluid">
        <div className="row">
        <div className="col-lg-1 col-md-1"></div>
        <Jumbotron className="col-lg-4 col-md-10 col-sm-8 col-xs-8 jumbotron_usage_summary">
          <Link className="back-summary-button" to="/billing"><h6>{t('summary.back_button')}</h6></Link>
          <br></br>
          <h2 className="payment_header_alignment">{t('payment.payment_information')}</h2>
          <div className="form-row header_firstrow_space">
            <div className={`select_payment_option ${this.state.isSkipPaymentDisabled ? '' : 'hidden'}`}>
              <input type="radio" name="paymentOption" id="monthlyInvoice" value="monthlyInvoice" checked={!this.state.showCreditCardForm} onChange={this.handlePaymentChange}/>
                <label className="payment_options" htmlFor="monthlyInvoice">{t('payment.option_monthly_invoice')}</label><br></br>
              
            </div>
            <div className={`credit_card_form ${this.state.showCreditCardForm ? 'form_expanded' : ''} form-group col-md-12 col-lg-12 col-sm-12 left_labels_alignment`}>
              <Iframe src={this.state.iFrameUrl} id="monerisFrame" scrolling="no" class="moneris" webkitallowfullscreen mozallowfullscreen allowfullscreen width="100%" height="270" frameBorder="0" marginHeight="0" marginWidth="0"/>
            </div>
            <div className="text-danger-alert">{this.state.monerisError && t(`payment.${this.state.errors.moneris}`)}</div>
          </div>
          <center><Pagination isNoButton={true} currentIndex={3} showPaginationNumber={true} className="payment_paginationchange" isNext={true} blockNavigation={!this.state.isValid}/><button className="btn btn-info next_button" onClick={() => this.saveInitiator()}>{t('payment.next')}</button></center>
        </Jumbotron>
        <div className="col-lg-1 col-md-1"></div>
        <BannerImage className="paymentBannerImage"/>
        </div>
      </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveSignUpMonerisDetails: (details) => dispatch(saveSignUpMonerisDetails(details)),
  savesignUpDetails: (details) => dispatch(savesignUpDetails(details))
});

const mapStateToProps = (state) => ({
  monerisDetailsSaved: state.crud.monerisDetails,
  signUpDetailsSaved: state.crud.signUpDetails,
  billingDetails: state.crud.billingDetails,
  defaultPage: state.crud.defaultPage
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Payment));
