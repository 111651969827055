// Import custom components
import { LOG_IN_SUCCESS, LOG_IN_FAILURE, LOG_OUT_SUCCESS } from '../constants/actionType';

const initialState = {
  token: null,
  isAuthenticated: false,
  isLoading: false
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
const authReducer = (stateValue, action) => {
  const state = stateValue || initialState;

  switch (action.type) {
    case LOG_IN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        token: action.data,
      };

    case LOG_IN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        token: null,
        errorMessage: action.error.message || 'Something went wrong.'
      };

    case LOG_OUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: true,
        token: null,
      };

    default:
      return state;
  }
};

export default authReducer;
