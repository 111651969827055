/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import { connect } from 'react-redux';
import { saveSelectedProduct } from '../../../actions/index';

class SubscribedProducts extends Component {
    t = this.props.t;

    constructor(props) {
      super(props);
      this.state = {};
      this.toggleClass = this.toggleClass.bind(this);
      this.getSelectedProduct = this.getSelectedProduct.bind(this);
      this.nameColorAddition = this.nameColorAddition.bind(this);
    }

    getSelectedProduct(item) {
      this.props.saveSelectedProduct(item);
    }
    
    toggleClass(e, c) {
      e.target.classList.toggle(c);
    }

    isSelected = () => {
      let isSelected = false;
      const { selectedProduct } = this.props;
      if (selectedProduct && selectedProduct.productName === this.props.productName) {
        isSelected = true;
      }
      if (this.props.isAllocatePage && selectedProduct && selectedProduct.id === this.props.productId) {
        isSelected = true;
      }
      return isSelected;
    }

    nameColorAddition(name) {
      var str = name;
      var res = str.split(" "); 
      res[res.length - 1] === "" ? res.splice(-1,1) : '';
      var last = res[res.length - 1];
      return last;
    }

    nameColorAdditionRemaining(name) {
      var str = name;
      var remainingString = '';
      var res = str.split(' ');
      res[res.length - 1] === "" ? res.splice(-1,1) : '';
      for( var i=0; i<res.length-1;i++){
        remainingString += res[i];
        remainingString += " ";
      }
      return remainingString;
    }

    render() {
      return (
        <>{
          this.props.isAdminPage
            ? this.props.isSamePortal 
            ?  <Link to='/usage' className='link-styles'>
                <Card className="admin-products-card-layout">
                  <Card.Body>
                    <Card.Text className='products-text-change'><span>{this.nameColorAdditionRemaining(this.props.productName)}</span><span style={{ color: '#16FFFB'}}>{this.nameColorAddition(this.props.productName)}</span></Card.Text>
                  </Card.Body>
                </Card>
              </Link> 
            : <a href={this.props.productLink} className='link-styles' target={this.props.target}>
                <Card className="admin-products-card-layout">
                  <Card.Body>
                    <Card.Text className='products-text-change'><span>{this.nameColorAdditionRemaining(this.props.productName)}</span><span style={{ color: '#16FFFB'}}>{this.nameColorAddition(this.props.productName)}</span></Card.Text>
                  </Card.Body>
                </Card>
              </a>
            : <Card className={`${this.isSelected() ? 'admin-products-card-layout-selected' : 'admin-products-card-layout'}`}>
                  <Card.Body>
                    <Card.Text>{this.props.productName}</Card.Text>
                  </Card.Body>
              </Card>
          }
        </>
      );
    }
}

const mapDispatchToProps = (dispatch) => ({
  saveSelectedProduct : (details) => dispatch(saveSelectedProduct(details)),
});

const mapStateToProps = (state) => ({
  saveSelectedProductDetail: state.crud.selectedProductDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(SubscribedProducts));
