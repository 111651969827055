/* eslint-disable no-undef */
/* eslint-disable no-case-declarations */
import {
  SELECTED_PRODUCTS_INITIATE,
  GET_CURRENT_USER_DETAILS,
  GET_CURRENT_BILLING_DETAILS,
  GET_CARD_DETAILS,
  GET_PAYMENT_DETAILS,
  GET_CURRENT_ADMIN_DETAILS,
  UPDATE_PAYMENT,
  PRODUCTS_DETAILS_INITIATE,
  GET_ADMIN_CONSOLE_DETAILS,
  GET_ROLE_ACCESS_DETAILS,
  GET_API_PRODUCTS_LIST,
  STORE_CURRENT_PAGE,
  GET_CONFIGURED_PRODUCTS,
  GET_AUTH_TOKEN,
  SUBSCRIBER_DETAILS,
  ASSIGN_PRODUCTS,
  UPDATE_SELECTED_CLIENT,
  ADMIN_SUMMARY_DETAILS,
  MONERIS_DETAILS,
  UPDATE_CLIENT_ID,
  UPDATE_CLIENT_DETAILS,
  UPDATE_NEW_CLIENT_DETAILS,
  SIGN_UP_INITIATE,
  BILLING_DETAILS_INITIATE,
  USER_DETAILS_INITIATE,
  RECIEVE_ALL_DETAILS,
  SUMMARY_DETAILS_INITIATE,
  TAX_RATE_DETAILS,
  TAX_RATE_DETAILS_ADMIN,
  SIGN_UP_MONERIS_DETAILS,
  REMOVE_ALL_SIGNUP_DETAILS,
  CLEAR_SUBSCRIBED_PRODUCTS,
  CLEAR_ALL_ADMIN_SUMMARY_DETAILS,
  UPDATE_CLIENT_TEAM_NAME,
  FROM_BILLING_UPDATE,
  USER_LIST_UPDATED,
  TAX_AND_PAYMENT_UPDATED,
  UPDATE_PROFILE_DETAILS
} from '../constants/actionType';

const initialState = {
  currentUser: {
    firstName: '',
    lastName: '',
    roleType: 'isUIAdmin',
    email: '',
    team: '',
    organization: '',
    clientId: '',
    userId: '',
    errorMessage: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    provinceState: '',
    country: '',
    postalCode: '',
    contactNumber: '',
    subscribedProducts: [],    
    usersList: [],
    listOfAllUsers: [],
    billinglist: [],
    adminBillingTable: [],
    billingPeriod: {},
    paymentAmount: '',
    paymentDate: '',
    paymentlist: '',
    userDetails: {},
    latest_payment_amount: '',
    products: [],
    selectedPeriod: '',
    selectedCategory: '',
    authToken: {},
    selectedProductDetail: '',
    paymentType: '',
  },
  adminBillingTable: [],
  pendingVerificationList: [],
  allUsersList: [],
  productsList: [],
  paymentlist: [],
  updateNewClientDetails: {},
  pendingProductStatus: [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Invalid',
      value: 'invalid',
    },
    {
      label: 'Pending Approval',
      value: 'pending approval',
    },
  ],
  verfiedProductStatus: [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Off Boarded',
      value: 'offboarded',
    },
  ],
  pageAccess: [],
  landingPage: '',
  selectedProducts: [],
  currentLoadingPage: '',
  subscribedProducts: [],
  currentUserProducts: [],
  usersList: [],
  listOfAllUsers: [],
  apiProductsList: [
    // {
    //   name: 'API Quotes Binned - Premium',
    //   totalspace: '1M',
    //   uspace: '500000'
    // },
    // {
    //   name: 'API Trades Binned - Starter',
    //   totalspace: '2M',
    //   uspace: '850000'
    // },
    // {
    //   name: 'TAQ NBBO',
    //   totalspace: '3M',
    //   uspace: '1950000'
    // },
    // {
    //   name: 'MX Analytics',
    //   totalspace: '4M',
    //   uspace: '3250000'
    // },
    // {
    //   name: 'MX Trades',
    //   totalspace: '5M',
    //   uspace: '4250000'
    // }
  ],
  subscriber: {},
  signUpDetails: {},
  assignProducts: [],
  selectedClientDetails: {},
  isNewCard: false,
  monerisDetails: {},
  paymentDetails: [],
  saveCard: false,
  products: [],
  selectedPeriod: '',
  selectedCategory: '',
  // signUpDetails: {},
  users: [],
  selectedItem: {
    product: {}
  },
  summaryDetails: [],
  productNames: [],
  productsArray: [],
  defaultPage: '/',
  isFromBilling: false,
  usersListUpdated: false,
  taxAndPaymentUpdated: false,
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
export default function reducer(stateValue, action) {
  const state = stateValue || initialState;

  switch (action.type) {

    case FROM_BILLING_UPDATE:
      return {
        ...state,
        isFromBilling: action.data
      }

    case GET_CURRENT_USER_DETAILS:
      return {
        ...state,
        currentUser: action.data
      };

    case SELECTED_PRODUCTS_INITIATE:
      return {
        ...state,
        selectedProductDetail: action.data
      };  

    case UPDATE_PAYMENT:
      return {
        ...state,
        latest_payment_amount: action.data
      };

    case GET_ADMIN_CONSOLE_DETAILS:
      return {
        ...state,
        adminConsoleDetails: action.data.all_users_list,
        pendingVerificationDetails: action.data.users_verification_list,
        productStatus: action.data.product_status
      };

    case GET_CURRENT_BILLING_DETAILS:
      const currentUserValues = state.currentUser;
      currentUserValues.adminBillingTable = action.data;
      return {
        ...state,
        // billinglist: action.data.billinglist,
        currentUser: currentUserValues,
        adminBillingTable: action.data,
        billingPeriod: action.data.billingPeriod,
        last_payment_amt: action.data.last_payment_amt,
        last_payment_date: action.data.last_payment_date,
        // paymentlist: action.data.paymentlist,
        userDetails: action.data.userDetails,
        card_number: action.data.card_number,
        dataKey: action.data.dataKey
      };
    case GET_CARD_DETAILS:
      return {
        ...state,
        cardDetails: action.data,
      };

    case GET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: action.data.paymentDetails,
        billinglist: action.data.billinglist,
        paymentlist: action.data.paymentlist,
        billingListError: action.data.billingListError,
        paymentListError: action.data.paymentListError
      };

    case GET_CURRENT_ADMIN_DETAILS:
      return {
        ...state,
        subscribedProducts: action.data.subscribedProducts,
        usersList: action.data.usersList,
        listOfAllUsers: action.data.allUsers,
      };
    
    case USER_LIST_UPDATED:
      return {
        ...state,
        usersListUpdated: action.data,
      }
    
    case TAX_AND_PAYMENT_UPDATED:
      return {
        ...state,
        taxAndPaymentUpdated: action.data,
      }
    
    case CLEAR_SUBSCRIBED_PRODUCTS:
      const currentUserList = state.currentUser;
      currentUserList.selectedProducts = [];
      return {
        ...state,
        currentUser: currentUserList
      };

    case GET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.data.access_token,
      };

    // case PRODUCTS_DETAILS_INITIATE:
    //   return {
    //     ...state,
    //     selectedPeriod: action.data.selectedPeriod,
    //     products: action.data.products,
    //     selectedCategory: action.data.selectedCategory,
    //     productsArray: action.data.productsArray
    //   };

    case GET_ROLE_ACCESS_DETAILS:
      const { currentUser } = state;
      if(action.data && action.data.response && action.data.response.data.data){
        currentUser.errorMessage = action.data.response.data.data;
        return {
          ...state,
          currentUser
        };
      }else {
        currentUser.firstName = action.data[0].firstName;
        currentUser.lastName = action.data[0].lastName;
        currentUser.email = action.data[0].email;
        currentUser.roleType = action.data[0].roleType;
        currentUser.selectedProducts = action.data[0].selectedProducts;
        currentUser.clientId = action.data[0].clientId;
        currentUser.userId = action.data[0].userId;
        currentUser.access_token = action.data[0].access_token;
        currentUser.team = action.data[0] && action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].teamName ? action.data[0].clientDetails[0].teamName: null;
        currentUser.organization = action.data[0] && action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].clientName ? action.data[0].clientDetails[0].clientName: null;
        currentUser.addressLine1 = action.data[0] && action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].addressLine1 ? action.data[0].clientDetails[0].addressLine1: null;
        currentUser.addressLine2 = action.data[0] && action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].addressLine2 ? action.data[0].clientDetails[0].addressLine2: null;
        currentUser.city = action.data[0] &&  action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].city ? action.data[0].clientDetails[0].city: null;
        currentUser.provinceState = action.data[0] && action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].provinceState ? action.data[0].clientDetails[0].provinceState: null;
        currentUser.country = action.data[0] && action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].country ? action.data[0].clientDetails[0].country: null;
        currentUser.postalCode = action.data[0] && action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].postalCode ? action.data[0].clientDetails[0].postalCode: null;
        currentUser.paymentType = action.data[0] && action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].paymentType ? action.data[0].clientDetails[0].paymentType: null;
        currentUser.contactNumber = action.data[0] && action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].contactNumber ? action.data[0].clientDetails[0].contactNumber: null;
        currentUser.billingEmail = action.data[0] && action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].email ? action.data[0].clientDetails[0].email: null;
        currentUser.billingFirstName = action.data[0] && action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].firstName ? action.data[0].clientDetails[0].firstName: null;
        currentUser.billingLastName = action.data[0] && action.data[0].clientDetails && action.data[0].clientDetails[0] && action.data[0].clientDetails[0].lastName ? action.data[0].clientDetails[0].lastName: null;
      return {
        ...state,
        pageAccess: action.data[0].pageAccess,
        landingPage: action.data[0].landingPage,
        selectedProducts: action.data[0].selectedProducts,
        subscribedProducts: action.data[0].subscribedProducts,
        currentUserProducts: action.data[0].currentUserProducts,        
        usersList: action.data[0].usersList,
        listOfAllUsers: action.data[0].allUsers,
        usersListUpdated: true,
        currentUser
      };
    }

    case UPDATE_PROFILE_DETAILS:
      const profileUser = state.currentUser;
      profileUser.addressLine1 = action.data.addressLine1;
      profileUser.addressLine2 = action.data.addressLine2;
      profileUser.city = action.data.city;
      profileUser.provinceState = action.data.provinceState;
      profileUser.country = action.data.country;
      profileUser.postalCode = action.data.postalCode;
      profileUser.contactNumber = action.data.contactNumber;
      profileUser.billingEmail = action.data.email;
      profileUser.billingFirstName = action.data.firstName;
      profileUser.billingLastName = action.data.lastName;
    return {
      ...state,
      currentUser : profileUser
    }

    case UPDATE_CLIENT_ID:
      const currentUsers = state.currentUser;
      currentUsers.clientId = action.data;
      return {
        ...state,
        currentUser: currentUsers
      };

    case GET_API_PRODUCTS_LIST:
      return {
        ...state,
        apiProductsList: action.data.apiProductsList
      };

    case STORE_CURRENT_PAGE:
      return {
        ...state,
        currentLoadingPage: action.data
      };

    case GET_CONFIGURED_PRODUCTS:
      return {
        ...state,
        productsList: action.data
      };

    case ASSIGN_PRODUCTS:
      return {
        ...state,
        assignProducts: action.data
      };

    case UPDATE_SELECTED_CLIENT:
      return {
        ...state,
        selectedClientDetails: action.data
      };

    case ADMIN_SUMMARY_DETAILS:
      return {
        ...state,
        pendingVerificationList: action.data.pendingVerificationList,
        allUsersList: action.data.clientList,
      }

    case CLEAR_ALL_ADMIN_SUMMARY_DETAILS:
      return {
        ...state,
        pendingVerificationList: [],
        allUsersList: [],
      }

    case MONERIS_DETAILS:
      return {
        ...state,
        monerisDetails: action.data.monerisDetails,
        isNewCard: action.data.isNewCard,
        saveCard: action.data.saveCard,
      }


    case UPDATE_CLIENT_DETAILS:
      const user = state.currentUser;
      user.team = action.data[0] && action.data[0].teamName ? action.data[0].teamName: null;
      user.organization = action.data[0] && action.data[0].clientName ? action.data[0].clientName: null;
      user.addressLine1 = action.data[0] && action.data[0].addressLine1 ? action.data[0].addressLine1: null;
      user.addressLine2 = action.data[0] && action.data[0].addressLine2 ? action.data[0].addressLine2: null;
      user.city = action.data[0] && action.data[0].city ? action.data[0].city: null;
      user.provinceState = action.data[0] && action.data[0].provinceState ? action.data[0].provinceState: null;
      user.country = action.data[0] && action.data[0].country ? action.data[0].country: null;
      user.postalCode = action.data[0] && action.data[0].postalCode ? action.data[0].postalCode: null;
      user.paymentType = action.data[0] && action.data[0].paymentType ? action.data[0].paymentType: null;
      user.contactNumber = action.data[0] && action.data[0].contactNumber ? action.data[0].contactNumber: null;
      return {
        ...state,
        currentUser: user
      }
    
    case UPDATE_NEW_CLIENT_DETAILS:
      return {
        ...state,
        updateNewClientDetails: action.data
      }
      
      case UPDATE_CLIENT_TEAM_NAME:
        const userDetails = state.currentUser;
        userDetails.team = action.data.teamName;
        userDetails.organization = action.data.clientName;
        return {
          ...state,
          currentUser: userDetails
        }

    case SIGN_UP_INITIATE:
      return {
        ...state,
        signUpDetails: action.data
      };

    case SUMMARY_DETAILS_INITIATE:
      return {
        ...state,
        summaryDetails: action.data
      }

    case BILLING_DETAILS_INITIATE:
      return {
        ...state,
        billingDetails: action.data
      };

    case USER_DETAILS_INITIATE:
      return {
        ...state,
        users: action.data
      };

    case RECIEVE_ALL_DETAILS:
      return {
        ...state
      };

    case PRODUCTS_DETAILS_INITIATE:
      return {
        ...state,
        selectedPeriod: action.data.selectedPeriod,
        products: action.data.products,
        selectedCategory: action.data.selectedCategory,
        productNames: action.data.productNames,
        productsArray: action.data.productsArray
      };

    case SUBSCRIBER_DETAILS:
      return {
        ...state,
        subscriber: action.data
      };

    case SIGN_UP_MONERIS_DETAILS:
      return {
        ...state,
        monerisDetails: action.data
      };
    case TAX_RATE_DETAILS:
      return {
        ...state,
        taxRateDetails: action.data
      }
    case TAX_RATE_DETAILS_ADMIN:
      return {
        ...state,
        taxRate: action.data
      }

    case REMOVE_ALL_SIGNUP_DETAILS:
      return {
        ...state,
        signUpDetails: {},
        billingDetails: {},
        monerisDetails: {},
        products: [],
        users: [],
        subscriber: {},
        selectedPeriod: '',
        selectedCategory: '',
        productNames: [],
        productsArray: [],
        summaryDetails: [],
      }
      
    default:
      return state;
  }
}
