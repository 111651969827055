/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../i18n';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

class ProductsLayout extends Component {
    t = this.props.t;

    state = {
      selectedproductCategory: '',
      isSelected: false
    };

    formatAmount(x) {
      let value = parseFloat(x).toLocaleString('en-US' ,{
        style: 'currency',
        currency: 'USD',//currency: 'CAD'
        maximumFractionDigits: 2
      })
      return value;
    }

    render() {
      let list = this.props.productDescription || '';
      return (
        <>
            {
              !this.props.isBillingPage
                ? <Card className={`recommendedproducts_card_layout1 card-layout ${this.props.descriptions ? 'api-card-layout' : ''}`}>
                <Card.Body className={`${this.isSelected() ? 'blue_border' : ''}`}>
                  <Card.Title><a href={this.props.productUrl} target="_blank" rel="noopener noreferrer" className="title_know_more_link">{this.props.productName}</a></Card.Title>
                  {
                  this.props.packageType ? <Card.Subtitle className="mb-2 price_font_change pack_font"> {this.props.packageType}  </Card.Subtitle> : <Card.Subtitle className="mb-2 price_font_change pack_font">  </Card.Subtitle>
                  }
                  {typeof list === 'object'
                    ? list.map((desc,i) => <ul key={i} className="product-description-ul">
                    {/* <p className="description-tick">&#10003;</p> */}
                    <li className="description-point">{desc}</li>
                    </ul>)
                    : <Card.Text><p className="description-tick" hidden={list === ''}>&#10003;</p><p className="description-point">{list}</p></Card.Text>
                  }
                  <div className="card-select-btn"><Button className={`select_button ${this.isSelected() ? 'blue_background' : ''}`} onClick={this.props.addProduct}>{this.isSelected() ? 'SELECTED' : 'SELECT'}</Button></div>
                  {this.props.descriptions && <div className="footer-content">{this.t('products.liquidSymbols')}</div>}
                  
              </Card.Body>
              <button onClick={this.props.removeProduct} className={`btn btn-info x_button ${this.isSelected() ? 'x-button-selected' : ''}`}>X</button>
              </Card>
                : <Card className="recommended-products-card-layout">
                {
                !this.props.addMoreProducts
                  ? <Card.Body>
                    <Card.Title><a href="/payment" target="_blank" rel="noopener noreferrer" className="title_know_more_link">{this.t(`products.${this.props.productName}`)}</a></Card.Title>
                    <Card.Subtitle className="mb-2 price_font_change">{this.formatAmount(this.props.productCost)}/<sub>{this.props.productDuration}</sub></Card.Subtitle>
                    <Card.Text>{this.props.productDescription}</Card.Text>
                    <Button className="btn btn-primary select_button" onClick={this.props.addProduct}>SELECT</Button>
                    </Card.Body>
                  : <Card.Body>
                    <Card.Title className="mb-2 addmore_change">.</Card.Title>
                    <Card.Text className="mb-2 addmore_change">.</Card.Text>
                    <Card.Subtitle className="mb-2 addmore_change">.</Card.Subtitle>
                    <Card.Title className="hyperlink_color_change add_more"><span><Link to="/admin-products" isComesFromBilling={true} className="addmore_products">{'Add More Products..'}</Link></span></Card.Title>
                  </Card.Body>
                }
                </Card>
            }
        </>
      );
    }

    isSelected = () => {
      let isSelected = false;
      const { selectedproductCategory } = this.props;
      if (selectedproductCategory && selectedproductCategory.length) {
        // eslint-disable-next-line no-plusplus
        for (let s = 0; s < selectedproductCategory.length; s++) {
          if (selectedproductCategory[s]
            && selectedproductCategory[s].subCategory === this.props.selectedProduct
            && selectedproductCategory[s].id === this.props.id) {
            isSelected = true;
          }
        }
      }
      return isSelected;
    }
}

export default withNamespaces()(ProductsLayout);
