/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { trackPromise } from 'react-promise-tracker';
import BannerImage from '../common/bannerImage';
// import validation from '../../assets/properties/validation.json';
import { store, fetch } from '../../../utils/httpUtil';
import apiUrl from '../../../utils/apiUrl';
import ErrorMessage from '../common/errorMessage';
import { removeAllSignUpDetails } from '../../../actions/index';
import { getLocalStorage } from '../../../utils/storageUtil';

const INITIAL_STATE = {
  client_name: '',
  clientId: 0,
  flag: false,
  selected: false,
  aboutUsOptions: [],
  enableSubmit: false,
  referredBy: '',
  resultMessage:'',
  isAlertNotRequired: true
};

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;

    this.setSubmit = this.setSubmit.bind(this);
    if (this.props.signUpDetailsSaved && !this.props.signUpDetailsSaved.first_name) {
      this.props.history.push(this.props.defaultPage);
    }
  }

  setSubmit() {
    const stateNew = this.state;
    const clientAdminId = this.state.clientId;
    const access_token = getLocalStorage('access_token');
    const request = {
      clientId: clientAdminId,
      referredBy: this.state.referredBy,
      token: access_token,
    };
    trackPromise(store(apiUrl.updateClientReferredByOption, request).then(() => {
      // if (res && res.data && res.data.data && res.data.data.aboutUsOptions) {
      //   this.setState({ aboutUsOptions: res.data.data.aboutUsOptions });
      // }
      sessionStorage.removeItem('jwt_token');
      stateNew.selected = true;
      this.setState({
        stateNew
      }); 
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

  componentDidMount() {
    if (this.props.signUpDetails && this.props.signUpDetails.first_name) {
      this.setState({
        client_name: this.props.signUpDetails.client_name,
      });
    }
    if (this.props.location && this.props.location.state && this.props.location.state.clientId) {
      this.setState({
        clientId: this.props.location.state.clientId 
      }, () => {
        this.props.removeAllSignUpDetails();
      });
    }

    trackPromise(fetch(apiUrl.getAboutUsOptions).then((res) => {
      if (res && res.data && res.data.data && res.data.data.aboutUsOptions) {
        this.setState({ aboutUsOptions: res.data.data.aboutUsOptions });
      }
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

  handleInputChange(e) {
    this.setState({
      enableSubmit:true,
      referredBy: e.target.value,
    });
  }

  render() {
    const { t } = this.props;
    const flag = this.props.summaryFlag || this.state.flag;
    return (
      <>
      <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1 col-md-1 col-sm-2 col-xs-1"></div>
          <Jumbotron className="col-lg-4 col-md-10 col-sm-8 col-xs-12 jumbotron_sample_confirmation">
          {flag
            ? <div className="msg_cnf">
              <span> {'Sign Up process has failed due to some technical glitch! Please try after sometime or write a email at data.support@tmx.com.'}</span>
            </div>
            : ''}
            <div>{!flag
              ? <div><div className="cnf_heading_lbl">{t('confirmation.thankyou_stmt')}</div>
                <div className="confirmation_msg">
                  {t('confirmation.confirm_statment1')}
                </div>
                <div className="confirmation_msg">
                <p>
                  {t('confirmation.confirm_statment2')}
                </p>
              </div>
                </div>
              : '' }
              <div className="confirmation-refernce-alignment">
               {!this.state.selected ? <div className="quest_section">
               <div className="question_sel">
                 <label className="quest_lbl" htmlFor="question">{t('confirmation.quest_stmt')}</label>
                 <select
                   className="cnf_select_btn"
                   name="question"
                   onChange={(e) => this.handleInputChange(e)}
                 >
                   <option className="select_option_payment" selected>
                     {t('confirmation.abt_us_question')}
                   </option>
                   {this.state.aboutUsOptions.map((option, i) => (
                      <option key={i} className="select_option_payment" value={option} >
                        {option}
                      </option>
                   ))}
                 </select>
               </div>
               <button className="btn cnf_inline_submit_btn" disabled={!this.state.enableSubmit} onClick={() => this.setSubmit()}>
                   {t('confirmation.submit_button')}
               </button>
               </div> : ''}
               <div className="cnf_button_flex">
                <button className="btn cnf_outline_btn" hidden>
                  <a className="outline_link" href="/">
                    {t('confirmation.home_button')}
                  </a>
                </button>                
                <button className="btn cnf_inline_btn" hidden>
                <a className="inline_link" href="/login">
                  {t('confirmation.login_button')}
                  </a>
                </button>
                {/* <div className="confirmation_msg">
                <p>
                  {t('confirmation.login_statment')}<a className="inline_link1" href="/login">{t('confirmation.login_link')}</a>
                </p>
                </div> */}
               </div>
               </div>
               </div>
            <div>
            {this.state.selected
              ? <div>
               <div className="cnf_feedback_panel">&#10003;&nbsp; {t('confirmation.feedback_stmt')}</div>
              </div>
              : ''
            }
            </div>
          </Jumbotron>
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
          <BannerImage className="confirmBannerImage" isConfirmation={false} />
        </div>
      </div>
      </>
      );
  }
}

const mapStateToProps = (state) => ({
  signUpDetailsSaved: state.crud.signUpDetails,
  defaultPage: state.crud.defaultPage
});

const mapDispatchToProps = (dispatch) => ({
  removeAllSignUpDetails: () => dispatch(removeAllSignUpDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Confirmation));
