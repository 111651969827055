/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
import apiUrl from '../../../utils/apiUrl';
import { Link } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import validation from '../../../assets/properties/validation.json';
import { store } from '../../../utils/httpUtil';
import bannerImage from '../../../assets/img/TMX-ANALYTICS-IMG.png';
// import BannerImage from '../common/bannerImage';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAPISuccess: true,
      isAPISuccessMessage: '',
      resultMessage: '',
      isRedAlertNotRequired: true,
      isButtonDisabled: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  async resetPassword(e) {
    e.preventDefault();
    this.setState({
      isButtonDisabled: true,
    });
    const email = document.getElementById('email').value;
    await trackPromise(store(apiUrl.resetPassword, {email}).then((resetPasswordInitiated) => {
        if (resetPasswordInitiated.data && resetPasswordInitiated.data.data && resetPasswordInitiated.data.data.statusCode && resetPasswordInitiated.data.data.statusCode === 200) {
          this.setState({
            isAPISuccess: false,
            isAPISuccessMessage: resetPasswordInitiated.data.data.message,
          });
        }
    }).catch(() => {
        this.setState({
          isButtonDisabled: false,
        });
    }));
  }

  checkMailRegex (value) {
    const splittedValueOne = value.split('@');
    const splittedValue = splittedValueOne[0].split('');
    const splittedValueLength = splittedValue.length;
    if (splittedValue[0] === '.' || splittedValue[splittedValueLength-1] === '.') {
      return true;
    } else {
      if (splittedValueOne.length > 1) {
        const splittedValueTwo = splittedValueOne[0].split('..');
        if (splittedValueTwo.length > 1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }
  }

  handleChange() {
    const email = document.getElementById('email').value;
    let resultMessage; let isRedAlertNotRequired; let isButtonDisabled;
    if (email) {
      const pattern = new RegExp(validation[`email_regx`]);
      if (!pattern.test(email)) {
        resultMessage = 'Please enter valid Email Address';
        isRedAlertNotRequired = false;
        isButtonDisabled = true;
      } else {
        resultMessage = '';
        isRedAlertNotRequired = true;
        isButtonDisabled = false;
      }
      const testRegex = this.checkMailRegex(email);
      if (testRegex) {
        resultMessage = 'Please enter valid Email Address';
        isRedAlertNotRequired = false;
        isButtonDisabled = true;
      }
      this.setState({
        resultMessage,
        isRedAlertNotRequired,
        isButtonDisabled,
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
          <div className="container-fluid">
            <div className="row">
            <div className="col-lg-1 col-md-1"></div>
            <Jumbotron className="col-lg-4 col-md-10 col-sm-8 col-xs-8 resetpwd_jumbotron_sample">
            <Link className="payment_console_back_link" to="/login"><h6>{t('login.back_button')}</h6></Link>
                <h2 className="signup_header_alignment">
                  {t('login.reset_password_title')}
                </h2>
              <form autoComplete="off">
                <div className="form-row emailreset-changes">
                    <div className="form-group col-md-12 col-lg-12 left_labels_alignment">
                    <label htmlFor="emailaddress">{t('login.email_address_lbl')}</label>&nbsp;<span className="required-flag">*</span>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        autoComplete="off"
                        onChange={this.handleChange}
                        placeholder={t('login.email_address_lbl')}
                        required={true}
                        maxLength={100}
                    />
                    </div>
                    <div className="text-danger-alert" hidden={this.state.isRedAlertNotRequired}>{this.state.resultMessage}</div>
                    <div className="text-success-alert" hidden={this.state.isAPISuccess}>{this.state.isAPISuccessMessage}</div>
                </div>
                {/* <div className="form-row">
                    <div className="form-group col-md-12 col-lg-12 signup-link-alignment">
                        <span>{t('login.back_to_login')}<a href='/login' className="download-bill-link">{t('login.click_here')}</a></span>
                    </div>
                </div> */}
                <div className="form-row reset-changes">
                    <div className="form-group col-md-12 col-lg-12 signintext-link-alignment">
                    <center><button className="btn btn-info next_button" onClick={this.resetPassword} disabled={this.state.isButtonDisabled}>{t('login.resetlink')}</button></center>
                    </div>
                </div>
              </form>
            </Jumbotron>
            <div className="col-lg-1 col-md-1"></div>
              <div className="col-lg-4 col-md-12 col-sm-8 col-xs-8">
                  <img className='loginbannerimage' src={bannerImage}/>
              </div>
            </div>
          </div>
          </>
    );
  }
}

export default (withNamespaces()(ResetPassword));
