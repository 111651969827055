/* eslint-disable no-undef */
import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';
import { clearLocalStorage } from '../utils/storageUtil';

import createRootReducer from '../reducers';
import history from '../utils/history';
import sessionTimeOut from '../../src/assets/properties/sessionTimeOut.json';

export { history };

const middlewares = [thunkMiddleware, logger, routerMiddleware(history)];

const pageWithoutReloadingTime = [
  '',
  'signup',
  'login',
  'resetpassword',
  'products',
  'signup-payment',
  'billing',
  'signup-subscriber',
  'signup-summary',
  'confirmation',
];

const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if(serializedState === null) {
      return undefined;
    }
    let sessionExpired = sessionStorage.getItem('sessionExpired');
    sessionExpired = (sessionExpired === 'true');
    if (sessionExpired) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
    // const hours = 1; // Reset when storage is more than 24hours
    const now = new Date().getTime();
    let setupTime = sessionStorage.getItem('setupTime');
    let locationURL = window.location.href.split('/');
    let exactURL = locationURL[locationURL.length-1];
    // hours*60*60*1000
    if (setupTime == null) {
      sessionStorage.setItem('setupTime', now)
    } else {
      setupTime = Number(setupTime);
      if(now-setupTime > sessionTimeOut.minutes*sessionTimeOut.seconds*sessionTimeOut.milliseconds && !pageWithoutReloadingTime.includes(exactURL)) {
        clearLocalStorage('state');
        clearLocalStorage('access_token');
        localStorage.clear();
        sessionStorage.clear();
        sessionStorage.setItem('sessionExpired', true);
        sessionStorage.setItem('setupTime', now);
        window.location.reload();
        window.location.href = '/login';
      } else {
        sessionStorage.setItem('setupTime', now);
      }
    }
  } catch (e) {
    // Ignore write errors;
  }
};

const peristedState = loadState() || {};

const store = createStore(createRootReducer(history), peristedState, compose(
  applyMiddleware(...middlewares)
));

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
