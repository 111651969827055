/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import { connect } from 'react-redux';
import Cryptr from 'cryptr';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { trackPromise } from 'react-promise-tracker';
import apiUrl from '../../../utils/apiUrl';
import { store } from '../../../utils/httpUtil';
import { setLocalStorage } from '../../../utils/storageUtil';
import { removeloginerrormsg, roleAccessDetails, removeAllSignUpDetails } from '../../../actions/index';
import { setSessionStorage } from '../../../utils/storageUtil';
import validation from '../../../assets/properties/validation.json';
// import BannerImage from '../common/bannerImage';
import { Link } from 'react-router-dom';
import bannerImage from '../../../assets/img/TMX-ANALYTICS-IMG.png';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {
        email: '',
        password: '',
        encryptedPassword: '',
        isLoginFail: true,
        isLoginFailMessage: ''
      },
      errors: {
        password: 'password_req_err_msg',
        email: 'email_req_err_msg'
      },
      touched: {},
      isValid: false,
     showResetLink: false,
     resetLink:""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleTouch = this.handleTouch.bind(this);
    this.loginInitiator = this.loginInitiator.bind(this);
    this.encryptPassword = this.encryptPassword.bind(this);
    this.props.removeloginerrormsg();
    this.props.removeAllSignUpDetails();
  }

  encryptPassword() {
    const { input } = this.state;
    let { isValid } = this.state;
    const cryptr = new Cryptr('myTotalySecretKey');
    let encryptedString = cryptr.encrypt(this.state.input.password);
    if(encryptedString) {
      input.encryptedPassword = encryptedString;
      isValid = isValid ? isValid : true;
      this.setState({
        input, isValid
      })
    }
  }

  handleTouch(e) {
    const { touched } = this.state;
    if (e.target.name && touched[e.target.name] !== true) {
      touched[e.target.name] = true;
      this.setState({
        touched
      });
    }
    // if(e.target.type === 'password') {
    //   this.encryptPassword(e);
    // }
  }

  validateAllFields() {
    const { errors } = this.state;
    const AllErrors = Object.values(errors);
    const isValid = AllErrors.every((arr) => !arr);
    this.setState({
      isValid
    });
  }

  loginInitiator(e) {
    e.preventDefault();
    this.validateForm(e);
    this.encryptPassword(e);
    if (this.state.isValid) {
      const loginRequest = {
        email: this.state.input.email,
        password: this.state.input.encryptedPassword
      }
      trackPromise(store(apiUrl.login, loginRequest).then((res) => {
          setLocalStorage('access_token', res.data.data[0].access_token);
          setSessionStorage('jwt_token',res.data.data[0].csrfToken);
          if (res && res.data && res.data.data && (res.data.status === 1)) {
            if (!this.state.firstLoad) {
              // window.location.reload();
              this.setState({
                firstLoad: true
              });
            }

            sessionStorage.setItem('sessionExpired', false);
            this.props.roleAccessDetails(res.data.data);
          }
        }).catch((err) => {
          var errorText = err.response.data.data.response.text;
          var errorMessage, errorMessageSplit;
          errorText = JSON.parse(errorText);
          if (errorText && errorText.message) {
            errorMessageSplit = errorText.message.split(': ');
            errorMessage = errorMessageSplit[1];
          }
          if (errorText && errorText.Message) {
            errorMessageSplit = errorText.Message.split(': ');
            errorMessage = errorMessageSplit[0];
          }
          let isResetLink = false;
          let resetLink = "";
          sessionStorage.removeItem("changePasswordEmail");
          if(errorMessage == "Password has expired."){
            sessionStorage.setItem("changePasswordEmail",this.state.input.email)
            isResetLink = true;
            errorMessage = "Your password has been expired, please click on the ‘Reset Password’ to reset it.";
            resetLink = errorText.link;
          }
          this.setState({
            isLoginFail: false,
            isLoginFailMessage: errorMessage,
            showResetLink:isResetLink,
            resetLink: resetLink
          });
        }));
      }
    }

  handleChange(e) {
    const { input } = this.state;
    input[e.target.name] = e.target.value;
    this.setState({
      input
    }, () => {
      this.validateAllFields(e);
    });
    if(e.target.type !== 'password') {
      this.handleTouch(e);
    }    
    this.validateForm(e);
  }

  validateForm(e) {
    const { name, value } = e.target;
    const { errors } = this.state;
    let isValid = true;

    if (!value || value === undefined || value === '') {
      isValid = false;
      errors[`${name}`] = e.target.required ? `${name}_req_err_msg` : '';
    }

    if (typeof value !== 'undefined' && value !== '') {
      const pattern = new RegExp(validation[`${name}_regx`]);
      if (!pattern.test(value)) {
        isValid = false;
        errors[`${name}`] = `${name}_invalid_err_msg`;
      } else {
        errors[`${name}`] = '';
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  componentDidUpdate() {
    let sessionExpired = sessionStorage.getItem('sessionExpired');
    sessionExpired = (sessionExpired === 'true');
    if(sessionExpired) {
      return;
    }
    if (this.props.pageAccess && this.props.pageAccess.length > 0) {
      this.props.initiateBody();
      this.props.history.push(this.props.landingPage);
    }
  }

  render() {
    const { t } = this.props;
    // let message = this.props.currentUserDetails.errorMessage;
    return (
      <>
          <div className="container-fluid">
            <div className="row">
            <div className="col-lg-1 col-md-1"></div>
            <Jumbotron className="col-lg-4 col-md-10 col-sm-8 col-xs-8 jumbotron_sample">
              <h2 className="signup_header_alignment">
                  {t('login.title')}
                </h2>
              <form autoComplete="off">
              <div className="form-row">
                <div className="form-group col-md-12 col-lg-12 left_labels_alignment">
                  <label htmlFor="emailaddress">{t('login.email_address_lbl')}</label>&nbsp;<span className="required-flag">*</span>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={this.state.input.email}
                    autoComplete="off"
                    placeholder={t('login.email_address_lbl')}
                    onChange={this.handleChange}
                    onBlur = {(e) => this.handleTouch(e)}
                    required={true}
                    maxLength={100}
                  />
                  <div className="text-danger-alert">{this.state.touched.email && this.state.errors.email && t(`login.${this.state.errors.email}`)}</div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12 col-lg-12 left_labels_alignment">
                  <label htmlFor="password">{t('login.password_lbl')}</label>&nbsp;<span className="required-flag">*</span>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={this.state.input.password}
                    autoComplete="off"
                    placeholder={t('login.password_lbl')}
                    onChange={this.handleChange}
                    onBlur = {(e) => this.handleTouch(e)}
                    required={true}
                    maxLength={100}
                  />
                  <div className="text-danger-alert">{this.state.touched.password && this.state.errors.password && t(`login.${this.state.errors.password}`)}</div>
                  <div className="text-danger-alert" hidden={this.state.isLoginFail}>{this.state.isLoginFailMessage}</div>
                </div>
              </div>

              <div className="form-check left_labels_alignment">
              {
                this.state.showResetLink ?  <span> <Link to='/changepassword' className="forgot-password">{t('login.resetpassword')}</Link></span> :
                <span><Link to='/resetpassword' className="forgot-password">{t('login.forgot_password')}</Link></span>
              }
                </div>

                <div className="form-row">
                    <div className="form-group col-md-12 col-lg-12 signup-link-alignment">
                        <span>{t('login.dont_have_account')}<a href='/signup' className="download-bill-link">{t('login.sign_up')}</a></span>
                    </div>
                </div>

            <div className="form-row">
                    <div className="form-group col-md-12 col-lg-12 signintext-link-alignment">
                    <center><button onClick={this.loginInitiator} disabled={!this.state.isValid} className="btn btn-info next_button" >{t('login.i_agree_signin')}</button></center>
                    </div>
                </div>

              </form>
            </Jumbotron>
            <div className="col-lg-1 col-md-1"></div>
              <div className="col-lg-4 col-md-12 col-sm-8 col-xs-8">
                  <img className='loginbannerimage' src={bannerImage}/>
              </div>
            </div> 
          </div>
          </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  roleAccessDetails: (details) => dispatch(roleAccessDetails(details)),
  removeAllSignUpDetails: (details) => dispatch(removeAllSignUpDetails(details)),
  removeloginerrormsg: () => dispatch(removeloginerrormsg()),
});

const mapStateToProps = (state) => ({
  pageAccess: state.crud.pageAccess,
  landingPage: state.crud.landingPage,
  currentUserDetails: state.crud.currentUser
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Login));
