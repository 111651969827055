/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import Jumbotron from 'react-bootstrap/Jumbotron';
import SubscribedProducts from '../common/subscribedProducts';
import { adminDetails, storeAssignProducts } from '../../../actions/index';
import { getLocalStorage } from '../../../utils/storageUtil';
import apiUrl from '../../../utils/apiUrl';
import { store } from '../../../utils/httpUtil';
import { storeCurrentPage, taxRateDetails } from '../../../actions/index';
import ErrorMessage from '../common/errorMessage';

class AllocateProducts extends Component {
  state = {};

  t = this.props.t;

  constructor(props) {
    super(props);
    this.state = {
        userslist: [],
        currentSubscribedProducts: [],
        selectedProductId: '',
        resultMessage: '',
        isAlertNotRequired: true,
        nextButtonDisabled: true,
    };
    this.selectUser = this.selectUser.bind(this);
    this.storeAssignProductsForUsers = this.storeAssignProductsForUsers.bind(this);
    this.blockNextButton = this.blockNextButton.bind(this);
    this.assignAllAPIProductsToAllUsers = this.assignAllAPIProductsToAllUsers.bind(this);
    // this.props.getadminDetails();
    // this.getAllUsers();
    this.props.taxRateDetails([]);
  }

  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
      this.props.history.push(this.props.currentLoadingPage);
      this.props.storeCurrentPage('');
    }
  }

  setSelectedProduct = (element) => {
    // const usersList = this.props.currentUsersList && this.props.currentUsersList.length ? this.props.currentUsersList : this.props.currentUserDetails.usersList;
    this.setState({
      selectedProduct: element,
      selectedProductId: element.id
    },() => {
      this.checkSelected('', element);
    });
  }

  assignAPIProductToAllUsers(productElement) {
    let { userslist } = this.state;
    for(let i=0; i<userslist.length; i++) {
      let element = userslist[i];
      if(element) {
        const index = element.selectedProducts.findIndex(x => x === productElement.id);
        if (index === -1) {
            element.selectedProducts.push(productElement.id);
        }
      }
    }
    this.setState({
      userslist,
      selectedProduct: productElement,
      selectedProductId: productElement.id,
    }, () => {
      this.blockNextButton();
    });
  }

  async getAllUsers() {
    if (this.props.assignProducts && this.props.assignProducts.length === 0) {
      const request = {
        clientId: this.props.currentUserDetails.clientId,
        token: this.props.currentUserDetails.access_token,
      } 
      await trackPromise(store(apiUrl.getAllUsers, request).then((allUsers) => {
        if (allUsers && allUsers.data.data) {
          let userslist = this.props.currentUsersList && this.props.currentUsersList.length ? this.props.currentUsersList : this.props.currentUserDetails.usersList;
          userslist = userslist.map((user) => {
            const users = allUsers.data.data.filter(function( currentUser ) {
              return user.userId === currentUser.userId;
            });
            if (users && users[0] && users[0].userId) {
              user.userStatus = users[0].isActive
              user.selectedProducts = [];
              return user
            }
          });
          userslist = userslist.filter(function( user ) {
            return user && user.userStatus;
          });
          this.setState({
            userslist,
          },() => {
            this.blockNextButton();
            this.assignAllAPIProductsToAllUsers();
          });
        }
      }).catch(() => {
        window.scrollTo(0, 0);
      }));
    }
  }

  selectUser(element, event) {
    let { userslist } = this.state;
    const checkBoxValue = event.target.checked;
    if (checkBoxValue) {
        // const index = element.selectedProducts.findIndex(x => x === this.state.selectedProductId);
        // if (index === -1) {
        //     element.selectedProducts.push(this.state.selectedProductId);
        // }
        this.props.currentSubscribedProducts.map((products) => {
          const index = element.selectedProducts.findIndex(x => x === products.id);
          if (index === -1) {
              element.selectedProducts.push(products.id);
          }
          // element.selectedProducts.push(products.id);
        });
    } else {
        // const index = element.selectedProducts.findIndex(x => x === this.state.selectedProductId);
        // if (index > -1) {
        //     element.selectedProducts.splice(index, 1);
        // }
        this.props.currentSubscribedProducts.map((products) => {
          const index = element.selectedProducts.findIndex(x => x === products.id);
          if (index > -1) {
              element.selectedProducts.splice(index, 1);
          }
        });
    }
    const userIndex = userslist.findIndex(x => x.userId === element.userId);
    userslist[userIndex] = element;
    this.setState({
        userslist
    }, () => {
      this.blockNextButton();
    });
  }

  componentDidMount() {
    if (!this.props.usersListUpdated && this.props.currentUserDetails.userId && this.props.currentUserDetails.clientId) {
      let details = {
        userId: this.props.currentUserDetails.userId,
        clientId: this.props.currentUserDetails.clientId
      }
      this.props.getadminDetails(details);
    }
    let userslist;
    if (this.props.assignProducts && this.props.assignProducts.length > 0) {
      userslist = this.props.assignProducts;
    } else {
      let allUsers = this.props.listOfAllUsers;
      if (allUsers && allUsers.length > 0) {
        userslist = this.props.currentUsersList && this.props.currentUsersList.length ? this.props.currentUsersList : this.props.currentUserDetails.usersList;
        userslist = userslist.map((user) => {
          const users = allUsers.filter(function( currentUser ) {
            return user.userId === currentUser.userId;
          });
          if (users && users[0] && users[0].userId) {
            user.userStatus = users[0].isActive
            user.selectedProducts = [];
            return user
          }
        });
        userslist = userslist.filter(function( user ) {
          return user && user.userStatus;
        });
      }
    }
    const currentSubscribedProducts = this.props.currentSubscribedProducts;
    this.setState({
      userslist,
      currentSubscribedProducts,
    }, () => {
      // this.blockNextButton();
      this.assignAllAPIProductsToAllUsers();
    });
    this.setSelectedProduct(currentSubscribedProducts[0]);
    this.getTaxRate(this);
  }

  getTaxRate(e) {
    const postData = e.props.allDetails.currentUser;
    const rateObj = {
      country: postData.country,
      region: postData.provinceState,
      postal: postData.postalCode
    };
    trackPromise(store(apiUrl.getTaxRateForAdmin, { rateObj }).then((taxRes) => {
      let taxRate = 0.00;
      let taxResponse = [];
      if(taxRes && taxRes.data && taxRes.data.data && taxRes.data.data.length > 0) {
        taxResponse = taxRes.data.data;
        for(let t=0; t<taxResponse.length; t++) {
          if(taxResponse[t]) {
            taxRate = taxRate + taxResponse[t].taxRate;
          }
        }
        taxRate = taxRate * 100;
        this.props.taxRateDetails({
          taxRate: taxRate,
          taxRateDetails: taxResponse
        });
        this.setState({
          taxRate: taxRate,
          taxRateDetails: taxResponse
        });
      } else {
        taxResponse = [{
          "applicableAddressType": "DEFAULT",
          "effectiveEndDate": moment(new Date()).toString(),
          "effectiveStartDate": moment(new Date()).toString(),
          "taxRate": 0.00,
          "taxAuthorityCode": "DEFAULT",
          "taxAuthorityLevel": "DEFAULT",
          "taxAuthorityType": "DEFAULT",
          "taxAuthorityName": "DEFAULT",
          "taxRateType": "DEFAULT"
        }];
        this.props.taxRateDetails({
          taxRate: 0.00,
          taxRateDetails: taxResponse
        });
        this.setState({
          taxRate: taxRate,
          taxRateDetails: taxResponse
        });
      }
    }).catch((err) => {
      console.log(err);
    }));
  }

  // neutralizingList(item) {
  //   console.log('item', item);
  //   this.props.currentSubscribedProducts.map((value) => {
  //     console.log('value.keyName', value.keyName);
  //     document.getElementById(value.keyName).checked = false;
  //     return null;
  //   });
  //   this.editApplicableProducts(item);
  // }

  // editApplicableProducts(item) {
  //     console.log('***********item', item);
  //   for (let i = 0; i < item.subscribedProducts.length; i++) {
  //     this.props.currentSubscribedProducts.map((value) => {
  //       if (item.subscribedProducts[i] === value.keyName) {
  //         document.getElementById(value.keyName).checked = true;
  //       }
  //       return null;
  //     });
  //   }
  //   const modal = document.getElementById('editApplicableProducts');
  //   modal.style.display = 'block';
  // }

  // closeEditUser() {
  //   const newmodal = document.getElementById('editApplicableProducts');
  //   newmodal.style.display = 'none';
  // }

  closeWarningPopup() {
    const newmodal = document.getElementById('openTMXAdminPopup');
    newmodal.style.display = 'none';
  }

  checkSelected(products) {
    // if (element && element.subCategory && element.subCategory === 'API') {
    //   this.assignAPIProductToAllUsers(element);
    //   return true;
    // }
    if(!products.length) return false;
    const index = products.findIndex(x => x === this.state.selectedProductId);
    if (index > -1) {
        return true;
    } else {
        return false;
    }
  }

  openTMXAdminPopup() {
    const modal = document.getElementById('openTMXAdminPopup');
    modal.style.display = 'block';
    this.storeAssignProductsForUsers();
  }

  storeAssignProductsForUsers() {
    this.props.storeAssignProducts(this.state.userslist);
  }

  saveInitiator() {
    this.props.history.push('/summary');
    // this.createPackage(this);
  }

  createPackage(e) {
    const postData = e.props.allDetails;
    postData.subscriber.signedOnName = postData.currentUser.email;
    postData.subscriber.agreedOn = new Date();
    const postObject = {
      products: postData.products,
      clientId: postData.selectedClientDetails.clientId,
      // users: _.map(postData.users, (object) => _.pick(object, ['first_name', 'last_name', 'email'])),
      subscriberDetails: postData.subscriber,
      users: this.state.userslist,
      signUpDetails: {
        email: postData.currentUser.email,
      },
      monerisDetails: {
        skipMonerisFlow: this.props.cardDetails ? false : true,
        dataKey: this.props.cardDetails ? this.props.cardDetails.respDataKey : '',
        currency: this.props.cardDetails ? this.props.cardDetails.cardCurrency : 'CAD',
        issuerId: this.props.cardDetails ? this.props.cardDetails.issuerId : '',
        cardDetails: this.props.cardDetails,
      },
      billingDetails: {
        selectedCountry: postData.currentUser.country,
        selectedProvince: postData.currentUser.provinceState
      },
      taxRate: this.state.taxRate,
      taxRateDetails: this.state.taxRateDetails
    };
    const access_token = getLocalStorage('access_token');
    postObject.token = access_token;
    postObject.users = postObject.users.map((users) => {
      if (users && users.selectedProduct) {
        delete users.selectedProduct;
      }
      return users;
    });
    trackPromise(store(apiUrl.updateSubscriptions, { postObject }).then((/* res */) => {
      this.closeWarningPopup();
      // this.props.history.push('/confirmation');
    }).catch((err) => {
      // this.props.history.push('/confirmation');
      this.closeWarningPopup();
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

  blockNextButton() {
    let nextButtonDisabled = true;
    const userSelectedProductList = this.state.userslist && this.state.userslist.map((user) => {
      if (user && user.selectedProducts && user.selectedProducts.length > 0) {
        return user.selectedProducts;
      }
    });
    const usersNotSelectedFullList = [].concat.apply([], userSelectedProductList);
    const usersNotSelectedUniqueList = _.uniq(usersNotSelectedFullList);
    let usersNotSelected = usersNotSelectedUniqueList.filter(function( element ) {
      return element !== undefined;
    });
    nextButtonDisabled = usersNotSelected && usersNotSelected.length === this.state.currentSubscribedProducts.length ? false : true;
    this.setState({
      nextButtonDisabled
    });
  }

  assignAllAPIProductsToAllUsers() {
    let { userslist } = this.state;
    if (userslist && userslist.length > 0) {
      for(let i=0; i<userslist.length; i++) {
        let element = userslist[i];
        if(element) {
          this.props.currentSubscribedProducts.map((productElement) => {
            if (productElement && productElement.subCategory && productElement.subCategory === 'API') {
              const index = element.selectedProducts.findIndex(x => x === productElement.id);
              if (index === -1) {
                  element.selectedProducts.push(productElement.id);
              }
            }
          });
        }
      }
      this.setState({
        userslist,
      }, () => {
        this.blockNextButton();
      });
    }
  }

  render() {
    const { t } = this.props;
    let userslist = this.state.userslist ? this.state.userslist : [];
    let subscribedproducts = this.state.currentSubscribedProducts;
    return (
    <>
    <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
    <div className='container-fluid'>
      <div className='row'>
        <div className="col-lg-1 col-md-1 col-sm-1"></div>
          <Jumbotron className="col-lg-10 col-md-10 col-sm-10 col-xs-12 jumbotron-users-list">
          {this.props.currentUserDetails.roleType === "Client Admin" || this.props.currentUserDetails.roleType === 'TMX Admin' || this.props.currentUserDetails.roleType === 'TMX BA Admin' ? <Link className="payment_console_back_link" to='/admin-products'>{t('summary.back_button')}</Link> : ''}
            <h2 className="usage_heading1">{t('allocateProducts.allocate_products')}</h2>
            <br/>
            <div className='row change-wrap'>
              <div className='col-lg-11 col-md-10 col-sm-11 col-xs-11 header-style-change'>
                <h4 className="usage_heading1">{t('allocateProducts.productssubscribed')}</h4>
              </div>
              {/* <div className='col-lg-1 col-md-2 col-sm-1 col-xs-1'>
                <Link className="brand-logo-align" to='/admin-products'><FontAwesomeIcon title= "Add Product" className='profile-icon' icon={faPlusCircle} size="2x" /></Link>
              </div> */}
            </div>
            <div className="usermanagementpage-products-class">
                {subscribedproducts.map((item, i) => <div key={i} onClick={() => this.setSelectedProduct(item)}><SubscribedProducts
                  key={i}
                  productId={item.id}
                  productName={item.keyName}
                  productLink={item.productUrl}
                  selectedProduct={this.state.selectedProduct}
                  isAdminPage={false}
                  isAllocatePage={true}
                />
                </div>)}
            </div>
            <br/><br/>
            <div className='row change-wrap'>
              <div className='col-lg-11 col-md-10 col-sm-2 header-style-change'>
                <h4 className="usage_heading1">{t('allocateProducts.userslist')}</h4>
              </div>
            </div>
            <br/>
            <div className='users-table-style'>
                <table>
                <thead>
                  <tr>
                    <th>{t('allocateProducts.allocate')}</th>
                    <th className='left-align-text'>{t('allocateProducts.firstname')}</th>
                    <th className='left-align-text'>{t('allocateProducts.lastname')}</th>
                    <th className='left-align-text'>{t('allocateProducts.email')}</th>
                    <th>{t('allocateProducts.admin')}</th>
                    <th>{t('allocateProducts.apps')}</th>
                  </tr>
                </thead>
                <tbody>
                    {userslist.map((item, i) => <tr key={i}>
                      <td><input type="checkbox" checked={this.checkSelected(item.selectedProducts)} onChange={(e) => this.selectUser(item, e)} className="allocate-user" name="allocateUser" /></td>
                      <td className='left-align-text'>{item.firstName}</td>
                      <td className='left-align-text'>{item.lastName}</td>
                      <td className='left-align-text'>{item.email}</td>
                        {item.isAdmin ? <td>&#10003;</td> : <td></td>}
                        {/* onClick={() => this.neutralizingList(item)} */}
                      <td className='CellWithComment'><a className='hamburger' style={{ cursor: 'pointer' }} id='apps'>&#9776;</a>
                        <span className="CellComment">
                          <h5 style={{ color: '#16fffb' }}>{t('allocateProducts.subscribedproducts')}</h5>
                          {
                            item.selectedProduct.sort((a,b) => (Number(a.productWeightage) < Number(b.productWeightage)) ? 1 : ((Number(b.productWeightage) < Number(a.productWeightage)) ? -1 : 0)).map((currentSubProduct, j) => <div key={j}><li className='align-list-text'>{currentSubProduct.productName}</li></div>)
                          }
                        </span>
                      </td>
                      </tr>)}
                </tbody>
                </table>
                </div>
          <br/><br/>
        <center>
            { (this.props.currentUserDetails && this.props.currentUserDetails.roleType !== 'TMX Admin' && this.props.currentUserDetails.roleType !== 'TMX BA Admin') ? 
            <button className='btn products_cont_btn' type='button' onClick={() => this.storeAssignProductsForUsers()} disabled={this.state.nextButtonDisabled}>{!this.state.nextButtonDisabled ? <Link className="pay_buttons" to='/summary'>Continue</Link> : 'Continue'}</button>
            : <button className='btn products_cont_btn' disabled={this.state.nextButtonDisabled} type='button' onClick={() => this.openTMXAdminPopup()}>Continue</button>
            }
        </center>
          </Jumbotron>
        <div className='col-lg-1 col-md-1'></div>
    </div>
    </div>

    <div id='editApplicableProducts' className='modal'>
      <div className="modal-content">
        <div className='container-fluid'>
          <div className='row'>
              <span className="close" onClick={this.closeEditUser}>&times;</span>
          </div>
          <div className='row'>
            <h5 className='edituser-popup-header'>{t('allocateProducts.subscribedproducts')}</h5>
          </div>
          {
            subscribedproducts.sort((a,b) => (Number(a.productWeightage) < Number(b.productWeightage)) ? 1 : ((Number(b.productWeightage) < Number(a.productWeightage)) ? -1 : 0)).map((item, i) => <div className='row edituser-popup-header' key={i}>
              <label htmlFor={item.keyName} style={{ width: '80%' }} className='col-lg-10 col-md-10 col-sm-10 col-xs-10'>{item.keyName}:</label>
              <input type="checkbox" name={item.keyName} id={item.keyName} style={{ width: '20%' }} className='sample-checkbox col-lg-2 col-md-2 col-sm-2 col-xs-2' />
            </div>)
          }
          </div>
          {/* <div className='row'>
            <button className="btn btn-info products-save-button">{t('allocateProducts.save')}</button>
          </div> */}
      </div>
    </div>

    {/* <div id='openTMXAdminPopup' className='modal'>
      <div className="modal-content">
        <div className='container-fluid'>
          <div className='row'>
              <span className="close" onClick={this.closeWarningPopup}>&times;</span>
          </div>
          <div className='row'>
            <h5 className='edituser-popup-header'>{t('allocateProducts.warning')}</h5>
          </div>
            <p>Test</p>
          </div>
          <div>
            <center>
                <button className="btn btn-info products-save-button">{t('allocateProducts.save')}</button>
                <button className="btn btn-info products-save-button">{t('allocateProducts.cancel')}</button>
            </center>
          </div>
      </div>
    </div> */}

    <div id="openTMXAdminPopup" className="modal">
      <div className="modal-content modal-content-warning" ref={(el) => {
      if (el) {
        el.style.setProperty('height', 'fit-content');
        el.style.setProperty('width', '50%', 'important');
      }
    }}>
          <div className='container-fluid'>
            <div className='row'>
              <span className="close" onClick={this.closeWarningPopup}>&times;</span>
            </div>
            <div className='row'>
              <h4 className='edituser-popup-header'>{t('allocateProducts.subnote')}</h4>
            </div>
            <div className='row'>
              <p>{t('allocateProducts.mainnote')}</p>
            </div>

            <div className='row'>
                <div className='form-group col-lg-7 col-md-7'>
                <button className="btn btn-info warning-popup-save-btn"  onClick={this.saveInitiator.bind(this)}>{t('allocateProducts.save')}</button>
                </div>
                <div className='form-group col-lg-3 col-md-3'>
                {/* <Link className="pay_buttons" to='/admin-products'><button className="btn btn-info warning-popup-cancel-btn">{t('allocateProducts.cancel')}</button></Link> */}
                <button className="btn btn-info warning-popup-cancel-btn" onClick={this.closeWarningPopup}>{t('allocateProducts.cancel')}</button>
                </div>
            </div>
          </div>
      </div>
    </div>

  </>);
  }
}

const mapDispatchToProps = (dispatch) => ({
  getadminDetails: (details) => dispatch(adminDetails(details)),
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
  storeAssignProducts: (details) => dispatch(storeAssignProducts(details)),
  taxRateDetails: (details) => dispatch(taxRateDetails(details)),
});

const mapStateToProps = (state) => ({
  allDetails: state.crud,
  currentUserDetails: state.crud.currentUser,
  currentSubscribedProducts: state.crud.products,
  currentUsersList: state.crud.usersList,
  currentLoadingPage: state.crud.currentLoadingPage,
  assignProducts: state.crud.assignProducts,
  cardDetails: state.crud.cardDetails,
  taxRate: state.crud.taxRate,
  usersListUpdated: state.crud.usersListUpdated,
  listOfAllUsers: state.crud.listOfAllUsers,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(AllocateProducts));
