/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

class SuccessMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldNotDisplaySuccess: true,
    };
    this.closeAlert = this.closeAlert.bind(this);
  }

  closeAlert() {
    document.getElementById('alert-window').style.display = 'none';
  }

  render() {
    return (
        <div className='container-fluid error-margin success-margin'>{() => displayState()}
            <div className='row'>
                <div className='col-lg-2 col-md-2 col-sm-0'></div>
                <div className='col-lg-10 col-md-10 col-sm-12'>
                    <Alert variant="danger" id='alert-window' className='alert-styles alert-styles-success' hidden={this.props.shouldNotDisplaySuccess}>
                      <a href="#" className="alert-close" data-dismiss="alert" aria-label="close" onClick={this.closeAlert}>&times;</a>
                        {this.props.successMessage
                        ? <div><h3>{this.props.successMessage}</h3>{this.props.backButton ? <Link to='/admin'>Back to Home</Link> : ''}</div>
                        : <div><h3>Payment Made Successfully.</h3><Link to='/admin'>Back to Home</Link></div>
                        }
                    </Alert>
                </div>
                <div className='col-lg-1'></div>
            </div>
        </div>
    );
  }
}

export default SuccessMessage;
