/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ProductsConfigure from './productsConfigure';
import AdminPaymentTab from './adminPaymentTab';
import UsageSummary from './usageSummary';
import Users from './users';
import Payment from './payment';
import { storeCurrentPage, updateSelectedClient, adminSummaryDetails } from '../../../actions/index';

class AdminConsoleTab extends Component {
  t = this.props.t;

  constructor(props) {
    super(props);
    this.state = {
      enableBilling: 1,
      load: false,
    };
    this.redirectToAdminSummary = this.redirectToAdminSummary.bind(this);
    this.updateValues = this.updateValues.bind(this);
  }

  handleSwitch = (value) => {
    const obj = this.state;
    obj.enableBilling = value;
    this.setState({
      obj,
    });
  };

  redirectToAdminSummary() {
    this.props.adminSummaryDetails({
      pendingVerificationList: [],
      allUsersList: []
    });
    this.props.history.push('/adminSummary');
  }

  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
        this.props.history.push(this.props.currentLoadingPage);
        this.props.storeCurrentPage('');
    }
  }

  componentDidMount() {
    if (this.props.location && this.props.location.state && this.props.location.state.clientSelected) {
      this.props.updateSelectedClient(this.props.location.state.clientSelected);
    }
  }

  updateValues () {
    this.setState({
      load: true,
    })
  }

  render() {
    const { enableBilling } = this.state;
    const { t } = this.props;
    const client = {
      orgName: this.props.currentUserDetails.organization,
      teamName: this.props.currentUserDetails.team
    };
    const breadCrumb = client.teamName ? `${client.orgName} - ${client.teamName}` : client.orgName;
    return (
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 payment_table_preview_panel">
        <div className='container-fluid'>
          <div>
          <div  className="row">
          <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
          <div className="payment_admin_console_body">
          <a style={{ cursor: 'pointer' }} className="admin_console_back_link" onClick={this.redirectToAdminSummary}>{'Home'}</a>
          <span className='heading_admin_payment_tab'> {breadCrumb}</span>
          </div>
          <div className="payment_sub3_body">
                <ToggleButtonGroup
                type="radio"
                className="nav_toggle_button_group"
                name="options"
                defaultValue={1}
                onChange={this.handleSwitch}
                >
                <ToggleButton className={(enableBilling === 1) ? 'toggle_active' : ''} value={1}>{t('adminConsoleTab.user_management')}</ToggleButton>
                <ToggleButton className={(enableBilling === 2) ? 'toggle_active' : ''} value={2}>{t('adminConsoleTab.product_configuration')}</ToggleButton>
                <ToggleButton className={(enableBilling === 3) ? 'toggle_active' : ''} value={3}>{t('adminConsoleTab.api_usage')}</ToggleButton>
                <ToggleButton className={(enableBilling === 4) ? 'toggle_active' : ''} value={4}>{t('adminConsoleTab.billing_payment')}</ToggleButton>
                </ToggleButtonGroup>
          </div>
          </div>
          <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'></div>
          </div>
          <div>
            {
                (enableBilling === 2)
                  ? <ProductsConfigure />
                  : ((enableBilling === 4)
                    ? <AdminPaymentTab details={client} />
                    : ((enableBilling === 3)
                      ? <UsageSummary details={client} />
                      : ((enableBilling === 1)
                        ? <Users updateValues={this.updateValues} />
                        : null)))
            }
          </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
  updateSelectedClient: (details) => dispatch(updateSelectedClient(details)),
  adminSummaryDetails: (details) => dispatch(adminSummaryDetails(details)),
});

const mapStateToProps = (state) => ({
  currentLoadingPage: state.crud.currentLoadingPage,
  currentUserDetails: state.crud.currentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(AdminConsoleTab));
