/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import apiUrl from '../../../utils/apiUrl';
import { trackPromise } from 'react-promise-tracker';
import { store } from '../../../utils/httpUtil';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { getLocalStorage } from '../../../utils/storageUtil';
import ErrorMessage from '../common/errorMessage';

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enablePayment: false,
      paymentlist: [],
      billinglist: [],
      resultMessage: '',
      isAlertNotRequired: true,
    };
    this.downloadBill = this.downloadBill.bind(this);
  }

  componentDidMount() {
    if(this.props.currentPaymentlist && this.props.currentPaymentlist.length) { //&& this.props.currentBillinglist && this.props.currentBillinglist.length
      // const paymentlist = this.props.currentPaymentlist
      // .filter((product) => {
      //   if (product.status && product.status.toLowerCase() === 'paid') {
      //     return product;
      //   }
      // });
      // const billinglist = this.props.currentBillinglist;
      this.setState({paymentlist: this.props.currentPaymentlist, billinglist: this.props.currentBillinglist});
    }
  }

  formatAmount(x) {
    let value = parseFloat(x).toLocaleString('en-US' ,{
      style: 'currency',
      currency: 'USD',//currency: 'CAD'
      maximumFractionDigits: 2
    })
    return value;
  }

  chechIsNull(data) {
    const returnVal = (data !== null && data !== 'null') ? data : '';
    return returnVal;
  }

  downloadBill(invoiceNumber) {
    const access_token = getLocalStorage('access_token');
    const request = {
      invoiceNo: invoiceNumber,
      token: access_token
    };
    if(invoiceNumber) {
    trackPromise(store(apiUrl.downloadInvoice, request).then((res) => {
      var documentName = 'TMX Invoice - '+`${invoiceNumber} - `+ moment(new Date()).toString();
      let response_buffer = res.data.data.data;
      const arr = new Uint8Array(response_buffer);
      var blob = new Blob([arr], {type: "application/pdf;charset=utf-8"});
      saveAs(blob, documentName);
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
    }
  }

  render() {
    const { t } = this.props;
    const paymentlist = this.state.paymentlist ? this.state.paymentlist : [];
    // const billinglist = this.state.billinglist ? this.state.billinglist : [];
    // const subscribedProducts = this.props.currentUserDetails.selectedProducts ? this.props.currentUserDetails.selectedProducts : [];
    return (
        <>
          <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
          <Jumbotron className="col-lg-12 col-md-12 col-sm-12 col-xs-12 jumbotron-payment-billing-table">
            <div className="payment-table-style">
                { !this.props.getPaymentTable
                  ? <table className="payment-details-table">
                <tr>
                  <th>{t('paymentDetails.invoice_number')}</th>
                  <th>{t('paymentDetails.bill_generated_date')}</th>
                  <th>{t('paymentDetails.period_start_date')}</th>
                  <th>{t('paymentDetails.period_end_date')}</th>
                  <th>{t('paymentDetails.amount_due')}</th>
                  <th>{t('paymentDetails.payment_date')}</th>
                  <th>{t('paymentDetails.bill_status')}</th>
                  <th>{t('paymentDetails.cnf_no')}</th>
                  <th></th>
                </tr>
                { paymentlist.length ? paymentlist.map((el, i) => (
                  <tr key={i}>
                    <td>{paymentlist[i].invoice_no}</td>
                    <td>{paymentlist[i].bill_generated_date}</td>
                    <td>{paymentlist[i].billing_start_date ? moment(paymentlist[i].billing_start_date).format('MMMM Do YYYY') : null}</td>
                    <td>{paymentlist[i].billing_end_date ? moment(paymentlist[i].billing_end_date).format('MMMM Do YYYY') : null}</td>
                    <td>
                      {this.formatAmount(paymentlist[i].amt_paid)}
                    </td>
                    <td>{paymentlist[i].payment_date}</td>
                    <td>{paymentlist[i].status}</td>
                    <td>{this.chechIsNull(paymentlist[i].cnf_no)}</td>
                    <td>
                      {
                        (paymentlist[i].status !== 'refunded' && paymentlist[i].status !== 'refund error') ? <Link className="download-bill-link" to='#' onClick={() => this.downloadBill(paymentlist[i].invoice_no)}>{t('paymentDetails.download_link')}</Link> : ''
                      }
                    </td>
                  </tr>
                )) : <tr></tr>}
              </table>
                  : ''}
                  {/* <table className="billing-details-table">
                <tr>
                  {/* <th>{t('paymentDetails.billing_header1')}</th>
                  <th>{t('paymentDetails.billing_header2')}</th>
                  <th>{t('paymentDetails.billing_header3')}</th> */}

                  {/* <th>{t('paymentDetails.invoice_number')}</th>
                  <th>{t('paymentDetails.bill_generated_date')}</th>
                  <th>{t('paymentDetails.period_start_date')}</th>
                  <th>{t('paymentDetails.period_end_date')}</th>
                  <th>{t('paymentDetails.amount_due')}</th>
                  <th>{t('paymentDetails.bill_status')}</th>
                  <th></th>
                </tr>
                {billinglist.length ? billinglist.map((el, i) => (
                  <tr key={i}>
                    <td>{billinglist[i].invoice_no}</td>
                    <td>{billinglist[i].bill_generated_date}</td>
                    <td>{billinglist[i].billing_start_date ? moment(billinglist[i].billing_start_date).format('MMMM Do YYYY') : null}</td>
                    <td>{billinglist[i].billing_end_date ? moment(billinglist[i].billing_end_date).format('MMMM Do YYYY') : null}</td>
                    <td>
                      {this.formatAmount(billinglist[i].amt_paid)}
                    </td>
                    <td>{billinglist[i].status}</td>
                    <td>
                      <Link className="download-bill-link" to='#' onClick={() => this.downloadBill(billinglist[i].invoice_no)}>{t('paymentDetails.download_link')}</Link>
                    </td>
                  </tr>
                )) : <tr></tr>}
              </table>} */} 
              </div>
          </Jumbotron>
        </>      
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserDetails: state.crud.currentUser,
  currentPaymentlist: state.crud.paymentlist,
  currentBillinglist: state.crud.billinglist
});

export default connect(mapStateToProps, null)(withNamespaces()(PaymentDetails));
