/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { connect } from 'react-redux';
import { billingDetails, getAdminConsoleDetails, getCurrentBillingDetails } from '../../../actions/index';
import { fetch,store } from '../../../utils/httpUtil';
import apiUrl from '../../../utils/apiUrl';
import ErrorMessage from '../common/errorMessage';
import { getLocalStorage } from '../../../utils/storageUtil';
import validation from '../../../../src/assets/properties/validation.json';
import SuccessMessage from '../common/successMessage';
// function BillingTerminal

class adminTerminalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enablePayment: false,
      adminBillingTable: [],
      invoiceNoList: [],
      resetTableDetails: [],
      refund_amount : '',
      refund_amount_with_tax : '',
      tax_rate : '',
      invoiceNo:'',
      addnewItem: {
        clientId: '',
        invoiceNo: '',
        billingDate: '',
        productID: '',
        quantity: '',
        productPrice: '',
        usageCost: '',
        taxRate: '',
        taxes: '',
        totalCost: '',
        currency: '',
        lineDetails: ''
      },
      resultMessage: '',
      isAlertNotRequired: true,
      isButtonDisabled: false,
      isSuccessAlertNotRequired: true,
      successMessage: '',
      refundFocus: false
    };
    this.formatAmount = this.formatAmount.bind(this);
    this.formatUsageAmount = this.formatUsageAmount.bind(this);
  }

  amountChange(e) {
    let value;
    value = e.target.value.replace(/[^0-9.]+/g, '');
    const pattern = new RegExp(validation[`numbersWithDecimal`]);
    if (!pattern.test(value)) {
      value = 0;
    }
    this.setState({
      refund_amount: value,
      refund_amount_with_tax: (parseFloat(value) + (parseFloat(value)*parseFloat(this.state.tax_rate)/100)).toFixed(2)
    });
  }

  handleNewItemChange(e) {
    console.log('Handle handleNewItemChange Called', e);
  }

  handleChange(e, i) {
    const values = [...this.state.adminBillingTable];
    if (e.target.name === 'noOfUsers') {
      const pattern = new RegExp(validation[`numbersOnly`]);
      if (!pattern.test(e.target.value)) {
        e.target.value = values[i][`${e.target.name}`];
      }
    }
    if (e.target.name === 'usageAmount') {
      const pattern = new RegExp(validation[`numbersWithDecimal`]);
      if (!pattern.test(e.target.value)) {
        e.target.value = values[i][`${e.target.name}`];
      }
    }
    if (e.target.name === 'noOfUsers') {
      if (e.target.value < 0) {
        values[i][`${e.target.name}`] = 0;
      } else {
        values[i][`${e.target.name}`] = e.target.value;
      }
    } else {
      values[i][`${e.target.name}`] = e.target.value;
    }
    values[i][`isDisabled`] = false;
    this.setState({
      adminBillingTable: values
    });
  }

  updateAmountDetails(e, i) {
    if(e.target.name === 'noOfUsers') {
      const amountState = this.state.adminBillingTable;
      e.target.value === '' ? amountState[i][`noOfUsers`] = 0 : amountState[i][`noOfUsers`];
      amountState[i][`calculatedBillAmount`] = parseFloat(this.state.resetTableDetails[i][`calculatedBillAmount`]) * parseFloat(amountState[i][`noOfUsers`]);
      let usageCost = parseFloat(this.state.resetTableDetails[i][`usageAmount`]) === amountState[i][`usageAmount`] ? this.state.resetTableDetails[i][`usageAmount`] : 
      amountState[i][`usageAmount`];
      amountState[i][`taxAmount`] = (parseFloat(amountState[i][`calculatedBillAmount`]) + parseFloat(usageCost)) * parseFloat(this.props.adminBillingTable[i][`taxPercentage`]/100);
      amountState[i][`finalAmount`] = parseFloat(amountState[i][`calculatedBillAmount`]) + parseFloat(usageCost) + parseFloat(amountState[i][`taxAmount`]);
      this.setState({
        adminBillingTable: amountState
      }, () => {
        this.disableSubmitButton();
      });
    } else if(e.target.name === 'usageAmount') {
      const amountState = this.state.adminBillingTable;
      e.target.value === '' ? amountState[i][`usageAmount`] = 0.0000 : amountState[i][`usageAmount`];
      amountState[i][`calculatedBillAmount`] = parseFloat(this.state.resetTableDetails[i][`calculatedBillAmount`]) * parseFloat(amountState[i][`noOfUsers`]);
      let usageCost = parseFloat(this.state.resetTableDetails[i][`usageAmount`]) === amountState[i][`usageAmount`] ? this.state.resetTableDetails[i][`usageAmount`] : 
      amountState[i][`usageAmount`];
      amountState[i][`taxAmount`] = (parseFloat(usageCost) + parseFloat(amountState[i][`calculatedBillAmount`])) * parseFloat(this.props.adminBillingTable[i][`taxPercentage`]/100);
      amountState[i][`finalAmount`] = parseFloat(amountState[i][`calculatedBillAmount`]) + parseFloat(usageCost) + parseFloat(amountState[i][`taxAmount`]);
      amountState[i][`focused`] = false;
      this.setState({
        adminBillingTable: amountState
      }, () => {
        this.disableSubmitButton();
      });
    }
  }

  handleSave(i) {
    const newState = this.state.adminBillingTable;
    newState[i][`isDisabled`] = true;
    this.setState({
      adminBillingTable: newState
    });
  }

  componentDidMount() {
    if (this.state.adminBillingTable && this.state.adminBillingTable.length > 0) {
      let list = this.state.adminBillingTable;
      list = list.map((v) => {
        const a = v;
        a.isDisabled = true;
        return a;
      });
      this.setState({
        adminBillingTable: list
      });
    }
    if (this.props.currentUserDetails) {
      const access_token = getLocalStorage('access_token');
      trackPromise(store(apiUrl.getRefundDetails, {clientId:this.props.currentUserDetails.clientId, token: access_token}).then((res) => {
        if (res && res.data && res.data.data) {
          this.setState({
            invoiceNoList: res.data.data
          });
        }
      }).catch((err) => {
        console.log(err);
      }));
    }
    this.props.billingDetails({clientId: this.props.currentUserDetails.clientId});
  }

  setBillingDetails() {
    if (this.props.adminBillingTable && this.props.adminBillingTable.length > 0) {
      let allProducts = this.props.adminBillingTable;
      allProducts = allProducts.map((v) => {
        const a = v;
        a.focused = false;
        return a;
      });
      this.setState({
        adminBillingTable: this.props.adminBillingTable,
        resetTableDetails: JSON.parse(JSON.stringify(this.props.adminBillingTable)),
      })
    }
  }

  invoiceNoChange(e){
    this.setState({
      amount: this.state.invoiceNoList.find(element => (element.invoiceNo == e.target.value)).amountWithTax,
      tax_rate: this.state.invoiceNoList.find(element => (element.invoiceNo == e.target.value)).taxRate,
      invoiceNo: e.target.value,
    });
  }

  createRefund(refundAmount){
    refundAmount = parseFloat(refundAmount);
    const access_token = getLocalStorage('access_token');
    const users = this.props.usersList.map((user) => {
      const currentUser = {
        email: user.email,
        firstName: user.firstName
      };
      return currentUser;
    })
    trackPromise(store(apiUrl.createRefund, {
      clientId:this.props.currentUserDetails.clientId,
      refundAmount:refundAmount,
      taxRate: this.state.tax_rate,
      amountWithTax: this.state.refund_amount_with_tax,
      invoiceNo:this.state.invoiceNo,
      users,
      token: access_token
    }).then((res) => {
      if (res && res.data && res.data.data) {
        if (res) {
          if(res.data && res.data.status == '-1'){
            this.setState({ isSuccessAlertNotRequired: false, isAlertNotRequired: true, successMessage: res.data.data });
          }else{
            this.setState({ isSuccessAlertNotRequired: false, isAlertNotRequired: true });
          }        
        }
      }
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

  updateDetails(billinglist) {
    this.props.getCurrentBillingDetails([]);
    const request = JSON.parse(JSON.stringify(billinglist));
    request.map((item) => {
      // let calcAmount = item.inputBillAmount;
      let productsPrice = item.productPrice;
      if (item.clientId) {
        delete item.clientId;
      }
      if(item.productId) {
        delete item.productId;
      }
      if(item.productPrice) {
        delete item.productPrice;
      }
      if(item.currency) {
        delete item.currency;
      }
      if(item.isDisabled === false || item.isDisabled === true) {
        delete item.isDisabled;
      }
      if(item.updatedBy === '' || item.updatedBy === ' ') {
        item.updatedBy = this.props.currentUserDetails.firstName;
      }
      if(item.packageId) {
        delete item.packageId;
      }
      item.inputBillAmount = parseFloat(item.calculatedBillAmount); // * item.noOfUsers;
      item.totalBeforeTax = parseFloat(item.inputBillAmount) + parseFloat(item.usageAmount);
      if(item.calculatedBillAmount) {
        // item.calculatedBillAmount = item.inputBillAmount;
        item.calculatedBillAmount = parseFloat(productsPrice);
        // calcAmount = item.calculatedBillAmount;
      }
    });
    const access_token = getLocalStorage('access_token');
    const req = {
      billinglist: request,
      token: access_token
    }
    trackPromise(store(apiUrl.updateBillingDetails, req).then((res) => {
      if (res && res.data && res.data.data) {
        this.props.billingDetails({clientId: this.props.currentUserDetails.clientId});
      }
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
      this.props.billingDetails({clientId: this.props.currentUserDetails.clientId});
    }));
  }

  disableSubmitButton() {
    let isButtonDisabled = false;
    const values = [...this.state.adminBillingTable];
    const updatedValues = values.filter((billing) => {
      if (billing.finalAmount && billing.finalAmount < 0) {
        return billing;
      }
    });
    if (updatedValues.length > 0) {
        isButtonDisabled = true;
    }
    this.setState({
      isButtonDisabled,
    })
  }

  formatAmount(x) {
    let value = parseFloat(x).toLocaleString('en-US' ,{
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2
    })
    return value;
  }

  formatUsageAmount(e, i) {
    const values = [...this.state.adminBillingTable];
    e.target.value = e.target.value.replace(/[^0-9.]+/g, '');
    values[i][`focused`] = true;
    this.setState({
      adminBillingTable: values
    });
  }

  removeFocus() {
    this.setState({refundFocus: false});
  }

  addFocus() {
    this.setState({refundFocus: true});
  }

  render() {
    (this.state.adminBillingTable.length === 0) ? this.setBillingDetails() : '';
    const { t } = this.props;
    const billinglist = this.state.adminBillingTable ? this.state.adminBillingTable : [];
    const totalTaxes = parseFloat(billinglist.reduce(
      (prevValue, currentValue) => prevValue + parseFloat(currentValue.taxAmount),
      0
    )).toFixed(2);
    const totalUsageCost = parseFloat(billinglist.reduce(
      (prevValue, currentValue) => prevValue + parseFloat(currentValue.finalAmount) - parseFloat(currentValue.taxAmount),
      0
    )).toFixed(2);
    const totalCost = parseFloat(billinglist.reduce(
      (prevValue, currentValue) => prevValue + parseFloat(currentValue.finalAmount),
      0
    )).toFixed(2);
    const newItem = this.state.addnewItem;
    return (<>
    <SuccessMessage shouldNotDisplaySuccess={this.state.isSuccessAlertNotRequired} successMessage={this.state.successMessage} backButton={true}/>
    <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
    <ErrorMessage onlyErrorMessage={true} message={t('billing_and_refunds.disable_button_error_message')} shouldNotDisplay={!this.state.isButtonDisabled} />
        <Jumbotron className="col-lg-12 col-md-12 col-sm-12 col-xs-12 jumbotron-admin-payment-billing-table">
            {this.props.getPaymentTable ? (
              <div className="jumbotron terminal-body-panel">
              { billinglist.length > 0 
              ?
              <table className="billing-terminal-table1">
              <thead>
              <tr>
                <th>Client ID</th>
                <th>Invoice No</th>
                <th>Billing Date</th>
                <th>Tax Rate</th>
                <th>Currency</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td>{billinglist[`${0}`].clientId}</td>
                  <td>{billinglist[`${0}`].invoiceNo}</td>
                  <td>{billinglist[`${0}`].billingStartDate}</td>
                  <td>{parseFloat(billinglist[`${0}`].taxPercentage).toFixed(2)}{'%'}</td>
                  <td>{billinglist[`${0}`].currency}</td>
              </tr>
              </tbody>
              </table>
              : <div style={{textAlign: 'center'}} className="text-danger-alert-large">{t('admin.no_data_available')}</div>
            }
             { billinglist.length > 0 
             ? <table className="billing-terminal-table2">
              <thead>
              <tr>
                <th>Product Id</th>
                <th>Quantity</th>
                <th>Product Price</th>
                <th>Usage Cost</th>                
                <th>Taxes</th>
                <th>Total Cost</th>
                <th>Remarks</th>
                <th hidden>Calc Amt</th>
              </tr>
              </thead>
              <tbody>
              {billinglist.map((el, i) => (
                <tr key={i}>
                  <td>{billinglist[i].productId}</td>
                  
                  <td><input type="text" name="noOfUsers" autoComplete="off" className="table-text1" value={billinglist[i].noOfUsers} onChange={(e) => this.handleChange(e, i)} onBlur={(e) => this.updateAmountDetails(e, i)}/></td>
                  <td>{(billinglist[i].productPrice ? this.formatAmount(billinglist[i].productPrice) : 0.00)}</td>
                  <td><input type="text" name="usageAmount" autoComplete="off" className="table-text1" value={billinglist[i].usageAmount && !billinglist[i].focused ? this.formatAmount(billinglist[i].usageAmount) : billinglist[i].usageAmount} onChange={(e) => this.handleChange(e, i)} onBlur={(e) => this.updateAmountDetails(e, i)} onFocus={(e) => this.formatUsageAmount(e, i)}/></td>
                  <td>{parseFloat(billinglist[i].taxAmount).toFixed(2) ? this.formatAmount(billinglist[i].taxAmount) : 0.00}</td>
                  <td>{parseFloat(billinglist[i].finalAmount).toFixed(2) ? this.formatAmount(billinglist[i].finalAmount) : 0.00}</td>
                  <td><input type="text" name="remarks" autoComplete="off" className="table-text1" value={billinglist[i].remarks} onChange={(e) => this.handleChange(e, i)}/></td>
                  <td hidden>{parseFloat(billinglist[i].calculatedBillAmount).toFixed(2)}</td>
                </tr>
              ))}
              <tr>
                  <td hidden><input type="text" name="productID" autoComplete="off" className="table-text1" value={newItem.productID} onChange={(e) => this.handleNewItemChange(e)}/></td>
                  <td hidden><input type="text" name="quantity" autoComplete="off" className="table-text1" value={newItem.quantity} onChange={(e) => this.handleNewItemChange(e)}/></td>
                  <td hidden><input type="text" name="productPrice" autoComplete="off" className="table-text1" value={newItem.productPrice} onChange={(e) => this.handleNewItemChange(e)}/></td>
                  <td hidden><input type="text" name="usageCost" autoComplete="off" className="table-text1" value={newItem.usageCost} onChange={(e) => this.handleNewItemChange(e)}/></td>
                  <td hidden><input type="text" name="taxes" autoComplete="off" className="table-text1" value={newItem.taxes} onChange={(e) => this.handleNewItemChange(e)}/></td>
                  <td hidden><input type="text" name="totalCost" autoComplete="off" className="table-text1" value={newItem.totalCost} onChange={(e) => this.handleNewItemChange(e)}/></td>
                  <td hidden><input type="text" name="lineDetails" autoComplete="off" className="table-text1" value={newItem.lineDetails} onChange={(e) => this.handleNewItemChange(e)}/></td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Sub Total</td>
                <td>{totalUsageCost ? this.formatAmount(totalUsageCost) : 0.00}</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Taxes</td>
                <td>{totalTaxes ? this.formatAmount(totalTaxes) : 0.00}</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>{totalCost ? this.formatAmount(totalCost) : 0.00}</td>
                <td><button
                className="btn admin-save-btn"
                type="button" disabled={this.state.isButtonDisabled} onClick={(e) => this.updateDetails(billinglist)}
                >Submit</button></td>
              </tr>
            </tfoot>
              </table>
              : '' }
              </div>
            ) : (
              <div className="jumbotron terminal-body-panel">
                <div className="top-terminal-part">
                <div className="terminal-flex1-item">
                    <span className="terminal-input-note">
                    {t('admin.invoice_no')}
                    </span>
                    <select
                      className="form-control"
                      name="user_status"
                      onChange={this.invoiceNoChange.bind(this)}
                    >
                      <option className="select-option-payment" value="default" selected disabled>
                        Select #
                      </option>
                      {
                          this.state.invoiceNoList.map((item) => <option className="select-option-payment" value={item.invoiceNo}
                           key={item.invoiceNo}>
                          {item.invoiceNo}</option>)
                        }
                    </select>
                  </div>
                  <div className="terminal-flex1-item">
                    <span className="terminal-input-note">{t('admin.invoice_amount')}</span><br />
                    <p name="paynow_amount">{this.state.amount ? this.formatAmount(this.state.amount) : 0.00}</p>
                  </div>
                  <div className="terminal-flex1-item">
                    <span className="terminal-input-note">{t('admin.refund_amount')}</span>
                    <input
                      type="text"
                      className="form-control amount-paynow-input"
                      id="paynow_amount"
                      name="paynow_amount"
                      autoComplete="off"
                      onChange={(e) => this.amountChange(e)}
                      onFocus={() => this.addFocus()}
                      onBlur={() => this.removeFocus()} 
                      value={this.state.refund_amount && this.state.refundFocus ? this.state.refund_amount : this.state.refund_amount > 0 ? this.formatAmount(this.state.refund_amount) : ''}
                    />
                    <div className="text-danger">{this.state.amount < this.state.refund_amount && t('admin.refund_amount_greater_than_invoice')}</div>
                    {
                      this.state.refund_amount && Number(this.state.refund_amount) < 1 ? <div className="text-danger">{t('admin.refund_amount_greater_than1')}</div>: ''
                    }
                  </div>
                  <div className="terminal-flex1-item">
                    <span className="terminal-input-note">{t('admin.refund_amount_tax')}</span><br />
                    <p name="paynow_amount_tax">{isNaN(this.state.refund_amount_with_tax) === true ? '' : this.state.refund_amount_with_tax ? this.formatAmount(this.state.refund_amount_with_tax) : ''}</p>
                  </div>
                </div>
                <div className="terminal-refund-bottom">
                  <div className="payment-submit-button">
                    <button
                      className="btn admin-save-btn"
                      type="button"
                      disabled={!this.state.invoiceNo || !this.state.refund_amount || !this.state.isSuccessAlertNotRequired || this.state.amount < this.state.refund_amount || this.state.refund_amount < 1}
                      onClick={() => this.createRefund(this.state.refund_amount)}
                    >Apply</button>
                  </div>
                </div>
              </div>
            )}
        </Jumbotron>
        </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserDetails: state.crud.currentUser,
  currentPaymentlist: state.crud.paymentlist,
  currentBillinglist: state.crud.billinglist,
  adminBillingTable: state.crud.adminBillingTable,
  usersList: state.crud.usersList,
});

const mapDispatchToProps = (dispatch) => ({
  getAdminConsoleDetails: (details) => dispatch(getAdminConsoleDetails(details)),
  billingDetails: (details) => dispatch(billingDetails(details)),
  getCurrentBillingDetails: (details) => dispatch(getCurrentBillingDetails(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(adminTerminalDetails));
