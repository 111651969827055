export const GET_CURRENT_USER_DETAILS = 'GET_CURRENT_USER_DETAILS';
export const PRODUCTS_DETAILS_INITIATE = 'PRODUCTS_DETAILS_INITIATE';
export const SELECTED_PRODUCTS_INITIATE = 'SELECTED_PRODUCTS_INITIATE';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const GET_CURRENT_BILLING_DETAILS = 'GET_CURRENT_BILLING_DETAILS';
export const GET_CARD_DETAILS = 'GET_CARD_DETAILS';
export const GET_PAYMENT_DETAILS = 'GET_PAYMENT_DETAILS';
export const GET_CURRENT_ADMIN_DETAILS = 'GET_CURRENT_ADMIN_DETAILS';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const GET_API_PRODUCTS_LIST = 'GET_API_PRODUCTS_LIST';
export const GET_ADMIN_CONSOLE_DETAILS = 'GET_ADMIN_CONSOLE_DETAILS';
export const GET_ROLE_ACCESS_DETAILS = 'GET_ROLE_ACCESS_DETAILS';
export const STORE_CURRENT_PAGE = 'STORE_CURRENT_PAGE';
export const GET_CONFIGURED_PRODUCTS = 'GET_CONFIGURED_PRODUCTS';
export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN';
export const SUBSCRIBER_DETAILS = 'SUBSCRIBER_DETAILS';
export const ASSIGN_PRODUCTS = 'ASSIGN_PRODUCTS';
export const UPDATE_SELECTED_CLIENT = 'UPDATE_SELECTED_CLIENT';
export const ADMIN_SUMMARY_DETAILS = 'ADMIN_SUMMARY_DETAILS';
export const MONERIS_DETAILS = 'MONERIS_DETAILS';
export const UPDATE_CLIENT_ID = 'UPDATE_CLIENT_ID';
export const UPDATE_CLIENT_DETAILS = 'UPDATE_CLIENT_DETAILS';
export const UPDATE_NEW_CLIENT_DETAILS = 'UPDATE_NEW_CLIENT_DETAILS';
export const CLEAR_SUBSCRIBED_PRODUCTS = 'CLEAR_SUBSCRIBED_PRODUCTS';
export const CLEAR_ALL_ADMIN_SUMMARY_DETAILS = 'CLEAR_ALL_ADMIN_SUMMARY_DETAILS';
export const UPDATE_CLIENT_TEAM_NAME = 'UPDATE_CLIENT_TEAM_NAME';
export const USER_LIST_UPDATED = 'USER_LIST_UPDATED';
export const TAX_AND_PAYMENT_UPDATED = 'TAX_AND_PAYMENT_UPDATED';

export const SIGN_UP_INITIATE = 'SIGN_UP_INITIATE';
export const SUMMARY_DETAILS_INITIATE = 'SUMMARY_DETAILS_INITIATE';
export const BILLING_DETAILS_INITIATE = 'BILLING_DETAILS_INITIATE';
export const USER_DETAILS_INITIATE = 'USER_DETAILS_INITIATE';
export const RECIEVE_ALL_DETAILS = 'RECIEVE_ALL_DETAILS';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const SIGN_UP_MONERIS_DETAILS = 'SIGN_UP_MONERIS_DETAILS';
export const REMOVE_ALL_SIGNUP_DETAILS = 'REMOVE_ALL_SIGNUP_DETAILS';
export const FROM_BILLING_UPDATE = 'FROM_BILLING_UPDATE';

export const ENTITY_FAILURE = 'ENTITY_FAILURE';
export const ENTITY_CREATE = 'ENTITY_CREATE';
export const ENTITY_UPDATE = 'ENTITY_UPDATE';
export const ENTITY_FETCH = 'ENTITY_FETCH';
export const ENTITY_DELETE = 'ENTITY_DELETE';
export const SELECT_ENTITY_ITEM = 'SELECT_ENTITY_ITEM';
export const CLEAR_ENTITY_LIST = 'CLEAR_ENTITY_LIST';
export const TAX_RATE_DETAILS = 'TAX_RATE_DETAILS';
export const TAX_RATE_DETAILS_ADMIN = 'TAX_RATE_DETAILS_ADMIN';

export const UPDATE_PROFILE_DETAILS = 'UPDATE_PROFILE_DETAILS';