/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
import apiUrl from '../../../utils/apiUrl';
import { Link } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import { store } from '../../../utils/httpUtil';
import bannerImage from '../../../assets/img/TMX-ANALYTICS-IMG.png';
import Cryptr from 'cryptr';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    const email = sessionStorage.getItem('changePasswordEmail');
    this.state = {
      isButtonDisabled:true,
      isAPISuccessMessage:'',
      body:{
        email:email,
        oldPassword:'',
        newPassword:'',
        confirmPassword:''
      },
      errors:{
        oldPassword:'',
        newPassword:'',
        confirmPassword:''
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.encryptPassword = this.encryptPassword.bind(this);
  }

  async changePassword(e) {
    e.preventDefault();
    const isValid = await this.validateFields();
    if(isValid){
      this.setState({
        isButtonDisabled: true,
      });
      const body = await this.encryptPassword(this.state.body);
      await trackPromise(store(apiUrl.changePassword, body).then((changePasswordInitiated) => {
        console.log(changePasswordInitiated)
        if(changePasswordInitiated.data && changePasswordInitiated.data.status == -1){
          this.setState({
            isButtonDisabled: false,
            isAPISuccessMessage: changePasswordInitiated.data.data
          });
        }
          if (changePasswordInitiated.data && changePasswordInitiated.data.data && changePasswordInitiated.data.data.statusCode && changePasswordInitiated.data.data.statusCode === 200) {
            this.setState({
              isAPISuccessMessage: changePasswordInitiated.data.data.message,
            });
            setTimeout(()=>{
              this.props.history.push('/login');
            },2000);          
          }
      }).catch((e) => {
          this.setState({ 
            isButtonDisabled: true,
            isAPISuccessMessage: e.response.data.message.message,
          });
      }));
    }
  }

  async validateFields(){
    let isValid = true;
    let errors = this.state.errors;
    if(!this.state.body.oldPassword){
      isValid = false;
      errors.oldPassword = "Please enter Old Password";
    }
    if(!this.state.body.newPassword){
      isValid = false;
      errors.newPassword = "Please enter New Password";
    }
    if(!this.state.body.confirmPassword){
      isValid = false;
      errors.confirmPassword = "Please enter Confirm Password";
    }
    if(this.state.body.confirmPassword && this.state.body.confirmPassword != this.state.body.newPassword){
      isValid = false;
      errors.confirmPassword = "New Password and Confirm Password doesn't match";
    }
    this.setState({errors});
    return isValid;
  }

  async encryptPassword(body) {
    const cryptr = new Cryptr('myTotalySecretKey');
    let encryptedoldPassword = await cryptr.encrypt(body.oldPassword);
    let encryptednewPassword = await cryptr.encrypt(body.newPassword);
    let encryptedconfirmPassword = await cryptr.encrypt(body.confirmPassword);
    const saveRequest = {
      encryptedoldPassword,
      encryptednewPassword,
      encryptedconfirmPassword,
      email: body.email
    }
    return saveRequest;
  }

  handleChange(e,key) {
    const value = e.target.value;
    let body = this.state.body;
    let errors = this.state.errors;
    if(value){
      errors[key] = '';
    }
    body[key] = value;
    let hasErrors = false;
    for(let keyName in body) {
      if(keyName == "email"){
        continue;
      }
      if(!body[keyName]){
        hasErrors = true;
        break;
      }
    }
    this.setState({
        body,
        errors,
        isButtonDisabled: hasErrors
      });
    }

  render() {
    const { t } = this.props;
    return (
      <>
          <div className="container-fluid">
            <div className="row">
            <div className="col-lg-1 col-md-1"></div>
            <Jumbotron className="col-lg-4 col-md-10 col-sm-8 col-xs-8 resetpwd_jumbotron_sample">
            <Link className="payment_console_back_link" to="/login"><h6>{t('login.back_button')}</h6></Link>
                <h2 className="signup_header_alignment">
                  {t('login.change_password_title')}
                </h2>
              <form autoComplete="off">
                <div className="form-row">
                    <div className="form-group col-md-12 col-lg-12 left_labels_alignment">
                    <label htmlFor="oldpassword">{t('header.oldpassword')}</label>&nbsp;<span className="required-flag">*</span>
                    <input
                         type="password"
                         className="form-control"
                         id="oldPassword"
                         name="oldPassword"
                         autoComplete="off"
                         placeholder={t('header.oldpassword')}
                         required={true}
                         maxLength={100}
                         onChange={(e) => this.handleChange(e,'oldPassword')}
                         value={this.state.body.oldPassword}
                    />
                    </div>
                    <div className="text-danger-alert" >{this.state.errors.oldPassword}</div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12 col-lg-12 left_labels_alignment">
                    <label htmlFor="newPassword">{t('header.newpassword')}</label>&nbsp;<span className="required-flag">*</span>
                    <input
                       type="password"
                       className="form-control"
                       id="newPassword"
                       name="newPassword"
                       autoComplete="off"
                       placeholder={t('header.newpassword')}
                       required={true}
                       maxLength={100}
                       onChange={(e) => this.handleChange(e,'newPassword')}
                       value={this.state.body.newPassword}
                    />
                    </div>
                    <div className="text-danger-alert" >{this.state.errors.newPassword}</div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12 col-lg-12 left_labels_alignment">
                    <label htmlFor="confirmPassword">{t('header.confirmpassword')}</label>&nbsp;<span className="required-flag">*</span>
                    <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        autoComplete="off"
                        placeholder={t('header.confirmpassword')}
                        required={true}
                        maxLength={100}
                        onChange={(e) => this.handleChange(e,'confirmPassword')}
                        value={this.state.body.confirmPassword}
                    />
                    </div>
                    <div className="text-danger-alert" >{this.state.errors.confirmPassword}</div>
                </div>
                <p className="text-danger-alert">{this.state.isAPISuccessMessage}</p>
                <div className="form-row">
                    <div className="form-group col-md-12 col-lg-12 signintext-link-alignment">
                    <center><button className="btn btn-info next_button" onClick={this.changePassword} disabled={this.state.isButtonDisabled}>{t('confirmation.submit_button')}</button></center>
                    </div>
                </div>
              </form>
            </Jumbotron>
            <div className="col-lg-1 col-md-1"></div>
              <div className="col-lg-4 col-md-12 col-sm-8 col-xs-8">
                  <img className='loginbannerimage' src={bannerImage}/>
              </div>
            </div>
          </div>
          </>
    );
  }
}

export default (withNamespaces()(ChangePassword));
