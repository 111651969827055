/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { store } from '../../../utils/httpUtil';
import apiUrl from '../../../utils/apiUrl';
import { getApiProductsList, storeCurrentPage, authToken } from '../../../actions/index';
import validation from '../../../assets/properties/validation.json';
import { trackPromise } from 'react-promise-tracker';
import { Link } from 'react-router-dom';
import UsageChart from '../common/usageChart';

class UsageSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isAunthenticationHappened: false,
      showError: false,
      input: {
        password: ''
      },
      startDate: moment().startOf('month'),
      endDate: moment(),
      endDateOnDisplay: moment().endOf('month'),
      differenceDate: moment().endOf('month').diff(moment(), 'days'),
      disableFunctions: true,
      isDisableUpdated: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.getToken = this.getToken.bind(this);
    this.getProductInformations();
    this.getVal();
  }

  convert(value) {
    let val = value;
    if (value >= 1000000) {
      val = `${value / 1000000}M`;
    } else if (value >= 1000) {
      val = `${value / 1000}K`;
    }
    return val;
  }

  getVal(val) {
    let amount = val;
    const multiplier = (typeof amount === 'string') ? amount.substr(amount.length - 1).toLowerCase() : '';
    if (multiplier === 'k') amount = parseFloat(val) * 1000;
    if (multiplier === 'm') amount = parseFloat(val) * 1000000;
    return amount;
  }

  async getProductInformations() {
    const request = {
      startDate: this.state.startDate.format('YYYY-MM-DD'),
      endDate: this.state.endDate.format('YYYY-MM-DD'),
      userId: this.props.currentUserDetails.userId,
      token: this.props.currentUserDetails.access_token,
      clientId: this.props.currentUserDetails.clientId,
    };
    trackPromise(store(apiUrl.getProductInformations, request).then((res) => {
      if (res && res.data && res.data.data && res.data.data.products) {
        this.setState({ products: res.data.data.products });
      }
    }))
  }

  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
        this.props.history.push(this.props.currentLoadingPage);
        this.props.storeCurrentPage('');
    }
    if(!this.state.isDisableUpdated){
      let disableFunctions = !(this.props.updateNewClientDetails && this.props.updateNewClientDetails.status && this.props.updateNewClientDetails.status != 'active');
      if(this.props.currentSubscribedProducts && this.props.currentUserDetails.selectedProducts.length > 0 && this.props.currentUserDetails.roleType === "Client Admin") {
        const activeClients = this.props.currentSubscribedProducts.filter((products) => products.clientSubsStatus === 'active');
        if (activeClients && activeClients.length > 0) {
          disableFunctions = true;
        } else {
          disableFunctions = false;
        }  
      } 
      if(this.props.currentUserDetails && this.props.currentUserDetails.selectedProducts && this.props.currentUserDetails.selectedProducts.length > 0 && this.props.currentUserDetails.roleType === "User") {
        const productsArray = [], resultArray = [];
        this.props.currentUserDetails.selectedProducts.map((products) => productsArray.push(products));
        productsArray.map((product) => resultArray.push(product.users));
        resultArray.forEach((result) => {
        const activeClients = result.filter((innerResult) => innerResult.user_subs_status === 'active');
        if (activeClients && activeClients.length > 0) {
            disableFunctions = true;
        } else {
            disableFunctions = false;
          }
        });
      }
      this.setState({
        disableFunctions,
        isDisableUpdated: true,
      });
    }
  }

  handleChange(e) {
    const { input } = this.state;
    input[e.target.name] = e.target.value;
    this.setState({
      input
    });
  }

  getToken() {
    const clientDetails = {
      email: this.props.currentUserDetails.email,
      password: this.state.input.password
    };
    trackPromise(store(apiUrl.getAuthToken, clientDetails).then((res) => {
      if (res && res.data && res.data.data && res.data.data.body) {
        this.setState({isAunthenticationHappened: true, authToken: res.data.data.body.access_token, apiKey: res.data.data.apiKey});
      }
    }).catch(() => {
      this.setState({showError: true});
    }));
  }

  usageIdentifier(uspace, totalspace) {
    let result;
    if (totalspace > 0) {
    result = parseFloat(
        (parseFloat(uspace).toFixed(2) *
          100) /
          parseFloat(
            this.getVal(totalspace)
          ).toFixed(2)
      ).toFixed(2);
    }
    else {
      result = Number(0).toFixed(2);
    }
    return result;
  }

  render() {
    const { t } = this.props;
    const { products } = this.state;
    return (
      <div className="container-fluid usage_summary_comp">
        <div className="row">
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
          <div className=" usage_summary_auth_panel">
          {this.props.currentUserDetails.roleType === "Client Admin" || this.props.currentUserDetails.roleType=== "User" ? <Link className="payment_console_back_link" to='/admin'>{'Home'}</Link> : ''}
            <div className="usage_heading1">{t('usage.auth_token_heading')}</div>
              <div className="auth_password_input_panel">
                {!this.state.isAunthenticationHappened && (
                      <div className="auth_input_flex">
                        <input
                          type="password"
                          className="form-control auth_password"
                          id="password"
                          name="password"
                          value={this.state.input.password}
                          autoComplete="off"
                          placeholder="Password"
                          onChange={this.handleChange}
                          required={true}
                          maxLength={100}
                        />
                        <button
                          onClick={this.getToken}
                          disabled={!(this.state.input.password && this.state.disableFunctions)}
                          className="btn btn-info next_button"
                        >
                          {t('usage.submit')}
                        </button>
                      </div>
                )}
                {this.state.authToken ? (
                  <div>
                    <div className="authenticate-text">
                      <p>{t('usage.your_auth_token')}:</p>
                    </div>
                    <div className="authenticate-text1 auth-word">
                      {' '}
                      {this.state.authToken}{' '}
                    </div>
                    <div className="authenticate-text1">
                      <p>
                      {t('usage.auth_token_instruction')}{' '}
                        <a target="blank" className='download-bill-link' href="https://docs.tmxanalytics.com/#tmx-data-science-api">
                          https://docs.tmxanalytics.com/#tmx-data-science-api
                        </a>
                      </p>
                    </div>
                    <div className="authenticate-text">
                      <p>{t('usage.your_api_key')} : <span className='download-bill-link'>{this.state.apiKey}</span></p>
                    </div>
                  </div>
                ) : !this.state.authToken &&
                  this.state.isAunthenticationHappened || this.state.showError ? (
                  <div className="authenticate-text1">
                    <p>
                    {t('usage.auth_instrctoion_issue')}
                    </p>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>   
          </div>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
        </div>
        <div className="row">
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
          <Jumbotron className="col-lg-10 col-md-10 col-sm-10 col-xs-10 jumbotron_api_usage_summary">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="usage_heading1">{t('usage.api_usage')}</div>
              <div className="usage_heading2">
                From {this.state.startDate.format('LL')} - {this.state.endDateOnDisplay.format('LL')} ({this.state.differenceDate} days left)
              </div>
              <div className="usage_heading">{t('usage.real_time_usage')}</div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 sol-xs-12">
                  <div className="jumbotron_usage_panel">
                    <div className="wrapper summary-card-main">
                      <div className="wrapper usage-cards-main">
                        {products.map((el, i) => (
                          <div key={i} className="card api-product-card-layout">
                            <div className="row">
                              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-10">
                                <div className="api_product_card_name2">
                                  {products[i].name}
                                </div>
                                <div className="api_product_card_limit3">
                                  Limit of {products[i].totalspace}(used{" "}
                                  {products[i].uspace}) requests)
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-md-3 col-md-3 api_product_card_percent2">
                                {this.usageIdentifier(products[i].uspace, products[i].totalspace)}
                                %{" "}
                                <span className="api-usage-used-percent2">
                                  USED
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="api-usage-progress-bar-wrapper">
                                <ProgressBar
                                  className="api-usage-progress-bar"
                                  now={this.usageIdentifier(products[i].uspace, products[i].totalspace)}
                                />
                                <div className="progress-bar-center">
                                  {products[i].uspace}
                                </div>
                              </div>
                              <div className="col-lg-12 api_usage_progress_footer">
                                <span className="progress-bar-start">
                                  {validation.default_start_usage}
                                </span>
                                <span className="progress-bar-end">
                                  {products[i].totalspace}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="usage_main_card_panel_note">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="usage_heading">Historical Usage</div>
              <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {products && products.length > 0
                ? <UsageChart products={products}/>
                : '' }
              </div>
              </div>
            </div>
          </Jumbotron>
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  apiProductsList: state.crud.apiProductsList,
  currentLoadingPage: state.crud.currentLoadingPage,
  currentUserDetails: state.crud.currentUser,
  authToken: state.crud.authToken,
  currentSubscribedProducts: state.crud.subscribedProducts,
  updateNewClientDetails: state.crud.updateNewClientDetails
});

const mapDispatchToProps = (dispatch) => ({
  updatePayment: (details) => dispatch(getApiProductsList(details)),
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
  getAuthToken: (details) => dispatch(authToken(details))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(UsageSummary));
