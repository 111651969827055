/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import uuid from 'react-uuid';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';
import '../../i18n';
import {
  Button, ButtonGroup, Badge
} from 'react-bootstrap';
import Pagination from '../common/pagination';
import ProductsLayout from '../common/productsLayout';
import { fetch, store } from '../../../utils/httpUtil';
import apiUrl from '../../../utils/apiUrl';
import { productsDetails } from '../../../actions/index';
import data from '../../../store/configureStore';
import ErrorMessage from '../common/errorMessage';
import { setSessionStorage } from '../../../utils/storageUtil';
import FreeProductCard from '../common/freeProductCard';

class Products extends Component {
  t = this.props.t;

  constructor(props) {
    super(props);
    this.state = {
      page: 'Products Page',
      dashboardCount: 0,
      grapeVineCount: 0,
      apiCount: 0,
      products: [],
      productNames: [],
      productCategory: [],
      selectedPeriod: 'monthly',
      selectedProduct: '',
      selectedproductCategory: [],
      resultMessage: '',
      isAlertNotRequired: true,
      isComesFromBillingPage: false,
      onlyMonthly: false
    };
    if (data.getState() && data.getState().crud && data.getState().crud.signUpDetails && Object.keys(data.getState().crud.signUpDetails).length > 0) {     
      // if(data.getState() && data.getState().crud && data.getState().crud.monerisDetails && Object.keys(data.getState().crud.monerisDetails).length > 0){	
      //   this.redirectTo = '/billing';	
      // }else {
      //   this.redirectTo = '/signup-payment';
      // }
      this.redirectTo = '/billing';	
    } else {
      this.redirectTo = '/signup';
    }
    this.getToken();
  }

  async getToken() {
    trackPromise(store(apiUrl.getToken, { id: uuid() }).then((jwtToken) => {
      setSessionStorage('jwt_token',jwtToken.data.token);
      this.getPackageInformations();
    }).catch((err) => {
      console.log(err);
    }));
  }

  componentDidMount() {
    if (this.props.selectedProductsDetails && this.props.selectedProductsDetails.length > 0) {
      this.setState({
        selectedproductCategory: this.props.selectedProductsDetails
      });
    }
    if(this.props.isComesFromBillingPage){
      this.setState({
        isComesFromBillingPage: this.props.isComesFromBillingPage
      });
    }
  }

  saveInitiator() {
    this.props.productsDetails({
      products: this.state.selectedproductCategory,
      selectedPeriod: this.state.selectedPeriod,
      selectedCategory: this.state.selectedProduct,
      productNames: this.state.productNames,
      productsArray: this.state.products
    });
  }

  getPackageInformations() {
    trackPromise(fetch(apiUrl.getSubscribers).then((res) => {
      if (res && res.data && res.data.data && res.data.data.products) {
        
        const { products } = res.data.data;
        const productNames = [];
        this.setState({
          products: res.data.data.products,
          apiProductCategory: res.data.data.apiProductCategory
        });
        for (let p = 0; p < products.length; p++) {
          if (products[p]) {
            const supValue = (products[p][Object.keys(products[p])[0]].map(a => a.productCategorySubTitle));
            productNames.push({ product: Object.keys(products[p])[0], count: 0, productCategorySubTitle: supValue ? supValue[0] : null });
          }
        }
        this.setState({ productNames: this.props.productNames && this.props.productNames.length ? this.props.productNames : productNames, selectedProduct: productNames[0].product, isAlertNotRequired: true });
        this.selectedProduct(this.state.selectedProduct);
      }
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

  selectedProduct = (element) => {
    const el = element || this.state.selectedProduct;
    this.setState({ 
      selectedProduct: el,
      onlyMonthly: el.toLowerCase() !== 'etf solutions'
    });
    if (this.state.products && this.state.products.length) {
      for (let p = 0; p < this.state.products.length; p += 1) {
        if (this.state.products[p] && this.state.products[p][el]) {
          if (el !== 'API') {
            if(el.toLowerCase() == 'tmx grapevine') {
              this.setState({ productCategory: this.state.products[p][el] });
            } else {
            this.filterProductsByCycle(this.state.products[p][el]);
            }
          } else {
            // this.filterProductsByCycle(this.state.apiProductCategory);
            this.setState({ productCategory: this.state.apiProductCategory });
          }
        }
      }
    }
  }

  filterProductsByCycle = (products) => {
    const billCycle = this.state.selectedPeriod;
    let selectedproductCategory = [];
    if(products && products.length) {
      for(let p=0; p<products.length; p++) {
        if(products[p] && products[p].billCycle && products[p].billCycle.toLowerCase() === billCycle.toLowerCase()) {
          selectedproductCategory.push(products[p]);
        }
      }
      this.setState({ productCategory: selectedproductCategory });
    }
  }
  

  selectedPer = (el) => {
    this.setState({
      selectedPeriod: el.toLowerCase()
    },() => {
      if(this.state.selectedPeriod) {
        this.selectedProduct('');
      }
    });
  }

  removeProductCategory = (el, productDetails) => {
    const newProductyCategory = this.state.selectedproductCategory;
    if (newProductyCategory && newProductyCategory.length) {
      for (let n = 0; n < newProductyCategory.length; n++) {
        if (newProductyCategory[n]
          && newProductyCategory[n].subCategory === productDetails.subCategory
          && newProductyCategory[n].id === productDetails.id) {
          newProductyCategory.splice(n, 1);
        }
      }
    }
    this.setState({ selectedproductCategory: newProductyCategory });
    this.storeProductCount('remove');
  }

  addProductCategory = (el, details) => {
    const productDetails = details;
    const newProductyCategory = this.state.selectedproductCategory;
    productDetails.subCategory = this.state.selectedProduct;
    productDetails.period = this.state.selectedProduct.toLowerCase() === 'etf solutions' ? this.state.selectedPeriod : 'monthly';
    if (this.state.selectedProduct && this.state.selectedProduct.toLowerCase() !== 'tmx grapevine') {
      for (let p = 0; p < newProductyCategory.length; p++) {
        if (newProductyCategory[p] && ((productDetails.keyName.toLowerCase() === newProductyCategory[p].keyName.toLowerCase()) || (productDetails.tmxProductId.toLowerCase() === newProductyCategory[p].tmxProductId.toLowerCase()))) {
          newProductyCategory.splice(p, 1);
          this.storeProductCount('remove');
        }
      }
    }
    newProductyCategory.push(productDetails);
    this.setState({ selectedproductCategory: newProductyCategory });
    this.storeProductCount('add');
  }

  storeProductCount = (action) => {
    const { productNames } = this.state;
    for (let p = 0; p < productNames.length; p++) {
      if (productNames[p] && productNames[p].product === this.state.selectedProduct) {
        productNames[p].count = (action === 'add') ? productNames[p].count + 1 : productNames[p].count - 1;
        this.setState({ productNames });
      }
    }
  }

  render() {
    const { t } = this.props;
    return (<>
      <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
      <div className="container-fluid">
      <div className="row">
      <div className="col-lg-2 col-md-1"></div>
      <div className="col-lg-7 col-md-10 col-sm-12 col-xs-12 products-main-container" style={{ color: '#fff' }}>
        { !(data.getState() && data.getState().crud && data.getState().crud.signUpDetails && Object.keys(data.getState().crud.signUpDetails).length > 0)
          ? ''
          // this.props.location.query && this.props.location.query.backUrl ? this.props.location.query.backUrl : '/signup'
          : <Link className="back-summary-button" to={this.props.isFromBilling ? '/billing' : '/signup'} onClick={this.saveInitiator.bind(this)}><h6>{t('summary.back_button')}</h6></Link>
        }
        <h2 className="header_alignment product_header">{this.t('products.chooseProduct')}</h2>
        <div className="flex-group-item1">
        <ButtonGroup className="product-list">
        {
        this.state.productNames.map((ele, x) => <Button onClick={() => this.selectedProduct(ele.product)}
          className={`product_button product-name ${this.state.selectedProduct === ele.product ? 'blue_background' : ''}`}
           key={x}> {
            (ele.count !== 0)
            // eslint-disable-next-line no-nested-ternary
            && <Badge variant="light"> {ele.count} </Badge>} {ele.product} {ele.productCategorySubTitle ? <sup className="product-sup"> {ele.productCategorySubTitle} </sup> : null} </Button>)
        }
       </ButtonGroup>
       </div>
      <br />
      {this.state.selectedProduct ?
      <div>
        <div className="flex-group-item1">
          <ButtonGroup className="mb-2 product-list">
            {this.state.onlyMonthly ? 
              <>
                <Button className="product_button product-name blue_background">{this.t('products.monthly')}</Button>
                <Button className="product_button product-name" disabled>{this.t('products.annual')}</Button>
              </>
              :
              <>
                <Button className={`product_button product-name ${this.state.selectedPeriod === 'monthly' ? 'blue_background' : ''}`} onClick={() => this.selectedPer('monthly')} >{this.t('products.monthly')}</Button>
                <Button className={`product_button product-name ${this.state.selectedPeriod === 'yearly' ? 'blue_background' : ''}`} onClick={() => this.selectedPer('yearly')}>{this.t('products.annual')}</Button>
              </>
            }
          </ButtonGroup>
        </div>
      </div>
      : <></>
      }
      <div className="product-trial">{this.state.selectedProduct  ? this.t('products.freeTrial') : (!this.state.selectedProduct ? 'Loading...' : '')}</div>
      { this.state.selectedProduct !== 'API'
       && <div className="api-flex-container1">
       {
       this.state.productCategory.sort((a,b) => !a.image && !b.image ? (a.productWeightage < b.productWeightage) ? 1 : ((b.productWeightage < a.productWeightage) ? -1 : 0) : 0).map((el, idx) => <ProductsLayout
        key={idx}
        productName= {el.keyName}
        id={el.id}
        selectedproductCategory={this.state.selectedproductCategory}
        selectedProduct = {this.state.selectedProduct}
        addProduct={() => this.addProductCategory(el.keyName, el)}
        removeProduct={() => this.removeProductCategory(el.keyName, el)}
        productUrl={el.productUrl}
        productCost={el.amount}
        productDescription={el.description} productDuration={this.state.selectedProduct.toLowerCase() === 'etf solutions' ? this.state.selectedPeriod && this.state.selectedPeriod === 'monthly' ? 'mo' : 'yr' : el.billCycle === 'monthly' ? 'mo' : 'yr'}
        className="product_layout"/>)
       }
       </div>
      }
      <div>
      { this.state.selectedProduct === 'API'
      && <div className="api-grid-container">
        {
      this.state.productCategory.map((col, colIndex) => <div key={colIndex} className={(colIndex== 0 && this.state.productCategory[0].length > 0) ? "api-flex-container no-free-product" : "api-flex-container"}>
      {
          col && col.sort((a,b) => !a.image && !b.image ? (a.productWeightage < b.productWeightage) ? 1 : ((b.productWeightage < a.productWeightage) ? -1 : 0) : (a.image && !b.image ? -1 : 0)).map((pro, i) => (
            pro && pro.image && colIndex==0 || pro.package == "Free"
            ? <></> 
            : <ProductsLayout
              key={i}
              packageType={pro.package}
              productName={pro.keyName}
              id={pro.id}
              productCost={pro.amount}
              descriptions={pro.descriptions}
              selectedproductCategory={this.state.selectedproductCategory}
              selectedProduct = {this.state.selectedProduct}
              addProduct={() => this.addProductCategory(pro.keyName, pro)}
              removeProduct={() => this.removeProductCategory(pro.keyName, pro)}
              productUrl={pro.productUrl}
              productDescription={pro.description} productDuration={'mo'}
              className="product_layout"/>
          ))
      }
      </div>)
        }</div>
      }
      </div>
      <div className="products-continue-button" onClick={this.saveInitiator.bind(this)}>
      <Pagination isComesFromBillingPage= {this.props.isComesFromBillingPage} currentIndex={0} isDisable={!this.state.selectedproductCategory.length}
          showPaginationNumber={false} isContinue={true} redirectTo={ this.redirectTo }/>
      </div>
     </div>
      <div className="col-lg-3 col-md-1"></div>
     </div>
     </div>
     </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  productsDetails: (details) => dispatch(productsDetails(details)),
});

const mapStateToProps = (state) => ({
  selectedProductsDetails: state.crud.products,
  signUpDetailsSaved: state.crud.signUpDetails,
  productNames: state.crud.productNames,
  isFromBilling: state.crud.isFromBilling
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Products));
