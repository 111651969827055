/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import { connect } from 'react-redux';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Graph from '../common/graph';
import { getCurrentUserDetails } from '../../../actions/index';
import download from 'downloadjs';
import json2xls from 'json2xls';

import "react-datepicker/dist/react-datepicker.css";

class usageChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProducts: '',
      subscriptionType: '',
      products: [],
      startDate: new Date(),
      endDate: new Date(),
      requestMade: 0,
      dataArr: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.downloadRequests = this.downloadRequests.bind(this);
    this.requestMade = this.requestMade.bind(this);
  }

  requestMade(requestMade, dataArr) {
    this.setState({
      requestMade,
      dataArr,
    });
  }

  handleChange(event) {
    const selectedProductType = this.props.products.filter((product) => {
      if(product.name === event.target.value) {
        return product;
      }
    });
    this.setState({
      selectedProducts: event.target.value,
      selectedProductType: selectedProductType[0].subscriptionType
    });
  }

  downloadRequests = () => {
    const { dataArr } = this.state;
    const graphData = [];
    dataArr.map((data) => {
      if(data.amt > 0){
        let obj = {};
        obj['Client Name'] = `${this.props.currentUserDetails.organization} ${this.props.currentUserDetails.team}`;
        obj['API Group Name'] = this.state.selectedProducts;
        obj['Date of Access'] = data.name;
        obj['Number of Calls'] = data.amt;
        graphData.push(obj);
      }
    });
    const xls = json2xls(graphData);
    download(xls, `${this.props.currentUserDetails.organization}.xlsx`, 'binary');
  }

  subDays(today, noOfDays){
    var myCurrentDate=today;
    var myPastDate=new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - noOfDays);
    return myPastDate;
  }

  setStartDate(event) {
    this.setState({
      startDate: event,
    });
  }

  setEndDate(event) {
    this.setState({
      endDate: event,
    });
  }

  componentDidMount() {
    if(this.props.products && this.props.products.length > 0) {
      this.setState({
        products: this.props.products,
        selectedProducts: this.props.products[0].name,
        selectedProductType: this.props.products[0].subscriptionType,
      });
    }
  }

  render() {
    const subscribedAPIProducts = this.props.products ? this.props.products : [];
    const { selectedProducts, selectedProductType, startDate, endDate } = this.state;
    return (
          <Jumbotron className="jumbotron_usage_chart_summary">
            <div className="usage_chart_main_panel_flex">
              <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 usage_chart_col1">
                <Jumbotron className="jumbotron_usage_chart11">
                  <div className="row usage_panel_dropdown_section">
                    <div className="usage-label">
                      <div><label>Group</label></div>
                      <div>
                        <select
                          id="dropdown-item-button"
                          className="usage-drop-downs"
                          onChange={(e) => this.handleChange(e)}>
                          {subscribedAPIProducts.map((item, i) =>
                            <option key={i} value={item.name} selected={item.name === selectedProducts}>{item.name}</option>
                          )}    
                        </select>
                      </div>
                    </div>
                    <div className="usage-label">
                      <div><label>From</label></div>
                      <div>
                        <DatePicker className="chart-datepicker"
                          selected={startDate}
                          onChange={(date) => this.setStartDate(date)}
                          minDate={this.subDays(new Date(), 180)}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className="usage-label">
                      <div><label>To</label></div>
                      <div>
                        <DatePicker className="chart-datepicker"
                          selected={endDate}
                          onChange={(date) => this.setEndDate(date)}
                          minDate={this.state.startDate || new Date() || this.subDays(new Date(), 180)}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className="usage-download-label">
                      <div className="usage-download"><label>Download</label></div>
                      <div className="fa-download-outline" onClick={this.downloadRequests}>
                        <FontAwesomeIcon icon={faDownload} size="1x" />
                      </div>
                    </div>
                  </div>
                </Jumbotron>
                <Jumbotron className="jumbotron_usage_chart12">
                    <div className="col-lg-12 col-md-12 col-sm-12 sol-xs-12">
                      {/* <img
                        className="chart_img"
                        src={chartAnnual}
                        alt="Chart Annual"
                      ></img> */}
                      {selectedProducts
                      ? <Graph selectedProducts={selectedProducts} startDate={startDate} endDate={endDate} requestMade={this.requestMade} />
                      : '' }
                    </div>
                </Jumbotron>
              </div>
              <Jumbotron className="jumbotron_usage_chart2">
                    <div className="api_usage_details_layout">
                      <div className="card_details_usage">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            Client Name
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            {`${this.props.currentUserDetails.organization} ${this.props.currentUserDetails.team} `} 
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="card_details_usage">
                          <span className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            Client Subscription
                          </span>
                          <span className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            {selectedProductType}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="card_details_usage">
                          <span className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            Requests made
                          </span>
                          <span className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            {this.state.requestMade}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="card_details_usage">
                          <span className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            Requests Allowed(Monthly)
                          </span>
                          <span className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            500000
                          </span>
                        </div>
                      </div>
                    </div>
              </Jumbotron>
            </div>
          </Jumbotron>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCurrentUserDetails: (details) => dispatch(getCurrentUserDetails(details)),
});

const mapStateToProps = (state) => ({
  currentUserDetails: state.crud.currentUser,
  selectedProduct: state.crud.selectedProductDetail,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(usageChart));
