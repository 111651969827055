/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import '../../i18n';

class SelectedProductsLayout extends Component {
    t = this.props.t;

    state = { };

    constructor(props) {
      super(props);
      this.state = {};
    }

    formatAmount(x) {
      let value = parseFloat(x).toLocaleString('en-US' ,{
        style: 'currency',
        currency: 'USD',//currency: 'CAD'
        maximumFractionDigits: 2
      })
      return value;
    }

    render() {
      return (
        <div  className="card products-card-layout">
                <button className="btn x_button" hidden={!this.props.isEditable} onClick={this.props.clickFn}>X</button>
                <span className="product_card_name">{this.props.productName}</span>
          <span className="summary-amount-tags" hidden={this.props.isEditable}>
          <h4 className="price_font_change">{this.props.productCost ? this.formatAmount(this.props.productCost) : `$0.00`}/<sub>{this.props.productDuration}</sub></h4>
          </span>
        </div>
      );
    }
}

export default withNamespaces()(SelectedProductsLayout);
