/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { trackPromise } from "react-promise-tracker";
import "../../i18n";
import Jumbotron from "react-bootstrap/Jumbotron";
import { connect } from "react-redux";
import Pagination from "../common/pagination";
import SelectedProducts from "../common/selectedProducts";
import validation from "../../../assets/properties/validation.json";
import { fetch, store } from "../../../utils/httpUtil";
import apiUrl from "../../../utils/apiUrl";
import {
  saveBillingDetails,
  isFromBillingUpdate,
  taxAndPaymentUpdate,
} from "../../../actions/index";
import ErrorMessage from "../common/errorMessage";

class Billing extends Component {
  state = {};

  t = this.props.t;

  constructor(props) {
    super(props);
    this.state = {
      input: {
        first_name: "",
        last_name: "",
        address1: "",
        address2: "",
        city: "",
        selectedCountry: "",
        selectedProvince: "",
        email: "",
        phonenumber: "",
        postalcode: "",
        ponumber: "",
        selectedProvincdeRefId: "",
      },
      requiredFlags: {
        first_name: true,
        last_name: true,
        address1: true,
        address2: false,
        city: true,
        selectedCountry: true,
        selectedProvince: true,
        email: true,
        phonenumber: true,
        postalcode: true,
        ponumber: false,
      },
      errors: {
        first_name: "first_name_req_err_msg",
        last_name: "last_name_req_err_msg",
        address1: "address1_req_err_msg",
        email: "email_req_err_msg",
        city: "city_req_err_msg",
        selectedCountry: "country_req_err_msg",
        selectedProvince: "province_req_err_msg",
        phonenumber: "phonenumber_req_err_msg",
        postalcode: "postalcode_req_err_msg",
      },
      touched: {},
      particularState: [],
      isValid: false,
      stateLabel: this.t("billing.province"),
      country: [],
      province: [],
      resultMessage: "",
      isAlertNotRequired: true,
      removeRequired: true,
      isDisableUpdated: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleTouch = this.handleTouch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.emptyPostalCode = this.emptyPostalCode.bind(this);
    this.getCountries();
    if (
      this.props.signUpDetailsSaved &&
      !this.props.signUpDetailsSaved.first_name
    ) {
      this.props.history.push(this.props.defaultPage);
    }
  }

  getCountries() {
    trackPromise(
      fetch(apiUrl.getCountries)
        .then((res) => {
          if (res && res.data && res.data.data) {
            let country = [];
            let priorityCountry = [];
            for (var i = 0; i < res.data.data.length; i++) {
              country.push({
                countryName: res.data.data[i].countryName,
                countryCode: res.data.data[i].countryCode,
              });
              if (
                res.data.data[i].countryCode === "US" ||
                res.data.data[i].countryCode === "CA"
              ) {
                country.pop(
                  res.data.data[i].countryName,
                  res.data.data[i].countryCode
                );
                priorityCountry.push({
                  countryName: res.data.data[i].countryName,
                  countryCode: res.data.data[i].countryCode,
                });
              }
            }
            country.sort(function (a, b) {
              if (a.countryName < b.countryName) {
                return -1;
              }
              if (a.countryName > b.countryName) {
                return 1;
              }
              return 0;
            });
            for (var j = 0; j < priorityCountry.length; j++) {
              country.unshift({
                countryName: priorityCountry[j].countryName,
                countryCode: priorityCountry[j].countryCode,
              });
            }
            this.setState({ country });
          }
        })
        .catch((err) => {
          this.setState({
            resultMessage: err.message,
            isAlertNotRequired: false,
          });
          window.scrollTo(0, 0);
        })
    );
  }

  getProvince(selectedCountry) {
    trackPromise(
      store(apiUrl.getProvince, { selectedCountry: selectedCountry })
        .then((res) => {
          if (res && res.data && res.data.data) {
            let particularState = [];
            for (var i = 0; i < res.data.data.length; i++) {
              particularState.push({
                provinceName: res.data.data[i].provinceName,
                provinceCode: res.data.data[i].provinceCode,
                referenceId: res.data.data[i].referenceId,
                countryCode: res.data.data[i].countryCode,
              });
            }
            particularState.sort(function (a, b) {
              if (a.provinceName < b.provinceName) {
                return -1;
              }
              if (a.provinceName > b.provinceName) {
                return 1;
              }
              return 0;
            });
            this.setState({ particularState });
          }
        })
        .catch((err) => {
          this.setState({
            resultMessage: err.message,
            isAlertNotRequired: true,
          });
          window.scrollTo(0, 0);
        })
    );
  }

  countryChange(e) {
    const { requiredFlags, errors, input } = this.state;
    const { t } = this.props;
    let stateLabel;
    let postalCodeLabel;
    let particularState;
    let removeRequired;
    if (e) {
      this.handleChange(e);
      this.getProvince(e.target.value);
      if (e.target.value === "CA") {
        input.selectedProvince = "";
        requiredFlags.postalcode = true;
        errors.selectedProvince = "province_req_err_msg";
        // if(input.postalcode === "" || undefined){
        //   errors.postalcode = 'postalcode_req_err_msg';
        // }else {
        errors.postalcode = "";
        // }
        stateLabel = t("billing.province");
        postalCodeLabel = t("billing.postalcode");
      } else if (e.target.value === "US") {
        input.selectedProvince = "";
        requiredFlags.postalcode = true;
        errors.selectedProvince = "province_req_err_msg";
        // if(input.postalcode === "" || undefined){
        //   errors.postalcode = 'postalcode_req_err_msg';
        // }else {
        errors.postalcode = "";
        // }
        stateLabel = t("billing.state");
        postalCodeLabel = t("billing.zipcode");
      } else {
        input.selectedProvince = "";
        requiredFlags.postalcode = false;
        errors.selectedProvince = "";
        errors.postalcode = "";
        stateLabel = t("billing.province");
        postalCodeLabel = t("billing.postalcode");
      }
      particularState = this.state.province.filter(
        (item) => item.country_code === e.target.value
      );
    } else {
      const { input } = this.state;
      if (input.selectedCountry === "CA") {
        requiredFlags.postalcode = true;
        errors.selectedProvince = "";
        // if(input.postalcode === "" || undefined){
        //   errors.postalcode = 'postalcode_req_err_msg';
        // }else {
        errors.postalcode = "";
        // }
        stateLabel = t("billing.province");
        postalCodeLabel = t("billing.postalcode");
      } else if (input.selectedCountry === "US") {
        requiredFlags.postalcode = true;
        errors.selectedProvince = "";
        // if(input.postalcode === "" || undefined){
        //   errors.postalcode = 'postalcode_req_err_msg';
        // }else {
        errors.postalcode = "";
        // }
        stateLabel = t("billing.state");
        postalCodeLabel = t("billing.zipcode");
      } else {
        requiredFlags.postalcode = false;
        errors.selectedProvince = "";
        errors.postalcode = "";
        stateLabel = t("billing.province");
        postalCodeLabel = t("billing.postalcode");
      }
      particularState = this.state.province.filter(
        (item) => item.country_code === input.selectedCountry
      );
    }
    requiredFlags.selectedProvince = true;
    removeRequired = true;

    this.setState({
      particularState,
      stateLabel,
      postalCodeLabel,
      removeRequired,
      requiredFlags,
      errors,
      input,
    });
  }

  componentDidMount() {
    this.props.isFromBillingUpdate(false);
    let stateLabel, postalCodeLabel, removeRequired, isValid;
    const { t } = this.props;
    if (this.props.billingDetailsSaved) {
      this.getProvince(this.props.billingDetailsSaved.selectedCountry);
      isValid = true;
      const touched = {
        first_name: true,
        last_name: true,
        address1: true,
        email: true,
        city: true,
        selectedProvince: true,
        selectedCountry: true,
        postalcode: true,
        phonenumber: true,
        selectedProvincdeRefId: true,
      };
      if (this.props.billingDetailsSaved.selectedCountry === "CA") {
        stateLabel = t("billing.province");
        postalCodeLabel = t("billing.postalcode");
      } else if (this.props.billingDetailsSaved.selectedCountry === "US") {
        stateLabel = t("billing.state");
        postalCodeLabel = t("billing.zipcode");
      } else {
        stateLabel = t("billing.province");
        postalCodeLabel = t("billing.postalcode");
      }
      removeRequired = true;

      const errors = {};
      this.setState(
        {
          isValid,
          input: this.props.billingDetailsSaved,
          touched,
          errors,
          stateLabel,
          postalCodeLabel,
          removeRequired,
        },
        () => {
          this.countryChange();
          this.validateAllFields();
          const productsCount = this.props.productDetails.length > 0;
          this.disableButton(productsCount);
        }
      );
    } else {
      stateLabel = t("billing.province");
      postalCodeLabel = t("billing.postalcode");
      (removeRequired = false),
        this.setState({
          stateLabel,
          postalCodeLabel,
          removeRequired,
        });
    }
  }

  handleTouch(e) {
    const { touched } = this.state;
    if (e.target.name && touched[e.target.name] !== true) {
      touched[e.target.name] = true;
      this.setState({
        touched,
      });
    }
  }

  handleChange(e) {
    const { input } = this.state;
    input[e.target.name] = e.target.value;
    this.props.taxAndPaymentUpdate(false);
    this.validateForm(e);
    this.setState(
      {
        input,
      },
      () => {
        this.validateAllFields();
        if (e.target.name === "selectedProvince") {
          this.emptyPostalCode();
        }
      }
    );
  }

  emptyPostalCode() {
    const { input, requiredFlags, errors } = this.state;
    input.postalcode = '';
    if(input.selectedCountry === 'CA') {
      requiredFlags.postalcode = true; 
      errors.postalcode = 'postalcode_req_err_msg';
    } else if(input.selectedCountry === 'US') {
      requiredFlags.postalcode = true;
      errors.postalcode = 'zipcode_req_err_msg';
    } else {
      requiredFlags.postalcode = false;
      errors.postalcode = '';
    }
    this.setState({
      input, requiredFlags, errors
    })
  }
  handleSubmit(e) {
    e.preventDefault();
    if (this.validateForm()) {
      const input = {};
      input.name = "";
      input.email = "";
      input.comment = "";
      this.setState({ input });
    }
  }

  checkMailRegex(value) {
    const splittedValueOne = value.split("@");
    const splittedValue = splittedValueOne[0].split("");
    const splittedValueLength = splittedValue.length;
    if (
      splittedValue[0] === "." ||
      splittedValue[splittedValueLength - 1] === "."
    ) {
      return true;
    } else {
      if (splittedValueOne.length > 1) {
        const splittedValueTwo = splittedValueOne[0].split("..");
        if (splittedValueTwo.length > 1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }
  }

  validateForm(e) {
    let { name, value } = e.target;
    const { input, errors } = this.state;
    let isValid = true;
    if (!value || value === undefined || value === "") {
      // errors[`${name}`] = `${name}_req_err_msg`;
      errors[`${name}`] = e.target.required
        ? e.target.name === "postalcode" && input.selectedCountry === "US"
          ? "zipcode_req_err_msg"
          : `${name}_req_err_msg`
        : "";
    }

    if (typeof value !== "undefined" && value !== "") {
      //const pattern = e.target.name === 'postalcode' && input.selectedCountry === 'US' ? new RegExp(validation['zipcode_regx']) : new RegExp(validation[`${name}_regx`]);
      let pattern;
      if (e.target.name === "postalcode" && input.selectedCountry === "US") {
        pattern = new RegExp(validation["zipcode_regx"]);
      } else if (
        e.target.name === "postalcode" &&
        input.selectedCountry === "CA"
      ) {
        pattern = new RegExp(validation["postalcode_regx"]);
      } else if (
        e.target.name === "postalcode" &&
        input.selectedCountry !== "CA" &&
        input.selectedCountry !== "US"
      ) {
        pattern = new RegExp(validation[""]);
      } else {
        pattern = new RegExp(validation[`${name}_regx`]);
      }
      if (!pattern.test(value) && value) {
        isValid = false;
        if (e.target.name === "postalcode" && input.selectedCountry === "US") {
          errors[`${name}`] = "zipcode_invalid_err_msg";
        } else if (
          e.target.name === "postalcode" &&
          input.selectedCountry === "CA"
        ) {
          errors[`${name}`] = "postalcode_invalid_err_msg";
        } else if (
          e.target.name === "postalcode" &&
          input.selectedCountry !== "CA" &&
          input.selectedCountry !== "US"
        ) {
          errors[`${name}`] = "";
        } else {
          errors[`${name}`] = `${name}_invalid_err_msg`;
        }
        // errors[`${name}`] = e.target.name === 'postalcode' && input.selectedCountry === 'US' ? 'zipcode_invalid_err_msg' : `${name}_invalid_err_msg`;
      } else {
        errors[`${name}`] = "";
      }

      if (e.target.type === "email") {
        const testRegex = this.checkMailRegex(value);
        if (testRegex) {
          isValid = false;
          errors[`${name}`] = `${name}_invalid_err_msg`;
        }
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  saveInitiator() {
    const { input } = this.state;
    const selectedProvincdeRefId = this.state.particularState.filter(
      (products) => products.provinceCode === this.state.input.selectedProvince
    );
    input.selectedProvincdeRefId =
      selectedProvincdeRefId && selectedProvincdeRefId.length > 0
        ? selectedProvincdeRefId[0].referenceId
        : "";
    this.setState({
      input,
    });
    this.props.saveBillingDetails(this.state.input);
  }

  componentDidUpdate() {
    if (
      this.state.particularState &&
      this.state.particularState.length > 0 &&
      !this.state.isDisableUpdated
    ) {
      const { input } = this.state;
      const selectedProvincdeRefId = this.state.particularState.filter(
        (products) =>
          products.provinceCode === this.state.input.selectedProvince
      );
      input.selectedProvincdeRefId =
        selectedProvincdeRefId && selectedProvincdeRefId.length > 0
          ? selectedProvincdeRefId[0].referenceId
          : "";
      this.setState({
        input,
        isDisableUpdated: true,
      });
    }
    this.props.saveBillingDetails(this.state.input);
  }

  validateAllFields() {
    const { errors, touched } = this.state;
    const inputNew = this.state.input;
    const allInputs = Object.entries(inputNew).map((arr) =>
      this.state.requiredFlags[arr[0]] ? arr[1] !== "" : true
    );
    const allErrors = Object.values(errors);
    const allTouched = Object.values(touched);
    const isValid =
      allErrors.every((arr) => !arr) &&
      (allTouched === {} ||
        (allTouched.every((arr) => arr) && allInputs.every((arr) => arr)));
    // isValid = this.props.sd.length > 0 ? isValid : false;
    this.setState(
      {
        isValid,
      },
      () => {
        const productsCount = this.props.productDetails.length > 0;
        this.disableButton(productsCount);
      }
    );
  }

  disableButton(key) {
    const { errors, touched } = this.state;
    const inputNew = this.state.input;
    const allInputs = Object.entries(inputNew).map((arr) =>
      this.state.requiredFlags[arr[0]] ? arr[1] !== "" : true
    );
    const allErrors = Object.values(errors);
    const allTouched = Object.values(touched);
    const isFieldValid =
      allErrors.every((arr) => !arr) &&
      (allTouched === {} ||
        (allTouched.every((arr) => arr) && allInputs.every((arr) => arr)));
    let isValid = key;
    if (isFieldValid || (isValid && isFieldValid)) {
      this.setState({
        isValid,
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <ErrorMessage
          message={this.state.resultMessage}
          shouldNotDisplay={this.state.isAlertNotRequired}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-1 col-md-1 col-sm-2"></div>
            <Jumbotron className="col-lg-4 col-md-10 col-sm-8 col-xs-8 jumbotron_usage_summary">
              <Link
                className="back-summary-button"
                to={{ pathname: "/products", query: { backUrl: "/billing" } }}
                onClick={this.saveInitiator.bind(this)}
              >
                <h6>{t("summary.back_button")}</h6>
              </Link>
              <h2 className="billing_header_alignment">
                {t("billing.header")}
              </h2>
              <form>
                <div className="form-row header_firstrow_space">
                  <div className="form-group col-lg-6 col-md-6 left_labels_alignment">
                    <label htmlFor="firstname">{t("billing.firstname")}</label>
                    &nbsp;<span className="required-flag">*</span>
                    <input
                      type="text"
                      name="first_name"
                      className="form-control"
                      id="firstname"
                      value={this.state.input.first_name || ""}
                      autoComplete="__"
                      maxLength="100"
                      onChange={this.handleChange}
                      onBlur={this.handleTouch}
                      required={true}
                    />
                    <div className="text-danger">
                      {this.state.touched.first_name &&
                        this.state.errors.first_name &&
                        t(`billing.${this.state.errors.first_name}`)}
                    </div>
                  </div>
                  <div className="form-group col-md-6 col-lg-6">
                    <label htmlFor="lastname">{t("billing.lastname")}</label>
                    &nbsp;<span className="required-flag">*</span>
                    <input
                      type="text"
                      name="last_name"
                      className="form-control"
                      id="lastname"
                      value={this.state.input.last_name || ""}
                      autoComplete="__"
                      maxLength="100"
                      onChange={this.handleChange}
                      onBlur={this.handleTouch}
                      required={true}
                    />
                    <div className="text-danger">
                      {this.state.touched.last_name &&
                        this.state.errors.last_name &&
                        t(`billing.${this.state.errors.last_name}`)}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6 col-lg-6 left_labels_alignment">
                    <label htmlFor="address1">{t("billing.address1")}</label>
                    &nbsp;<span className="required-flag">*</span>
                    <input
                      type="text"
                      name="address1"
                      className="form-control"
                      autoComplete="__"
                      maxLength="500"
                      id="address1"
                      value={this.state.input.address1 || ""}
                      onChange={this.handleChange}
                      onBlur={this.handleTouch}
                      required={true}
                    />
                    <div className="text-danger">
                      {this.state.touched.address1 &&
                        this.state.errors.address1 &&
                        t(`billing.${this.state.errors.address1}`)}
                    </div>
                  </div>
                  <div className="form-group col-md-6 col-lg-6">
                    <label htmlFor="address2">{t("billing.address2")}</label>
                    <input
                      type="text"
                      name="address2"
                      className="form-control"
                      autoComplete="__"
                      maxLength="500"
                      id="address2"
                      value={this.state.input.address2 || ""}
                      onChange={this.handleChange}
                      onBlur={this.handleTouch}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6 col-lg-6 left_labels_alignment">
                    <label htmlFor="city">{t("billing.city")}</label>&nbsp;
                    <span className="required-flag">*</span>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      autoComplete="__"
                      maxLength="100"
                      id="city"
                      value={this.state.input.city || ""}
                      onChange={this.handleChange}
                      required={true}
                      onBlur={this.handleTouch}
                    />
                    <div className="text-danger">
                      {this.state.touched.city &&
                        this.state.errors.city &&
                        t(`billing.${this.state.errors.city}`)}
                    </div>
                  </div>
                  <div className="form-group col-md-6 col-lg-6">
                    <label htmlFor="country">{t("billing.country")}</label>
                    &nbsp;<span className="required-flag">*</span>
                    <select
                      className="form-control dropdown_alignment"
                      name="selectedCountry"
                      id="country"
                      onChange={this.countryChange.bind(this)}
                    >
                      <option selected disabled value="">
                        Select
                      </option>
                      {this.state.country.map((item) => (
                        <option
                          value={item.countryCode}
                          selected={
                            item.countryCode ===
                            this.state.input.selectedCountry
                          }
                          key={item.countryCode}
                        >
                          {item.countryName}
                        </option>
                      ))}
                    </select>
                    <div className="text-danger">
                      {this.state.touched.country &&
                        this.state.errors.country &&
                        t(`billing.${this.state.errors.country}`)}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6 col-lg-6 left_labels_alignment">
                    <label htmlFor="province">{this.state.stateLabel}</label>
                    &nbsp;
                    {this.state.removeRequired ? (
                      <span className="required-flag">*</span>
                    ) : (
                      ""
                    )}
                    {this.state.particularState.length === 0 ? (
                      <input
                        type="text"
                        name="selectedProvince"
                        value={this.state.input.selectedProvince}
                        className="form-control"
                        id="province"
                        autoComplete="__"
                        maxLength="100"
                        onChange={this.handleChange}
                        onBlur={this.handleTouch}
                        required={false}
                      />
                    ) : (
                      <select
                        className="form-control dropdown_alignment"
                        id="selectedProvince"
                        name="selectedProvince"
                        onChange={this.handleChange.bind(this)}
                        required={true}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        {this.state.particularState.map((item) => (
                          <option
                            value={item.provinceCode}
                            selected={
                              item.provinceCode ===
                              this.state.input.selectedProvince
                            }
                            key={item.provinceCode}
                          >
                            {item.provinceName}
                          </option>
                        ))}
                      </select>
                    )}
                    <div className="text-danger">
                      {this.state.errors.province &&
                        t(`billing.${this.state.errors.province}`)}
                    </div>
                  </div>
                  <div className="form-group col-md-6 col-lg-6">
                    <label htmlFor="email">{t("billing.email")}</label>&nbsp;
                    <span className="required-flag">*</span>
                    <input
                      type="email"
                      className="form-control"
                      autoComplete="__"
                      maxLength="100"
                      id="email"
                      name="email"
                      value={this.state.input.email || ""}
                      onChange={this.handleChange}
                      onBlur={this.handleTouch}
                      required={true}
                    />
                    <div className="text-danger">
                      {this.state.touched.email &&
                        this.state.errors.email &&
                        t(`billing.${this.state.errors.email}`)}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6 col-lg-6 left_labels_alignment">
                    <label htmlFor="phonenumber">
                      {t("billing.phonenumber")}
                    </label>
                    &nbsp;<span className="required-flag">*</span>
                    <input
                      type="text"
                      name="phonenumber"
                      className="form-control"
                      autoComplete="__"
                      maxLength="100"
                      id="phonenumber"
                      value={this.state.input.phonenumber || ""}
                      onChange={this.handleChange}
                      onBlur={this.handleTouch}
                      required={true}
                    />
                    <div className="text-danger">
                      {this.state.touched.phonenumber &&
                        this.state.errors.phonenumber &&
                        t(`billing.${this.state.errors.phonenumber}`)}
                    </div>
                  </div>
                  <div className="form-group col-md-6 col-lg-6">
                    <label htmlFor="postalcode">
                      {this.state.postalCodeLabel}
                    </label>
                    &nbsp;
                    {this.state.requiredFlags["postalcode"] ? (
                      <span className="required-flag">*</span>
                    ) : (
                      ""
                    )}
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="__"
                      maxLength="100"
                      id="postalcode"
                      name="postalcode"
                      value={this.state.input.postalcode || ""}
                      onChange={this.handleChange}
                      onBlur={this.handleTouch}
                      required={this.state.removeRequired}
                    />
                    <div className="text-danger">
                      {this.state.touched.postalcode &&
                        this.state.errors.postalcode &&
                        t(`billing.${this.state.errors.postalcode}`)}
                    </div>
                  </div>
                  {/* <div className="form-group col-md-6 col-lg-6">
                  <label htmlFor="ponumber">{t('billing.ponumber')}</label>
                  <input type="text" className="form-control" autoComplete="__" maxLength="100" id="ponumber" name="ponumber" value={this.state.input.ponumber || ''} onChange={this.handleChange} onBlur = {this.handleTouch} required={true}/>
                </div> */}
                </div>
              </form>
              <center onClick={this.saveInitiator.bind(this)}>
                <Pagination
                  currentIndex={2}
                  showPaginationNumber={true}
                  isNext={true}
                  isDisable={!this.state.isValid}
                />
              </center>
            </Jumbotron>
            <SelectedProducts
              isBillingPage={true}
              isRecommendedProducts={true}
              disableButton={this.disableButton}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveBillingDetails: (details) => dispatch(saveBillingDetails(details)),
  isFromBillingUpdate: (details) => dispatch(isFromBillingUpdate(details)),
  taxAndPaymentUpdate: (details) => dispatch(taxAndPaymentUpdate(details)),
});

const mapStateToProps = (state) => ({
  billingDetailsSaved: state.crud.billingDetails,
  signUpDetailsSaved: state.crud.signUpDetails,
  defaultPage: state.crud.defaultPage,
  productDetails: state.crud.products,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Billing));
