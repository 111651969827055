/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import Header from './components/admin/common/header';
import SignUpHeader from './components/subscription/common/header';
import Body from './components/admin/common/body';
import Footer from './components/admin/common/footer';
import SignUpFooter from './components/subscription/common/footer';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { storeCurrentPage } from './actions/index';

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && <div
      style={{
        width: '100%',
        display: 'flex',
        top: '10%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: '1000000',
        height: '100%',
        opacity: '0.5',
      }}
      >
      <Loader type="Circles" color="#16fffb" height="100" width="100" />
      </div>
  );
};

const LoadingScreen = () => {
  return (
    // <div className="loading">Loading&#8230;</div>
    <div
      style={{
        width: '100%',
        display: 'flex',
        top: '10%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: '1000000',
        height: '70vh',
        opacity: '0.5',
      }}
      >
      <Loader type="Circles" color="#16fffb" height="100" width="100" />
      </div>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadFirst: false,
      currentUserDetails: {},
      currentPage: '',
      loadingscreen: false
    };
    this.initiateApp = this.initiateApp.bind(this);
    this.routeInitiate = this.routeInitiate.bind(this);
    this.changeLoader = this.changeLoader.bind(this);
    let locationURL = window.location.href.split('/');
    let exactURL = locationURL[locationURL.length-1].split('?')[0];
    if (exactURL === 'login' || exactURL === 'resetpassword') {
      import('./App.css');
    } else {
      if (this.props.currentUserDetails.roleType !== 'isUIAdmin') {
        import('./App.css');
      } else {
        import('./App_signup.css');
      }
    } 
  }

  initiateApp () {
    if (!this.state.loadFirst) {
      this.setState({
        loadFirst: true,
        currentUserDetails: this.props.currentUserDetails
      });
    }
  }

  routeInitiate (page) {
    this.setState({
      currentPage: page,
    });
    this.props.storeCurrentPage(page);
  }

  changeLoader() {
    this.setState({
      loadingscreen: true
    });
  }

  render() {
    let locationURL = window.location.href.split('/');
    let exactURL = locationURL[locationURL.length-1].split('?')[0];
    if(!this.state.loadingscreen) { 
      setTimeout(() => {
        this.changeLoader();
      }, 1000);
    }
    return (
      <>
      {!this.state.loadingscreen 
      ? <LoadingScreen />
      : <div className="body-font">
        {(exactURL === 'login' || exactURL === 'resetpassword') ? <Header currentUser={this.state.currentUserDetails} routeInitiate={this.routeInitiate}/> : (this.props.currentUserDetails.roleType !== 'isUIAdmin')
        ? <Header currentUser={this.state.currentUserDetails} routeInitiate={this.routeInitiate}/>
        : <SignUpHeader /> }
          <div className="background_color"><LoadingIndicator /><Body initiateApp={this.initiateApp} currentPage={this.state.currentPage} /></div>
        {(exactURL === 'login' || exactURL === 'resetpassword') ? <Footer /> : this.props.currentUserDetails.roleType !== 'isUIAdmin'
        ? <Footer />
        : <SignUpFooter /> }
      </div>}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUserDetails: state.crud.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
