/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';

class ErrorMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldNotDisplay: true
    };
    this.closeAlert = this.closeAlert.bind(this);
  }

  closeAlert() {
    document.getElementById('alert-window').style.display = 'none';
  }

  componentDidUpdate() {
    if (this.props.shouldNotDisplay !== this.state.shouldNotDisplay) {
      this.setState({
        shouldNotDisplay: this.props.shouldNotDisplay
      });
    }
    if (!this.state.shouldNotDisplay) {
      document.getElementById('alert-window').style.display = 'block';
    }
  }

  render() {
    return (
        <div className='container-fluid error-margin'>{() => displayState()}
            <div className='row'>
                <div className='col-lg-2 col-md-2 col-sm-0'></div>
                <div className='col-lg-10 col-md-10 col-sm-12'>
                    <Alert variant="danger" id='alert-window' className='alert-styles' hidden={this.props.shouldNotDisplay}>
                        <a style={{ cursor: 'pointer' }} className="alert-close" data-dismiss="alert" aria-label="close" onClick={this.closeAlert}>&times;</a>
                        { !this.props.onlyErrorMessage
                        ? <div><h1>There’s been a glitch…</h1><p>We’re not quite sure what went wrong. You can try again shortly or reach out to us directly using the help button on the bottom right.</p></div>
                        : '' }
                        <p>{this.props.message}</p>
                    </Alert>
                </div>
                <div className='col-lg-1'></div>
            </div>
        </div>
    );
  }
}

export default ErrorMessage;
