/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import { storeCurrentPage, userListUpdated } from '../../../actions/index';
import apiUrl from '../../../utils/apiUrl';
import { store } from '../../../utils/httpUtil';
import ErrorMessage from '../common/errorMessage';
import SuccessMessage from '../common/successMessage';

class PaymentFinal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      resultMessage: '',
      isAlertNotRequired: true,
      isSuccessAlertNotRequired: true,
      successMessage: '',
      disableBackButton: false
    };
    this.finalSubmit = this.finalSubmit.bind(this);
    this.formatAmount = this.formatAmount.bind(this);
  }

  finalSubmit() {
    let monerisDetails;
    if (this.props.isNewCard) {
      monerisDetails = this.props.monerisDetails;
      // monerisDetails['currency'] = 'CAD';
    } else {
      monerisDetails = {
        skipMonerisFlow: this.props.cardDetails ? false : true,
        dataKey: this.props.cardDetails ? this.props.cardDetails.respDataKey : '',
        // currency: this.props.cardDetails ? this.props.cardDetails.cardCurrency : 'CAD',
        issuerId: this.props.cardDetails ? this.props.cardDetails.issuerId : '',
        cardDetails: this.props.cardDetails,
        monerisError: false,
      }
    }
    const clientDetails = {
      firstName: this.props.userDetails.firstName,
      lastName: this.props.userDetails.lastName,
      email: this.props.userDetails.email,
      usersList: this.props.usersList,
    }
    const billingDetails = {
      selectedCountry: this.props.userDetails.country,
      addressLine1: this.props.userDetails.addressLine1,
      zipCode: this.props.userDetails.postalCode,
      phonenumber: this.props.userDetails.contactNumber,
      email: this.props.userDetails.email,
    };
    const access_token = this.props.userDetails.access_token;
    const request = {
      payment_amount: this.props.latest_payment_amount || 1,
      monerisDetails,
      billingDetails,
      clientDetails,
      isReplaceNewCard: this.props.isReplaceNewCard,
      isNewCard: this.props.isNewCard,
      returnBilling: this.props.billingListError,
      paymentListError: this.props.paymentListError,
      clientId: this.props.userDetails.clientId,
      cardId: this.props.cardDetails.cardId,
      token: access_token,
    };
    let url = this.props.isNewCard ? apiUrl.saveMonerisDetails : apiUrl.purchaseMoneris;
    this.props.userListUpdated(false);
    trackPromise(store(url, request).then((res) => {
      if (res) {
        if(res.data && res.data.status == '-1'){
          if(res.data.data === 'Failed to create the payment') {
            this.setState({ isSuccessAlertNotRequired: false, isAlertNotRequired: true, successMessage: res.data.data, disableBackButton: false });
          }
        }else{
          this.setState({ isSuccessAlertNotRequired: false, isAlertNotRequired: true, disableBackButton: true });
        }        
      }
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false, disableBackButton: false });
      window.scrollTo(0, 0);
    }));
  }

  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
        this.props.history.push(this.props.currentLoadingPage);
        this.props.storeCurrentPage('');
    }
  }

  formatAmount(x) {
    let value = parseFloat(x).toLocaleString('en-US' ,{
      style: 'currency',
      currency: 'USD',//currency: 'CAD'
      maximumFractionDigits: 2
    })
    return value;
  }

  render() {
    const { t } = this.props;
    return (
      <>
      <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
      <SuccessMessage shouldNotDisplaySuccess={this.state.isSuccessAlertNotRequired} successMessage={this.state.successMessage} backButton={true}/>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
          <Jumbotron className="col-lg-10 col-md-10 col-sm-10 col-xs-10 jumbotron_usage_summary">
          {this.props.userDetails.roleType === "Client Admin" || this.props.userDetails.roleType === 'TMX Admin' || this.props.userDetails.roleType === 'TMX BA Admin' ? <Link className="payment_console_back_link" to='/selectCard' hidden={this.state.disableBackButton}>{t('summary.back_button')}</Link> : ''}
          <div className="row payment_header_panel">
                <div className="payment_heading1">
                {t('paymentFinal.main_heading')}
                </div>
              </div>
          <Jumbotron className="jumbotron_usage_panel">
                  <div className="col-lg-12 col-md-12 col-sm-12 sol-xs-12">
                      <div className="pay_now_cards">
                        <div>
                        <p>{t('paymentFinal.main_statment')}</p>
                        </div>
                        <div>
                            <div className="confirm_info_panel">
                                <span>{t('paymentFinal.payment_amount')} {this.props.latest_payment_amount ? this.formatAmount(this.props.latest_payment_amount) : 0.00} </span>
                            </div>
                        </div>
                        <div></div>
                        <div className="payment-submit-button">
                        {/* <Link className="pay_buttons" to='/paymentConfirm' amount={this.props.amount}></Link> */}
                      <button className='btn pay_now_cont_btn' onClick={this.finalSubmit} type='button' disabled={this.props.latest_payment_amount && !this.state.isSuccessAlertNotRequired}>Submit</button>
                    </div>
                      </div>
 
                  </div>
                </Jumbotron>
          </Jumbotron>
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  latest_payment_amount: state.crud.latest_payment_amount.payableAmount,
  currentLoadingPage: state.crud.currentLoadingPage,
  isNewCard: state.crud.isNewCard,
  isReplaceNewCard: state.crud.saveCard,
  monerisDetails: state.crud.monerisDetails,
  userDetails: state.crud.currentUser,
  cardDetails: state.crud.cardDetails,
  billingListError: state.crud.billingListError,
  paymentListError: state.crud.paymentListError,
  usersList: state.crud.usersList,
});

const mapDispatchToProps = (dispatch) => ({
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
  userListUpdated: (details) => dispatch(userListUpdated(details))
  
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(PaymentFinal));
