/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import { connect } from 'react-redux';
import Jumbotron from 'react-bootstrap/Jumbotron';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import apiUrl from '../../../utils/apiUrl';
import { trackPromise } from 'react-promise-tracker';
import { store } from '../../../utils/httpUtil';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import PaymentDetails from '../common/paymentDetails';
import AdminTerminalDetails from '../common/adminTerminalDetails';
import { billingDetails, storeCurrentPage, paymentDetails, updatePayment } from '../../../actions/index';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ErrorMessage from '../common/errorMessage';
import infoImage from '../../../assets/img/information_icon.png';

class adminPaymentTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableBillingTable: true,
      enableBillingTerminal: true,
      paymentDet: {
        payableAmount: 0,
        paymentAmount: 0,
        lastPaid: ''
      },
      resultMessage: '',
      isAlertNotRequired: true,
    };
    // this.props.getBillingDetails();

    this.handleTableSwitch = this.handleTableSwitch.bind(this);
    this.handleTerminalSwitch = this.handleTerminalSwitch.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.getPaymentDetails();
  }

  openPopup() {
    const modal = document.getElementById('instructionPopup');
    modal.style.display = 'block';
  }

  handleTableSwitch = (value) => {
    const obj = this.state;
    obj.enableBillingTable = value === 1;
    this.setState({
      obj,
    });
  };

  handleTerminalSwitch = (value) => {
    const obj = this.state;
    obj.enableBillingTerminal = value === 1;
    this.setState({
      obj,
    });
  };

  getPaymentDetails() {
    const paymentDetails = this.props.paymentDetails;
    let paymentDet = this.state.paymentDet;
    if(paymentDetails && paymentDetails.length) {
      for(let i=0; i<paymentDetails.length; i++) {
        if(paymentDetails[i] && paymentDetails[i].status && paymentDetails[i].status.toLowerCase() === 'error') {
          const amount = parseFloat(paymentDetails[i].amt_paid);
          paymentDet.payableAmount = paymentDet.payableAmount + amount;
          paymentDet.billingPeriod = {
            startDate: moment(paymentDetails[i].billing_start_date).format('MMMM Do YYYY'),
            endDate: moment(paymentDetails[i].billing_end_date).format('MMMM Do YYYY'),
          }
        } else {
          paymentDet.paidAmount = paymentDetails[i].amt_paid;
          paymentDet.lastPaid = moment(paymentDetails[i].payment_date).format('MMMM Do YYYY');
        }
      }
      this.setState({paymentDet});
      this.props.updatePayment(paymentDet);
    }
  }

  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
        this.props.history.push(this.props.currentLoadingPage);
        this.props.storeCurrentPage('');
    }
  }

  exportPdf() {
    const details = {
      clientId: this.props.currentUserDetails.clientId,
      token: this.props.currentUserDetails.access_token
    }
    trackPromise(store(apiUrl.exportPdf, details).then((res) => {
      var documentName = 'TMX Export PDF - '+ moment(new Date()).toString();
      let response_buffer = res.data.data.data;
      const arr = new Uint8Array(response_buffer);
      var blob = new Blob([arr], {type: "application/pdf;charset=utf-8"});
      saveAs(blob, documentName);
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

  closeEditUser() {
    const modal = document.getElementById('instructionPopup');
    modal.style.display = 'none';
  }

  render() {
    const { t } = this.props;
    const userDetails = this.props.userDetails ? this.props.userDetails : null;
    const { enableBillingTable, enableBillingTerminal } = this.state;
    const billingPeriod = this.props.billingPeriod ? this.props.billingPeriod : null;
    const paymentAmount = this.props.paymentAmount ? this.props.paymentAmount : null;
    const paymentDate = this.props.paymentDate ? this.props.paymentDate : null;
    const { paymentType } = this.props.currentUserDetails;
    const paymentTypeDisplay = paymentType ? t(`current_payment_method.${paymentType}`) : '';

    return (
      <>
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            <Jumbotron className="col-lg-10 col-md-10 col-sm-10 col-xs-10 jumbotron_usage_summary">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="usage_heading1">Billing & Refunds</div>
                  <Jumbotron className="col-lg-12 col-md-12 col-sm-12 col-xs-12 jumbotron_admin_payment_billing_panel1">
                    <div className="payment_admin_main_body">
                      <div className="payment_method">{t('current_payment_method.label')}<span className="payment_method_value">{paymentTypeDisplay}</span></div>
                      <div className="payment_sub2_body">
                        <ToggleButtonGroup
                            type="radio"
                            name="options"
                            className="terminal_toggle_btn"
                            defaultValue={1}
                            onChange={this.handleTerminalSwitch}
                        >
                          <ToggleButton className={enableBillingTerminal ? 'toggle_terminal_active' : ''} value={1}>Billing Terminal </ToggleButton>
                          { (this.props.currentUserDetails && this.props.currentUserDetails.roleType === 'TMX BA Admin') ?
                          <ToggleButton className={!enableBillingTerminal ? 'toggle_terminal_active' : ''} value={2}>Refund Terminal </ToggleButton>
                          : '' }
                        </ToggleButtonGroup>
                      </div>
                      <AdminTerminalDetails getPaymentTable={enableBillingTerminal} details={this.props.details} />                            
                    </div>
                  </Jumbotron>
                  <br />
                  <div className="payment_heading2">
                  {t('payment.detailed_sub1')} &amp; {t('payment.detailed_sub2')}
                  </div>
                  <span className="payment-detailed-icon-other" onClick={this.openPopup}><img src={infoImage} className='info_style'/><span className="AddMoreProductsWithCommentOther"><h6>{t('payment.understand_bill_list')}</h6></span></span>
                  <Jumbotron className="col-lg-12 col-md-12 col-sm-12 col-xs-12 jumbotron_payment_billing2">
                      <div className="col-lg-12 col-md-12 col-sm-12 sol-xs-12 payment_table_preview_panel">
                        <div className="payment_main_body">
                          <div className="payment_sub1_body">
                            {/* <ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleTableSwitch}>
                               <ToggleButton className={enableBillingTable ? 'toggle_active' : ''} value={1}>{t('payment.billing_btn')} </ToggleButton>
                              <ToggleButton className='toggle_active' value={2}>{t('payment.payment_btn')} </ToggleButton>
                            </ToggleButtonGroup>  */}
                          </div>
                          <div style={{textAlign: 'right', marginRight: '2.5rem'}}>
                            <a className="export_pdf_link" to="#" style={{cursor: 'pointer'}} onClick={() => this.exportPdf()}> {t('payment.export_pdf')}</a>
                          </div>
                          <div className="payment_sub_table">
                            <PaymentDetails />
                          </div>
                        </div>
                      </div>
                  </Jumbotron>
                </div>
            </Jumbotron>
            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
          </div>
        </div>

        <div id='instructionPopup' className='modal instructionDiv'>
          <div className="subscribedproducts-modal-content">
            <div className='container-fluid'>
              <div className='row'>
                  <span className="close" onClick={this.closeEditUser}>&times;</span>
              </div>
              <div className='row'>
                {/* <h5 className='edituser-popup-header'>{t('usermanagement.subscribedproducts')}</h5> */}
                <ul>
                    <li><h6>{t('payment.understand_bill_list1')}</h6></li>
                    <li><h6>{t('payment.understand_bill_list2')}</h6></li>
                </ul>
              </div>
              </div>
              <div className='row'>
                {/* <button onClick={this.updateProducts} className="btn btn-info products-save-button">{t('usermanagement.save')}</button> */}
              </div>
          </div>
          </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserDetails: state.crud.currentUser,
  userDetails: state.crud.userDetails,
  enableBilling: state.crud.enableBilling,
  billingPeriod: state.crud.billingPeriod,
  paymentAmount: state.crud.last_payment_amt,
  paymentDate: state.crud.last_payment_date,
  currentLoadingPage: state.crud.currentLoadingPage,
  paymentDetails: state.crud.paymentlist
});

const mapDispatchToProps = (dispatch) => ({
  getBillingDetails: () => dispatch(billingDetails()),
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
  updatePayment: (details) => dispatch(updatePayment(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(adminPaymentTab));
