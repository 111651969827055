/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import bannerImage from '../../../assets/img/TMX-ANALYTICS-IMG.png';

class BannerImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="col-lg-12 col-md-12 col-sm-8 col-xs-8 margin-left banner-body-main ">
          {this.props.isConfirmation ? <div className="confirm_banner_header">{t('confirmation.banner_header')}</div> : ''}
          <img className={`bannerImage ${this.props.className}`} src={bannerImage}/>
      </div>
    );
  }
}

export default BannerImage;
