/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
// import { trackPromise } from 'react-promise-tracker';
import Jumbotron from 'react-bootstrap/Jumbotron';
import SelectedProductsLayout from './selectedProductsLayout';
import { productsDetails, isFromBillingUpdate } from '../../../actions/index';
import ProductsLayout from './productsLayout';
// import { fetch } from '../../../utils/httpUtil';
// import apiUrl from '../../../utils/apiUrl';
import ErrorMessage from './errorMessage';

class SelectedProducts extends Component {
    state = {
      products: [],
      productNames: [],
      allProducts: [],
      recommendedProducts: [],
      resultMessage: '',
      isAlertNotRequired: true,
      showMessage: true,
    };

    constructor(props) {
      super(props);
      // this.getPackageInformations();
    }

    removeProduct(id, tmxProductId) {
      let { recommendedProducts, products, showMessage } = this.state;
      let selectedProduct = products.find((product) => product.id === id);
      recommendedProducts = [...recommendedProducts, selectedProduct];
      let newSelectedProduct = products.find((product) => product.tmxProductId === tmxProductId && product.id !== id);
      if (newSelectedProduct !== undefined && newSelectedProduct.length > 0) {
        recommendedProducts = [...recommendedProducts, newSelectedProduct];
      }
      this.setState({
        recommendedProducts
      });
      products = products.filter((product) => product.id !== selectedProduct.id);
      if(products.length === 0 ){
        showMessage = false;
        this.props.disableButton(false);
      } 
      this.setState({
        products, showMessage
      },() => {
        this.updateProduct();
      });
      this.storeProductCount('remove', selectedProduct);
      
    }

    storeProductCount = (action, selectedProduct) => {
      const { productNames } = this.state;
      for (let p = 0; p < productNames.length; p++) {
        if (productNames[p] && productNames[p].product === selectedProduct.subCategory) {
          productNames[p].count = (action === 'add') ? productNames[p].count + 1 : productNames[p].count - 1;
          this.setState({ productNames }, () => {});
        }
      }
    }

    getPackageInformations() {
      // trackPromise(fetch(apiUrl.getSubscribers).then((res) => {
      //   if (res && res.data && res.data.data) {
          // this.setState({
          //   allProducts: this.props.products
          // }, () => {
            this.settingValues();
          // });
      //   }
      // }).catch((err) => {
      //   this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      //   window.scrollTo(0, 0);
      // }));
    }

    componentDidMount() {
      this.settingValues();
      let showMessage = true;
      if(this.props.productNames && this.props.productNames.length) {
        this.setState({
          products:this.props.selectedProductsDetails,
          productNames: this.props.productNames
        });
      }
      this.setState({
        showMessage
      })
    }

    settingValues() {
      const allProducts = this.props.products;
      const allNewProducts = [];
      const productCategory = allProducts && allProducts
        .map((products) => Object.keys(products)[0]);
      for (let i = 0; i < allProducts.length; i++) {
        const newOne = allProducts[i][productCategory[i]];
        for (let j = 0; j < newOne.length; j++) {
          if (productCategory[i] !== 'API') {
            newOne[j].subCategory = productCategory[i];
            allNewProducts.push(newOne[j]);
          } else if (newOne[j] && newOne[j].length) {            
            for (let k = 0; k < newOne[j].length; k++) {
              if (newOne[j][k] && newOne[j][k]["package"]!="Free" && Object.keys(newOne[j][k])[0] !== 'image') {
                allNewProducts.push(newOne[j][k]);
              }
            }
          }
        }
      }
      if (this.props.selectedProductsDetails && this.props.selectedProductsDetails.length > 0) {
        let updatedrecommendedProducts = allNewProducts
          .filter((item) => {
            const newIndex = this.props.selectedProductsDetails.findIndex((x) => x.id === item.id);
            return newIndex === -1;
          });
          updatedrecommendedProducts.sort((a,b) => !a.image && !b.image ? (a.productWeightage < b.productWeightage) ? 1 : ((b.productWeightage < a.productWeightage) ? -1 : 0) : 0);
          updatedrecommendedProducts = updatedrecommendedProducts
          .filter((item) => {
            const newIndex = this.props.selectedProductsDetails.findIndex((x) => x.tmxProductId === item.tmxProductId);
            return newIndex === -1;
          });
        this.setState({
          products: this.props.selectedProductsDetails,
          recommendedProducts: updatedrecommendedProducts
        });
      } else {
        this.setState({
          recommendedProducts: allNewProducts
        });
      }
    }

    // componentWillUnmount() {
    //   this.props.productsDetails({
    //     products: this.state.products,
    //     selectedPeriod: this.props.selectedPeriod
    //   });
    // }

    updateProduct(isFromBilling) {
      if (isFromBilling) {
        this.props.isFromBillingUpdate(isFromBilling);
      }
      this.props.productsDetails({
        products: this.state.products,
        selectedPeriod: this.props.selectedPeriod,
        productNames: this.state.productNames,
        productsArray: this.props.products,
      });
    }

    addRecommendedProduct(id) {
      let { recommendedProducts, products } = this.state;      
      const selectedProduct = recommendedProducts.find((product) => product.id === id);
        products = products.filter((product) => {
          if ((product.subCategory.toLowerCase() === 'api' && product.keyName === selectedProduct.keyName) || (selectedProduct.subCategory.toLowerCase() === 'etf solutions' && product.tmxProductId === selectedProduct.tmxProductId) || (selectedProduct.subCategory.toLowerCase() === 'tmx grapevine' && product.id !== selectedProduct.id)) {
            recommendedProducts = [...recommendedProducts, product]
            if(selectedProduct.subCategory.toLowerCase() !== 'tmx grapevine') {
              this.storeProductCount('remove', selectedProduct);
            }
          }
          return ((product.subCategory.toLowerCase() === 'api' && product.keyName !== selectedProduct.keyName) || (product.subCategory.toLowerCase() === 'etf solutions' && product.tmxProductId !== selectedProduct.tmxProductId) || (product.subCategory.toLowerCase() === 'tmx grapevine' && product.id !== selectedProduct.id))
        });
      products.push(selectedProduct);
      recommendedProducts = recommendedProducts.filter((product) => (product.id !== selectedProduct.id));
      recommendedProducts = recommendedProducts
      .filter((item) => {
        const newIndex = this.props.selectedProductsDetails.findIndex((x) => x.tmxProductId === item.tmxProductId);
        return newIndex === -1;
      });
      this.setState({
        products,
        recommendedProducts
      }, () => {
        this.updateProduct();
      });
      
      this.props.disableButton(true);
      this.storeProductCount('add', selectedProduct);
      
    }

    render() {
      const productsList = this.state.products;      
      const { t } = this.props;
      return (
        <>
        <div className="col-lg-7">
            <Jumbotron className="col-lg-11 col-md-12 col-sm-12 col-xs-12 selectedProducts_jumbotron">
            <div className="row">
              <div className="col-lg-12">
                {
                  this.props.isBillingPage ? <h2 className="billingpage_billing_header">{t('selectedProducts.selectedProducts')}</h2> : <h2 className="summarypage_billing_header">{t('selectedProducts.products_you_subs')}</h2>
                }
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 "></div>
              {
                productsList.length > 0 
                ?  <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 selected-products-flex">
                {productsList.map((item, i) =>
                <SelectedProductsLayout
                  key={i}
                  productId={item.id}
                  productName={item.keyName}
                  productCost={item.amount}
                  productSubCategory={item.subCategory}
                  productDuration={this.props.selectedPeriod && this.props.selectedPeriod === 'monthly' ? 'mo' : 'an'}
                  isEditable={true}
                  clickFn={() => this.removeProduct(item.id, item.tmxProductId)}
                  className="selected-flex-item"/>
                  )
                }
                </div> 
                : <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                    <div className="text-danger-alert-large">{t('selectedProducts.products_error_msg')}</div>
                  </div>
              }
              <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 "></div>
            </div>
            <div className="selectedproduct_recomendedproduct_spacing">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {
                  this.props.isRecommendedProducts ? <h2 className="billingpage_billing_header">{t('selectedProducts.other_products_header')}</h2> : <h2 className="summarypage_billing_header"></h2>
                }
              </div>
              {/* {this.state.recommendedProducts.map((item, i) => <ProductsLayout key={i}
                productId={item.id} productName={item.productName}
                productDescription={item.productDescription}
                productCost={item.productCost} productDuration={item.productDuration}
                addProduct={() => this.addRecommendedProduct(item.id)}/>) */}
                {
                this.props.isBillingPage
                  ? <div className="billing-recommended-flex">{
                    this.state.recommendedProducts.map((item, i) => {
                    if (i > 4) {
                      if (i > 5) {
                        return false;
                      }
                      return <ProductsLayout key={i}
                        updateProduct={() => this.updateProduct(true)}
                        productDescription='Add more Products..' addMoreProducts={true} isBillingPage={true} />;
                    }
                    return <ProductsLayout key={i}
                      addMoreProducts={false}
                      productId={item.id} productName={item.keyName}
                      productDescription={item.description}
                      productUrl={item.productUrl}
                      productSubCategory={item.subCategory}
                      packageType={item.package}
                      productCost={item.amount} productDuration={item.billCycle.toLowerCase() == 'monthly' ? 'Monthly' : 'Annual'}
                      addProduct={() => this.addRecommendedProduct(item.id)} isBillingPage={true}/>;
                    })}
                    </div>
                  : this.state.recommendedProducts.map((item, i) => <ProductsLayout key={i}
                    addMoreProducts={false}
                    productId={item.id} productName={item.keyName}
                    productDescription={item.description}
                    productUrl={item.productUrl}
                    packageType={item.package}
                    productCost={item.amount} productDuration={item.billCycle.toLowerCase() == 'monthly' ? 'mo' : 'an'}
                    addProduct={() => this.addRecommendedProduct(item.id)}
                    isBillingPage={true}/>)
                }
            </div>
            </Jumbotron>
            <div className="col-lg-1"></div>
            </div>
        <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
        </>
      );
    }
}

const mapDispatchToProps = (dispatch) => ({
  productsDetails: (details) => dispatch(productsDetails(details)),
  isFromBillingUpdate: (details) => dispatch(isFromBillingUpdate(details)),
});

const mapStateToProps = (state) => ({
  selectedProductsDetails: state.crud.products,
  selectedPeriod: state.crud.selectedPeriod,
  selectedCategory: state.crud.selectedCategory,
  productNames: state.crud.productNames,
  products: state.crud.productsArray
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(SelectedProducts));
