/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';
import { trackPromise } from 'react-promise-tracker';
import Jumbotron from 'react-bootstrap/Jumbotron';
import validation from '../../../assets/properties/validation.json';
import { updateProfileDetails, cardDetails } from '../../../actions/index';
import apiUrl from '../../../utils/apiUrl';
import { fetch, store } from '../../../utils/httpUtil';
import { storeCurrentPage } from '../../../actions/index';
import SuccessMessage from '../common/successMessage';
import ErrorMessage from '../common/errorMessage';

class Profile extends Component {

  t = this.props.t;

  constructor(props) {
    super(props);
    this.state = {
      random: 0,
      isModified: false,
      input: {
        first_name: '',
        last_name: '',
        address1: '',
        address2: '',
        city: '',
        selectedCountry: '',
        selectedProvince: '',
        email: '',
        phonenumber: '',
        postalcode: '',
        ponumber: '',
        selectedProvincdeRefId: '',
        card_no : '',
        expiry_date : '',
        cvc : '',
      },
      requiredFlags: {
        first_name: true,
        last_name: true,
        address1: true,
        address2: false,
        city: true,
        selectedCountry: true,
        selectedProvince: true,
        email: true,
        phonenumber: true,
        postalcode: true,
        ponumber: false,
      },
      errors: {
        first_name: 'first_name_req_err_msg',
        last_name: 'last_name_req_err_msg',
        address1: 'address1_req_err_msg',
        email: 'email_req_err_msg',
        city: 'city_req_err_msg',
        selectedCountry: 'country_req_err_msg',
        selectedProvince: 'province_req_err_msg',
        phonenumber: 'phonenumber_req_err_msg',
        postalcode: 'postalcode_req_err_msg',
      },
      touched: {},
      particularState: [],
      isValid: false,
      stateLabel: this.t('billing.province'),
      country: [],
      province: [],
      resultMessage: '',
      isAlertNotRequired: true,
      removeRequired: true,
      isDisableUpdated: false,
      isSuccessAlertNotRequired: true,
      successMessage: '',
      monerisFrameProfile: false,
      iFrameUrl: '',
      iFrameSubmitUrl: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleTouch = this.handleTouch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.emptyPostalCode = this.emptyPostalCode.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.getCountries();
  }

  getIframeDetails() {
    const countrySelected = this.props.currentUserDetails.country;
    trackPromise(store(apiUrl.adminiFrameUrl, { selectedCountry: countrySelected }).then((iFrameDetails) => {
      if (iFrameDetails && iFrameDetails.data && iFrameDetails.data.data) {
        this.setState({
          iFrameUrl: iFrameDetails.data.data.url,
          iFrameSubmitUrl: iFrameDetails.data.data.submit
        });
      }
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

  getCountries() {
    trackPromise(fetch(apiUrl.getCountriesOnAdmin).then((res) => {
      if (res && res.data && res.data.data) {
        let country = [];
        let priorityCountry = [];
        for(var i=0 ; i<res.data.data.length; i++){
          country.push({
            countryName: res.data.data[i].countryName,
            countryCode: res.data.data[i].countryCode,
          }); 
          if (res.data.data[i].countryCode === 'US' || res.data.data[i].countryCode === 'CA' ) {
            country.pop(
              res.data.data[i].countryName,
              res.data.data[i].countryCode,
            );
            priorityCountry.push({
              countryName: res.data.data[i].countryName,
              countryCode: res.data.data[i].countryCode,
            });
          }
        }
        country.sort(function(a, b){
          if(a.countryName < b.countryName) { return -1; }
          if(a.countryName > b.countryName) { return 1; }
          return 0;})
        for(var j=0 ; j<priorityCountry.length; j++){
          country.unshift({
            countryName: priorityCountry[j].countryName,
            countryCode: priorityCountry[j].countryCode,
          });
        }
        this.setState({ country });
      }
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

  getProvince(selectedCountry) {
    trackPromise(store(apiUrl.getProvinceOnAdmin, { selectedCountry: selectedCountry }).then((res) => {
      if (res && res.data && res.data.data) {
        let particularState = [];
        for(var i=0 ; i<res.data.data.length; i++){
          particularState.push({
            provinceName: res.data.data[i].provinceName,
            provinceCode: res.data.data[i].provinceCode,
            referenceId : res.data.data[i].referenceId,
            countryCode : res.data.data[i].countryCode,
          });
        }
        particularState.sort(function(a, b){
          if(a.provinceName < b.provinceName) { return -1; }
          if(a.provinceName > b.provinceName) { return 1; }
          return 0;})
        this.setState({ particularState });
      }
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: true });
      window.scrollTo(0, 0);
    }));
  }

  countryChange(e) {
    const { requiredFlags, errors, input } = this.state;
    const { t } = this.props;
    let stateLabel; let postalCodeLabel; let particularState; let removeRequired;
    if (e) {
      this.handleChange(e);
      this.getProvince(e.target.value);
      if (e.target.value === 'CA') {
        input.selectedProvince = '';
        requiredFlags.postalcode = true; 
        errors.selectedProvince = 'province_req_err_msg';
        errors.postalcode = '';
        stateLabel = t('billing.province');
        postalCodeLabel = t('billing.postalcode');
      } else if (e.target.value === 'US') {
        input.selectedProvince = '';
        requiredFlags.postalcode = true;
        errors.selectedProvince = 'province_req_err_msg';
        errors.postalcode = '';
        stateLabel = t('billing.state');
        postalCodeLabel = t('billing.zipcode');
      } else {
        input.selectedProvince = '';
        requiredFlags.postalcode = false;
        errors.selectedProvince = '';
        errors.postalcode = '';
        stateLabel = t('billing.province');
        postalCodeLabel = t('billing.postalcode');
      }
      particularState = this.state.province
        .filter((item) => item.country_code === e.target.value);
    } else {
      const { input } = this.state;
      if (input.selectedCountry === 'CA') {
        requiredFlags.postalcode = true; 
        errors.selectedProvince = '';
        errors.postalcode = '';
        stateLabel = t('billing.province');
        postalCodeLabel = t('billing.postalcode');
      } else if (input.selectedCountry === 'US') {
        requiredFlags.postalcode = true;
        errors.selectedProvince = '';
        errors.postalcode = '';
        stateLabel = t('billing.state');
        postalCodeLabel = t('billing.zipcode');
      } else {
        requiredFlags.postalcode = false;
        errors.selectedProvince = '';
        errors.postalcode = '';
        stateLabel = t('billing.province');
        postalCodeLabel = t('billing.postalcode');
      }
      particularState = this.state.province
        .filter((item) => item.country_code === input.selectedCountry);
    }
    requiredFlags.selectedProvince = true;
    removeRequired = true;
      
    this.setState({
      particularState, stateLabel, postalCodeLabel, removeRequired, requiredFlags, errors, input
    });
  }

  setStateValue(){
    this.setState({
      input:{
        first_name : this.props.currentUserDetails.billingFirstName,
        last_name : this.props.currentUserDetails.billingLastName,
        address1 : this.props.currentUserDetails.addressLine1,
        address2 : this.props.currentUserDetails.addressLine2,
        city : this.props.currentUserDetails.city,
        selectedCountry : this.props.currentUserDetails.country,
        selectedProvince : this.props.currentUserDetails.provinceState,
        email : this.props.currentUserDetails.billingEmail,
        postalcode : this.props.currentUserDetails.postalCode,
        phonenumber : this.props.currentUserDetails.contactNumber,
        card_no : this.props.cardDetails.cardNo,
        expiry_date: this.props.cardDetails.expiryDate,
        cvc: ''
      },
      paymentType : this.props.currentUserDetails.paymentType
    });
  }

  componentDidMount() {
    let stateLabel, postalCodeLabel, removeRequired, isValid;
    const { t } = this.props;
    this.setStateValue();
    if (this.props.currentUserDetails) {
      this.getProvince(this.props.currentUserDetails.country);
      isValid = true;
      const touched = {
        first_name: true,
        last_name: true,
        address1: true,
        email: true,
        city: true,
        selectedProvince: true,
        selectedCountry: true,
        postalcode: true,
        phonenumber: true,
        selectedProvincdeRefId: true,
        expiry_date: true
      };
      if (this.props.currentUserDetails.country === 'CA') {
        stateLabel = t('billing.province');
        postalCodeLabel = t('billing.postalcode');
      } else if (this.props.currentUserDetails.country === 'US') {
        stateLabel = t('billing.state');
        postalCodeLabel = t('billing.zipcode');
      } else {
        stateLabel = t('billing.province');
        postalCodeLabel = t('billing.postalcode');
      }
      removeRequired = true;

      const errors = {};
      this.setState({
        isValid,
        touched,
        errors,
        stateLabel, 
        postalCodeLabel,
        removeRequired,
      }, () => {
        this.countryChange();
        this.validateAllFields();
      });
    } else {
      stateLabel = t('billing.province');
      postalCodeLabel = t('billing.postalcode');
      removeRequired = false,
      this.setState({
        stateLabel,
        postalCodeLabel,
        removeRequired
      });
    }
  }

  handleTouch(e) {
    const { touched } = this.state;
    if (e.target.name && touched[e.target.name] !== true) {
      touched[e.target.name] = true;
      this.setState({
        touched
      });
    }
  }

  handleChange(e) {
    const { input } = this.state;
    input[e.target.name] = e.target.value;
    if(e.target.name != 'cvc' && e.target.name != 'expiry_date'){
      this.setState({ isModified : true })
    }
    this.validateForm(e);
    this.setState({
      input
    }, () => {
      this.validateAllFields();
      if(e.target.name === 'selectedProvince') {
        this.emptyPostalCode();
      }
    });
  }

  emptyPostalCode() {
    const { input, requiredFlags, errors } = this.state;
    input.postalcode = '';
    if(input.selectedCountry === 'CA') {
      requiredFlags.postalcode = true; 
      errors.postalcode = 'postalcode_req_err_msg';
    } else if(input.selectedCountry === 'US') {
      requiredFlags.postalcode = true;
      errors.postalcode = 'zipcode_req_err_msg';
    } else {
      requiredFlags.postalcode = false;
      errors.postalcode = '';
    }
    this.setState({
      input, requiredFlags, errors
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.validateForm()) {
      const input = {};
      input.name = '';
      input.email = '';
      input.comment = '';
      this.setState({ input });
    }
  }

  checkMailRegex (value) {
    const splittedValueOne = value.split('@');
    const splittedValue = splittedValueOne[0].split('');
    const splittedValueLength = splittedValue.length;
    if (splittedValue[0] === '.' || splittedValue[splittedValueLength-1] === '.') {
      return true;
    } else {
      if (splittedValueOne.length > 1) {
        const splittedValueTwo = splittedValueOne[0].split('..');
        if (splittedValueTwo.length > 1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }
  }

  validateForm(e) {
    let { name, value } = e.target;
    const { input, errors } = this.state;
    let isValid = true;
    if (!value || value === undefined || value === '') {
      errors[`${name}`] = e.target.name === 'postalcode' && e.target.required && (input.selectedCountry === 'US' || input.selectedCountry === 'CA') ? input.selectedCountry === 'US' ? 'zipcode_req_err_msg' : `${name}_req_err_msg` : '';
    }
    if (typeof value !== 'undefined' && value !== '') {
      let pattern;
      if (e.target.name === 'postalcode' && input.selectedCountry === 'US') {
        pattern = new RegExp(validation['zipcode_regx']);
      } else if(e.target.name === 'postalcode' && input.selectedCountry === 'CA') {
        pattern = new RegExp(validation['postalcode_regx']);
      } else if(e.target.name === 'postalcode' && input.selectedCountry !== 'CA' && input.selectedCountry !== 'US') {
        pattern = new RegExp(validation['']);
      } else {
        pattern = new RegExp(validation[`${name}_regx`]);
      }
      if (!pattern.test(value) && value) {
        isValid = false;
        if(e.target.name === 'postalcode' && input.selectedCountry === 'US') {
          errors[`${name}`] = 'zipcode_invalid_err_msg';
        } else if(e.target.name === 'postalcode' && input.selectedCountry === 'CA') {
          errors[`${name}`] = 'postalcode_invalid_err_msg';
        } else if(e.target.name === 'postalcode' && input.selectedCountry !== 'CA' && input.selectedCountry !== 'US') {
          errors[`${name}`] = '';
        } else {
          errors[`${name}`] = `${name}_invalid_err_msg`;
        }
      } else {
        errors[`${name}`] = '';
      }
      if (e.target.type === 'email') {
        const testRegex = this.checkMailRegex(value);
        if (testRegex) {
          isValid = false;
          errors[`${name}`] = `${name}_invalid_err_msg`;
        }
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  saveInitiator() {
    const { input } = this.state;
    const selectedProvincdeRefId = this.state.particularState.filter((products) => products.provinceCode === this.state.input.selectedProvince);
    input.selectedProvincdeRefId = selectedProvincdeRefId && selectedProvincdeRefId.length>0 ? selectedProvincdeRefId[0].referenceId : '';
  }

  validateAllFields() {
    const { errors, touched } = this.state;
    const inputNew = this.state.input;
    const allInputs = Object.entries(inputNew).map((arr) => this.state.requiredFlags[arr[0]] ? arr[1] !== "" : true);    
    const allErrors = Object.values(errors);
    const allTouched = Object.values(touched);
    const isValid = allErrors.every((arr) => !arr) && (allTouched === {} || allTouched.every((arr) => arr) && allInputs.every((arr) => arr));
    this.setState({
      isValid
    });
  }

  disableButton(key) {
    const { errors, touched } = this.state;
    const inputNew = this.state.input;
    const allInputs = Object.entries(inputNew).map((arr) => this.state.requiredFlags[arr[0]] ? arr[1] !== "" : true);
    const allErrors = Object.values(errors);
    const allTouched = Object.values(touched);
    const isFieldValid = allErrors.every((arr) => !arr) && (allTouched === {} || allTouched.every((arr) => arr) && allInputs.every((arr) => arr));
    let isValid = key;
    if(isFieldValid || (isValid && isFieldValid)) {
      this.setState({
        isValid
      });
    }
  }
 
  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
      this.props.history.push(this.props.currentLoadingPage);
      this.props.storeCurrentPage('');
    }
  }

  updateClientBillingDetails(e){
    e.preventDefault();
    const selectedProvincdeRefId = this.state.particularState.filter((products) => products.provinceCode === this.state.input.selectedProvince);
    const postObj = {
      clientId : this.props.currentUserDetails.clientId,
      firstName : this.state.input.first_name,
      lastName : this.state.input.last_name,
      email : this.state.input.email,
      addressLine1 : this.state.input.address1,
      addressLine2 : this.state.input.address2,
      city : this.state.input.city,
      country : this.state.input.selectedCountry,
      provinceState : this.state.input.selectedProvince,
      contactNumber : this.state.input.phonenumber,
      postalCode : this.state.input.postalcode ? this.state.input.postalcode : 'NA',
      selectedProvincdeRefId : selectedProvincdeRefId && selectedProvincdeRefId.length>0 ? selectedProvincdeRefId[0].referenceId : '',
      token : this.props.currentUserDetails.access_token,
      isProfile : true
    };
    trackPromise(store(apiUrl.updateClientBillingDetails, postObj).then((res) => {
      if (res && res.data && res.data.data) {
        this.props.updateProfileDetails(postObj);
        this.setState({ isSuccessAlertNotRequired: false, isAlertNotRequired: true, successMessage: this.props.t('toastmessage.success'), isModified:false });
        window.scrollTo(0, 0);
      }
    }).catch(() => {
      this.setState({ isSuccessAlertNotRequired: false, isAlertNotRequired: true, successMessage: this.props.t('toastmessage.failure'), disableBackButton: false });
      window.scrollTo(0, 0);
    }));
  }

  checkValidity(exp, cvc){
    return (new RegExp("(0[1-9]|1[0-2])(0[1-9]|[12]\\d|3[01])").test(exp) && new RegExp("^.{3,4}$").test(cvc));
  }

  updateCreditCardDetails(){
    this.setState({isTriggered : false});
    if (this.state.monerisFrameProfile) {
      const ele = document.getElementById('monerisFrameProfile');
      if(ele) {
        const monFrame = ele.contentWindow;
        monFrame.postMessage('', this.state.iFrameSubmitUrl);
      }
      if (window.addEventListener) {
        window.addEventListener('message', this.responseMessageProfile.bind(this), false);
      } else if (window.attachEvent) {
        window.attachEvent('onmessage', this.responseMessageProfile.bind(this));
      }
    } else {
      const isEditCardValid = this.checkValidity(this.state.input.expiry_date, this.state.input.cvc);
      if(isEditCardValid){
        this.setState({ ccError : false });
        const ccobj = {
          expDate : this.state.input.expiry_date,
          cvc : this.state.input.cvc,
          clientId : this.props.currentUserDetails.clientId,
          token : this.props.currentUserDetails.access_token
        }
        trackPromise(store(apiUrl.updateExpiryAndCVC, ccobj).then((res) => {
          if (res && res.data && res.data.data) {
            this.props.getCardDetails({'clientId': this.props.currentUserDetails.clientId, 'status': 'active', 'oneTimeCard': 'N'});
            // this.setStateValue();
            this.setState({ isSuccessAlertNotRequired: false, isAlertNotRequired: true, successMessage: this.props.t('toastmessage.success') });
            this.revertPaymentDetails();
            // this.componentDidMount();
            window.scrollTo(0, 0);
          }
        }).catch(() => {
          this.setState({ isSuccessAlertNotRequired: false, isAlertNotRequired: true, successMessage: this.props.t('toastmessage.failure'), disableBackButton: false });
          window.scrollTo(0, 0);
        }));
      }else{
        this.setState({ ccError : true });
      }
    }
  }

  responseMessageProfile(e) {
    const ele = document.getElementById('monerisFrameProfile');
    if(ele){
      ele.removeEventListener('message', this.responseMessageProfile.bind(this), false);
      ele.removeEventListener('onmessage', this.responseMessageProfile.bind(this));
      if(!this.state.isTriggered){
        if (JSON.parse(e.data).responseCode.indexOf('001') === -1) {
          this.setState({ monerisError: true, isTriggered: true });
        } else {
          this.setState({ monerisError: false, isTriggered: true });
          const ccobj = {
            dataKey : JSON.parse(e.data).dataKey,
            clientId : this.props.currentUserDetails.clientId,
            addressLine1 : this.props.currentUserDetails.addressLine1,
            addressLine2 : this.props.currentUserDetails.addressLine2,
            zipCode : this.props.currentUserDetails.postalCode,
            phonenumber: this.props.currentUserDetails.contactNumber,
            email: this.props.currentUserDetails.billingEmail,
            token : this.props.currentUserDetails.access_token,
            selectedCountry : this.props.currentUserDetails.country,
            selectedCurrency : this.props.cardDetails.cardCurrency == 'CAD' ? 'CA' : 'US',
          }
          trackPromise(store(apiUrl.addNewCard, ccobj).then((res) => {
            if (res && res.data && res.data.data) {
              this.props.getCardDetails({'clientId': this.props.currentUserDetails.clientId, 'status': 'active', 'oneTimeCard': 'N'});
              this.setState({isTriggered: true, random: this.state.random + 1, isSuccessAlertNotRequired: false, isAlertNotRequired: true, successMessage: this.props.t('toastmessage.success'), monerisFrameProfile: true });
              this.revertPaymentDetails();
              window.scrollTo(0, 0);
            }
          }).catch(() => {
            this.setState({ isSuccessAlertNotRequired: false, isAlertNotRequired: true, successMessage: this.props.t('toastmessage.failure'), disableBackButton: false });
            window.scrollTo(0, 0);
          }));
        }
      }
    }
  }

  handlePaymentChange(event) {
    const newState = this.state;
    newState.monerisFrameProfile = event.target.name !== 'editCard';
    this.setState({
      newState,
    });
    if(this.state.monerisFrameProfile){
      this.getIframeDetails();
    }
    this.revertPaymentDetails();
  }

  revertClientDetails(e){
    e.preventDefault();
    this.componentDidMount();
  }

  revertPaymentDetails(){
    if(this.state.monerisFrameProfile){
      this.setState({random: this.state.random + 1});
    }else{
      setTimeout(() => {
        this.setState({
          input:{
            card_no : this.props.cardDetails.cardNo,
            expiry_date: this.props.cardDetails.expiryDate,
            cvc: '',
            first_name: this.state.input.first_name,
            last_name: this.state.input.last_name,
            address1: this.state.input.address1,
            address2: this.state.input.address2,
            city: this.state.input.city,
            selectedCountry: this.state.input.selectedCountry,
            selectedProvince: this.state.input.selectedProvince,
            email: this.state.input.email,
            phonenumber: this.state.input.phonenumber,
            postalcode: this.state.input.postalcode,
            ponumber: this.state.input.ponumber,
            selectedProvincdeRefId: this.state.input.selectedProvincdeRefId,
          }
        });
      }, 2000);
    }
  }

  render() {
    const { t } = this.props;
    return (
    <>
      <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
      <SuccessMessage shouldNotDisplaySuccess={this.state.isSuccessAlertNotRequired} successMessage={this.state.successMessage} backButton={false}/>
      <div className='container-fluid'>
        <div className='row'>
          <div className="col-lg-1 col-md-1 col-sm-1"></div>
            <Jumbotron className="col-lg-10 col-md-10 col-sm-10 col-xs-12 jumbotron_usage_summary">
              {this.props.currentUserDetails.roleType === "Client Admin" ? <Link className="payment_console_back_link" to='/admin'>{'Home'}</Link> : ''}
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="usage_heading1">{t('profilemanagement.header')}</div>
                <div className="users-table-style"></div>
                <div className="profile-sub-header"> {t('profilemanagement.billing_address')}</div>
                <form>
                  <div className="form-row header_firstrow_space">
                    <div className="form-group col-lg-6 col-md-6 left_labels_alignment">
                      <label htmlFor="firstname">{t('billing.firstname')}</label>&nbsp;<span className="required-flag">*</span>
                      <input type="text" name="first_name" className="form-control" id="firstname" value={this.state.input.first_name || ''} autoComplete="__" maxLength="100" onChange={this.handleChange} onBlur = {this.handleTouch} required={true} />
                      <div className="text-danger">{this.state.touched.first_name && this.state.errors.first_name && t(`billing.${this.state.errors.first_name}`)}</div>
                    </div>
                    <div className="form-group col-md-6 col-lg-6">
                      <label htmlFor="lastname">{t('billing.lastname')}</label>&nbsp;<span className="required-flag">*</span>
                      <input type="text" name="last_name" className="form-control" id="lastname" value={this.state.input.last_name || ''} autoComplete="__" maxLength="100" onChange={this.handleChange} onBlur = {this.handleTouch} required={true} />
                      <div className="text-danger">{this.state.touched.last_name && this.state.errors.last_name && t(`billing.${this.state.errors.last_name}`)}</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6 col-lg-6 left_labels_alignment">
                      <label htmlFor="address1">{t('billing.address1')}</label>&nbsp;<span className="required-flag">*</span>
                      <input type="text" name="address1" className="form-control" autoComplete="__" maxLength="500" id="address1" value={this.state.input.address1 || ''} onChange={this.handleChange} onBlur = {this.handleTouch} required={true} />
                      <div className="text-danger">{this.state.touched.address1 && this.state.errors.address1 && t(`billing.${this.state.errors.address1}`)}</div>
                    </div>
                    <div className="form-group col-md-6 col-lg-6">
                      <label htmlFor="address2">{t('billing.address2')}</label>
                      <input type="text" name="address2" className="form-control" autoComplete="__" maxLength="500" id="address2" value={this.state.input.address2 || ''} onChange={this.handleChange} onBlur = {this.handleTouch} />
                    </div>
                  </div>
                  <div className="form-row">
                  <div className="form-group col-md-6 col-lg-6 left_labels_alignment">
                      <label htmlFor="city">{t('billing.city')}</label>&nbsp;<span className="required-flag">*</span>
                      <input type="text" name="city" className="form-control" autoComplete="__" maxLength="100" id="city" value={this.state.input.city || ''} onChange={this.handleChange} required={true} onBlur = {this.handleTouch} />
                      <div className="text-danger">{this.state.touched.city && this.state.errors.city && t(`billing.${this.state.errors.city}`)}</div>
                    </div>
                    <div className="form-group col-md-6 col-lg-6">
                      <label htmlFor="country">{t('billing.country')}</label>&nbsp;<span className="required-flag">*</span>
                        <select className="form-control dropdown_alignment" name="selectedCountry" id="country" onChange={this.countryChange.bind(this)}>
                          <option selected disabled value="">Select</option>
                            {
                              this.state.country.map((item) => <option value={item.countryCode}
                              selected={item.countryCode === this.state.input.selectedCountry}
                              key={item.countryCode}>
                              {item.countryName}</option>)
                            }
                        </select>
                      <div className="text-danger">{this.state.touched.country && this.state.errors.country && t(`billing.${this.state.errors.country}`)}</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6 col-lg-6 left_labels_alignment">
                      <label htmlFor="province">{this.state.stateLabel}</label>&nbsp; { this.state.removeRequired ? <span className="required-flag">*</span> : '' }
                        {
                          this.state.particularState.length === 0  
                            ? <input type="text" name="selectedProvince" value={this.state.input.selectedProvince} className="form-control"
                              id="province" autoComplete="__" maxLength="100"
                              onChange={this.handleChange} onBlur={this.handleTouch} required={false}/>
                            : 
                            <select className="form-control dropdown_alignment" id="selectedProvince" name="selectedProvince" onChange={this.handleChange.bind(this)} required={true}>
                              <option selected disabled value="">Select</option>
                              {
                                this.state.particularState
                                  .map((item) => <option value={item.provinceCode}
                                  selected={item.provinceCode === this.state.input.selectedProvince}
                                  key={item.provinceCode}
                                  >
                                {item.provinceName}</option>)
                              }
                            </select>
                        }
                      <div className="text-danger">{this.state.errors.province && t(`billing.${this.state.errors.province}`)}</div>
                    </div>
                    <div className="form-group col-md-6 col-lg-6">
                      <label htmlFor="email">{t('billing.email')}</label>&nbsp;<span className="required-flag">*</span>
                      <input type="email" className="form-control" autoComplete="__" maxLength="100" id="email" name="email" value={this.state.input.email || ''}
                      onChange={this.handleChange} onBlur = {this.handleTouch} required={true}/>
                      <div className="text-danger">{this.state.touched.email && this.state.errors.email && t(`billing.${this.state.errors.email}`)}</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6 col-lg-6 left_labels_alignment">
                      <label htmlFor="phonenumber">{t('billing.phonenumber')}</label>&nbsp;<span className="required-flag">*</span>
                      <input type="text" name="phonenumber" className="form-control" autoComplete="__" maxLength="100" id="phonenumber" value={this.state.input.phonenumber || ''} onChange={this.handleChange} onBlur = {this.handleTouch} required={true}/>
                      <div className="text-danger">{this.state.touched.phonenumber && this.state.errors.phonenumber && t(`billing.${this.state.errors.phonenumber}`)}</div>
                    </div>
                    <div className="form-group col-md-6 col-lg-6">
                      <label htmlFor="postalcode">{this.state.postalCodeLabel}</label>&nbsp; { this.state.requiredFlags['postalcode'] ? <span className="required-flag">*</span> : '' }
                      <input type="text" className="form-control" autoComplete="__" maxLength="100" id="postalcode" name="postalcode" value={this.state.input.postalcode || ''} onChange={this.handleChange} onBlur = {this.handleTouch} required={this.state.removeRequired}/>
                      <div className="text-danger">{this.state.touched.postalcode && this.state.errors.postalcode && t(`billing.${this.state.errors.postalcode}`)}</div>
                    </div>
                  </div>
                  <br></br>
                  <div className='form-row'>
                    <div className="form-group col-md-6 col-lg-6 col-sm-6 align-header">
                      <button className="btn profile-btn" onClick={this.updateClientBillingDetails.bind(this)} disabled={(!this.state.isValid || !this.state.isModified)}>{t('profilemanagement.update')}</button>
                    </div>
                    <div className="form-group col-md-6 col-lg-6 col-sm-6">
                      <button className="btn profile-btn" onClick={this.revertClientDetails.bind(this)} disabled={!this.state.isModified}>{t('profilemanagement.cancel')}</button>
                    </div>
                  </div>
                </form>
              </div>
              <br></br>
              <div className="users-table-style"></div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="profile-sub-header"> {t('profilemanagement.payment_type')}</div>
                  <div className="payment_method client_admin">{t('current_payment_method.label')}<span className="payment_method_value">{t(`current_payment_method.${this.state.paymentType}`)}</span></div>
                </div>
                {this.state.paymentType != 'invoice' ? 
                <div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div>
                      <div className="form-row">
                        <div className="form-group col-md-4 col-lg-4 left_labels_alignment">
                          <input type="radio" id="editCard" name="editCard" value="editCard" checked={!this.state.monerisFrameProfile} onChange={this.handlePaymentChange}/>
                          <label className="card_options" htmlFor="editCard">{t('profilemanagement.edit_card')}</label><br></br>
                        </div>
                        <div className="form-group col-md-4 col-lg-4">
                          <input type="radio" id="newCard" name="newCard" value="newCard" checked={this.state.monerisFrameProfile} onChange={this.handlePaymentChange}/>
                          <label className="card_options" htmlFor="newCard">{t('profilemanagement.add_card')}</label><br></br>
                        </div>
                      </div>
                        {this.state.monerisFrameProfile ? 
                          <div className="col-lg-6 col-md-10">
                            <div className="form-row">
                              <div className="form-row header_firstrow_space">
                                <div className="form-group col-md-12 col-lg-12 col-sm-12 left_labels_alignment">
                                <Iframe src={this.state.iFrameUrl} key={this.state.random} id="monerisFrameProfile" scrolling="no" class="moneris" webkitallowfullscreen mozallowfullscreen allowfullscreen width="100%" height="270" frameBorder="0" marginHeight="0" marginWidth="0"/>
                                <div className="text-danger-alert">{this.state.monerisError && 'Invalid Data'}</div>
                                </div>
                                <label className="card_optionss">{t('profilemanagement.save_this_card')}</label>
                              </div>
                            </div>
                          </div>
                        : <div className="col-lg-6 col-md-10">
                            <div className="form-row">
                              <div className="form-group col-md-12 col-lg-12 col-sm-12 left_labels_alignment">
                                <label htmlFor="cardno">{t('profilemanagement.cardno')}</label>&nbsp;<span className="required-flag">*</span>
                                <input type="text" name="cardno" className="form-control" id="cardno" value={this.state.input.card_no|| ''} disabled />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-12 col-lg-12 col-sm-12 left_labels_alignment">
                                <label htmlFor="expirydate">{t('profilemanagement.expirydate')}</label>&nbsp;<span className="required-flag">*</span>
                                <input type="text" name="expiry_date" onChange={this.handleChange} className="form-control" autoComplete="__" maxLength="4" id="expiry_date" value={this.state.input.expiry_date || ''} required={true} />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-12 col-lg-12 col-sm-12 left_labels_alignment">
                                <label htmlFor="cvc">{t('profilemanagement.cvc')}</label>&nbsp;<span className="required-flag">*</span>
                                <input type="text" name="cvc" onChange={this.handleChange} className="form-control" autoComplete="__" maxLength="4" id="cvc" value={this.state.input.cvc || ''} required={true} />
                              </div>
                            </div>
                            <div className="text-danger-alert">{this.state.ccError && 'Invalid Data'}</div>
                          </div>
                        }
                    </div>
                    <br></br>
                    <div className='form-row'>
                      <div className="form-group col-md-6 col-lg-6 col-sm-6 align-header">
                        <button className="btn profile-btn" onClick={this.updateCreditCardDetails.bind(this)}>{t('profilemanagement.update')}</button>
                      </div>
                      <div className="form-group col-md-6 col-lg-6 col-sm-6">
                        <button className="btn profile-btn" onClick={this.revertPaymentDetails.bind(this)}>{t('profilemanagement.cancel')}</button>
                      </div>
                    </div>
                  </div>
                </div>
                : ''
              }
              <div className='col-lg-9 col-md-8 col-sm-8 col-xs-8'> </div>
            </Jumbotron>
          <div className='col-lg-1 col-md-1'></div>
      </div>
      </div>
    </>);
  }
}

const mapDispatchToProps = (dispatch) => ({
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
  updateProfileDetails: (details) => dispatch(updateProfileDetails(details)),
  getCardDetails: (details) => dispatch(cardDetails(details)),
});

const mapStateToProps = (state) => ({
  currentUserDetails: state.crud.currentUser,
  currentLoadingPage: state.crud.currentLoadingPage,
  updateNewClientDetails: state.crud.updateNewClientDetails,
  cardDetails: state.crud.cardDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Profile));
