/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import uuid from 'react-uuid';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import { connect } from 'react-redux';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { trackPromise } from 'react-promise-tracker';
import { savesignUpDetails } from '../../../actions/index';
import BannerImage from '../common/bannerImage';
import Pagination from '../common/pagination';
import validation from '../../../assets/properties/validation.json';
import { store } from '../../../utils/httpUtil';
import apiUrl from '../../../utils/apiUrl';
import ErrorMessage from '../common/errorMessage';
import data from '../../../store/configureStore';
import { setSessionStorage, clearLocalStorage } from '../../../utils/storageUtil';

class SignUp extends Component {
  constructor(props) {
    super(props);
    clearLocalStorage('state');
    this.state = {
      input: {
        first_name: '',
        last_name: '',
        client_name: '',
        team_name: '',
        is_individual: false,
        email: ''
      },
      requiredFlags: {
        first_name: true,
        last_name: true,
        client_name: true,
        team_name: false,
        is_individual: false,
        email: true
      },
      errors: {
        first_name: 'first_name_req_err_msg',
        last_name: 'last_name_req_err_msg',
        client_name: 'client_name_req_err_msg',
        email: 'email_req_err_msg'
      },
      touched: {},
      isValid: false,
      isAlertNotRequired: true,
      isRedAlertNotRequired: true,
      isOrgAlertNotRequired: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleTouch = this.handleTouch.bind(this);
    this.removeOrganization = this.removeOrganization.bind(this);
    if (data.getState() && data.getState().crud && data.getState().crud.products && data.getState().crud.products.length > 0) {
      this.redirectTo = '/billing';
    } else {
      this.redirectTo = '/products';
    }
    this.getToken();
  }

  async getToken() {
    trackPromise(store(apiUrl.getToken, { id: uuid() }).then((jwtToken) => {
      setSessionStorage('jwt_token',jwtToken.data.token);
    }).catch((err) => {
      console.log(err);
    }));
  }

  componentDidMount() {
    if (this.props.signUpDetailsSaved && this.props.signUpDetailsSaved.first_name) {
      const touched = {
        first_name: true,
        last_name: true,
        client_name: true,
        email: true
      };
      const requiredFlags = this.state.requiredFlags;
      requiredFlags.is_individual = this.props.signUpDetailsSaved.is_individual;
      requiredFlags.client_name = !this.props.signUpDetailsSaved.is_individual;
      const errors = {};
      this.setState({
        input: this.props.signUpDetailsSaved,
        touched,
        errors
      }, () => {
        this.validateAllFields();
      });
    }
  }

  handleTouch(e) {
    const { touched } = this.state;
    if (e.target.name && touched[e.target.name] !== true) {
      touched[e.target.name] = true;
      this.setState({
        touched
      });
    }
  }

  validateAllFields() {
    const { errors } = this.state;
    const inputNew = this.state.input;
    const allInputs = Object.entries(inputNew).map((arr) => this.state.requiredFlags[arr[0]] ? arr[1] !== "" : true); 
    const AllErrors = Object.values(errors);
    const isValid = AllErrors.every((arr) => arr === "") && allInputs.every((arr) => arr );
    this.setState({
      isValid
    });
  }

  saveInitiator(e) {
    // this.validateForm(e);
    e.preventDefault();
    let orgMessage,resultMessage, isAlertNotRequired = false, isOrgAlertNotRequired = true, isRedAlertNotRequired = true;
    if (this.state.isValid) {
      let obj = {
        email:this.state.input.email
      };
      if(this.state.input.is_individual){
        obj.individual = true;
      }else{
        obj.organization = this.state.input.client_name;
        obj.team = this.state.input.client_name
      }
      trackPromise(store(apiUrl.checkOrgAndEmail, obj).then((organization) => {
        if(organization.data && organization.data.data && organization.data.data.isOrgExists){
          orgMessage = ' This Organization already exists';
          isAlertNotRequired = true;
          isOrgAlertNotRequired = false;
        }if(organization.data && organization.data.data && organization.data.data.isEmailExists){
          resultMessage = 'This Email Address Already Exists';
          isAlertNotRequired = true;
          isRedAlertNotRequired = false;
        }
        this.props.savesignUpDetails(this.state.input);
        this.setState({orgMessage, isAlertNotRequired, isOrgAlertNotRequired, resultMessage, isRedAlertNotRequired});
        if(!isAlertNotRequired) this.props.history.push(this.redirectTo);
      }).catch((err) => {
        orgMessage = err.message;
        isAlertNotRequired = false;
        isOrgAlertNotRequired = true;
        this.setState({
          orgMessage, isAlertNotRequired, isOrgAlertNotRequired
        })
        window.scrollTo(0, 0);
      }));
    }
  }

  handleChange(e) {
    const { input } = this.state;
    if (e.target.type === 'checkbox') {
      input[e.target.name] = !input[e.target.name];
    } else {
      input[e.target.name] = e.target.value;
    }
    this.handleTouch(e);
    this.validateForm(e);
    this.setState({
      input
    }, () => {
      this.validateAllFields(e);
    });
  }

  removeOrganization() {
    const { input, errors, requiredFlags } = this.state;
    input.client_name = '';
    if (input.is_individual) {
      errors.client_name = '';
      requiredFlags.client_name = false;
      requiredFlags.is_individual = true;
    } else {
      errors.client_name = 'client_name_req_err_msg';
      requiredFlags.client_name = true;
      requiredFlags.is_individual = false;
    }
    this.setState({ input, errors, requiredFlags });
  }

  validateForm(e) {
    const { name, value } = e.target;
    let { errors, isRedAlertNotRequired, resultMessage } = this.state;
    let isValid = true;

    if ((!value || value === undefined || value === '') && e.target.type !== 'checkbox') {
      isValid = false;
      errors[`${name}`] = e.target.required ? `${name}_req_err_msg` : '';
      if(name === 'email' && errors[`${name}`] !== '') {
        isRedAlertNotRequired = true;
        resultMessage = '';

      }
    }

    if (typeof value !== 'undefined' && value !== '' && e.target.type !== 'checkbox') {
      const pattern = new RegExp(validation[`${name}_regx`]);
      if (!pattern.test(value)) {
        isValid = false;
        errors[`${name}`] = `${name}_invalid_err_msg`;
      } else {
        errors[`${name}`] = '';
      }
      if (e.target.type === 'email') {
        const testRegex = this.checkMailRegex(value);
        if (testRegex) {
          isValid = false;
          errors[`${name}`] = `${name}_invalid_err_msg`;
        }
      }
    }

    this.setState({
      errors, isRedAlertNotRequired, resultMessage
    });

    if (e.target.type === 'checkbox' && e.target.name === 'is_individual') {
      this.removeOrganization();
    }
    return isValid;
  }

  checkMailRegex (value) {
    const splittedValueOne = value.split('@');
    const splittedValue = splittedValueOne[0].split('');
    const splittedValueLength = splittedValue.length;
    if (splittedValue[0] === '.' || splittedValue[splittedValueLength-1] === '.') {
      return true;
    } else {
      if (splittedValueOne.length > 1) {
        const splittedValueTwo = splittedValueOne[0].split('..');
        if (splittedValueTwo.length > 1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
          <div className={!this.props.isSummary ? 'container-fluid' : ''}>
            {
            this.state.isAlertNotRequired  ? '' : <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={false}/>
            }
            <div className={!this.props.isSummary ? 'row' : ''}>
            <div className="col-lg-1 col-md-1"></div>
            <Jumbotron className={!this.props.isSummary ? 'col-lg-4 col-md-10 col-sm-8 col-xs-8 jumbotron_usage_summary' : 'col-lg-12 jumbotron_summary_users'}>
              {!this.props.isSummary
                ? <h2 className="signup_header_alignment">
                  {t('signup.title')}
                </h2> : ''}
              <form autoComplete="__">
              <div className={!this.props.isSummary ? 'form-row header_firstrow_space' : 'form-row'}>
                <div className={!this.props.isSummary ? 'form-group col-md-6 col-lg-6 col-sm-7 left_labels_alignment' : 'col-lg-6 left_labels_alignment'}>
                  <label htmlFor="firstname">{t('signup.first_name_lbl')}</label>&nbsp;<span className="required-flag">{!this.props.isSummary ? '*' : ''}</span>
                  <input
                      type="text"
                      className={!this.props.isSummary ? 'form-control' : 'summary-signup'}
                      id="first_name"
                      name="first_name"
                      value={this.state.input.first_name || ''}
                      autoComplete="__"
                      onChange={this.handleChange}
                      onBlur = {this.handleTouch}
                      required={true}
                      maxLength={100}
                      disabled={this.props.isSummary}
                    />
                  <div className="text-danger-alert">{this.state.touched.first_name && this.state.errors.first_name && t(`signup.${this.state.errors.first_name}`)}</div>
                </div>
                <div className={!this.props.isSummary ? 'form-group col-md-6 col-lg-6' : 'col-lg-6'}>
                  <label htmlFor="lastname">{t('signup.last_name_lbl')}</label>&nbsp;<span className="required-flag">{!this.props.isSummary ? '*' : ''}</span>
                  <input
                      type="text"
                      className={!this.props.isSummary ? 'form-control' : 'summary-signup'}
                      id="last_name"
                      name="last_name"
                      value={this.state.input.last_name || ''}
                      autoComplete="__"
                      onChange={this.handleChange}
                      onBlur = {this.handleTouch}
                      required={true}
                      maxLength={100}
                      disabled={this.props.isSummary}
                    />
                    <div className="text-danger-alert">{this.state.touched.last_name && this.state.errors.last_name && t(`signup.${this.state.errors.last_name}`)}</div>
                </div>
              </div>
              <div className="form-row">
                <div className={!this.props.isSummary ? this.state.input.is_individual ? 'form-group col-md-12 col-lg-12 left_labels_alignment div-disabled' : 'form-group col-md-12 col-lg-12 left_labels_alignment' : 'col-lg-12'}>
                  <label htmlFor="organisationname">{t('signup.organisation_name_lbl')}</label>&nbsp;<span className="required-flag">{(!this.state.input.is_individual && !this.props.isSummary) ? '*' : ''}</span>
                  <input
                    type="text"
                    className={!this.props.isSummary ? 'form-control' : 'form-control summary-signup'}
                    id="client_name"
                    name="client_name"
                    value={this.state.input.client_name || (this.props.isSummary ? 'NA' : '')}
                    autoComplete="__"
                    onChange={this.handleChange}
                    onBlur = {this.handleTouch}
                    disabled={this.state.input.is_individual || this.props.isSummary}
                    required={!this.state.input.is_individual}
                    maxLength={255}
                  />
                  <div className="text-danger-alert">{this.state.touched.client_name && this.state.errors.client_name && t(`signup.${this.state.errors.client_name}`)}</div>
                  <div className="text-danger-alert" hidden={this.state.isOrgAlertNotRequired}>{this.state.orgMessage}</div>
                </div>
              </div>
              
              <div className="form-row">
                <div className={!this.props.isSummary ? 'form-group col-md-12 col-lg-12 left_labels_alignment' : 'col-lg-12'}>
                  <label htmlFor="emailaddress">{t('signup.email_address_lbl')}</label>&nbsp;<span className="required-flag">{!this.props.isSummary ? '*' : ''}</span>
                  <input
                    type="email"
                    className={!this.props.isSummary ? 'form-control' : 'summary-signup'}
                    id="email"
                    name="email"
                    value={this.state.input.email || ''}
                    autoComplete="__"
                    onChange={this.handleChange}
                    onBlur = {this.handleTouch}
                    required={true}
                    maxLength={100}
                    disabled={this.props.isSummary}
                  />
                  <div className="text-danger-alert">{this.state.touched.email && this.state.errors.email && t(`signup.${this.state.errors.email}`)}</div>
                  <div className="text-danger-alert" hidden={this.state.isRedAlertNotRequired}>{this.state.resultMessage}</div>
                </div>
              </div>
                {!this.props.isSummary
                  ? <center>
                  <Pagination isNoButton={true} currentIndex={1} blockNavigation={!this.state.isValid} showPaginationNumber={true} isNext={true} />
                  <button className="btn btn-info next_button" onClick={this.saveInitiator.bind(this)} disabled={!this.state.isValid}>{t('pagination.next')}</button>
                </center>
                  : ''}
              </form>
            </Jumbotron>
            <div className="col-lg-1 col-md-1"></div>
            {!this.props.isSummary
              ? <BannerImage className="signUpBannerImage"/>
              : ''}
            </div> 
          </div>
          </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  savesignUpDetails: (details) => dispatch(savesignUpDetails(details)),
});

const mapStateToProps = (state) => ({
  signUpDetailsSaved: state.crud.signUpDetails
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(SignUp));
