/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitterSquare,
  faLinkedinIn,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import TMXLogo from '../../../assets/img/TMX-Analytics-Logo.png';

class Footer extends Component {
  state = {};

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container-fluid footer navbar-font">
          <div className='row'>
            <div className="col-lg-2 col-md-1"></div>
            <nav className="col-lg-8 col-md-10">
              <Navbar
                collapseOnSelect
                expand="lg"
                variant="dark"
                className="navbar_color sticky-top"
              >
                <Navbar.Brand href="/signup">
                <img src={TMXLogo} height="50" width="230"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto">
                    {/* <Nav.Link eventKey={t('header.home')} href='/signup'>{t('header.home')}</Nav.Link>
                <Nav.Link eventKey={t('header.products_pricing')} href="#pricing">{t('header.products_pricing')}</Nav.Link>
                <Nav.Link eventKey={t('header.solutions')} href="#solutions">{t('header.solutions')}</Nav.Link> */}
                </Nav>
                  <Nav className="links_name_panel">
                    <Nav.Link className='link-hover-style' target="_blank" eventKey={t('footer.contact_us')} href={t('footer.contactus_link')}>
                      {t('footer.contact_us')}
                    </Nav.Link>
                    <Nav.Link
                       className='link-hover-style'
                      target="_blank"
                      eventKey={t('footer.terms_use_link')}
                      href={t('footer.terms_use_link')}
                    >
                      {t('footer.terms')}
                    </Nav.Link>
                    <Nav.Link
                      className='link-hover-style'
                      target="_blank"
                      eventKey={t('footer.privacy_policy_link')}
                      href={t('footer.privacy_policy_link')}
                    >
                      {t('footer.privacy')}
                    </Nav.Link>
                    <Nav.Link
                      className='link-hover-style'
                      target="_blank"
                      eventKey={t('footer.access_link')}
                      href={t('footer.access_link')}
                    >
                      {t('footer.accessability')}
                    </Nav.Link>
                    <Nav className="links_icon_panel">
                      <Nav.Link
                      className='link-hover-style'
                        target="_blank"
                        eventKey={t('footer.facebook_link')}
                        href={t('footer.facebook_link')}
                      >
                        <FontAwesomeIcon icon={faFacebook} size="1x" />
                      </Nav.Link>
                      <Nav.Link
                      className='link-hover-style'
                        target="_blank"
                        eventKey={t('footer.twitter_link')}
                        href={t('footer.twitter_link')}
                      >
                        <FontAwesomeIcon icon={faTwitterSquare} size="1x" />
                      </Nav.Link>
                      <Nav.Link
                      className='link-hover-style'
                        target="_blank"
                        eventKey={t('footer.linkedin_link')}
                        href={t('footer.linkedin_link')}
                      >
                        <FontAwesomeIcon icon={faLinkedinIn} size="1x" />
                      </Nav.Link>
                      <Nav.Link
                      className='link-hover-style'
                        target="_blank"
                        eventKey={t('footer.youtube_link')}
                        href={t('footer.youtube_link')}
                      >
                        <FontAwesomeIcon icon={faYoutubeSquare} size="1x" />
                      </Nav.Link>
                    
                  </Nav>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </nav>
            <div className="col-lg-2 col-md-1"></div>
          </div>
          <div className='row'>
            <div className="col-lg-2 col-md-1"></div>
            <div className="col-lg-8 col-md-10 footer-cotent">
                {t('footer.footerContent')}
            </div>
            <div className="col-lg-2 col-md-1"></div>
          </div>
          </div>
      </>
    );
  }
}

export default withNamespaces()(Footer);
