/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import apiUrl from '../../../utils/apiUrl';
import { trackPromise } from 'react-promise-tracker';
import { store } from '../../../utils/httpUtil';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import moment from 'moment';
import PaymentDetails from '../common/paymentDetails';
import { billingDetails, paymentDetails, storeCurrentPage, updatePayment } from '../../../actions/index';
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ErrorMessage from '../common/errorMessage';
import infoImage from '../../../assets/img/information_icon.png';

class Payment extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      enableBilling: true,
      paymentDet: {
        payableAmount: 0,
        paymentAmount: 0,
        lastPaid: ''
      },
      recentPayments: {
        paidAmount: 0,
        lastPaid: ''
      },
      resultMessage: '',
      isAlertNotRequired: true,
    };
    // this.props.getCurrentUserDetails();
    this.handleSwitch = this.handleSwitch.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.formatAmount = this.formatAmount.bind(this);
    this.getPaymentDetails();
    this.setPaymentInfo();
  }

  openPopup() {
    const modal = document.getElementById('instructionPopup');
    modal.style.display = 'block';
  }

  closeEditUser() {
    const modal = document.getElementById('instructionPopup');
    modal.style.display = 'none';
  }

  handleSwitch = (value) => {
    const obj = this.state;
    obj.enableBilling = value === 1;
    this.setState({
      obj,
    });
  };

  getPaymentDetails() {
    const paymentDetails = this.props.paymentDetails;
    let paymentDet = this.state.paymentDet;
    if(paymentDetails && paymentDetails.length) {
      for(let i=0; i<paymentDetails.length; i++) {
        if(paymentDetails[i] && paymentDetails[i].status && paymentDetails[i].status.toLowerCase() === 'error') {
          const amount = parseFloat(paymentDetails[i].amt_paid);
          paymentDet.payableAmount = paymentDet.payableAmount + amount;
          paymentDet.billingPeriod = {
            startDate: moment(paymentDetails[i].billing_start_date).format('MMMM Do YYYY'),
            endDate: moment(paymentDetails[i].billing_end_date).format('MMMM Do YYYY'),
          }
        } else {
          paymentDet.paidAmount = paymentDetails[i].amt_paid;
          paymentDet.lastPaid = moment(paymentDetails[i].payment_date).format('MMMM Do YYYY');
        }
      }
      this.setState({paymentDet});
      this.props.updatePayment(paymentDet);
    }
  }

  //x: string amount with or without comma seperated values: .replace('/\,/g','')
  formatAmount(x) {
    let value = parseFloat(x).toLocaleString('en-US' ,{
      style: 'currency',
      currency: 'USD',//currency: 'CAD'
      maximumFractionDigits: 2
    })
    return value;
  }

  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
        this.props.history.push(this.props.currentLoadingPage);
        this.props.storeCurrentPage('');
    }
  }

  exportPdf() {
    const details = {
      clientId: this.props.currentUser.clientId,
      token: this.props.userDetails ? this.props.userDetails.access_token : this.props.currentUser.access_token
    }
    trackPromise(store(apiUrl.exportPdf, details).then((res) => {
      var documentName = 'TMX Export PDF - '+ moment(new Date()).toString();
      let response_buffer = res.data.data.data;
      const arr = new Uint8Array(response_buffer);
      var blob = new Blob([arr], {type: "application/pdf;charset=utf-8"});
      saveAs(blob, documentName);
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

  setPaymentInfo() {
    if(this.props.paymentDetails && this.props.paymentDetails.length > 0) {
      const paidArray = [];
      this.props.paymentDetails.forEach((element) => {
        if (element.status === 'paid') {
          paidArray.push(element);
        }
      });
      const newarr = paidArray.sort((a, b) => {
        return moment(a.payment_date).diff(b.payment_date);
      });
      if(newarr && newarr.length > 0){
        const recentPaymentDetails = newarr[newarr.length-1];
        let recentPayments = this.state.recentPayments;
        recentPayments.paidAmount = recentPaymentDetails.amt_paid;
        recentPayments.lastPaid = moment(recentPaymentDetails.payment_date).format('MMMM Do YYYY');
        this.setState({recentPayments});
      }
    }
  }

  render() {
    const userDetails = this.props.userDetails ? this.props.userDetails : null;
    const currentUser = this.props.currentUser ? this.props.currentUser : null;
    const { enableBilling } = this.state;
    const billingPeriod = this.state.paymentDet.billingPeriod;
    // const billingPeriod = this.props.billingPeriod ? this.props.billingPeriod : null;
    // const paymentAmount = this.props.paymentAmount ? this.props.paymentAmount : null;
    // const paymentDate = this.props.paymentDate ? this.props.paymentDate : null;
    const { t } = this.props;
    const { paymentType } = this.props.currentUser;
    const paymentTypeDisplay = paymentType ? t(`current_payment_method.${paymentType}`) : '';
    return (
    <>
      <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
      <div className="container-fluid ">
        <div className="row">
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
          <Jumbotron className="col-lg-10 col-md-10 col-sm-10 col-xs-10 jumbotron_usage_summary">
            <Link className="payment_console_back_link" to='/admin'>{'Home'}</Link>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="payment_header_panel">
                <div className="usage_heading1">
                 {t('payment.welcome_stmt')}{currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : null}
                </div>
              </div>
              {/* <div className="payment_hr">
                <hr></hr>
              </div> */}
              <div className="row payment_balnc_panel">
                <div className="payment_method client_admin">{t('current_payment_method.label')}<span className="payment_method_value">{paymentTypeDisplay}</span></div>
                <div className="payment_balnc_header"> {t('payment.blnc_stmt')}</div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 payment_balnc_body">
                  <div className="row balnc_body_panel">
                    <div className="account_status_panel">
                        <span className="account_balnc_details">
                          <span> Amount Due: &nbsp; {this.state.paymentDet.payableAmount ? this.formatAmount(this.state.paymentDet.payableAmount) : 0.00}</span>
                          {/* <FontAwesomeIcon
                            className="payment_sync_icon"
                            icon={faSync}
                            size="1x"
                          /> */}
                        </span>
                        {/* <span className="account_status">
                          {t('payment.update_stmt')}
                        </span> */}
                    </div>
                    <div className="col-lg-10 col-md-12 details_section_panel">
                      <div className='col-lg-3 col-md-5'></div>
                      <div className="col-lg-6 col-md-3">
                        {/* <div className="row billing_info_tab">
                          <span className="billing_date_info">
                            {t('payment.billing_date_label')} {billingPeriod ? billingPeriod.startDate : null} - {billingPeriod ? billingPeriod.endDate : null}
                          </span>
                          <div className="billing_date_info">
                            <hr></hr>
                          </div>
                          <div>
                            <span className="billing_date_info">
                             {t('payment.thank_you_lbl')} {this.formatAmount(paymentAmount) || null} receieved in {paymentDate || null}
                            </span>
                          </div>
                        </div> */}
                      </div>
                      <div className="col-lg-3 col-md-4">
                        <div className="billing_info_tab_bottom">
                            <button className="btn pay_now_link" disabled={this.state.paymentDet && this.state.paymentDet.payableAmount === 0} onClick={() => this.props.history.push('/selectCard')}>{t('payment.pay_now_btn')}</button>
                            {/* <Link to="/selectCard" className="pay_now_link" style={{pointerEvents: 'none'}} onClick={ (event) => event.preventDefault() }>{t('payment.pay_now_btn')}</Link> : */}
                            {/* <Link className="pay_now_link" to="/selectCard">
                              {t('payment.pay_now_btn')}
                            </Link> */}
{/* <button disabled={this.state.paymentDet && this.state.paymentDet.payableAmount === 0} className='btn'>
                            { this.state.paymentDet && this.state.paymentDet.payableAmount === 0 ?
                            <Link to="/selectCard" className="pay_now_link" style={{pointerEvents: 'none'}} onClick={ (event) => event.preventDefault() }>{t('payment.pay_now_btn')}</Link> :
                            <Link className="pay_now_link" to="/selectCard">
                              {t('payment.pay_now_btn')}
                            </Link>
                            }
                          </button> */}
                          {/* <Link className="download_bill_link_payment" to="#">
                            {t('payment.download_bill')}
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row balnc_body_panel sizing'>
                    {/* <div className='col-lg-2 col-md-5'></div> */}
                    <div className="col-lg-6 col-md-3">
                        <div className="row billing_info_tab">
                          {
                          billingPeriod && billingPeriod.startDate && <span className="billing_date_info">
                            {t('payment.billing_date_label')} {billingPeriod ? billingPeriod.startDate : null} - {billingPeriod ? billingPeriod.endDate : null}
                          </span>
                          }
                          <div className="billing_date_info">
                            <hr></hr>
                          </div>
                          <div>
                            {
                             this.state.recentPayments && this.state.recentPayments.paidAmount ?  <span className="billing_date_info">
                             {t('payment.thank_you_lbl')} {this.state.recentPayments.paidAmount ? this.formatAmount(this.state.recentPayments.paidAmount) : 0.00 || null} received on {this.state.recentPayments.lastPaid || null}
                            </span> : <span className="billing_date_info"> No payments done so far </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <br />
              <div className="payment_heading2">
                {t('payment.detailed_sub1')} &amp; {t('payment.detailed_sub2')}
              </div>
              <span className="payment-detailed-icon" onClick={this.openPopup}><img src={infoImage} className='info_style'/><span className="AddMoreProductsWithComment"><h6>{t('payment.understand_bill_list')}</h6></span></span>
                <Jumbotron className="col-lg-12 col-md-12 col-sm-12 col-xs-12 jumbotron_payment_billing2">
                    {/* <div className="col-lg-3 col-md-12 col-sm-12 sol-xs-12 payment_bill_preview_panel">
                      <div className="payment_sub_head1">
                        {t('payment.understand_bill')}
                      </div>
                      <div className="bill_preview">
                      <p className='align-list'>{t('payment.understand_bill_list1')}</p>
                      <p className='align-list'>{t('payment.understand_bill_list2')}</p>
                      </div>
                    </div> */}
                    <div className="col-lg-12 col-md-12 col-sm-12 sol-xs-12 payment_table_preview_panel">
                      <div className="payment_main_body">
                        <div className="payment_sub1_body">
                          {/* <ToggleButtonGroup
                            type="radio"
                            name="options"
                            defaultValue={1}
                            onChange={this.handleSwitch}
                          >
                            <ToggleButton className={enableBilling ? 'toggle_active' : ''} value={1}>{t('payment.billing_btn')} 
                            <span className="AddMoreProductsWithComment"><h6>{t('payment.understand_bill_list')}</h6></span>
                            </ToggleButton> 
                            <ToggleButton className='toggle_active' value={1}>{t('payment.payment_btn')}
                            <span className="AddMoreProductsWithComment"><h6>{t('payment.understand_bill_list')}</h6></span>
                             </ToggleButton>
                          </ToggleButtonGroup>*/}
                        </div>
                        <div style={{textAlign: 'right', marginRight: '2.5rem'}}>
                          <Link className="export_pdf_link" to='#' style={{cursor: 'pointer'}} onClick={() => this.exportPdf()}>{t('payment.export_pdf')}</Link>
                        </div>
                        <div className="payment_sub_table">
                          <PaymentDetails />
                        </div>
                      </div>
                    </div>
                </Jumbotron>
            </div>
          </Jumbotron>
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
        </div>
      </div>
      <div id='instructionPopup' className='modal instructionDiv'>
        <div className="subscribedproducts-modal-content">
          <div className='container-fluid'>
            <div className='row'>
                <span className="close" onClick={this.closeEditUser}>&times;</span>
            </div>
            <div className='row'>
              {/* <h5 className='edituser-popup-header'>{t('usermanagement.subscribedproducts')}</h5> */}
              <ul>
                  <li><h6>{t('payment.understand_bill_list1')}</h6></li>
                  <li><h6>{t('payment.understand_bill_list2')}</h6></li>
              </ul>
            </div>
            </div>
            <div className='row'>
              {/* <button onClick={this.updateProducts} className="btn btn-info products-save-button">{t('usermanagement.save')}</button> */}
            </div>
        </div>
      </div>
      
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.crud.userDetails,
  enableBilling: state.crud.enableBilling,
  billingPeriod: state.crud.billingPeriod,
  paymentAmount: state.crud.last_payment_amt,
  paymentDate: state.crud.last_payment_date,
  currentLoadingPage: state.crud.currentLoadingPage,
  currentUser: state.crud.currentUser,
  paymentDetails: state.crud.paymentlist
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUserDetails: () => dispatch(billingDetails()),
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
  updatePayment: (details) => dispatch(updatePayment(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Payment));
