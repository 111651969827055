/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import ReactHtmlParser from 'react-html-parser';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';
import { getLocalStorage } from '../../../utils/storageUtil';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
// import Users from './users';
import { storeCurrentPage, userListUpdated } from '../../../actions/index';
// import Signup from './signUp';
import { store } from '../../../utils/httpUtil';
import apiUrl from '../../../utils/apiUrl';
import ErrorMessage from '../common/errorMessage';
import SuccessMessage from '../common/successMessage';
import moment from 'moment';

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_info: {
        totalamount: 0,
        freetrailendson: '10th Sept'
      },
      signupData: {
        orgName: ''
      },
      products_selected: [],
      selectedPeriod: '',
      summary: {
        isPnPagreed: false,
        pnpErrMsg: false,
        submitDisable: true,
        isPromoEnabled: false,
        msaErrMsg: false,
        orgNameFlag: false
      },
      resultMessage: '',
      isAlertNotRequired: true,
      productAmountCalculationTable: [],
      productAmountCalculation: {},
      isSuccessAlertNotRequired: true,
      proRatedAmount : 0,
      hideBackButton: false,
      successMessages: '',
      currencyApplied: ''
    };
    this.setPromoFlag = this.setPromoFlag.bind(this);
    this.setPnPFlag = this.setPnPFlag.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateField = this.validateField.bind(this);
    this.monitorMSAflag = this.monitorMSAflag.bind(this);
    this.formatAmount = this.formatAmount.bind(this);
  }

  getProductDetails(taxRate) {
    trackPromise(this.getProRatedAmount().then((proRatedValue) => {
      this.setState({ proRatedAmount : proRatedValue.totalAmountWithTax });
      let billingData = proRatedValue.totalAmtbillArray;
      if (this.props.allDetails && this.props.allDetails.products && this.props.allDetails.products.length) {
        const { products } = this.props.allDetails;
        taxRate = taxRate || 0.00;
        const productAmountCalculation = {
          totalAmount: 0,
          totalFreeTrialAmount: 0,
          totalTax: 0,
          totalFreeTrialTax: 0,
          taxWithAmount: 0,
          taxWithFreeTrialAmount: 0,
          totalMonthlyRecurring: 0,
          totalMonthlyRecurringTax: 0,
          taxWithMonthlyRecurring: 0
        };
        for (let i = 0; i < products.length; i++) {
          for (let j = 0; j < billingData.length; j++) {
            if (products[i].tmxProductId === billingData[j].tmxProductId) {
              products[i].newAmount = billingData[j].billAmount;
              products[i].freeTrialEndsOn = billingData[j].freeTrialEndsOn.replace('-','');
            }
          }
        }
        const productAmountCalculationTable = [];
        for (let i = 0; i < products.length; i++) {
          if (products[i]) {
            const productDet = {
              no: i + 1,
              productName: products[i].keyName,
              amount: (!(products[i].freeTrialDays && products[i].freeTrialDays > 0 )) ? products[i].newAmount : 0,
              freeTrialAmount: ((products[i].freeTrialDays && products[i].freeTrialDays > 0)) ? products[i].newAmount : 0,
              freeTrialEndsOn: products[i].freeTrialEndsOn,
              monthlyRecurring: products[i].amount
            };
            productAmountCalculationTable.push(productDet);
            productAmountCalculation.totalAmount += productDet.amount;
            productAmountCalculation.totalFreeTrialAmount += productDet.freeTrialAmount;
            productAmountCalculation.totalMonthlyRecurring += productDet.monthlyRecurring;
            if (productDet.freeTrialEndsOn) {
              productAmountCalculation.freeTrialEndsOn = productDet.freeTrialEndsOn;
            }
          }
        }
        productAmountCalculation.totalTax = (productAmountCalculation.totalAmount / 100) * taxRate;
        productAmountCalculation.totalFreeTrialTax = (productAmountCalculation.totalFreeTrialAmount / 100) * taxRate;
        productAmountCalculation.totalMonthlyRecurringTax = (productAmountCalculation.totalMonthlyRecurring / 100) * taxRate;
        productAmountCalculation.taxWithAmount = productAmountCalculation.totalAmount + productAmountCalculation.totalTax;
        productAmountCalculation.taxWithFreeTrialAmount = productAmountCalculation.totalFreeTrialAmount + productAmountCalculation.totalFreeTrialTax;
        productAmountCalculation.taxWithMonthlyRecurring = productAmountCalculation.totalMonthlyRecurring + productAmountCalculation.totalMonthlyRecurringTax;
        this.setState({ productAmountCalculationTable, productAmountCalculation });
      }
    }));
  }

  calculateBillingStartDate(freeTrialDays, agreedOn) {
    let billingStartDate = agreedOn;
    if (freeTrialDays === 0) {
      const date = new Date(agreedOn);
      const addedDate = date.setDate(date.getDate() + (freeTrialDays));
      billingStartDate = new Date(addedDate);
    } else {
      const date = new Date(agreedOn);
      const addedDate = date.setDate(date.getDate() + (freeTrialDays));
      billingStartDate = new Date(addedDate);
    }
    return billingStartDate;
  }

  calculateDaysInMonth(startDate) {
    const now = new Date(startDate);
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  }

  calculateBillingEndDate(billCycle, startDate) {
    const date = new Date(startDate);
    let billingEndDate = new Date();
    if (billCycle && billCycle.toLowerCase() === 'monthly') {
      billingEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else {
      billingEndDate = new Date(date.setFullYear(date.getFullYear() + 1));
    }
    return billingEndDate;
  }

  calculateDiffDays(startDate, endDate) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  getFormattedAmount(amount) {
    let value = amount;
    if (value) {
      value = parseFloat(value.toFixed(2));
    }
    return value;
  }

  calculateBillAmount(amount, startDate, endDate, billCycle) {
    const daysInMonth = this.calculateDaysInMonth(startDate);
    const diffDays = this.calculateDiffDays(startDate, endDate);
    let billAmount = amount;
    if (billCycle && billCycle.toLowerCase() === 'monthly') {
      if (daysInMonth && diffDays && amount) {
        billAmount = (amount / daysInMonth) * diffDays;
      }
    }
    return this.getFormattedAmount(billAmount);
  }

  calculatePaymentAmountWithTax(amount, tax) {
    let taxAmount = amount;
    if (amount && tax) {
      taxAmount = (amount / 100) * tax;
      taxAmount += amount;
    }
    return this.getFormattedAmount(taxAmount);
  }

  getProRatedAmount(){
    const receivedObj = {
      products: this.props.allDetails.products,
      subscriberDetails: this.props.allDetails.subscriber,
      taxRate: this.props.taxRate ? this.props.taxRate.taxRate : 0,
    };
    return new Promise((fulfill) => {
      trackPromise(store(apiUrl.getPayableAmount, { receivedObj }).then((res) => {
        if (res) {
          return fulfill(res.data.data);
        }
      }).catch(() => {
        window.scrollTo(0, 0);
      }));
    })
  }

  calculateFreeTrialEndson(freeTrialDays) {
    let freeTrialEndson = new Date();
    if (freeTrialDays) {
        let date = new Date(),
            addedDate = date.setDate(date.getDate() + freeTrialDays);
            freeTrialEndson = new Date(addedDate);
    }
    return moment(freeTrialEndson).format('YYYY/MM/DD');
  }

  formatAmount(x) {
    let value = parseFloat(x).toLocaleString('en-US' ,{
      style: 'currency',
      currency: 'USD',//currency: 'CAD'
      maximumFractionDigits: 2
    })
    return value;
  }

  componentDidMount() {
    // this.props.recieveAllDetails();
    this.getProductDetails(this.props.taxRate ? this.props.taxRate.taxRate : 0);
    let totalAmount = 0;
    if (this.props.allDetails && this.props.allDetails.products
      && this.props.allDetails.products.length > 0) {
      this.props.allDetails.products.forEach((val) => {
        totalAmount += parseFloat(val.amount[this.props.allDetails.selectedPeriod]);
      });
      this.setState({
        products_selected: this.props.allDetails.products,
        selectedPeriod: this.props.allDetails.selectedPeriod,
        payment_info: {
          totalamount: totalAmount
        }
      });
    }
    if(this.props.allDetails && this.props.allDetails.currentUser && this.props.allDetails.currentUser.country) {
      let country = this.props.allDetails.currentUser.country;
      if(country === 'CA') {
        this.setState({
          currencyApplied: 'CAD'
        });
      } else {
        this.setState({
          currencyApplied: 'USD'
        });
      }
    }
  }

  monitorMSAflag(e) {
    const { summary } = this.state;
    summary.msaErrMsg = !!e.target.checked;
    this.setState({
      summary
    },() => {
      if(this.state.summary.orgNameFlag ){
        summary.orgNameFlag == !e.target.checked;
        this.setState({
          summary
        });
      }
    });
  }

  setPromoFlag(e) {
    const { summary } = this.state;
    summary.isPromoEnabled = e.target.checked;
    this.setState({
      summary
    });
  }

  setPnPFlag(e) {
    const { summary } = this.state;
    summary.isPnPagreed = e.target.checked;
    summary.pnpErrMsg = !e.target.checked;
    this.setState({
      summary
    });
  }

  saveInitiator() {
    this.createPackage(this);
  }

  createPackage(e) {
    const postData = e.props.allDetails;
    postData.subscriber.signedOnName = this.state.signupData.orgName;
    const users = postData.assignProducts.map((products) => {
      delete products.selectedProduct;
      return products;
    });
    const postObject = {
      products: postData.products,
      clientId: postData.currentUser.clientId,
      // users: _.map(postData.usersList, (object) => _.pick(object, ['userId', 'firstName', 'email'])),
      subscriberDetails: postData.subscriber,
      users,
      signUpDetails: {
        lastName: postData.currentUser.lastName,
        firstName: postData.currentUser.firstName,
        email: postData.currentUser.email,
      },
      billingDetails: {
        selectedCountry: postData.currentUser.country,
        selectedProvince: postData.currentUser.provinceState
      },
      monerisDetails: {
        skipMonerisFlow: _.isEmpty(this.props.cardDetails) ? true : false
      //   dataKey: this.props.cardDetails ? this.props.cardDetails.respDataKey : '',
      //   currency: this.props.cardDetails ? this.props.cardDetails.cardCurrency : 'CAD',
      //   issuerId: this.props.cardDetails ? this.props.cardDetails.issuerId : '',
      //   cardDetails: this.props.cardDetails,
      },
      taxRate: this.props.taxRate && this.props.taxRate.taxRate && this.props.taxRate.taxRate !== 0 ? this.props.taxRate.taxRate : 0,
      taxRateDetails: this.props.taxRate && this.props.taxRate.taxRateDetails && this.props.taxRate.taxRateDetails.length>0 ? this.props.taxRate.taxRateDetails : [],
    };
    const access_token = getLocalStorage('access_token');
    postObject.token = access_token;
    let successMessages = 'Payment Made Successfully.';
    trackPromise(store(apiUrl.updateSubscriptions, { postObject }).then((res) => {
      // this.props.history.push('/confirmation');
      this.props.userListUpdated(false);
      if (res) {
        if (postObject.monerisDetails.skipMonerisFlow) {
          successMessages = 'Products added successfully';
        }else{
          if(res.data && res.data.data && Object.keys(res.data.data).length == 0){
            successMessages = 'Payment Made Successfully.';
          }
          else if(res.data && res.data.data && !res.data.data.isSuccess){
            successMessages = 'Payment Failure.';
          }
        } 
        this.setState({ successMessages, isSuccessAlertNotRequired: false, hideBackButton: true });
        // this.props.storeAssignProducts([]);     
      }
    }).catch((err) => {
      // this.props.history.push('/confirmation');
      // this.setState({ isSuccessAlertNotRequired: false });
      this.setState({ successMessages, resultMessage: err.message, isAlertNotRequired: false, isSuccessAlertNotRequired: true });
      window.scrollTo(0, 0);
      this.props.storeAssignProducts([]);
    }));
  }

  handleChange(e) {
    const summaryNew = this.state.summary;
    const signUpNew = this.state.signupData;
      signUpNew.orgName = e.target.value;
    if(this.validateField(e.target.value) && !this.props.allDetails.signUpDetails.is_individual) {
      summaryNew.orgNameFlag = true;
      this.setState({
        summary: summaryNew,
        signupData: signUpNew
      });
    } else {
      //console.log("Validation Failed oNly if Required"); 
      if(!this.props.allDetails.signUpDetails.is_individual){
      summaryNew.orgNameFlag = false;
      this.setState({
        summary: summaryNew,
        signupData: signUpNew
      });
      } else {
        if(this.validateField(e.target.value)) {
            summaryNew.orgNameFlag = true;
            this.setState({
            summary: summaryNew,
            signupData: signUpNew
          });
        } else {
          summaryNew.orgNameFlag = false;
          this.setState({
          summary: summaryNew,
          signupData: signUpNew
        });
        }
      }
    }
    
  }

  validateField(e) {
    return e !== '';
  }

  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
      this.props.history.push(this.props.currentLoadingPage);
      this.props.storeCurrentPage('');
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
      <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
      <SuccessMessage shouldNotDisplaySuccess={this.state.isSuccessAlertNotRequired} backButton={true} successMessage={this.state.successMessages} />
      <div className="container-fluid" onClick={this.clickFunction}>
        <div className="row">
        <div className="col-lg-1 col-md-1"></div>
        <Jumbotron className="col-lg-10 col-md-10 col-sm-12 col-xs-12 jumbotron_usage_summary">
          <Link className="payment_console_back_link" to="/allocateProducts" hidden={this.state.hideBackButton}><h6>{t('summary.back_button')}</h6></Link>
          <div className="row">
            <div className='col-lg-1'></div>
            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 usage_heading1-selected">{t('summary.products_selected_lbl')}</div>
            <div className='col-lg-1'></div>
            </div>
          <br></br>
          <div className="row">
          <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'></div>
          <table className="summary-amount-table scrollbar scrollbar-primary">
            <thead>
              <tr className="amount-borderer-tr">
              <th className="number-alignment"></th>
              <th></th>
              <th className="text-alignment">{t('summary.amount_due_now')}</th>
              <th className="text-alignment">{t('summary.amount_free_trail')}</th>
              <th className="text-alignment">{t('summary.monthly_recurring')}</th>
              </tr>
            </thead>
            <tbody>
            {this.state.productAmountCalculationTable.map((el, index) => <tr className="amount-borderer-tr" key={index}>
              <td className="number-alignment">{el.no}</td>
              <td>{el.productName}</td>
              <td className='align-amount-value'>{el.amount ? this.formatAmount(el.amount) : `$0.00`}</td>
              <td className='align-amount-value'>{el.freeTrialAmount ? this.formatAmount(el.freeTrialAmount) : `$0.00`}</td>
              <td className='align-amount-value'>{el.monthlyRecurring ? this.formatAmount(el.monthlyRecurring) : `$0.00`}</td>
              </tr>
            )
            }
            <tr className="amount-nonborderer-tr unborder-first">
              <td></td>
              <td>Subtotal</td>
              <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.totalAmount ? this.formatAmount(this.state.productAmountCalculation.totalAmount) : `$0.00`}</td>
              <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.totalFreeTrialAmount ? this.formatAmount(this.state.productAmountCalculation.totalFreeTrialAmount) : `$0.00`}</td>
              <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.totalMonthlyRecurring ? this.formatAmount(this.state.productAmountCalculation.totalMonthlyRecurring) : `$0.00`}</td>
            </tr>
            <tr className="amount-nonborderer-tr">
              <td></td>
              <td>Tax</td>
              <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.totalTax ? this.formatAmount(this.state.productAmountCalculation.totalTax) : `$0.00`}</td>
              <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.totalFreeTrialTax ? this.formatAmount(this.state.productAmountCalculation.totalFreeTrialTax) : `$0.00`}</td>
              <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.totalMonthlyRecurringTax ? this.formatAmount(this.state.productAmountCalculation.totalMonthlyRecurringTax) : `$0.00`}</td>
            </tr>
            <tr className="amount-nonborderer-tr">
              <td></td>
              <td>Total</td>
              <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.taxWithAmount ? this.formatAmount(this.state.productAmountCalculation.taxWithAmount) : `$0.00`} </td>
              <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.taxWithFreeTrialAmount ? this.formatAmount(this.state.productAmountCalculation.taxWithFreeTrialAmount) : `$0.00`}</td>
              <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.taxWithMonthlyRecurring ? this.formatAmount(this.state.productAmountCalculation.taxWithMonthlyRecurring) : `$0.00`}</td>
            </tr>
            </tbody>
          </table>
          <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'></div>
          </div>
          {this.state.productAmountCalculation.freeTrialEndsOn ?
            <div className="row">
              <div className='col-lg-1'></div>
                <div className="col-lg-10 summary-paymentinfo-card">
                  <div className="payment-info-tags">{t('summary.free_trial_end_lbl')}&nbsp;<span className="payment-info-values">{this.state.productAmountCalculation.freeTrialEndsOn ? this.state.productAmountCalculation.freeTrialEndsOn : `--`}</span></div>
                </div>
              <div className='col-lg-1'></div>
            </div>
            : <></>
          }
          <div className="row">
          <div className='col-lg-1'></div>
          <div className="col-lg-10">
          <div className="summary-payment-note">{ t('summary.payment_note')}</div>         
          </div>
          <div className='col-lg-1'></div>
          </div>
          <br></br>
          <div className="container-fluid summary-footer-comtainer">
          <div className='row'>
          <div className='col-lg-1'></div>
          <form className="col-lg-10">
          <div className="form-check left_labels_alignment checkbox_summary_alignment">
            <input
              type="checkbox"
              className="form-check-input"
              id="isMSAagreed"
              name="isMSAagreed"
              onChange={this.monitorMSAflag}
              checked={this.props.allDetails.subscriber.isMSAagreed || false}
              required
            />
            <label className="summary-check-labels" htmlFor="isMSAagreed">
            {t('summary.msa_statment1')}
            <Link className='btn summary-links' to="/subscriber">{t('summary.subscriber_link')}</Link>
            {t('summary.msa_statment2')}
            <input
              type="text"
              className='form-control subscriber_input'
              id="subscriber_input"
              name="subscriber_input"
              value={this.state.signupData.orgName}
              autoComplete="__"
              placeholder={t('summary.org_name')}
              onChange={this.handleChange}
              required
              maxLength={100}
            />
            <div className="text-danger-alert-small">{}</div>
            </label>
            <div className="text-danger-alert">{this.state.summary.msaErrMsg
              ? t('summary.msaErrMsg') : !this.state.summary.msaErrMsg && !this.state.summary.orgNameFlag
              ? t('summary.orgNameErrMsg') : ''}</div>
          </div>
          <div className="form-check left_labels_alignment checkbox_summary_alignment">
            <input
              type="checkbox"
              className="form-check-input"
              id="isPnPagreed"
              name="isPnPagreed"
              value={this.state.summary.isPnPagreed}
              onChange={this.setPnPFlag}
              required
            />
            <label className="summary-check-labels" htmlFor="isMSAagreed">
            {t('summary.tc_statment')}<a className='btn summary-links' rel="noreferrer" target="_blank" href={t('summary.termsC_url')}>{t('summary.termsC_link')}</a>{t('summary.tc_and_statment')}<a rel="noreferrer" className='btn summary-links' target="_blank" href={t('summary.privacyP_url')}>{t('summary.privacyP_link')}</a></label>
            <div className="text-danger-alert">{this.state.summary.pnpErrMsg
              ? t('summary.pnpErrMsg') : ''}</div>
          </div>
          <div className="form-check left_labels_alignment checkbox_summary_alignment">
            <input
              type="checkbox"
              className="form-check-input"
              id="isPromoEnabled"
              value={this.state.summary.isPromoEnabled}
              onChange={this.setPromoFlag}
              name="isPromoEnabled"
            />
            <label className="summary-check-labels" htmlFor="isPromoEnabled">
            {ReactHtmlParser(t('summary.advt_statment'))}
            </label>
          </div>
          <center>
          <Link><button className="btn btn-info next_button" disabled={(!this.props.allDetails.subscriber.isMSAagreed || !this.state.summary.isPnPagreed || !this.state.summary.orgNameFlag || !this.state.isSuccessAlertNotRequired)} onClick={this.saveInitiator.bind(this)}>
              {t('summary.summary_submit_button')}
            </button></Link>
          </center>
          </form>
          <div className='col-lg-1 col-md-0 col-sm-1'></div>
          </div>
          </div>
        </Jumbotron>
        <div className='col-lg-1 col-md-1'></div>
        </div>
      </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
  storeAssignProducts: (details) => dispatch(storeAssignProducts(details)),
  userListUpdated: (details) => dispatch(userListUpdated(details)),
});

const mapStateToProps = (state) => ({
  allDetails: state.crud,
  signUpDetailsSaved: state.crud.signUpDetails,
  currentLoadingPage: state.crud.currentLoadingPage,
  taxRate: state.crud.taxRate,
  cardDetails: state.crud.cardDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Summary));
