/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// import Moment from 'react-moment';
// import moment from 'moment-timezone';
// import 'moment-timezone'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import ReactHtmlParser from 'react-html-parser';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
// import { Table } from 'react-bootstrap';
import validation from '../../../assets/properties/validation.json';
import { recieveAllDetails, taxRateDetailsSignUp, taxAndPaymentUpdate } from '../../../actions/index';
import { saveSummaryDetails, removeAllSignUpDetails} from '../../../actions/index';
import Signup from './signUp';
import { store } from '../../../utils/httpUtil';
import apiUrl from '../../../utils/apiUrl';
import ErrorMessage from '../common/errorMessage';

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_info: {
        totalamount: 0
      },
      products_selected: [],
      selectedPeriod: '',
      summary: {
        signedOnName: '',
        isPnPagreed: false,
        pnpErrMsg: false,
        submitDisable: true,
        isPromoEnabled: false,
        msaErrMsg: false,
        orgNameFlag: false
      },
      msaErrorMessage: 'summary.orgNameErrMsg',
      resultMessage: '',
      isAlertNotRequired: true,
      productAmountCalculationTable: [],
      productAmountCalculation: {},
      currencyApplied: '',
      isDisableSubmit: false,
      proRatedAmount : 0,
    };
    this.setPromoFlag = this.setPromoFlag.bind(this);
    this.setPnPFlag = this.setPnPFlag.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateField = this.validateField.bind(this);
    this.monitorMSAflag = this.monitorMSAflag.bind(this);
    this.formatAmount = this.formatAmount.bind(this);
    if (this.props.signUpDetailsSaved && !this.props.signUpDetailsSaved.first_name) {
      this.props.history.push(this.props.defaultPage);
    }
  }

  getProductDetails(taxRate) {
    if (this.props.allDetails && this.props.allDetails.products && this.props.allDetails.products.length) {
      trackPromise(this.getProRatedAmount().then((proRatedValue) => {
        const { products } = this.props.allDetails;
        taxRate = taxRate || 0.00;
        let billingData = proRatedValue.totalAmtbillArray;
        const productAmountCalculation = {
          totalAmount: 0,
          totalFreeTrialAmount: 0,
          totalTax: 0,
          totalFreeTrialTax: 0,
          taxWithAmount: 0,
          taxWithFreeTrialAmount: 0,
          totalMonthlyRecurring: 0,
          totalMonthlyRecurringTax: 0,
          taxWithMonthlyRecurring: 0
        };
        for (let i = 0; i < products.length; i++) {
          for (let j = 0; j < billingData.length; j++) {
            if (products[i].tmxProductId === billingData[j].tmxProductId) {
              products[i].newAmount = billingData[j].billAmount;
              products[i].freeTrialEndsOn = billingData[j].freeTrialEndsOn.replace('-','');
            }
          }
        }
        const productAmountCalculationTable = [];
        for (let i = 0; i < products.length; i++) {
          if (products[i]) {
            const productDet = {
              no: i + 1,
              productName: products[i].keyName,
              amount: (!(products[i].freeTrialDays && products[i].freeTrialDays > 0 )) ? products[i].newAmount : 0,
              freeTrialAmount: ((products[i].freeTrialDays && products[i].freeTrialDays > 0)) ? products[i].newAmount : 0,
              freeTrialEndsOn: products[i].freeTrialEndsOn,
              monthlyRecurring: products[i].amount
            };
            productAmountCalculationTable.push(productDet);
            productAmountCalculation.totalAmount += productDet.amount;
            productAmountCalculation.totalFreeTrialAmount += productDet.freeTrialAmount;
            productAmountCalculation.totalMonthlyRecurring += productDet.monthlyRecurring;
            if (productDet.freeTrialEndsOn) {
              productAmountCalculation.freeTrialEndsOn = productDet.freeTrialEndsOn;
            }
          }
        }
        productAmountCalculation.totalTax = (productAmountCalculation.totalAmount / 100) * taxRate;
        productAmountCalculation.totalFreeTrialTax = (productAmountCalculation.totalFreeTrialAmount / 100) * taxRate;
        productAmountCalculation.totalMonthlyRecurringTax = (productAmountCalculation.totalMonthlyRecurring / 100) * taxRate;
        productAmountCalculation.taxWithAmount = productAmountCalculation.totalAmount + productAmountCalculation.totalTax;
        productAmountCalculation.taxWithFreeTrialAmount = productAmountCalculation.totalFreeTrialAmount + productAmountCalculation.totalFreeTrialTax;
        productAmountCalculation.taxWithMonthlyRecurring = productAmountCalculation.totalMonthlyRecurring + productAmountCalculation.totalMonthlyRecurringTax;
        this.setState({ productAmountCalculationTable, productAmountCalculation });
      }));
    }
  }

  formatAmount(x) {
    let value = parseFloat(x).toLocaleString('en-US' ,{
      style: 'currency',
      currency: 'USD',//currency: 'CAD'
      maximumFractionDigits: 2
    })
    return value;
  }

  getTaxRate(e) {
    if (!this.props.taxAndPaymentUpdated) {
      const postData = e.props.allDetails.billingDetails;
      const rateObj = {
        country: postData.selectedCountry,
        region: postData.selectedProvince,
        postal: postData.postalcode
      };
      trackPromise(this.getProRatedAmount().then((proRatedValue) => {    
        trackPromise(store(apiUrl.getTaxRate, { rateObj }).then((taxRes) => {
          this.props.taxAndPaymentUpdate(true);
          let taxRate = 0.00;
          let taxResponse = [];
          if(taxRes && taxRes.data && taxRes.data.data && taxRes.data.data.length > 0) {
            taxResponse = taxRes.data.data;
            for(let t=0; t<taxResponse.length; t++) {
              if(taxResponse[t]) {
                taxRate = taxRate + taxResponse[t].taxRate;
              }
            }
            taxRate = taxRate * 100;
            this.getProductDetails(taxRate);
            this.setState({taxRate: taxRate, taxRateDetails: taxResponse});
            this.props.taxRateDetails({
              taxRate: taxRate, taxRateDetails: taxResponse
            });
          } else {
            taxResponse = [];
            this.getProductDetails(taxRate);
            this.setState({taxRate: taxRate, taxRateDetails: taxResponse});
            this.props.taxRateDetails({
              taxRate: taxRate, taxRateDetails: taxResponse
            });
          }
          this.setState({proRatedAmount : proRatedValue.totalAmountWithTax});
        }).catch(() => {
          this.getProductDetails(1);
          this.setState({proRatedAmount : proRatedValue.totalAmountWithTax});
        }));
      }));
    } else {
      this.getProductDetails(this.props.taxRateData.taxRate);
      this.setState({taxRate: this.props.taxRateData.taxRate, taxRateDetails: this.props.taxRateData.taxRateDetails});
    }
  }

  calculateBillingStartDate(freeTrialDays, agreedOn) {
    let billingStartDate = agreedOn;
    if (freeTrialDays === 0) {
      const date = new Date(agreedOn);
      const addedDate = date.setDate(date.getDate() + (freeTrialDays));
      billingStartDate = new Date(addedDate);
    } else {
      const date = new Date(agreedOn);
      const addedDate = date.setDate(date.getDate() + (freeTrialDays));
      billingStartDate = new Date(addedDate);
    }
    return billingStartDate;
  }

  calculateDaysInMonth(startDate) {
    const now = new Date(startDate);
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  }

  calculateBillingEndDate(billCycle, startDate) {
    const date = new Date(startDate);
    let billingEndDate = new Date();
    if (billCycle && billCycle.toLowerCase() === 'monthly') {
      billingEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else {
      billingEndDate = new Date(date.setFullYear(date.getFullYear() + 1));
    }
    return billingEndDate;
  }

  calculateDiffDays(startDate, endDate) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  getFormattedAmount(amount) {
    let value = amount;
    if (value) {
      value = parseFloat(value.toFixed(2));
    }
    return value;
  }

  calculateBillAmount(amount, startDate, endDate, billCycle) {
    const daysInMonth = this.calculateDaysInMonth(startDate);
    const diffDays = this.calculateDiffDays(startDate, endDate);
    let billAmount = amount;
    if (billCycle && billCycle.toLowerCase() === 'monthly') {
      if (daysInMonth && diffDays && amount) {
        billAmount = (amount / daysInMonth) * diffDays;
      }
    }
    return this.getFormattedAmount(billAmount);
  }

  calculatePaymentAmountWithTax(amount, tax) {
    let taxAmount = amount;
    if (amount && tax) {
      taxAmount = (amount / 100) * tax;
      taxAmount += amount;
    }
    return this.getFormattedAmount(taxAmount);
  }

  getProRatedAmount(){
    const receivedObj = {
      products: this.props.allDetails.products,
      subscriberDetails: this.props.allDetails.subscriber,
      taxRate: this.state.taxRate
    };
    return new Promise((fulfill) => {
      trackPromise(store(apiUrl.getPayableAmount, { receivedObj }).then((res) => {
        if (res) {
          return fulfill(res.data.data);
        }
      }).catch(() => {
        window.scrollTo(0, 0);
      }));
    })
    // const agreedOn = new Date();
    // const { products } = receivedObj;
    // let totalAmountWithoutTax = 0;
    // const totalAmtbillArray = [];
    // if (products && products.length) {
    //   for (let p = 0; p < products.length; p++) {
    //     if (products[p]) {
    //         const billingStartDate = this.calculateBillingStartDate(products[p].freeTrialDays || 0, agreedOn);
    //         const billingEndDate = this.calculateBillingEndDate(products[p].billCycle || 0, billingStartDate);
    //         const billAmount = this.calculateBillAmount(products[p].amount || 0, billingStartDate, billingEndDate, products[p].billCycle);
    //         totalAmountWithoutTax += billAmount;
    //         const arrayObj = {};
    //         arrayObj.billAmount = billAmount;
    //         arrayObj.tmxProductId = products[p].tmxProductId;
    //         totalAmtbillArray.push(arrayObj);
    //     }
    //   }
    // }
    // const totalAmount = {
    //   totalAmtbillArray,
    //   totalAmountWithoutTax: this.getFormattedAmount(totalAmountWithoutTax),
    //   totalAmountWithTax: this.calculatePaymentAmountWithTax(totalAmountWithoutTax, (receivedObj.taxRate || 0)),
    // };
    // return totalAmount;
  }

  componentDidMount() {
    this.props.recieveAllDetails();
    // if (this.props.taxRateData) {
    //   this.getProductDetails(this.props.taxRateData.taxRate);
    //   this.setState({taxRate: this.props.taxRateData.taxRate, taxRateDetails: this.props.taxRateData.taxRateDetails});
    // } else {
    //   this.getTaxRate(this);
    // }
    this.getTaxRate(this);
    if (this.props.allDetails && this.props.allDetails.products
      && this.props.allDetails.products.length > 0) {
      // this.props.allDetails.products.forEach((val) => {
      //   totalAmount += parseFloat(val.amount[this.props.allDetails.selectedPeriod]);
      // });
      this.setState({
        products_selected: this.props.allDetails.products,
        selectedPeriod: this.props.allDetails.selectedPeriod,
        payment_info: {
          totalamount: this.state.productAmountCalculation.totalamount
        }
      });
    }
    const { summary } = this.state;
    if(this.props.allDetails.summaryDetails && this.props.allDetails.summaryDetails.signedOnName) {
      summary.signedOnName = this.props.allDetails.summaryDetails.signedOnName;
      summary.orgNameFlag = true;
    } else {
      if (!this.props.allDetails.signUpDetails.is_individual) {
        summary.signedOnName = this.props.allDetails.signUpDetails.client_name;
        summary.orgNameFlag = true;
      }
    }
    if(this.props.allDetails.summaryDetails && this.props.allDetails.summaryDetails.isPnPagreed) {
      // const { summary } = this.state;
      summary.isPnPagreed = this.props.allDetails.summaryDetails.isPnPagreed;
    }
    if(this.props.allDetails.summaryDetails && this.props.allDetails.summaryDetails.isPromoEnabled) {
      summary.isPromoEnabled = this.props.allDetails.summaryDetails.isPromoEnabled;
    }
    this.setState({
      summary
    });
    if(this.props.allDetails.billingDetails.selectedCountry) {
      let country = this.props.allDetails.billingDetails.selectedCountry;
      if(country === 'CA') {
        this.setState({
          currencyApplied: 'CAD'
        });
      } else {
        this.setState({
          currencyApplied: 'USD'
        });
      }
    }
  }

  monitorMSAflag(e) {
    const summaryData1 = this.state.summary;
    summaryData1.msaErrMsg = !!e.target.checked;
    this.setState({
      summary: summaryData1
    },() => {
      const  summaryData2 = this.state.summary;
      if(this.state.summary.orgNameFlag ){
        summaryData2.orgNameFlag == !e.target.checked;
        this.setState({
          summary: summaryData2
        });
      }
    });
  }

  setPromoFlag(e) {
    const { summary } = this.state;
    summary.isPromoEnabled = e.target.checked;
    this.setState({
      summary
    });
    const propsNew = this.props.saveSummaryDetails;
    propsNew.isPromoEnabled = this.state.summary.isPromoEnabled;
    this.props.saveSummaryDetails(propsNew);
  }

  setPnPFlag(e) {
    const { summary } = this.state;
    summary.isPnPagreed = e.target.checked;
    summary.pnpErrMsg = !e.target.checked;
    this.setState({
      summary
    });
    const propsNew = this.props.saveSummaryDetails;
    propsNew.isPnPagreed = this.state.summary.isPnPagreed;
    this.props.saveSummaryDetails(propsNew);
  }

  saveInitiator(submitFlag) {
    if (this.state.users && this.state.users.length) {
      const selectedUsers = this.state.users.filter((user) => user.isRequired);
      this.props.userDetails(selectedUsers);
    }
    // this.props.savesignUpDetails(propsNew);
    this.setState({isDisableSubmit: true});
    if(submitFlag) {
      this.createPackage(this);
    }
  }

  createPackage(e) {
    const postData = e.props.allDetails;
    postData.subscriber.signedOnName = this.state.summary.signedOnName;
    postData.billingDetails.enablePromotionalEmails = this.state.summary.isPromoEnabled;
    // postData.monerisDetails.currency = this.state.currencyApplied;
    const postObject = {
      signUpDetails: postData.signUpDetails,
      billingDetails: postData.billingDetails,
      monerisDetails: postData.monerisDetails,
      products: postData.products,
      users: _.map(postData.users, (object) => _.pick(object, ['first_name', 'last_name', 'email'])),
      amount: this.state.productAmountCalculation.totalamount,
      subscriberDetails: postData.subscriber,
      taxRate: postData.taxRateDetails.taxRate,
      taxRateDetails: postData.taxRateDetails.taxRateDetails
    };
    trackPromise(store(apiUrl.createPackage, { postObject }).then((res) => {
      // this.props.history.push('/confirmation');
      // this.props.removeAllSignUpDetails();
      this.props.history.push({
        pathname: '/confirmation',
        state: { clientId: res.data.data.clientId }
      });
      // sessionStorage.removeItem('jwt_token');
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false, isDisableSubmit: false });
      window.scrollTo(0, 0);
    }));
  } 

  handleChange(e) {
    this.validateField(e.target.value);
    // const summaryNew = this.state.summary;
    // summaryNew.signedOnName = e.target.value;
    // this.setState({
    //   summary: summaryNew
    // });
    // if(isValid && !this.props.allDetails.signUpDetails.is_individual) {
    //   summaryNew.orgNameFlag = true;
    //   this.setState({
    //     summary: summaryNew
    //   });
    // } else {
    //   //console.log("Validation Failed oNly if Required"); 
    //   if(!this.props.allDetails.signUpDetails.is_individual){
    //   summaryNew.orgNameFlag = false;
    //   this.setState({
    //     summary: summaryNew
    //   });
    //   } else {
    //     if(isValid) {
    //         summaryNew.orgNameFlag = true;
    //         this.setState({
    //         summary: summaryNew
    //       });
    //     } else {
    //       summaryNew.orgNameFlag = false;
    //       this.setState({
    //       summary: summaryNew
    //     });
    //     }
    //   }
    // }
    const propsNew = this.props.saveSummaryDetails;
    propsNew.signedOnName = this.state.summary.signedOnName;
    this.props.saveSummaryDetails(propsNew);
  }

  componentWillUnmount() {
    const propsNew = this.props.saveSummaryDetails;
    propsNew.signedOnName = this.state.summary.signedOnName;
    propsNew.isPnPagreed = this.state.summary.isPnPagreed;
    propsNew.isPromoEnabled = this.state.summary.isPromoEnabled;
    this.props.saveSummaryDetails(propsNew);
  }

  validateField(e) { 
    let isValid = false;
    let { msaErrorMessage, summary } = this.state;
    summary.orgNameFlag = true;
    if (e) {
      const pattern = new RegExp(validation[`signedon_regx`]);
      if (!pattern.test(e)) {
        isValid = false;
        msaErrorMessage = 'summary.validSignOnErr';
        summary.orgNameFlag = false;
      }
      else {
        isValid = true;
        summary.orgNameFlag = true;
        msaErrorMessage = 'summary.orgNameErrMsg';
      }
    }else {
      isValid = false;
      msaErrorMessage = 'summary.orgNameErrMsg';
      summary.orgNameFlag = false;
    }
    summary.signedOnName = e;
    this.setState((
      msaErrorMessage,
      summary
    ));
    return isValid;
  }

  render() {
    const { t } = this.props;
    return (
      <>
      <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
      <div className="container-fluid">
        <div className="row">
        <div className="col-lg-1 col-md-1"></div>
        <Jumbotron className="col-lg-10 col-md-10 col-sm-12 col-xs-12 jumbotron_usage_summary">
          <Link className="back-summary-button" to="/signup-payment"><h6>{t('summary.back_button')}</h6></Link>
          <div className="wrapper summary-card-main">
            <div className="container-fluid">
              <div className="row">
                <div className='col-lg-1 col-md-1'></div>
                <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 products-subscribed-summary-title">{t('summary.summary-title')}</div>
              </div>
              <div className="row summary-wrap-row">
                <div className="col-lg-1 col-md-1"></div>
                <div className="col-lg-3 summary-card-body1">
                <span className="summary-card-header">{t('summary.signup-title')}</span>
                  <div className='signup-details-grid'>
                  <Signup {...this.props} isSummary={true}/>
                  </div>
                </div>
                <div className="col-lg-10n col-md-2 summary-card-body2 scrollbar scrollbar-primary">
                  <span className="summary-card-header">{t('summary.products_summary_lbl')}</span>
                  <div className="row">
                    <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'></div>
                    <table className="summary-amount-table scrollbar scrollbar-primary">
                      <thead>
                        <tr className="amount-borderer-tr">
                        <th></th>
                        <th></th>
                        <th className="text-alignment">{t('summary.amount_due_now')}</th>
                        <th className="text-alignment">{t('summary.amount_free_trail')}</th>
                        <th className="text-alignment">{t('summary.monthly_recurring')}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.productAmountCalculationTable.map((el, index) => 
                        <tr className="amount-borderer-tr" key={index}>
                          <td>{el.no}</td>
                          <td>{el.productName}</td>
                          <td className='align-amount-value'>{el.amount ? this.formatAmount(el.amount) : `$0.00`}</td>
                          <td className='align-amount-value'>{el.freeTrialAmount ? this.formatAmount(el.freeTrialAmount) : `$0.00`}</td>
                          <td className='align-amount-value'>{el.monthlyRecurring ? this.formatAmount(el.monthlyRecurring) : `$0.00`}</td>
                        </tr>
                      )}
                      <tr className="amount-nonborderer-tr unborder-first">
                        <td></td>
                        <td>Subtotal</td>
                        <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.totalAmount ? this.formatAmount(this.state.productAmountCalculation.totalAmount) : `$0.00`}</td>
                        <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.totalFreeTrialAmount ? this.formatAmount(this.state.productAmountCalculation.totalFreeTrialAmount) : `$0.00`}</td>
                        <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.totalMonthlyRecurring ? this.formatAmount(this.state.productAmountCalculation.totalMonthlyRecurring) : `$0.00`}</td>
                      </tr>
                      <tr className="amount-nonborderer-tr">
                        <td></td>
                        <td>Tax</td>
                        <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.totalTax ? this.formatAmount(this.state.productAmountCalculation.totalTax) : `$0.00`}</td>
                        <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.totalFreeTrialTax ? this.formatAmount(this.state.productAmountCalculation.totalFreeTrialTax) : `$0.00`}</td>
                        <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.totalMonthlyRecurringTax ? this.formatAmount(this.state.productAmountCalculation.totalMonthlyRecurringTax) : `$0.00`}</td>
                      </tr>
                      <tr className="amount-nonborderer-tr">
                        <td></td>
                        <td>Total</td>
                        <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.taxWithAmount ? this.formatAmount(this.state.productAmountCalculation.taxWithAmount) : `$0.00`} </td>
                        <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.taxWithFreeTrialAmount ? this.formatAmount(this.state.productAmountCalculation.taxWithFreeTrialAmount) : `$0.00`}</td>
                        <td className='align-amount-value check-new-style'> {this.state.currencyApplied} {this.state.productAmountCalculation.taxWithMonthlyRecurring ? this.formatAmount(this.state.productAmountCalculation.taxWithMonthlyRecurring) : `$0.00`}</td>
                      </tr>
                      </tbody>
                    </table>
                    <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.productAmountCalculation.freeTrialEndsOn ?
            <div className="row">
              <div className='col-lg-1'></div>
                <div className="col-lg-10 summary-paymentinfo-card">
                  <div className="payment-info-tags">{t('summary.free_trial_end_lbl')}&nbsp;<span className="payment-info-values">{this.state.productAmountCalculation.freeTrialEndsOn ? this.state.productAmountCalculation.freeTrialEndsOn : `--`}</span></div>
                </div>
              <div className='col-lg-1'></div>
            </div>
            : <></>
          }
          <div className="row">
          <div className='col-lg-1'></div>
          <div className="col-lg-10">
          <div className="summary-payment-note">{ t('summary.payment_note')}</div>         
          </div>
          <div className='col-lg-1'></div>
          </div>
          <br></br><br></br>
          <div className="container-fluid summary-footer-comtainer">
          <div className='row'>
          <div className='col-lg-1'></div>
          <form className="col-lg-10">
          <div className="form-check left_labels_alignment checkbox_summary_alignment">
            <input
              type="checkbox"
              className="form-check-input"
              id="isMSAagreed"
              name="isMSAagreed"
              onChange={this.monitorMSAflag}
              checked={this.props.allDetails.subscriber.isMSAagreed || false}
            />
            <label className="summary-check-labels" htmlFor="isMSAagreed">
            {t('summary.msa_statment1')}
            <Link className='btn summary-links' to="/signup-subscriber" onClick={this.saveInitiator.bind(this, false)}>{t('summary.subscriber_link')}</Link>
            {t('summary.msa_statment2')}
            <input
              type="text"
              className='form-control subscriber_input'
              id="subscriber_input"
              name="subscriber_input"
              value={this.state.summary.signedOnName}
              autoComplete="__"
              placeholder={t('summary.org_name')}
              onChange={this.handleChange}
              maxLength={100}
            />
            <div className="text-danger-alert-small">{}</div>
            </label>
            <div className="text-danger-alert">{this.state.summary.msaErrMsg
              ? t('summary.msaErrMsg') : !this.state.summary.msaErrMsg && !this.state.summary.orgNameFlag
              ? t(`${this.state.msaErrorMessage}`) : ''}</div>
          </div>
          <div className="form-check left_labels_alignment checkbox_summary_alignment">
            <input
              type="checkbox"
              className="form-check-input"
              id="isPnPagreed"
              name="isPnPagreed"
              checked={this.state.summary.isPnPagreed}
              onChange={this.setPnPFlag}
            />
            <label className="summary-check-labels" htmlFor="isMSAagreed">
            {t('summary.tc_statment')}<a className='btn summary-links' rel="noreferrer" target="_blank" href={t('summary.termsC_url')}>{t('summary.termsC_link')}</a>{t('summary.tc_and_statment')}<a rel="noreferrer" className='btn summary-links' target="_blank" href={t('summary.privacyP_url')}>{t('summary.privacyP_link')}</a></label>
            <div className="text-danger-alert">{this.state.summary.pnpErrMsg
              ? t('summary.pnpErrMsg') : ''}</div>
          </div>
          <div className="form-check left_labels_alignment checkbox_summary_alignment">
            <input
              type="checkbox"
              className="form-check-input"
              id="isPromoEnabled"
              checked={this.state.summary.isPromoEnabled}
              onChange={this.setPromoFlag}
              name="isPromoEnabled"
            />
            <label className="summary-check-labels" htmlFor="isPromoEnabled">
            {ReactHtmlParser(t('summary.advt_statment'))}</label>
          </div>
          <center>
          <Link><button className="btn btn-info next_button align-submit" disabled={(!this.props.allDetails.subscriber.isMSAagreed || !this.state.summary.isPnPagreed || !this.state.summary.orgNameFlag || this.state.isDisableSubmit)} onClick={this.saveInitiator.bind(this, true)}>
              {t('summary.summary_submit_button')}
            </button></Link>
          </center>
          </form>
          <div className='col-lg-1 col-md-0 col-sm-1'></div>
          </div>
          </div>
        </Jumbotron>
        <div className='col-lg-1 col-md-1'></div>
        </div>
      </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  recieveAllDetails: () => dispatch(recieveAllDetails()),
  saveSummaryDetails : (details) => dispatch(saveSummaryDetails(details)),
  taxRateDetails: (details) => dispatch(taxRateDetailsSignUp(details)),
  removeAllSignUpDetails: () => dispatch(removeAllSignUpDetails()),
  taxAndPaymentUpdate : (details) => dispatch(taxAndPaymentUpdate(details)),
});

const mapStateToProps = (state) => ({
  allDetails: state.crud,
  signUpDetailsSaved: state.crud.signUpDetails,
  saveSummaryDetails: state.crud.saveSummaryDetails,
  defaultPage: state.crud.defaultPage,
  taxRateData: state.crud.taxRateDetails,
  taxAndPaymentUpdated: state.crud.taxAndPaymentUpdated,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Summary));
