/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { trackPromise } from 'react-promise-tracker';
import { connect } from 'react-redux';
import { getAdminConsoleDetails, storeCurrentPage, getAdminSummary, changeClientId, updateClientDetails, adminSummaryDetails, adminPageCount, getConfiguredProducts, getCurrentBillingDetails, updateNewclientdetails, getCurrentAdminDetails } from '../../../actions/index';
import apiUrl from '../../../utils/apiUrl';
import { store } from '../../../utils/httpUtil';
import ErrorMessage from '../common/errorMessage';

class adminSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allUsersList: [],
            pendingVerificationList: [],
            pendingVerificationFullList: [],
            allUsersFullList: [],
            resultMessage: '',
            isAlertNotRequired: true,
            offset: 0,
            perPage: 10,
            currentPage: 0,
            enableBilling: 1,
            searchValue: null,
            pendingSearch: null,
            verifiedSearch: null,
            isChangePage: false,
            pageCount: false,
        };
        this.props.adminSummaryDetails({
            pendingVerificationList: [],
            allUsersList: []
        });
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangePending = this.handleChangePending.bind(this);
        this.handleChangeVerified = this.handleChangeVerified.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
      const { currentPage, verifiedSearch, pendingSearch, enableBilling } = this.state;
      const request = {
        currentPage,
        searchValue: enableBilling === 1 ? pendingSearch : verifiedSearch
      };
      this.props.getAdminSummary(request);
      this.props.getConfiguredProducts([]);
      this.props.getCurrentAdminDetails({
          subscribedProducts: [],
          usersList: [],
          listOfAllUsers: [],
      });
      this.props.getCurrentBillingDetails([]);
        
    }

    async onClientClick(user) {
        const access_token = this.props.currentUserDetails.access_token;
        await trackPromise(store(apiUrl.getClientDetails, { clientId: user.clientId, token: access_token }).then((res) => {
            if (res && res.data && res.data.data) {
                this.props.updateClientDetails(res.data.data);
                this.props.changeClientId(user.clientId);
                this.props.updateNewclientdetails(user);
                return this.props.history.push({
                    pathname: '/adminConsole',
                    state: { clientSelected: user }
                });
            }
        }).catch((err) => {
            this.setState({ resultMessage: err.message, isAlertNotRequired: false });
            window.scrollTo(0, 0);
        }));
    }

    onClientMouseOver(user, i, isPending) {
        const access_token = this.props.currentUserDetails.access_token;
        if(!user.products) {
            trackPromise(store(apiUrl.getClientProductDetails, {clientId: user.clientId, token: access_token}).then((res) => {
                if (res && res.data && res.data.data) {
                    const { allUsersList, pendingVerificationList } = this.state;
                    if (isPending) {
                        pendingVerificationList[i].products = res.data.data;
                        this.setState({
                            pendingVerificationList
                        });
                    } else {
                        allUsersList[i].products = res.data.data;
                        this.setState({
                            allUsersList
                        });
                    }
                }
            }).catch((err) => {
                this.setState({ resultMessage: err.message, isAlertNotRequired: false });
                window.scrollTo(0, 0);
            }));
        }
    }

    updateClient(user) {
        const request = {
            token: this.props.currentUserDetails.access_token,
            email: this.props.currentUserDetails.email,
            status: user.status,
            clientId: user.clientId,
        }
        this.props.adminSummaryDetails({
            pendingVerificationList: [],
            allUsersList: []
        });
        trackPromise(store(apiUrl.updateClient, request).then((res) => {
            if (res && res.data) {
                this.setState({
                    allUsersList: [],
                    pendingVerificationList: [],
                    allUsersFullList: [],
                    pendingVerificationFullList: [],
                }, () => {
                  const { currentPage, verifiedSearch, pendingSearch, enableBilling } = this.state;
                  const request = {
                    currentPage,
                    searchValue: enableBilling === 1 ? pendingSearch : verifiedSearch
                  };
                  this.props.getAdminSummary(request);
                });
            }
        }).catch((err) => {
            this.setState({ resultMessage: err.message, isAlertNotRequired: false });
            window.scrollTo(0, 0);
        }));
    }

    setSummaryDetails() {
        if ((this.props.allUsersList && this.props.allUsersList.length > 0) || (this.props.pendingVerificationList && this.props.pendingVerificationList.length > 0)) {
            let allUsersList = this.props.allUsersList;
            let pendingVerificationList = this.props.pendingVerificationList;
            allUsersList.sort(function(a, b){
                if(a.clientId < b.clientId) { return -1; }
                if(a.clientId > b.clientId) { return 1; }
                return 0;
            })
            pendingVerificationList.sort(function(a, b){
                if(a.clientId < b.clientId) { return -1; }
                if(a.clientId > b.clientId) { return 1; }
                return 0;
            })
            allUsersList = allUsersList.map((v) => {
                const a = v;
                a.isDisabled = true;
                return a;
            });
            pendingVerificationList = pendingVerificationList.map((v) => {
                const a = v;
                a.isDisabled = true;
                return a;
            });
            this.setState({
                // allUsersList,
                allUsersFullList: allUsersList,
                // pendingVerificationList,
                pendingVerificationFullList: pendingVerificationList,
                isChangePage: false,
                pageCount: false,
            }, () => {
              this.changePage(1);
            });
        }
    }

    componentDidUpdate() {
        if (this.props.currentLoadingPage) {
            this.props.history.push(this.props.currentLoadingPage);
            this.props.storeCurrentPage('');
        }
    }

    handleInputChange(e, i, isPending) {
        const { allUsersList, pendingVerificationList } = this.state;
        if (!isPending) {
            allUsersList[i].isDisabled = false;
            allUsersList[i].status = e.target.value;
            this.setState({
                allUsersList
            });
        } else {
            pendingVerificationList[i].isDisabled = false;
            pendingVerificationList[i].status = e.target.value;
            this.setState({
                pendingVerificationList
            });
        }
    }

    handlePageClick = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * this.state.perPage;
      this.setState({
          currentPage: selectedPage+1,
          offset: offset,
          isChangePage: false,
          // pageCount: false,
      }, () => {
        this.changePage(selectedPage+1);
      });
    };

    changePage = (page) => {
      // Validate page
      if (page < 1) page = 1;
      if (page > this.numPages()) page = this.numPages();
      const workingObj = this.state.enableBilling === 1 ? this.state.pendingVerificationFullList : this.state.allUsersFullList;
      const returnObj = [];
      for (var i = (page-1) * 10; i < (page * 10); i++) {
        if (workingObj[i]) {
          returnObj.push(workingObj[i]);
        }
      }
      if (this.state.enableBilling === 1) {
        this.setState({pendingVerificationList: returnObj, isChangePage: true, pageCount: true});
      } else {
        this.setState({allUsersList: returnObj, isChangePage: true, pageCount: true});
      }
    }

    numPages = () => {
      return Math.ceil(this.state.enableBilling === 1 ? this.state.pendingVerificationFullList.length/10 : this.state.allUsersFullList.length/10);
    }

    // handleSearch = () => {
    //   // const { currentPage, verifiedSearch, pendingSearch, enableBilling } = this.state;
    //   // const request = {
    //   //   currentPage,
    //   //   searchValue: enableBilling === 1 ? pendingSearch : verifiedSearch
    //   // };
    //   // this.props.getAdminSummary(request);
    //   this.myFunction();
    // };

    handleSearch = () => {
      let input, filter, i, txtValue;
      const { verifiedSearch, pendingSearch, enableBilling } = this.state;
      input = enableBilling === 1 ? pendingSearch : verifiedSearch;
      filter = input.toUpperCase();
      const filterList = enableBilling === 1 ? this.props.pendingVerificationList : this.props.allUsersList;
      const returnObj = [];
      if (filter) {
        for (i = 0; i < filterList.length; i++) {
          txtValue = filterList[i];
          if (txtValue.orgName && txtValue.orgName.toUpperCase().indexOf(filter) > -1) {
            returnObj.push(filterList[i]);
          } else if (txtValue.teamName && txtValue.teamName.toUpperCase().indexOf(filter) > -1) {
            returnObj.push(filterList[i]);
          }
        }
        if (this.state.enableBilling === 1) {
          this.setState({pendingVerificationFullList: returnObj, isChangePage: false, pageCount: false,}, () => {
            this.changePage(1);
          });
        } else {
          this.setState({allUsersFullList: returnObj, isChangePage: false, pageCount: false,}, () => {
            this.changePage(1);
          });
        }
      } else {
        this.setSummaryDetails();
      }
    }

    handleChangeVerified(e) {
      this.setState({
        verifiedSearch: e.target.value
      }, () => {
        this.handleSearch();
      });
    }

    handleChangePending(e) {
      this.setState({
        pendingSearch: e.target.value
      }, () => {
        this.handleSearch();
      });
    }

    handleSwitch = (value) => {
      const obj = this.state;
      obj.enableBilling = value;
      this.setState({
        obj,
        pendingSearch: '',
        verifiedSearch: '',
        isChangePage: false,
        pageCount: false,
      }, () => {
        // this.changePage(1);
        this.setSummaryDetails();
      });
    };

    render() {
        (this.state.allUsersFullList.length === 0 && this.state.pendingVerificationFullList.length === 0) ? this.setSummaryDetails() : '';
        const { allUsersList, pendingVerificationList } = this.state;
        const { enableBilling } = this.state;
        const { t } = this.props;
        return (
          <>
            <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                <Jumbotron className="col-lg-10 col-md-10 col-sm-10 col-xs-10 jumbotron_usage_summary">
                  <div className="row payment_header_panel">
                    <div className="payment_heading1">{t('adminSummary.main_heading')}</div>
                  </div>
                  <div className="row payment-header-toggle">
                    <ToggleButtonGroup
                        type="radio"
                        className="mb-2"
                        name="options"
                        defaultValue={1}
                        onChange={this.handleSwitch}
                        >
                        <ToggleButton size="lg" className={(enableBilling === 1) ? 'toggle_active' : ''} value={1}><span className="badge badge-light"> {this.state.pendingVerificationFullList.length} </span>{t('adminSummary.pendingVList')}</ToggleButton>
                        <ToggleButton size="lg" className={(enableBilling === 2) ? 'toggle_active' : ''} value={2}><span className="badge badge-light"> {this.state.allUsersFullList.length} </span>{t('adminSummary.verifiedList')}</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  {
                  (enableBilling === 1)
                  ? 
                  <>
                  <div>
                    <span className='sub_heading_admin_summary'>{t('adminSummary.pendingVList')}</span>
                    <input type="text" placeholder="Search" className="admin_summary_search" value={this.state.pendingSearch} onChange={this.handleChangePending} />
                  </div>
                  <div>
                  <Jumbotron className="col-lg-12 col-md-12 col-sm-12 col-xs-12 jumbotron_pending_panel">
                    <div className="col-lg-12 col-md-12 col-sm-12 sol-xs-12">
                    <div className="payment-table-style">
                    { <table className="payment-details-table">
                        <thead>
                          <tr>
                            <th>{t('adminSummary.table1_label1')}</th>
                            <th>{t('adminSummary.table1_label2')}</th>
                            <th>{t('adminSummary.table1_label3')}</th>
                            <th>{t('adminSummary.table1_label4')}</th>
                            <th>{t('adminSummary.table1_label5')}</th>
                            <th></th>
                          </tr>
                        </thead>
                      <tbody>
                      {pendingVerificationList.map((el, i) => (
                        <tr key={i}>
                          <td>
                          <select
                            className="users_status_dd"
                            name="user_status"
                            defaultValue={pendingVerificationList[i].status}
                            onChange={(e) => this.handleInputChange(e, i, true)}
                          >
                            {/* <option className="select-option-payment" value="default">{pendingVerificationList[i].status}</option> */}
                            {this.props.pendingProductStatus.map((product, j) => <option key={j} className="select-option-payment" value={product.value} selected={product.value === pendingVerificationList[i].status}>{product.label}</option>)}
                            </select>
                          </td>
                          <td onClick={() => this.onClientClick(pendingVerificationList[i])}>{pendingVerificationList[i].orgName}</td>
                          <td onClick={() => this.onClientClick(pendingVerificationList[i])}>{pendingVerificationList[i].teamName}</td>
                          <td className='hover_panel_main' onClick={() => this.onClientClick(pendingVerificationList[i])}><span className='CellWithComment'><a onMouseOver={() => this.onClientMouseOver(pendingVerificationList[i], i, true)}>{pendingVerificationList[i].noOfProducts}</a>
                          {  pendingVerificationList[i].products &&  pendingVerificationList[i].products.length > 0 ?
                            <span className="CellComment">
                              <h5 style={{ color: '#16fffb' }}>{t('adminSummary.product_list_label')}</h5>
                              {
                                pendingVerificationList[i].products && pendingVerificationList[i].products.sort((a,b) => (Number(a.productWeightage) < Number(b.productWeightage)) ? 1 : ((Number(b.productWeightage) < Number(a.productWeightage)) ? -1 : 0)).map((product, j) => <div key={j}><li className='align-list-text'>{product.productName}</li></div>)
                              }
                            </span>
                          :
                            <span className="CellComment">
                              {t('adminSummary.loading')}
                            </span>
                          }
                          </span>
                          </td>
                          <td onClick={() => this.onClientClick(pendingVerificationList[i])}>{pendingVerificationList[i].noOfUsers}</td>
                          <td><button className="btn admin-save-btn" onClick={() => this.updateClient(pendingVerificationList[i], i)} disabled={pendingVerificationList[i].isDisabled}>{t('adminSummary.save')}</button></td>
                        </tr>
                      ))}
                      </tbody>
                  </table>
                  }
                  </div>
                  </div>
                  </Jumbotron>
                  </div>
                  </>
                  : 
                  <>
                  <div>
                    <span className='sub_heading_admin_summary'>{t('adminSummary.verifiedList')}</span>
                    <input type="text" placeholder="Search" className="admin_summary_search" value={this.state.verifiedSearch} onChange={this.handleChangeVerified} />
                  </div>
                  <Jumbotron className="col-lg-12 col-md-12 col-sm-12 col-xs-12 jumbotron_pending_panel">
                    <div className="col-lg-12 col-md-12 col-sm-12 sol-xs-12">
                    <div className="payment-table-style">
                    { <table className="payment-details-table">
                    <thead>
                      <tr>
                        <th>{t('adminSummary.table2_label1')}</th>
                        <th>{t('adminSummary.table2_label2')}</th>
                        <th>{t('adminSummary.table2_label3')}</th>
                        <th>{t('adminSummary.table2_label4')}</th>
                        <th>{t('adminSummary.table2_label5')}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                    {allUsersList.map((el, i) => (
                      <tr key={i} >
                        {allUsersList[i].status == 'inactive' ? <td>{allUsersList[i].status}</td> : <td>
                          <select
                        className="users_status_dd"
                        name="user_status"
                        defaultValue={allUsersList[i].status}
                        onChange={(e) => this.handleInputChange(e, i, false)}
                      >
                      <option className="select-option-payment">{allUsersList[i].status}</option>
                      {this.props.verfiedProductStatus.map((product, j) => <option key={j} className="select-option-payment" value={product.value} selected={product.value === allUsersList[i].status}>{product.label}</option>)}
                      </select></td> }
                        <td onClick={() => this.onClientClick(allUsersList[i])}>{allUsersList[i].orgName}</td>
                        <td onClick={() => this.onClientClick(allUsersList[i])}>{allUsersList[i].teamName}</td>
                        <td className='hover_panel_main' onClick={() => this.onClientClick(allUsersList[i])} ><span className='CellWithComment'><a onMouseOver={() => this.onClientMouseOver(allUsersList[i], i, false)}>{allUsersList[i].noOfProducts}</a>
                        {  allUsersList[i].products &&  allUsersList[i].products.length > 0 ?
                          <span className="CellComment">
                            <h5 style={{ color: '#16fffb' }}>{t('adminSummary.product_list_label')}</h5>
                            {
                              allUsersList[i].products && allUsersList[i].products.sort((a,b) => (Number(a.productWeightage) < Number(b.productWeightage)) ? 1 : ((Number(b.productWeightage) < Number(a.productWeightage)) ? -1 : 0)).map((product, j) => <div key={j}><li className='align-list-text'>{product.productName}</li></div>)
                            }
                          </span>
                        : 
                        <span className="CellComment">
                          {t('adminSummary.loading')}
                        </span>
                        }
                        </span>
                        </td>
                        <td onClick={() => this.onClientClick(allUsersList[i])}>{allUsersList[i].noOfUsers}</td>
                        <td><button className="btn admin-save-btn" onClick={() => this.updateClient(allUsersList[i], i)} disabled={allUsersList[i].isDisabled}>{t('adminSummary.save')}</button></td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                  }
                  </div>
                    </div>
                  </Jumbotron>
                  </>
                  }
                  {(this.state.pageCount && (this.state.allUsersList.length !== 0 || this.state.pendingVerificationList.length !== 0)) ?
                  <div className="user-pagination">
                    <ReactPaginate
                        previousLabel={"PREV"}
                        nextLabel={"NEXT"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.enableBilling === 1 ? this.state.pendingVerificationFullList.length/10 : this.state.allUsersFullList.length/10}
                        marginPagesDisplayed={5}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}/>
                  </div>
                  : '' }
                </Jumbotron>
                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
              </div>
            </div>
          </>
        );
      }
    }

const mapStateToProps = (state) => ({
    allUsersList: state.crud.allUsersList,
    pendingVerificationList: state.crud.pendingVerificationList,
    // productStatus: state.crud.productStatus,
    pendingProductStatus: state.crud.pendingProductStatus,
    verfiedProductStatus: state.crud.verfiedProductStatus,
    currentLoadingPage: state.crud.currentLoadingPage,
    currentUserDetails: state.crud.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    getAdminConsoleDetails: (details) => dispatch(getAdminConsoleDetails(details)),
    storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
    getAdminSummary: (details) => dispatch(getAdminSummary(details)),
    changeClientId: (details) => dispatch(changeClientId(details)),
    updateClientDetails: (details) => dispatch(updateClientDetails(details)),
    updateNewclientdetails: (details) => dispatch(updateNewclientdetails(details)),
    adminSummaryDetails: (details) => dispatch(adminSummaryDetails(details)),
    adminPageCount: (details) => dispatch(adminPageCount(details)),
    getConfiguredProducts: (details) => dispatch(getConfiguredProducts(details)),
    getCurrentBillingDetails: (details) => dispatch(getCurrentBillingDetails(details)),
    getCurrentAdminDetails: (details) => dispatch(getCurrentAdminDetails(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(adminSummary));
