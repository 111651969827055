/* eslint-disable no-undef */
/**
 * Store string record in the storage
 *
 * @param {string} key
 * @param {string | array | object} value
 */
export const setLocalStorage = (key, value) => {
  if (value && typeof (value) === 'string') {
    sessionStorage.setItem(key, value);
  } else {
    sessionStorage.setItem(key, JSON.stringify(value)); // convert arrays or objects into strings
  }
};

export const setSessionStorage = (key, value) => {
  if (value && typeof (value) === 'string') {
    sessionStorage.setItem(key, value);
  } else {
    sessionStorage.setItem(key, JSON.stringify(value)); // convert arrays or objects into strings
  }
};

/**
 * Retrieve record from the storage using the key
 *
 * @param {string} key
 */
export const getLocalStorage = (key) => {
  const data = sessionStorage.getItem(key);
  try {
    return JSON.parse(data); // converts a JSON string into a Javascript Object
  } catch (e) {
    return data;
  }
};

export const getSessionStorage = (key) => {
  const data = sessionStorage.getItem(key);
  try {
    return JSON.parse(data); // converts a JSON string into a Javascript Object
  } catch (e) {
    return data;
  }
};

/**
 * Clear records from the storage using the key
 *
 * @param {string} key
 */
export const clearLocalStorage = (key) => sessionStorage.removeItem(key);
