/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import moment from 'moment-timezone';
import { trackPromise } from 'react-promise-tracker';
import { store } from '../../../utils/httpUtil';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { getCurrentUserDetails } from '../../../actions/index';
import apiUrl from '../../../utils/apiUrl';

class Graph extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null, selectedProducts: '', startDate: '', endDate: '' };
    this.convert = this.convert.bind(this);
  }

  //Checks to see whether Graph should update
  componentDidMount() {
    const { selectedProducts } = this.props;
    this.setState({
      selectedProducts
    }, () => {
      this.getProductInformationsForChart();
    });
  }

  formatDate(date){
    return moment(date).format('YYYY-MM-DD')
  }

  isDateModified(startDate, endDate){
    if((this.formatDate(startDate) != this.formatDate(this.state.startDate)) || (this.formatDate(endDate) != this.formatDate(this.state.endDate))){
      return true;
    }else return false;
  }

  componentDidUpdate() {
    const { selectedProducts, startDate, endDate } = this.props;
    if ((this.state.selectedProducts && selectedProducts && selectedProducts !== this.state.selectedProducts) || this.isDateModified(startDate, endDate)) {
      this.setState({
        selectedProducts
      }, () => {
        this.getProductInformationsForChart();
      });
    }
  }

  async getProductInformationsForChart() {
    const request = {
      startDate: this.formatDate(this.props.startDate),
      endDate: this.formatDate(this.props.endDate),
      groupName: this.state.selectedProducts,
      token: this.props.currentUserDetails.access_token,
      clientId: this.props.currentUserDetails.clientId,
    };
    this.setState({
      startDate: this.props.startDate,
      endDate: this.props.endDate
    })
    trackPromise(store(apiUrl.getProductInformationsForChart, request).then((res) => {
      if (res && res.data && res.data.data) {
        this.convert(res.data.data);
      }
    }));
  }

  // Converts API response into a format for the Graph, and updates state.
  convert(requests) {
    const dataArr = [];
    let d = moment().tz('America/New_York');
    d.subtract(requests.length - 1, 'days');
    for (let i = 0; i < requests.length; i++) {
      dataArr.push({
        name: moment(d).format('MM/DD/YYYY'),
        amt: requests[i],
      });
      d = moment(d).add(1, 'days');
    }
    this.setState({ data: dataArr });
    const requestMade = requests.reduce((a, b) => a + b, 0);
    this.props.requestMade(requestMade, dataArr);
  }

  // Renders graph. Please check recharts docs for more info.
  render() {
    const { data } = this.state;
    return (
      <div className="GraphContainer">
        <ResponsiveContainer width="99%" height={600}>
          <AreaChart
            width={600}
            height={600}
            data={data}
            margin={{
              top: 10, right: 30, left: 0, bottom: 30,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0" stopColor="#16fffb" stopOpacity={0.8} />
                <stop offset="1" stopColor="#0c1e67" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" angle={-45} dy={20} tick={{ fontSize: 12 }} />
            <YAxis />
            <Tooltip />
            <Area strokeWidth={3.5} type="monotone" dataKey="amt" stroke="#16fffb" fill="#D1E6Fe" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCurrentUserDetails: (details) => dispatch(getCurrentUserDetails(details)),
});

const mapStateToProps = (state) => ({
  currentUserDetails: state.crud.currentUser,
  selectedProduct: state.crud.selectedProductDetail,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Graph));
