/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import Jumbotron from 'react-bootstrap/Jumbotron';
import validation from '../../../assets/properties/validation.json';
import { faBorderNone, faPen, faPlusCircle, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubscribedProducts from '../common/subscribedProducts';
import { adminDetails, getCurrentAdminDetails, paymentDetails, userDetails, updateTeamClientName, productsDetails, storeAssignProducts } from '../../../actions/index';
import apiUrl from '../../../utils/apiUrl';
import { store, update } from '../../../utils/httpUtil';
import { storeCurrentPage } from '../../../actions/index';
import Card from 'react-bootstrap/Card';
import SuccessMessage from '../common/successMessage';

class Users extends Component {
  state = {};

  t = this.props.t;

  constructor(props) {
    super(props);
    this.state = {
      isDetailsValid: true,
      isAlertNotRequired: true,
      usersList: [],
      listUsersByProduct: [],
      listUsersByProductUpdated: false,
      allProductsWithClientSubsId: [],
      isAllUsersSelected: false,
      disableFunctions: true,
      isDisableUpdated: false,
      editValuesUpdated: false,
      emailValidated: false,
      teamValidated: false,
      teamMessage: '',
      resultMessage: '',
      isSuccessAlertNotRequired: true,
      isOnlyOneAdmin: false,
      editingUser: {},
      isOnlyOneAdminErrorMessage: true,
      oldProductsValue: {},
      allUsersList: []
    };
    this.openNav = this.editUser.bind(this);
    this.createUser = this.createUser.bind(this);
    this.getAllUsers = this.getAllUsers.bind(this);
    this.closeNav = this.closeEditUser.bind(this);
    this.editApplicableProducts = this.editApplicableProducts.bind(this);
    this.neutralizingList = this.neutralizingList.bind(this);
    this.checkRedundantEmail = this.checkRedundantEmail.bind(this);
    this.fieldValidation = this.fieldValidation.bind(this);
    this.updateProducts = this.updateProducts.bind(this);
    this.unSubscribeProducts = this.unSubscribeProducts.bind(this);
    // this.disableButton = this.disableButton.bind(this);
    // this.unableClick = this.unableClick.bind(this);
    this.props.productsDetails({
      products: [],
      selectedPeriod: '',
      selectedCategory: '',
      productNames: []
    });
    this.props.storeAssignProducts([]);
  }
 
  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
      this.props.history.push(this.props.currentLoadingPage);
      this.props.storeCurrentPage('');
    }
    if(!this.state.isDisableUpdated && this.props.currentSubscribedProducts && this.props.currentSubscribedProducts.length > 0 && this.props.currentUserDetails.roleType === 'Client Admin') {
      const activeClients = this.props.currentSubscribedProducts.filter((products) => products.clientSubsStatus === 'active');
      let disableFunctions;
      if (activeClients && activeClients.length > 0) {
        disableFunctions = true;
      } else {
        disableFunctions = false;
        document.getElementById('addUser').style.pointerEvents = 'none';
        document.getElementById('addProducts').style.pointerEvents = 'none';
      }
      this.setState({
        disableFunctions,
        isDisableUpdated: true,
      });
    }
  }

  componentDidMount() {
    if(this.props.currentUserDetails.roleType === "TMX Admin" || this.props.currentUserDetails.roleType === "TMX BA Admin") {
      if(this.props.updateNewClientDetails && this.props.updateNewClientDetails.status && this.props.updateNewClientDetails.status != 'active') {
        document.getElementById('addUser').style.pointerEvents = 'none';
        document.getElementById('addUser').style.opacity = '0.5';
        document.getElementById('addProducts').style.pointerEvents = 'none';
        document.getElementById('addProducts').style.opacity = '0.5';
      }
    }
    if (!this.props.usersListUpdated && this.props.currentUserDetails.userId && this.props.currentUserDetails.clientId) {
      // let details = {
      //   userId: this.props.currentUserDetails.userId,
      //   clientId: this.props.currentUserDetails.clientId
      // }
      // this.props.getadminDetails(details);
      this.props.getCurrentAdminDetails({
        subscribedProducts: [],
        usersList: [],
        listOfAllUsers: [],
      });
      this.newAPICall();
      let details = {
        userId: this.props.currentUserDetails.userId,
        clientId: this.props.currentUserDetails.clientId
      }
      this.props.getadminDetails(details);
    }
    const token = this.props.currentUserDetails.access_token;
    this.props.getPaymentDetails({clientId: this.props.currentUserDetails.clientId, token});
  }

  async checkRedundantEmail(e) {
    if (!this.state.isEditUser && (this.state.resultMessage === '')) {
      this.setState({
        isDetailsValid: true
      })
      let resultMessage; let isAlertNotRequired; let isRedAlertNotRequired; let
        isDetailsValid; let emailValidated;
      const access_token = this.props.currentUserDetails.access_token;
      if(e.target.value){
        await trackPromise(store(apiUrl.checknewUserEmail, { email: e.target.value, token: access_token }).then((userEmail) => {
          if (userEmail.data && userEmail.data.data) {
            resultMessage = 'This Email Address Already Exists';
            isAlertNotRequired = true;
            isRedAlertNotRequired = false;
            isDetailsValid = true;
            emailValidated = false;
          } else {
            isAlertNotRequired = true;
            isRedAlertNotRequired = true;
            isDetailsValid = false;
            emailValidated = true;
          }
          this.setState({
            resultMessage, isAlertNotRequired, isRedAlertNotRequired, isDetailsValid, emailValidated
          });
          // this.fieldValidation();
        }).catch((err) => {
          resultMessage = `Error Message: ${err}`;
          isAlertNotRequired = false;
          isRedAlertNotRequired = true;
          isDetailsValid = true;
          emailValidated = false;
          this.setState({
            resultMessage, isAlertNotRequired, isRedAlertNotRequired, isDetailsValid, emailValidated
          });
          window.scrollTo(0, 0);
        }));
      }
    }
  }

  async checkRedundantTeam(e) {
    const access_token = this.props.currentUserDetails.access_token;
    const organization = document.getElementById('organization').value;
    const team = document.getElementById('team').value;
    let teamMessage; let isTeamRedAlertNotRequired; let teamValidated;
    if (team && organization && (team !== this.props.currentUserDetails.team || organization !== this.props.currentUserDetails.organization)) {
      this.setState({
        isDetailsValid: true
      });
      trackPromise(store(apiUrl.checkOrganizationAndTeamForAdmin, { organization, team, token: access_token }).then((team) => {
        //Already there false
        if (team.data.data) {
          teamMessage = 'This organization already has a team with the same name subscribed to TMX Analytics. Please enter a different team name.';
          isTeamRedAlertNotRequired = false;
          teamValidated = false;
        } else {
          teamMessage = '';
          isTeamRedAlertNotRequired = true;
          teamValidated = true;
        }
        this.setState({
          teamMessage, isTeamRedAlertNotRequired, teamValidated, isDetailsValid: false
        });
      }).catch((err) => {
        teamMessage = `Error Message: ${err}`;
        isTeamRedAlertNotRequired = false;
        teamValidated = false;
        this.setState({
          teamMessage, isTeamRedAlertNotRequired, teamValidated, isDetailsValid: false
        });
        window.scrollTo(0, 0);
      }));
    }
  }

  updateProducts() {
    if (this.props.currentSubscribedProducts) {
      const products = this.props.currentSubscribedProducts;
      let updatedProductsValue = [];
      let userDetails = [];
      let adminUserList = [];
      this.state.usersList.forEach((user) => {
        if(user.isAdmin) {
          const currentUser = {
            firstName: user.firstName,
            email: user.email
          }
          adminUserList.push(currentUser);
        }
      });
      products.map((value, i) => {
        const productValue = document.getElementById(value.productName).checked;
        const currentUserSubsId = document.getElementById(value.productName+'_user_subs_id').value;
        let currentClientSubsId = document.getElementById(value.productName+'_clientSubsId').value;
        currentClientSubsId = currentClientSubsId ? currentClientSubsId : 0;
        updatedProductsValue.push(
          {
            subscriptionId: value.subscriptionId,
            status: productValue,
            userSubsId: Number(currentUserSubsId),
            productName: value.productName,
            clientSubsId: Number(currentClientSubsId),
            subCategory: value.productCategory,
            productUrl: value.productUrl,
          }
        );
        return null;
      });
      this.state.listUsersByProduct.forEach((user) => {
        if(user.userId === this.state.selectedUserId) {
          const currentUser = {
            isAdmin: user.isAdmin,
            firstName: user.firstName,
            email: user.email
          }
          userDetails.push(currentUser);
        }
      });

      // const alreadySubscribed = [];
      // let oldProductsValue = [];
      // for (let i = 0; i < userDetails.length; i++) {
      //   alreadySubscribed.push(userDetails[i].selectedProduct);
      //   alreadySubscribed.forEach((element) => {
      //     element.forEach((smallerElement) => {
      //       this.props.currentSubscribedProducts.sort((a,b) => (Number(a.productWeightage) < Number(b.productWeightage)) ? 1 : ((Number(b.productWeightage) < Number(a.productWeightage)) ? -1 : 0)).map((value) => {
      //         if (smallerElement.subscriptionId === value.subscriptionId) {
      //           const retainedValue = document.getElementById(value.productName).checked;
      //           oldProductsValue.push(
      //             {
      //               subscriptionId: value.subscriptionId,
      //               status: retainedValue,
      //               productName: value.productName,
      //               productCategory: value.productCategory,
      //               subCategory: value.productCategory,
      //             }
      //           )
      //         }
      //       });
      //     });
      //   });
      // }
      let oldProductsValue = this.state.oldProductsValue;
      const request = {
        updatedProductsValue,
        userId: this.state.selectedUserId,
        clientId: this.props.currentUserDetails.clientId,
        token: this.props.currentUserDetails.access_token,
        oldProductsValue,
        clientUserDetails: userDetails,
        adminUserList,
      };
      this.updateProductsAPICall(request);
    }
  }

  async updateProductsAPICall(request) {
    this.closeEditUser();
    this.props.getCurrentAdminDetails({
      subscribedProducts: [],
      usersList: [],
      listOfAllUsers: [],
    });
    this.newAPICall();
    await trackPromise(store(apiUrl.userPackageUpdation, request).then((updatedUser) => {
      if (updatedUser.data && updatedUser.data.data) {
        let details = {
          userId: this.props.currentUserDetails.userId,
          clientId: this.props.currentUserDetails.clientId
        }
        this.props.getadminDetails(details);
        // location.reload();
        // this.closeEditUser();
        this.setState({ selectedUserId: '' });
      }
    }).catch(() => {
      window.scrollTo(0, 0);
    }));
  }

  saveUpdateUser() {
    const firstName = document.getElementById('firstName').value;
    const lastName = document.getElementById('lastName').value;
    const email = document.getElementById('email').value;
    const isAdmin = document.getElementById('isAdmin').checked;
    const organization = document.getElementById('organization').value;
    const team = document.getElementById('team').value;
    const clientId = (this.props.currentUserDetails && this.props.currentUserDetails.clientId) ? this.props.currentUserDetails.clientId : null;
    // const adminMailList = this.state.usersList.filter((list) => list.isAdmin);      
    const saveRequest = {
      firstName,
      lastName,
      email,
      isAdmin,
      clientId,
      // adminMailList,
    }
    if (this.state.isEditUser) {
      const isActive = document.getElementById('isActive').checked;
      const updateRequest = {
        firstName: firstName,
        lastName: lastName,
        isAdmin: isAdmin,
        clientId: clientId,
        clientName: organization,
        teamName: team,
        email: email,
        userId: this.state.selectedUserId,
        userStatus: isActive ? 'active' : 'offboarded',        
        skipUpdateClient: true,
        // adminMailList,
      }
      if (this.props.currentUserDetails.roleType === "TMX Admin" || this.props.currentUserDetails.roleType === "TMX BA Admin") {
        if (team !== this.props.currentUserDetails.team || organization !== this.props.currentUserDetails.organization) {
          updateRequest.skipUpdateClient = false;
        }
      }
      if (this.state.isOnlyOneAdminUserId === updateRequest.userId) {
        if (this.state.isOnlyOneAdmin && this.state.isEditUser && (this.state.editingUser.isAdmin === isAdmin) && (this.state.editingUser.isActive === isActive)) {
          this.updateUserAPICall(updateRequest);
        } else {
          this.setState({
            isOnlyOneAdminErrorMessage: false
          });
        }
      } else {
        this.updateUserAPICall(updateRequest);
      }
    } else {
      saveRequest.currentSubscribedProducts = this.state.allProductsWithClientSubsId;
      this.saveUserAPICall(saveRequest)
    }
  }


  async saveUserAPICall(request) {
    const access_token = this.props.currentUserDetails.access_token;
    request.token = access_token;
    this.props.getCurrentAdminDetails({
      subscribedProducts: [],
      usersList: [],
      listOfAllUsers: [],
    });
    this.newAPICall();
    await trackPromise(store(apiUrl.saveUser, request).then((savedUser) => {
      if (savedUser.data && savedUser.data.data) {
        // location.reload();
        let details = {
          userId: this.props.currentUserDetails.userId,
          clientId: this.props.currentUserDetails.clientId
        }
        this.props.getadminDetails(details);
        this.closeEditUser();
      }
    }).catch(() => {
      window.scrollTo(0, 0);
    }));
  }

  unSubscribeProducts() {
    const productUserList = []
    this.state.listUsersByProduct.forEach((val) => {
      if(val.isActive === true) {
        productUserList.push(val.email);
      }
    });
    const request = {
      firstName: this.props.currentUserDetails.firstName, 
      clientId: this.props.currentUserDetails.clientId,
      teamName: this.props.currentUserDetails.team,
      organizationName: this.props.currentUserDetails.organization,
      productName: this.state.selectedProduct.productName,
      email: this.props.currentUserDetails.email,
      productUserList: productUserList,
    };
    trackPromise(store(apiUrl.unSubscribeProducts, request).then((updatedUser) => {
       this.setState({
        isSuccessAlertNotRequired: false,
      });
      window.scrollTo(0, 0);
    }).catch((err) => {
      console.log(err);
      window.scrollTo(0, 0);
    }));
  }

  async updateUserAPICall(request) {
    const access_token = this.props.currentUserDetails.access_token;
    request.token = access_token;
    this.props.getCurrentAdminDetails({
      subscribedProducts: [],
      usersList: [],
      listOfAllUsers: [],
    });
    this.newAPICall();
    await trackPromise(update(apiUrl.updateUser, request).then((updatedUser) => {
      if (updatedUser.data && updatedUser.data.data) {
        this.props.updateTeamClientName({
          teamName: request.teamName,
          clientName: request.clientName
        });
        let details = {
          userId: this.props.currentUserDetails.userId,
          clientId: this.props.currentUserDetails.clientId
        }
        this.props.getadminDetails(details);
        if (this.props.currentUserDetails.roleType === "TMX Admin" || this.props.currentUserDetails.roleType === "TMX BA Admin") {
          this.props.updateValues();
        }
        // location.reload();
        this.closeEditUser();
        this.setState({ selectedUserId: '' });
      }
    }).catch(() => {
      window.scrollTo(0, 0);
    }));
  }

  newAPICall() {
    // this.props.getCurrentAdminDetails({
    //   subscribedProducts: [],
    //   usersList: [],
    // });
    this.setState({
      usersList: [],
      listUsersByProduct: [],
      listUsersByProductUpdated: false,
      allUsersList: [],
    });
}

  async resetPassword() {
    let isAlertNotRequired;
    const email = document.getElementById('email').value;
    const access_token = this.props.currentUserDetails.access_token;
    await trackPromise(store(apiUrl.resetPassword, {email, token: access_token}).then((resetPasswordInitiated) => {
      if (resetPasswordInitiated.data && resetPasswordInitiated.data.data) {
        // this.closeEditUser();
        isAlertNotRequired = false;
      }
      this.setState({ isAlertNotRequired });
    }).catch(() => {
      window.scrollTo(0, 0);
    }));
  }

  checkMailRegex (value) {
    const splittedValueOne = value.split('@');
    const splittedValue = splittedValueOne[0].split('');
    const splittedValueLength = splittedValue.length;
    if (splittedValue[0] === '.' || splittedValue[splittedValueLength-1] === '.') {
      return true;
    } else {
      if (splittedValueOne.length > 1) {
        const splittedValueTwo = splittedValueOne[0].split('..');
        if (splittedValueTwo.length > 1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }
  }

  fieldValidation() {
    this.setState({
      isOnlyOneAdminErrorMessage: true
    });
    let isDetailsValid = true;
    const firstName = document.getElementById('firstName').value;
    const lastName = document.getElementById('lastName').value;
    const email = document.getElementById('email').value;
    const organization = document.getElementById('organization').value
    const team = document.getElementById('team').value
    let { resultMessage, teamValidated } = this.state;
    let isAlertNotRequired; let isRedAlertNotRequired; let firstNameIsAlertNotRequired; let firstNameIsRedAlertNotRequired; let firstNameResultMessage; let lastNameIsAlertNotRequired; let lastNameIsRedAlertNotRequired; let lastNameResultMessage;
    if (this.props.currentUserDetails.roleType !== "TMX Admin" && this.props.currentUserDetails.roleType !== "TMX BA Admin") {
      if (firstName && lastName && email && organization) {
        isDetailsValid = false;
      }
    } else {
      if (this.props.currentUserDetails.team) {
        if (firstName && lastName && email && organization && team) {
          if (!teamValidated && (team !== this.props.currentUserDetails.team || organization !== this.props.currentUserDetails.organization)) {
            isDetailsValid = true;
          } else {
            isDetailsValid = false;
          }
        }
      } else {
        if (firstName && lastName && email && organization) {
          isDetailsValid = false;
        }
      }
    }
    if (email) {
      const pattern = new RegExp(validation[`email_regx`]);
      if (!pattern.test(email)) {
        resultMessage = 'Please enter valid Email Address';
        isAlertNotRequired = true;
        isRedAlertNotRequired = false;
        isDetailsValid = true;
      } else {
        resultMessage = '';
        isAlertNotRequired = true;
        isRedAlertNotRequired = true;
      }
      const testRegex = this.checkMailRegex(email);
      if (testRegex) {
        resultMessage = 'Please enter valid Email Address';
        isAlertNotRequired = true;
        isRedAlertNotRequired = false;
        isDetailsValid = true;
      }
      if (!this.state.isEditUser && this.state.isDetailsValid) {
        this.setState({
          emailValidated: false,
        });
      }
    }
    if (firstName) {
      const pattern = new RegExp(validation[`first_name_regx`]);
      if (!pattern.test(firstName)) {
        firstNameResultMessage = 'Please enter valid First Name';
        firstNameIsAlertNotRequired = true;
        firstNameIsRedAlertNotRequired = false;
        isDetailsValid = true;
      } else {
        firstNameResultMessage = '';
        firstNameIsAlertNotRequired = true;
        firstNameIsRedAlertNotRequired = true;
      }
    }
    if (lastName) {
      const pattern = new RegExp(validation[`last_name_regx`]);
      if (!pattern.test(lastName)) {
        lastNameResultMessage = 'Please enter valid Last Name';
        lastNameIsAlertNotRequired = true;
        lastNameIsRedAlertNotRequired = false;
        isDetailsValid = true;
      } else {
        lastNameResultMessage = '';
        lastNameIsAlertNotRequired = true;
        lastNameIsRedAlertNotRequired = true;
      }
    }
    this.setState({
      resultMessage, isAlertNotRequired, isRedAlertNotRequired, isDetailsValid, lastNameResultMessage, lastNameIsAlertNotRequired, lastNameIsRedAlertNotRequired, firstNameResultMessage, firstNameIsAlertNotRequired, firstNameIsRedAlertNotRequired
    })
    if (this.state.isEditUser) {
      this.setState({
        emailValidated: true
      });
    }
  }

  editUser(item) {
    let isAlertNotRequired =  true;
    let isRedAlertNotRequired = true;
    this.setState({
      isAlertNotRequired, isRedAlertNotRequired, teamMessage: '',
      resultMessage: '', editingUser: item, isOnlyOneAdminErrorMessage: true,
    });
    this.setState({ isEditUser: true, selectedUserId: item.userId ,isDetailsValid: true }, () => {
      const modal = document.getElementById('myModal');
      document.getElementById('firstName').value = item.firstName;
      document.getElementById('lastName').value = item.lastName;
      document.getElementById('organization').value = this.props.currentUserDetails.organization;
      document.getElementById('team').value = this.props.currentUserDetails.team;
      document.getElementById('email').value = item.email;
      document.getElementById('email').classList.add('disable');
      document.getElementById("email").readOnly = true;
      this.props.currentUserDetails.team === '' || this.props.currentUserDetails.team === null  || this.props.currentUserDetails.team === 'null' ? document.getElementById('organization').classList.add('disable') : document.getElementById('organization').classList.remove('disable');
      this.props.currentUserDetails.team === '' || this.props.currentUserDetails.team === null  || this.props.currentUserDetails.team === 'null' ? document.getElementById('team').classList.add('disable') : document.getElementById('team').classList.remove('disable');
      item.isAdmin === true ? document.getElementById('isAdmin').checked = true : document.getElementById('isAdmin').checked = false;
      item.isActive === true ? document.getElementById('isActive').checked = true : document.getElementById('isActive').checked = false;
      modal.style.display = 'block';
    });
  }

  createUser() {
    this.setState({ isEditUser: false, isDetailsValid: true, teamMessage: '',
    resultMessage: '', editingUser: {}, isOnlyOneAdminErrorMessage: true });
    const modal = document.getElementById('myModal');
    document.getElementById('firstName').value = '';
    document.getElementById('lastName').value = '';
    document.getElementById('organization').value = this.props.currentUserDetails.organization;
    document.getElementById('team').value = this.props.currentUserDetails.team;
    document.getElementById('email').value = '';
    document.getElementById('isAdmin').checked = false;
    // document.getElementById('isActive').checked = false;
    document.getElementById('email').classList.remove('disable');
    document.getElementById("email").readOnly = false;
    modal.style.display = 'block';
  }

  async getAllUsers() {
    if (this.state.allUsersList && this.state.allUsersList.length <= 0) {
      const request = {
        clientId: this.props.currentUserDetails.clientId,
        token: this.props.currentUserDetails.access_token,
      }
      let isOnlyOneAdmin = false;
      let isOnlyOneAdminUserId = '';
      await trackPromise(store(apiUrl.getAllUsers, request).then((allUsers) => {
        if (allUsers.data && allUsers.data.data) {
          const onlyOneAdmin = allUsers.data.data.filter((users) => {
            if (users.isAdmin) {
              return users;
            }
          });
          if (onlyOneAdmin && onlyOneAdmin.length <= 1) {
            isOnlyOneAdmin = true;
          }
          if(onlyOneAdmin && onlyOneAdmin.length === 1) {
            isOnlyOneAdminUserId = onlyOneAdmin[0].userId;
          }
          this.setState({
            isAllUsersSelected: true,
            listUsersByProduct: allUsers.data.data,
            allUsersList: allUsers.data.data,
            allUsers: allUsers.data.data,
            listUsersByProductUpdated: true,
            selectedProduct: {},
            isOnlyOneAdmin,
            isOnlyOneAdminUserId,
          });
        }
      }).catch(() => {
        window.scrollTo(0, 0);
      }));
    } else {
      let isOnlyOneAdmin = false;
      let isOnlyOneAdminUserId = '';
      if (this.state.allUsersList && this.state.allUsersList.length > 0) {
        const onlyOneAdmin = this.state.listUsersByProduct.filter((users) => {
          if (users.isAdmin) {
            return users;
          }
        });
        if (onlyOneAdmin && onlyOneAdmin.length <= 1) {
          isOnlyOneAdmin = true;
        }
        if(onlyOneAdmin && onlyOneAdmin.length === 1) {
          isOnlyOneAdminUserId = onlyOneAdmin[0].userId;
        }
        this.setState({
          isAllUsersSelected: true,
          listUsersByProductUpdated: true,
          listUsersByProduct: this.state.allUsersList,
          selectedProduct: {},
          isOnlyOneAdmin,
          isOnlyOneAdminUserId,
        });
      }
    }
  }

  closeEditUser() {
    const modal = document.getElementById('myModal');
    const newmodal = document.getElementById('editApplicableProducts');
    modal.style.display = 'none';
    newmodal.style.display = 'none';
    this.setState({
      resultMessage:'',
       isAlertNotRequired:false,
       isRedAlertNotRequired:false,
        isDetailsValid:false,
       lastNameResultMessage:false, lastNameIsAlertNotRequired:false,
        lastNameIsRedAlertNotRequired:false, firstNameResultMessage:false, 
        firstNameIsAlertNotRequired:false, firstNameIsRedAlertNotRequired:false
    })
  }

  neutralizingList(item) {
    this.props.currentSubscribedProducts.sort((a,b) => (Number(a.productWeightage) < Number(b.productWeightage)) ? 1 : ((Number(b.productWeightage) < Number(a.productWeightage)) ? -1 : 0)).map((value) => {
      document.getElementById(value.productName).checked = false;
      document.getElementById(value.productName+'_user_subs_id').value = '';
      // document.getElementById(value.productName+'_clientSubsId').value = '';
      return null;
    });
    this.editApplicableProducts(item);
  }

  editApplicableProducts(item) {
    if (item.userId) {
      this.setState({
        selectedUserId: item.userId
      });
    }
    for (let i = 0; i < item.selectedProduct.length; i++) {
      this.props.currentSubscribedProducts.sort((a,b) => (Number(a.productWeightage) < Number(b.productWeightage)) ? 1 : ((Number(b.productWeightage) < Number(a.productWeightage)) ? -1 : 0)).map((value) => {
        // document.getElementById(value.productName+'_clientSubsId').value = item.selectedProduct[i].clientSubsId;
        if (item.selectedProduct[i].subscriptionId === value.subscriptionId) {
          document.getElementById(value.productName).checked = true;
          document.getElementById(value.productName+'_user_subs_id').value = item.selectedProduct[i].user_subs_id;
        }
        return null;
      });
    }
    const products = this.props.currentSubscribedProducts;
    let checkUpdatedProductsValue = [];
    products.map((value, i) => {
      const productValue = document.getElementById(value.productName).checked;
      const currentUserSubsId = document.getElementById(value.productName+'_user_subs_id').value;
      let currentClientSubsId = document.getElementById(value.productName+'_clientSubsId').value;
      currentClientSubsId = currentClientSubsId ? currentClientSubsId : 0;
      checkUpdatedProductsValue.push(
        {
          subscriptionId: value.subscriptionId,
          status: productValue,
          userSubsId: Number(currentUserSubsId),
          productName: value.productName,
          clientSubsId: Number(currentClientSubsId),
          subCategory: value.productCategory
        }
      );
      return null;
    });
    this.setState({oldProductsValue: checkUpdatedProductsValue})
    const modal = document.getElementById('editApplicableProducts');
    modal.style.display = 'block';
  }

  getUsersByProduct = (element) => {
    // const { usersList } = this.state;
    const usersList = this.props.currentUsersList;
    let isOnlyOneAdmin = false;
    let isOnlyOneAdminUserId = '';
    let listUsersByProduct = [];
    if(usersList && usersList.length) {
      for(let i=0; i<usersList.length; i++) {        
        const isProduct = usersList[i].selectedProduct && usersList[i].selectedProduct.filter((product) => {
          if (product.subscriptionId === element.subscriptionId) {
            return product;
          }
        });
        if(usersList[i] && usersList[i].selectedProduct && isProduct && isProduct.length > 0) {
          listUsersByProduct.push(usersList[i]);
        }
      }
      const onlyOneAdmin = usersList.filter((users) => {
        if (users.isAdmin) {
          return users;
        }
      });
      if (onlyOneAdmin && onlyOneAdmin.length <= 1) {
        isOnlyOneAdmin = true;
      }
      if(onlyOneAdmin && onlyOneAdmin.length === 1) {
        isOnlyOneAdminUserId = onlyOneAdmin[0].userId;
      }
      this.setState({listUsersByProduct: listUsersByProduct, listUsersByProductUpdated: true, selectedProduct: element, isAllUsersSelected: false, isOnlyOneAdmin, isOnlyOneAdminUserId });
    }
    const allProductsWithClientSubsId = this.props.currentSubscribedProducts.map((product) => {
      return {
        subscriptionId: product.subscriptionId,
        productName: product.productName,
        clientSubsId: product.clientSubsId,
        productCategory: product.productCategory,
        productUrl: product.productUrl
      }
    });
    this.setState({
      allProductsWithClientSubsId
    });
  }

  setProductsToUsers() {
    const currentSubscribedProducts = this.props.currentSubscribedProducts;
    if (this.props.currentUsersList && this.props.currentUsersList.length > 0) {
      if  (this.state.listUsersByProduct.length === 0) {
        this.setState({
          usersList: this.props.currentUsersList,
          allUsersList: this.props.listOfAllUsers,
        }, () => {
          this.getAllUsers();
        });
        this.getUsersByProduct(currentSubscribedProducts[0]);
        // this.getAllUsers();
      }
    }
  }

  isSelected = () => {
    let isSelected = false;
    const { selectedProduct } = this.props;
    if (selectedProduct && selectedProduct.productName === this.props.productName) {
      isSelected = true;
    }
    if (this.props.isAllocatePage && selectedProduct && selectedProduct.id === this.props.productId) {
      isSelected = true;
    }
    return isSelected;
  }

  render() {
    (!this.state.listUsersByProductUpdated) ? this.setProductsToUsers() : '';
    const { t } = this.props;
    let userslist = this.state.listUsersByProduct ? this.state.listUsersByProduct : [];
    let subscribedproducts = this.props.currentSubscribedProducts ? this.props.currentSubscribedProducts : [];
    // const addedProducts = this.props.products;
    // this.props.currentUserDetails.subscribedProducts.push(addedProducts);
    return (
    <>
    { this.props.currentUserDetails.roleType !== "TMX Admin" && this.props.currentUserDetails.roleType !== "TMX BA Admin"
    ? <SuccessMessage shouldNotDisplaySuccess={this.state.isSuccessAlertNotRequired} successMessage={t('usermanagement.mail_sent_success_message')} backButton={false} />
    : '' }
    <div className='container-fluid'>
      <div className='row'>
        <div className="col-lg-1 col-md-1 col-sm-1"></div>
          <Jumbotron className="col-lg-10 col-md-10 col-sm-10 col-xs-12 jumbotron_usage_summary">
           {this.props.currentUserDetails.roleType === "Client Admin" ? <Link className="payment_console_back_link" to='/admin'>{'Home'}</Link> : ''}
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="usage_heading1">{t('usermanagement.header')}</div>
          <div className='row change-wrap'>
              <div className='col-lg-11 col-md-11 col-sm-11 col-xs-11 header-style-change'>
              <div className="usage_heading1">{t('usermanagement.productssubscribed')}</div>
              </div>
              <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'>
                <Link id='addProducts' className="brand-logo-align" to='/admin-products'>
                  <FontAwesomeIcon title= "Add Products" className='profile-icon' icon={faPlusCircle} size="2x" />
                    <span className="AddMoreProductsWithComment"><h6>{t('usermanagement.moreproducts')}</h6></span>
                </Link>
              </div>
            </div>
            <div className="usermanagementpage-products-class">
                {subscribedproducts.sort((a,b) => (Number(a.productWeightage) < Number(b.productWeightage)) ? 1 : ((Number(b.productWeightage) < Number(a.productWeightage)) ? -1 : 0)).map((item, i) => <div key={i} onClick={() => this.getUsersByProduct(item)}><SubscribedProducts
                  key={i}
                  productId={item.subscriptionId}                   
                  productName={item.productName}
                  productLink={item.productUrl}
                  selectedProduct={this.state.selectedProduct}
                  isAdminPage={false}
                />
                </div>)}
            </div>
            <br/><br/><br/>
            <div className='row change-wrap'>
              <div className='col-lg-2 col-md-3 col-sm-2 col-xs-2 header-style-change'>
              <div className="usage_heading1 userlist_heading1">{t('usermanagement.userslist')}</div>
              {/* <button className="btn btn-primary all-users-button" onClick={this.getAllUsers} >All Users</button> */}
              {/* admin-products-card-layout-selected admin-products-card-layout */}
              </div>
              <div className='col-lg-9 col-md-8 col-sm-8 col-xs-8'>
              <Card onClick={this.getAllUsers} className={`${this.state.isAllUsersSelected ? 'admin-products-card-layout-selected all-users-style' : 'userslist-products-card-layout'}`}>
                    <Card.Body>
                      <Card.Text>All Users</Card.Text>
                    </Card.Body>
                </Card>
              </div>
              <div className='col-lg-1 col-md-1 col-sm-2 col-xs-2'>
                <a className="brand-logo-align" id="addUser" onClick={this.createUser} style={{ cursor: 'pointer' }}><FontAwesomeIcon title= "Add User" className='profile-icon' icon={faUserPlus} size="2x" /></a>
              </div>
            </div>
            <br/>
            <div className='users-table-style'>
                <table>
                <thead>
                  <tr>
                    <th>{t('usermanagement.status')}</th>
                    <th className='left-align-text'>{t('usermanagement.firstname')}</th>
                    <th className='left-align-text'>{t('usermanagement.lastname')}</th>
                    <th className='left-align-text'>{t('usermanagement.email')}</th>
                    <th>{t('usermanagement.admin')}</th>
                    <th>{t('usermanagement.apps')}</th>
                    <th>{t('usermanagement.edituser')}</th>
                  </tr>
                </thead>
                <tbody>
                    {userslist.map((item, i) => <tr key={i}>
                    {item.isActive ? <td><label className="switch"><input type="checkbox" checked readOnly/>
                          <span className="slider round"></span>
                          </label>
                      </td> : <td><label className="switch"><input type="checkbox" disabled/>
                          <span className="slider round"></span>
                          </label>
                      </td>}
                      <td className='left-align-text'>{item.firstName}</td>
                      <td className='left-align-text'>{item.lastName}</td>
                      <td className='left-align-text'>{item.email}</td>
                        {item.isAdmin ? <td>&#10003;</td> : <td></td>}
                      <td><span className='CellWithComment'>
                        {
                        this.props.currentUserDetails.roleType === "TMX Admin" || this.props.currentUserDetails.roleType === "TMX BA Admin" ? 
                         this.props.updateNewClientDetails && this.props.updateNewClientDetails.status && this.props.updateNewClientDetails.status != 'active' ?  
                              <a className='hamburger' style={{ cursor: 'pointer', opacity: '0.5' }} id='apps'>&#9776;</a>
                            : <a className='hamburger' style={{ cursor: 'pointer' }} id='apps' onClick={() => this.neutralizingList(item)}>&#9776;</a>
                        : this.state.disableFunctions === false ?  
                              <a className='hamburger' style={{ cursor: 'pointer', opacity: '0.5' }} id='apps'>&#9776;</a>
                            : <a className='hamburger' style={{ cursor: 'pointer' }} id='apps' onClick={() => this.neutralizingList(item)}>&#9776;</a>
                        }
                        <span className="CellComment">
                          <h5 style={{ color: '#16fffb' }}>{t('usermanagement.subscribedproducts')}</h5>
                          {
                            item.selectedProduct && item.selectedProduct.sort((a,b) => (Number(a.productWeightage) < Number(b.productWeightage)) ? 1 : ((Number(b.productWeightage) < Number(a.productWeightage)) ? -1 : 0)).map((currentSubProduct, j) => <div key={j}><li className='align-list-text'>{currentSubProduct.productName}</li></div>)
                          }
                        </span>
                        </span>
                      </td>
                      <td>
                        {
                        this.props.currentUserDetails.roleType === "TMX Admin" || this.props.currentUserDetails.roleType === "TMX BA Admin" ? 
                         this.props.updateNewClientDetails && this.props.updateNewClientDetails.status && this.props.updateNewClientDetails.status != 'active' ?  
                            <a id='editUserDetails' style={{ color: 'whitesmoke', pointerEvents: 'none', opacity: '0.5' }} onClick={() => this.editUser(item)}><FontAwesomeIcon icon={faPen} size="xs" /></a>
                          : <a id='editUserDetails' style={{ color: 'whitesmoke', cursor: 'pointer' }} onClick={() => this.editUser(item)}><FontAwesomeIcon icon={faPen} size="xs" /></a>
                        : this.state.disableFunctions === false ?   
                            <a id='editUserDetails' style={{ color: 'whitesmoke', pointerEvents: 'none', opacity: '0.5' }} onClick={() => this.editUser(item)}><FontAwesomeIcon icon={faPen} size="xs" /></a>
                          : <a id='editUserDetails' style={{ color: 'whitesmoke', cursor: 'pointer' }} onClick={() => this.editUser(item)}><FontAwesomeIcon icon={faPen} size="xs" /></a>
                        }
                      </td>
                      </tr>)}
                </tbody>
                </table>
                </div>
          <br/><br/>
          {this.props.currentUserDetails.roleType !== "TMX Admin" && this.props.currentUserDetails.roleType !== "TMX BA Admin"
          ? <div className='row'>
            <div className='col-lg-5 col-md-3 col-sm-3'></div>
                <p className='unsubscribe-align'>{t('usermanagement.unsubscribelink')}<a className='hyperlink_color_change' onClick={() => this.unSubscribeProducts()}>{t('usermanagement.unsubscribelinkhere')}</a></p>
          </div>
          : '' }
          </div>
          </Jumbotron>
        <div className='col-lg-1 col-md-1'></div>
    </div>
    </div>

    <div id="myModal" className="modal">
      <div className="modal-content" ref={(el) => {
        if (el) {
          el.style.setProperty('width', '25%', 'important');
        }
      }}>
          <div className='container-fluid'>
            <div className='row'>
              <span className="close" onClick={() => this.closeEditUser()}>&times;</span>
            </div>
            <div className='row'>
              <h4 className='edituser-popup-header'>{t('usermanagement.userdetails')}</h4>
            </div>
            <div className='row'>
              <form autoComplete="off">
                <div className='form-row'>
                  <div className='form-group col-lg-6 col-md-12'>
                    <label htmlFor="firstName">{t('usermanagement.firstname')}</label>
                    <input type='text' onChange={this.fieldValidation} name="firstName" id="firstName" className='form-control'/>
                    <div className="text-danger-alert" hidden={this.state.firstNameIsRedAlertNotRequired}>{this.state.firstNameResultMessage}</div>
                  </div>
                  <div className='form-group col-lg-6 col-md-12'>
                    <label htmlFor="lastName">{t('usermanagement.lastname')}</label>
                    <input type='text' onChange={this.fieldValidation} name="lastName" id="lastName" className='form-control' />
                    <div className="text-danger-alert" hidden={this.state.lastNameIsRedAlertNotRequired}>{this.state.lastNameResultMessage}</div>
                  </div>
                </div>
                <div className="form-row">
                  <div className='form-group col-lg-6 col-md-12'>
                    <label htmlFor="organization">{t('usermanagement.organization')}</label>
                    <input type='text' onChange={this.fieldValidation} onBlur={(e) => this.checkRedundantTeam(e)} name="organization" id="organization" className='form-control' disabled={(this.props.currentUserDetails.roleType !== 'TMX Admin' && this.props.currentUserDetails.roleType !== 'TMX BA Admin') || !this.state.isEditUser}/>
                  </div>
                  <div className="form-group col-lg-6 col-md-12">
                    <label htmlFor="team">{t('usermanagement.team')}</label>
                    <input type='text' onChange={this.fieldValidation} onBlur={(e) => this.checkRedundantTeam(e)} name="team" id="team" className='form-control' disabled={(this.props.currentUserDetails.roleType !== 'TMX Admin' && this.props.currentUserDetails.roleType !== 'TMX BA Admin') || !this.state.isEditUser}/>
                    <div className="text-danger-alert" hidden={this.state.isTeamRedAlertNotRequired}>{this.state.teamMessage}</div>
                  </div>
                </div>
                <div className="form-row">
                  <div className='form-group col-lg-12 col-md-12'>
                    <label htmlFor="email">{t('usermanagement.email')}</label>
                    <input type='email' onChange={this.fieldValidation} name="email" id="email" className='form-control' onBlur={(e) => this.checkRedundantEmail(e)}/>
                    <div className="text-danger-alert" hidden={this.state.isRedAlertNotRequired}>{this.state.resultMessage}</div>
                  </div>
                </div>
                <div className="form-row">
                  <div className='form-group col-lg-6 col-md-12'>
                    <label htmlFor="isAdmin">{t('usermanagement.admin')}</label>
                    {/* disabled={this.state.isOnlyOneAdmin && this.state.isEditUser && this.state.editingUser.isAdmin} */}
                    <input type="checkbox" onChange={this.fieldValidation} name="isAdmin" className='edituser-popup-checkbox-style' id="isAdmin" />
                  </div>
                  {this.state.isEditUser
                  ? <div className='form-group col-lg-6 col-md-6 col-sm-12'>
                    <a className="" onClick={() => this.resetPassword()} style={{ cursor: 'pointer' }}>
                      Reset Password
                    </a>
                 </div>
                 : ''}
                </div>
                {this.state.isEditUser
                ? <center><div className="text-danger-alert" hidden={this.state.isAlertNotRequired}>Password reset mail has been sent to the user!</div></center>
                : ''}
{/* {this.state.isEditUser && <div className='form-row'>
                          <div className="form-group col-lg-6 col-md-12">
                          <a className="" href="Change Password" style={{ cursor: 'pointer' }}>
                              Change Password
                            </a>
                          </div>
                        </div>} */}
                      {this.state.isEditUser
                        ? <div className='form-row'>
                          <div className="form-group col-lg-6 col-md-6 col-sm-12">
                            <label htmlFor="isActive">{t('usermanagement.status')}:</label>
                            <label className="new-switch"><input type="checkbox" id="isActive" onChange={this.fieldValidation}/><span className="slider round"></span></label>
                          </div>
                        </div>
                      : '' }
              </form>
            </div>
            </div>
            <div className="form-row">
              <div className='form-group col-lg-12 col-md-12'>
                <center><div className="text-danger-alert" hidden={this.state.isOnlyOneAdminErrorMessage}>{t('usermanagement.only_one_admin_error_message')}</div></center>
              </div>
            </div>
            <div className='row'>
              <button className="btn btn-info save-button" disabled={this.state.isDetailsValid || (!this.state.emailValidated && !this.state.isEditUser) || (!this.state.teamValidated && this.state.isEditUser && (this.props.currentUserDetails.roleType === 'TMX Admin' && this.props.currentUserDetails.roleType === 'TMX BA Admin')) || this.state.teamMessage !== ''} 
              onClick={() => this.saveUpdateUser()}
              >{this.state.isEditUser ? t('usermanagement.update') : t('usermanagement.save')}</button>
            </div>
          </div>
      </div>

    <div id='editApplicableProducts' className='modal'>
      <div className="subscribedproducts-modal-content">
        <div className='container-fluid'>
          <div className='row'>
              <span className="close" onClick={this.closeEditUser}>&times;</span>
          </div>
          <div className='row'>
            <h5 className='edituser-popup-header'>{t('usermanagement.subscribedproducts')}</h5>
          </div>
          {
            subscribedproducts.map((item, i) => <div className='row edituser-popup-header' key={i}>
              <label htmlFor={item.productName} style={{ width: '80%' }} className='col-lg-10 col-md-10 col-sm-10 col-xs-10'>{item.productName}:</label>
              <input type="checkbox" name={item.productName} id={item.productName} style={{ width: '20%' }} className='sample-checkbox col-lg-2 col-md-2 col-sm-2 col-xs-2'/>
              <input type="hidden" id={`${item.productName}_user_subs_id`} />
              <input type="hidden" id={`${item.productName}_clientSubsId`} value={item.clientSubsId} />
            </div>)
          }
          </div>
          <div className='row'>
            <button onClick={this.updateProducts} className="btn btn-info products-save-button">{t('usermanagement.save')}</button>
          </div>
      </div>
    </div>

  </>);
  }
}

const mapDispatchToProps = (dispatch) => ({
  getadminDetails: (details) => dispatch(adminDetails(details)),
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
  getCurrentAdminDetails: (details) => dispatch(getCurrentAdminDetails(details)),
  getPaymentDetails: (details) => dispatch(paymentDetails(details)),
  updateTeamClientName: (details) => dispatch(updateTeamClientName(details)),
  productsDetails: (details) => dispatch(productsDetails(details)),
  storeAssignProducts: (details) => dispatch(storeAssignProducts(details)),
});

const mapStateToProps = (state) => ({
  currentUserDetails: state.crud.currentUser,
  currentSubscribedProducts: state.crud.subscribedProducts,
  currentUsersList: state.crud.usersList,
  listOfAllUsers: state.crud.listOfAllUsers,
  currentLoadingPage: state.crud.currentLoadingPage,
  updateNewClientDetails: state.crud.updateNewClientDetails,
  usersListUpdated: state.crud.usersListUpdated,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Users));
