/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
  Route, Switch, BrowserRouter as Router, Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';
import ScrollToTop from './ScrollToTop';
import Admin from '../admin/admin';
import Users from '../admin/users';
import Profile from '../admin/profile';
import UsageSummary from '../admin/usageSummary';
import Payment from '../admin/payment';
import PayNow from '../admin/payNow';
import PaymentCards from '../admin/paymentCards';
import PaymentFinal from '../admin/paymentFinal';
import Products from '../admin/products';
import BannerImage from '../common/bannerImage';
import AdminSummary from '../admin/adminSummary';
import AdminConsoleTab from '../admin/adminConsoleTab';
import ResetPassword from '../admin/resetPassword';
import Login from '../admin/login';
import AllocateProducts from '../admin/allocateProducts';
import Summary from '../admin/summary';
import Subscriber from '../admin/subscriber';
import Signup from '../../subscription/login/signUp';
import SignupProducts from '../../subscription/login/products';
import SignupPayment from '../../subscription/login/payment';
import Billing from '../../subscription/login/billing';
import SignupSubscriber from '../../subscription/login/subscriber';
import SignupSummary from '../../subscription/login/summary';
import Confirmation from '../../subscription/login/confirmation';
import { storeCurrentPage } from '../../../actions/index';
import ChangePassword from '../admin/changePassword';
class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: ''
    };
    this.initiateBody = this.initiateBody.bind(this);
  }

  initiateBody () {
    this.props.initiateApp();
  }

  returnRoute(route, props) {
    switch (route) {
      case 'Admin':
        return <Admin {...props}/>;
      case 'Profile':
        return <Profile {...props}/>;
      case 'Users':
        return <Users {...props}/>;
      case 'UsageSummary':
        return <UsageSummary {...props}/>;
      case 'Payment':
        return <Payment {...props}/>;
      case 'PayNow':
        return <PayNow {...props}/>;
      case 'PaymentCards':
        return <PaymentCards {...props}/>;
      case 'PaymentFinal':
        return <PaymentFinal {...props}/>;
      case 'Products':
        return <Products {...props}/>;
      case 'AdminSummary':
        return <AdminSummary {...props}/>;
      case 'AdminConsole':
        return <AdminConsoleTab {...props}/>;
      case 'ResetPassword':
        return <ResetPassword {...props}/>;
      case 'AllocateProducts':
        return <AllocateProducts {...props}/>;
      case 'Summary':
        return <Summary {...props}/>;
      case 'Subscriber':
        return <Subscriber {...props}/>;

      default:
        return <Login initiateBody = {this.initiateBody} {...props} />;
    }
  }

  componentDidUpdate() {
    if (this.props.currentPage) {
      this.props.storeCurrentPage(this.props.currentPage);
    }
  }

  NoMatch(landing) {
    let sessionExpired = sessionStorage.getItem('sessionExpired');
    sessionExpired = (sessionExpired === 'true');
    return (
      landing && this.props.currentUser.roleType !== 'isUIAdmin' && !sessionExpired
        ? <Redirect to={landing} />
        : <Redirect to="/login" />
    );
  }

  render() {
    let sessionExpired = sessionStorage.getItem('sessionExpired');
    sessionExpired = (sessionExpired === 'true');
    const pageAccess = this.props.pageAccess ? this.props.pageAccess : [];
    return (
      this.props.currentUser.roleType !== 'isUIAdmin' && !sessionExpired
      ? <div className='body-style'>
      {
        !sessionExpired && pageAccess.length !== 0 ? <BannerImage className="tmxBannerImage"/> : ''
      }
      <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path="/login" render={(props) => (
                <Login initiateBody = {this.initiateBody} {...props} />
              )} />
              <Route exact path="/resetpassword" component={ResetPassword}/>
              <Route exact path="/changepassword" component={ChangePassword}/>
              {
                pageAccess.map((item, idx) => <Route key={idx} path={item.path} exact render={(props) => (
                  this.returnRoute(item.component, props)
                )} />)
              }
              <Route>
                {this.NoMatch(this.props.landingPage)}
              </Route>
            </Switch>
          </ScrollToTop>
        </Router>
      </div>
      : <div>
      <Router>
        <ScrollToTop>
          <Switch>
          <Route exact path="/login" render={(props) => (
                <Login initiateBody = {this.initiateBody} {...props} />
              )} />
            <Route exact path="/resetpassword" component={ResetPassword}/>
            <Route exact path="/changepassword" component={ChangePassword}/>
            <Route exact path="/" render={(props) => (
              <Signup {...props} isSummary={false} />
            )} />
            <Route exact path="/products" component={SignupProducts} />
            <Route exact path="/signup-payment" render={(props) => (
              <SignupPayment {...props} isSummary={false} />
            )} />
            <Route exact path="/signup" render={(props) => (
              <Signup {...props} isSummary={false} />
            )} />
            <Route exact path="/billing" component={Billing} />
            <Route exact path="/signup-subscriber" component={SignupSubscriber} />
            <Route exact path="/signup-summary" component={SignupSummary} />
          <Route exact path="/confirmation" component={Confirmation} />
            <Route>
            { (this.props.signUpDetailsSaved && this.props.signUpDetailsSaved.first_name) 
              ? null
              : <Redirect to={this.props.defaultPage} /> 
            }
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.crud.currentUser,
  pageAccess: state.crud.pageAccess,
  landingPage: state.crud.landingPage,
});

const mapDispatchToProps = (dispatch) => ({
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Body);
