/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import freeProductIcon from '../../../assets/img/free_icon_unlock.png';

class FreeProductCard extends Component {
  t = this.props.t;

  render() {
    const cardTitle = this.t('products.free_product_title');
    const cardTitleLine2 = this.t('products.free_product_title2');

    return (
      <Card className={`product-category-td image-td recommendedproducts_card_layout1 card-layout`}>
        <Card.Body>
          <div className="free_product_content">
            <div className="free_product_img"><img src={freeProductIcon} /></div>
            <div className="free_product_text_block">
              <div className="free_product_title">
                <div>{cardTitle}</div>
                <div className="free_product_line_2">{cardTitleLine2}</div>
              </div>
              <div className="free_product_text_separator"></div>
              <div className="free-text">
                {this.t('products.freeProductDescription1')} 
                <spam className="bold_text"> {this.t('products.freeProductDescription2')} </spam> 
                {this.t('products.freeProductDescription3')}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default withNamespaces()(FreeProductCard);