/* eslint-disable no-undef */
import axios from 'axios';
import Promise from 'bluebird';

import { API_URL, JWT_TOKEN } from '../config/apiConfig';
import { getSessionStorage } from './storageUtil';

function getDuration(start, end){
  return ((end-start)/1000)+'s';
}

function httpBase() {
  const api = axios.create({
    baseURL: `${API_URL}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getSessionStorage(JWT_TOKEN)
    },
    responseType: 'json'
  });
  const startDate = new Date();
  api.interceptors.response.use(function(response) {
    let endDate = new Date();
    console.log(`API URL :: ${(response && response.config ? response.config.url : 0)} DURATION :: ${getDuration(startDate, endDate)}`);
    return Promise.resolve(response);
  },function(error) {
    let endDate = new Date();
    if (error.response.status === 401) {
      // redirect to login page
      window.location.href = '/login';
    }
    if (error.response.status === 404) {
      // redirect to 404 page
    }
    if (error.response.status === 500) {
      // redirect to 500 page
    }
    console.log(`API URL :: DURATION :: ${getDuration(startDate, endDate)}`);
    return Promise.reject(error);
  });

  return api;
}

export default httpBase;
