const V1 = 'signup';
const V2 = 'admin';

const getUsers = `${V2}/users`;
const getCurrentBillingDetails = `${V2}/billingDetails`;
const getCardDetails = `${V2}/cardDetails`;
const getPaymentDetails = `${V2}/getPaymentDetails`;
const getCurrentAdminDetails = `${V2}/adminDetails`;
const checknewUserEmail = `${V2}/checknewUserEmail`;
const paymentSubmit = `${V2}/paymentSubmit`;
const getSubscribers = `${V1}/subscription`;
const getSubscribersForAdmin = `${V2}/subscription`;
const getProductInformations = `${V2}/getProductInformations`;
const getProductInformationsForChart = `${V2}/getProductInformationsForChart`;
const login = `${V2}/login`;
const getConfiguredProducts = `${V2}/getConfiguredProducts`;
const getAuthToken = `${V2}/getAuthToken`;
const saveUser = `${V2}/saveUser`;
const updateUser = `${V2}/updateUser`;
const resetPassword = `${V2}/resetPassword`;
const changePassword = `${V2}/changePassword`;
const iFrameUrl = `${V1}/iframeurl`;
const saveMonerisDetails = `${V2}/saveMonerisDetails`;
const getAgreement = `${V1}/getAgreement`;
const updateSubscriptions = `${V2}/updateSubscriptions`;
const getAdminSummary = `${V2}/getAdminSummary`;
const updateProducts = `${V2}/updateProducts`;
const purchaseMoneris = `${V2}/purchaseMoneris`;
const userPackageUpdation = `${V2}/userPackageUpdation`;
const logout = `${V2}/logout`;
const getClientDetails = `${V2}/getClientDetails`;
const updateClient = `${V2}/updateClient`;
const updateClientBillingDetails = `${V2}/updateClientBillingDetails`;
const getClientProductDetails = `${V2}/getClientProductDetails`;
const unSubscribeProducts = `${V2}/unSubscribeProducts`;
const getAllUsers = `${V2}/getAllUsers`;
const getGreetings = `${V1}/greeting/hello`;
// const getSubscribers = `${V1}/subscription`;
const adminiFrameUrl = `${V2}/iframeurl`;
const getCountries = `${V1}/getCountries`;
const getCountriesOnAdmin = `${V2}/getCountriesOnAdmin`;
const checkUserEmail = `${V1}/checkUserEmail`;
const checkOrganization = `${V1}/checkOrganizationAndTeam`;
const checkOrgAndEmail = `${V1}/checkOrgAndEmail`;
const checkTeam = `${V1}/checkOrganizationAndTeam`;
const checkOrganizationAndTeamForAdmin = `${V2}/checkOrganizationAndTeam`;
// const getAgreement = `${V1}/getAgreement`;
const createPackage = `${V1}/createPackage`;
const getAboutUsOptions = `${V1}/getAboutUsOptions`;
const getTaxRate = `${V1}/getTaxRate`;
const getTaxRateForAdmin = `${V2}/getTaxRate`;
const getProvince = `${V1}/getProvince`;
const getProvinceOnAdmin = `${V2}/getProvinceOnAdmin`;
const updateClientReferredByOption = `${V1}/updateClientReferredByOption`;
const getRefundDetails = `${V2}/getRefundDetails`;
const createRefund = `${V2}/createRefund`;
const updateBillingDetails = `${V2}/updateBillingDetails`;
const getToken = `${V1}/getToken`;
const getAgreementForAdmin = `${V2}/getAgreement`;
const downloadInvoice = `${V2}/downloadInvoice`;
const exportPdf = `${V2}/exportPdf`;
const getPayableAmount = `${V2}/getPayableAmount`;
const updateExpiryAndCVC = `${V2}/updateExpiryAndCVC`;
const addNewCard = `${V2}/addNewCard`;

export default {
  getUsers,
  getProductInformations,
  getCurrentBillingDetails,
  getCurrentAdminDetails,
  checknewUserEmail,
  paymentSubmit,
  getSubscribers,
  getSubscribersForAdmin,
  login,
  getConfiguredProducts,
  getAuthToken,
  saveUser,
  updateUser,
  resetPassword,
  changePassword,
  iFrameUrl,
  adminiFrameUrl,
  saveMonerisDetails,
  getAgreement,
  getAgreementForAdmin,
  updateSubscriptions,
  getAdminSummary,
  updateProducts,
  purchaseMoneris,
  userPackageUpdation,
  logout,
  getCardDetails,
  getPaymentDetails,
  getClientDetails,
  updateClient,
  getClientProductDetails,
  unSubscribeProducts,
  getAllUsers,
  getGreetings,
  getCountries,
  getCountriesOnAdmin,
  checkUserEmail,
  checkOrganization,
  checkTeam,
  createPackage,
  getAboutUsOptions,
  getTaxRate,
  getTaxRateForAdmin,
  getProvince,
  getProvinceOnAdmin,
  updateClientReferredByOption,
  getRefundDetails,
  createRefund,
  updateBillingDetails,
  getToken,
  checkOrganizationAndTeamForAdmin,
  downloadInvoice,
  exportPdf,
  getPayableAmount,
  getProductInformationsForChart,
  checkOrgAndEmail,
  updateClientBillingDetails,
  updateExpiryAndCVC,
  addNewCard,
};
