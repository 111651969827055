/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import { connect } from 'react-redux';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { getCurrentUserDetails, storeCurrentPage, cardDetails, paymentDetails, adminDetails, getCurrentAdminDetails } from '../../../actions/index';
import SubscribedProducts from '../common/subscribedProducts';
// // import arrowImage from '../../../src/assets/img/Asset Images for TMX Analytics-08.png'
import arrowImage from '../../../assets/img/Asset Images for TMX Analytics-08.png';

class Admin extends Component {
  t = this.props.t;

  constructor(props) {
    super(props);
    this.state = {
      isDisableUpdated: false,
      disableFunctions: true
    };
    if (!this.props.usersListUpdated && this.props.currentUserDetails.roleType !== 'User') {
      this.props.getCurrentAdminDetails({
        subscribedProducts: [],
        usersList: [],
        listOfAllUsers: [],
      });
      let details = {
        userId: this.props.currentUserDetails.userId,
        clientId: this.props.currentUserDetails.clientId
      }
      this.props.getadminDetails(details);
    }
    if (this.props.currentUserDetails.roleType !== 'User') {
      this.props.getCardDetails({'clientId': this.props.currentUserDetails.clientId, 'status': 'active', 'oneTimeCard': 'N'});
      this.props.getPaymentDetails({'clientId': this.props.currentUserDetails.clientId, token: this.props.currentUserDetails.access_token});
    }
    this.transformProducts = this.transformProducts.bind(this);
  }

  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
      this.props.history.push(this.props.currentLoadingPage);
      this.props.storeCurrentPage('');
    }
    if(!this.state.isDisableUpdated){
      let disableFunctions = !(this.props.updateNewClientDetails && this.props.updateNewClientDetails.status && this.props.updateNewClientDetails.status === 'pending approval');
      if(this.props.currentSubscribedProducts && this.props.currentUserDetails.selectedProducts.length > 0 && this.props.currentUserDetails.roleType === "Client Admin") {
        const activeClients = this.props.currentSubscribedProducts.filter((products) => products.clientSubsStatus === 'active');
        if (activeClients && activeClients.length > 0) {
          disableFunctions = true;
        } else {
          disableFunctions = false;
        }  
      } 
      if(this.props.currentUserDetails && this.props.currentUserDetails.selectedProducts && this.props.currentUserDetails.selectedProducts.length > 0 && this.props.currentUserDetails.roleType === "User") {
        const productsArray = [], resultArray = [];
        this.props.currentUserDetails.selectedProducts.map((products) => productsArray.push(products));
        productsArray.map((product) => resultArray.push(product.users));
        // resultArray.forEach((result) => {
        for (let i = 0; i < resultArray.length; i++) {
        const activeClients = resultArray[i].filter((innerResult) => innerResult.user_subs_status === 'active');
        if (activeClients && activeClients.length > 0) {
            disableFunctions = true;
            break;
        } else {
            disableFunctions = false;
          }
        // });
        }
      }
      if(this.props.currentSubscribedProducts && this.props.currentSubscribedProducts.length !== 0 && this.props.currentUserDetails.roleType === "Client Admin") {
        this.setState({
          disableFunctions,
          isDisableUpdated: true,
        });
      } else if (this.props.currentUserDetails.roleType !== "Client Admin") {
        this.setState({
          disableFunctions,
          isDisableUpdated: true,
        });
      }
    }
  }

  componentDidMount() {
    // if(!this.state.isDisableUpdated){
    //   let disableFunctions = !(this.props.updateNewClientDetails && this.props.updateNewClientDetails.status && this.props.updateNewClientDetails.status === 'pending approval');
    //   if(this.props.currentSubscribedProducts && this.props.currentUserDetails.selectedProducts.length > 0 && this.props.currentUserDetails.roleType === "Client Admin") {
    //     const activeClients = this.props.currentSubscribedProducts.filter((products) => products.clientSubsStatus === 'active');
    //     if (activeClients && activeClients.length > 0) {
    //       disableFunctions = true;
    //     } else {
    //       disableFunctions = false;
    //     }  
    //   } 
    //   if(this.props.currentUserDetails && this.props.currentUserDetails.selectedProducts && this.props.currentUserDetails.selectedProducts.length > 0 && this.props.currentUserDetails.roleType === "User") {
    //     const productsArray = [], resultArray = [];
    //     this.props.currentUserDetails.selectedProducts.map((products) => productsArray.push(products));
    //     productsArray.map((product) => resultArray.push(product.users));
    //     resultArray.forEach((result) => {
    //     const activeClients = result.filter((innerResult) => innerResult.user_subs_status === 'active');
    //     if (activeClients && activeClients.length > 0) {
    //         disableFunctions = true;
    //     } else {
    //         disableFunctions = false;
    //       }
    //     });
    //   }
    //   this.setState({
    //     disableFunctions,
    //     isDisableUpdated: true,
    //   });
    // }
  }
  transformProducts(){
    let subscribedProducts = this.props.currentSubscribedProducts ? this.props.currentSubscribedProducts : [];
    subscribedProducts = subscribedProducts.map(row =>{
      if(["TMX Logicly for Advisors", "TMX Logicly"].includes(row.productName)){
        row["target"] = "_blank";
      }else{
        row["target"] = "";
      }
      return row;
    })
    return subscribedProducts;
  }

  render() {
    const { t } = this.props;
    let subscribedProducts = [];
    subscribedProducts = this.transformProducts();
    /*
    if (this.props.currentUserDetails.roleType !== 'User') {
      subscribedProducts = this.props.currentSubscribedProducts ? this.props.currentSubscribedProducts : [];
    } else {
      subscribedProducts = this.props.currentUserDetails.selectedProducts ? this.props.currentUserDetails.selectedProducts : [];
    }*/
    return (
      <div className="container-fluid products-text-style">
        <div className="row">
          <div className="col-lg-2 col-md-1 col-sm-1 col-xs-1"></div>
          <Jumbotron className="col-lg-8 col-md-10 col-sm-8 col-xs-8 admin-jumbotron-sample">
            <h2 className="admin_heading1">{t('admin.welcome')} {`${this.props.currentUserDetails.firstName}!`}&nbsp;<span><img src={arrowImage} className='arrow-style'></img></span></h2>
            <h4 className="admin_heading2">{t('admin.yourproducts')}</h4>
            <div className="subscribed-products-class">
                {subscribedProducts.sort((a,b) => (Number(a.productWeightage) < Number(b.productWeightage)) ? 1 : ((Number(b.productWeightage) < Number(a.productWeightage)) ? -1 : 0)).map((item, i) =><SubscribedProducts
                  key={i}
                  productId={item.subscriptionId}
                  productName = {item.subscriptionType !== '' || item.subscriptionType !==  'NA' ? item.productName : item.productName + ' ' +  item.subscriptionType}  
                  productLink= {
                    this.state.disableFunctions === false ? null : ((item.productCategory !== '' && item.productCategory !== 'API') ? (item.grapevineUrl ? item.grapevineUrl : item.productSigninUrl) : '/usage')
                    }
                    isSamePortal = {
                    this.state.disableFunctions === false ? null : ((item.productCategory !== '' && item.productCategory !== 'API') ? false : true)
                    }
                  isAdminPage={true}
                  target={item.target}
                />)}
                {subscribedProducts.length === 0
                ? <div className="full_width">
                  <center>
                    <h3>{t('admin.noproducts')}</h3>
                  </center>
                </div>
                : '' }
            </div>
          </Jumbotron>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCurrentUserDetails: (details) => dispatch(getCurrentUserDetails(details)),
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
  getCardDetails: (details) => dispatch(cardDetails(details)),
  getPaymentDetails: (details) => dispatch(paymentDetails(details)),
  getadminDetails: (details) => dispatch(adminDetails(details)),
  getCurrentAdminDetails: (details) => dispatch(getCurrentAdminDetails(details)),
});

const mapStateToProps = (state) => ({
  currentUserDetails: state.crud.currentUser,
  currentSubscribedProducts: state.crud.currentUserProducts,
  currentLoadingPage: state.crud.currentLoadingPage,
  usersListUpdated: state.crud.usersListUpdated,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Admin));
