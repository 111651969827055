/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { storeCurrentPage, productConfiguration, getConfiguredProducts, userListUpdated } from '../../../actions/index';
import apiUrl from '../../../utils/apiUrl';
import { update } from '../../../utils/httpUtil';
import { trackPromise } from 'react-promise-tracker';
import ErrorMessage from '../common/errorMessage';
import validation from '../../../../src/assets/properties/validation.json';

class ProductsConfigure extends Component {
  t = this.props.t;

  constructor(props) {
    super(props);
    this.state = {
      enableBilling: 1,
      products: [],
      preProducts: [],
      resultMessage: '',
      isAlertNotRequired: true,
      setDataFlag: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    const request = {
      clientId: this.props.currentUserDetails.clientId,
      token: this.props.currentUserDetails.access_token,
    }
    this.props.getConfiguredProducts([]);
    this.props.productConfiguration(request);
  }

  todaysDate(startDate) {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    today = `${yyyy}-${mm}-${dd}`;
    return (new Date(startDate) > new Date()) ? startDate : today;
  }

  handleChange(i, event) {
    const values = [...this.state.products];
    values[i].isDisabled = false;
    if (event.target.name === 'trialDays') {
      const pattern = new RegExp(validation[`onlyNumbers`]);
      if (!pattern.test(event.target.value)) {
        values[i][event.target.name] = event.target.value;
      }
      const value = Number(event.target.value);
      if (event.target.value === '' || event.target.value === '0'|| value === 0) {
        // values[i].isDisabled = true;
      }
    } else {
      values[i][event.target.name] = event.target.value;
    }
    this.setState({
      products: values
    });
  }

  handleBlur(i, event) {
    const values = [...this.state.products];
    if (event.target.name === 'trialDays') {
      const value = Number(event.target.value);
      if (event.target.value === '' || event.target.value === '0'|| value === 0) {
        values[i][event.target.name] = this.state.preProducts[i][event.target.name];
      }
    }
    this.setState({
      products: values
    });
  }

  handleSave(i, event) {
    event.preventDefault();
    this.setState({setDataFlag:false});
    // const newState = this.state;
    // const previousObject = this.props.currentProductDetails.find(o => o.productId === this.state.products[i].productId);
    const selectedUsers = this.state.products[i];
    const propsNew = this.props.currentProductDetails;
    propsNew[i] = selectedUsers;
    selectedUsers.agreementAcceptedBy = `${this.props.currentUserDetails.firstName} ${this.props.currentUserDetails.lastName}`;
    selectedUsers.clientId = this.props.currentUserDetails.clientId;
    selectedUsers.token = this.props.currentUserDetails.access_token;
    selectedUsers.tmxProductId = this.state.products[i].tmxProductId;
    selectedUsers.startDate = this.state.products[i].startDate;
    selectedUsers.billCycle = this.state.products[i].billCycle;
    delete selectedUsers.isDisabled;
    selectedUsers.updatedBy = `${this.props.currentUserDetails.firstName} ${this.props.currentUserDetails.lastName}`;
    if(selectedUsers.status == 'offboarded'){
      selectedUsers.endDate = new Date();
    }// else if(selectedUsers.endDate != previousObject.endDate){
    //   selectedUsers.status = 'pending offboard'
    // }
    if (!selectedUsers.endDate || selectedUsers.endDate == 'Invalid date') {
      delete selectedUsers.endDate;
    }
    selectedUsers.adminUserList = [];
    this.props.currentUsersList.forEach((user) => {
      if(user.isAdmin) {
        const currentUser = {
          firstName: user.firstName,
          email: user.email
        }
        selectedUsers.adminUserList.push(currentUser);
      }
    });
    // selectedUsers.adminUserList = this.props.currentUsersList.filter((user) => user.isAdmin);
    this.updateProducts(selectedUsers);
  }

  async updateProducts(products) {
    // this.props.getConfiguredProducts([]);
    trackPromise(update(apiUrl.updateProducts, products).then((response) => {
      if (response.data && response.data.data) {
        this.props.getConfiguredProducts([]);
        this.props.userListUpdated(false);
        this.setState({ resultMessage: '', isAlertNotRequired: true, products: [] });
        // this.props.productConfiguration({
        //   clientId: this.props.currentUserDetails.clientId,
        //   access_token: this.props.currentUserDetails.access_token,
        // });
        const request = {
          clientId: this.props.currentUserDetails.clientId,
          token: this.props.currentUserDetails.access_token,
        }
        this.props.productConfiguration(request);
        // window.location.reload();
        // this.setProductState();
      }
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

  componentDidMount() {
  }

  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
        this.props.history.push(this.props.currentLoadingPage);
        this.props.storeCurrentPage('');
    }
  }

  checkIsValid(item, flag){
    if(item.trialDays !== '') {
      var addtrialDays = new Date(item.startDate);
      addtrialDays.setDate(addtrialDays.getDate()+Number(item.trialDays));
      if(flag){
        return !(new Date() < new Date(addtrialDays));
      }else{
        return addtrialDays;
      }
    }
  }

  checkIsValidForTrialDays(i, flag){
    const values = this.state.preProducts;
    const item = values[i];
    if(item.trialDays !== '') {
      var addtrialDays = new Date(item.startDate);
      addtrialDays.setDate(addtrialDays.getDate()+Number(item.trialDays));
      if(flag){
        return !(new Date() < new Date(addtrialDays));
      }else{
        return addtrialDays;
      }
    }
  }

  setProductState() {
    if (!this.state.setDataFlag && this.props.currentProductDetails && this.props.currentProductDetails.length > 0) {
      let allProducts = this.props.currentProductDetails;
      allProducts = allProducts.map((v) => {
        const a = v;
        a.isDisabled = true;
        return a;
      });
      if (this.state.products.length === 0) {
        allProducts = allProducts.filter((v) => {
          if (v.status !== 'inactive') {
            return v;
          }
        });
        this.setState({
          products: allProducts,
          preProducts : JSON.parse(JSON.stringify(allProducts)),
          setDataFlag:true
        });
      }
    }
  }

  render() {
    (this.state.products.length === 0) ? this.setProductState() : '';
    const { t } = this.props;
    const products = this.state.products ? this.state.products : [];
    return (<>
    <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
    <div className='container-fluid'>
      <div className='row'>
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
          <Jumbotron className="col-lg-10 col-md-10 col-sm-10 col-xs-12 jumbotron_usage_summary">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="usage_heading1">{t('productsConfiguration.subscribed_product_list')}</div>
          <div className="payment_sub_table">
            <div className='products-table-style'>
            <table>
                <thead>
                  <tr>
                    <th>{t('productsConfiguration.product_name')}</th>
                    <th>{t('productsConfiguration.subscription_type')}</th>
                    <th>{t('productsConfiguration.start_date')}</th>
                    <th>{t('productsConfiguration.trial_period')}</th>
                    <th>{t('productsConfiguration.end_date')}</th>
                    <th>{t('productsConfiguration.status')}</th>
                    <th hidden>{'productsConfiguration.status'}</th>
                  </tr>
                </thead>
                <tbody>
                    {products.map((item, i) => 
                    <tr key={i}>
                      <td>{item.productName}</td>
                      <td>{item.subscriptionType}</td>
                      <td>{item.startDate}</td>
                      <td>{item.trialDays === 0 || this.checkIsValidForTrialDays(i, true) ? item.trialDays : <input type="text" name="trialDays" autoComplete="off" className="table-text" value={item.trialDays} onChange={(e) => this.handleChange(i, e)} /* onBlur={(e) => this.handleBlur(i, e)} */ disabled={item.trialDays === 0 || item.status !="active" || this.checkIsValidForTrialDays(i, true) }/>}</td>
                      <td><input type="date" name="endDate" autoComplete="off" value={item.endDate} min={this.todaysDate(item.startDate) || this.checkIsValid(item, false)} disabled={item.status !="active" } onChange={(e) => this.handleChange(i, e)}/></td>
                      <td>
                          { (item.status == "pending offboard" || item.status == "pending approval") ? 
                            <select name="status" className="select-option-payment capital">
                             <option className="select-option-payment capital" disabled="true" selected={item.status}>{item.status}</option>
                            </select> : 
                            <select name="status" onChange={(e) => this.handleChange(i, e)}>
                              {this.props.productStatus.map((product, j) => <option key={j} className="select-option-payment" selected={item.status === product.value} value={product.value}>{product.label}</option>)}
                            </select>
                          }
                      </td>
                      <td>
                        <button className="btn admin-save-btn" type="button" disabled={item.isDisabled} onClick={(e) => this.handleSave(i, e)}>{t('productsConfiguration.save')}</button>
                      </td>
                    </tr>)
                  }
                </tbody>
                </table>
            </div>
            </div>
            </div>
            </Jumbotron>
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
          </div>
    </div>
    </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
  productConfiguration: (details) => dispatch(productConfiguration(details)),
  getConfiguredProducts: (details) => dispatch(getConfiguredProducts(details)),
  userListUpdated: (details) => dispatch(userListUpdated(details)),
});

const mapStateToProps = (state) => ({
  currentProductDetails: state.crud.productsList,
  productStatus: state.crud.verfiedProductStatus,
  currentLoadingPage: state.crud.currentLoadingPage,
  currentUserDetails: state.crud.currentUser,
  currentUsersList: state.crud.usersList,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ProductsConfigure));
