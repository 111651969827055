/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import '../../i18n';
import { storeCurrentPage, saveMonerisDetails } from '../../../actions/index';
import { trackPromise } from 'react-promise-tracker';
import Iframe from 'react-iframe';
import apiUrl from '../../../utils/apiUrl';
import { store } from '../../../utils/httpUtil';
import ErrorMessage from '../common/errorMessage';

class PaymentCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      enableNewCard: false,
      iFrameUrl: '',
      iFrameSubmitUrl: '',
      dataKey: null,
      monerisError: false,
      saveCard: false,
      errors: {
        moneris: 'moneris_err_msg'
      },
      resultMessage: '',
      isAlertNotRequired: true
    };

    this.amountChange = this.amountChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSaveCard = this.handleSaveCard.bind(this);
    this.saveInitiator = this.saveInitiator.bind(this);
    this.getIframeDetails();
  }

  getIframeDetails() {
    trackPromise(store(apiUrl.adminiFrameUrl, {selectedCountry: this.props.currentUser.country}).then((iFrameDetails) => {
      if (iFrameDetails && iFrameDetails.data && iFrameDetails.data.data) {
        this.setState({
          iFrameUrl: iFrameDetails.data.data.url,
          iFrameSubmitUrl: iFrameDetails.data.data.submit
        });
      }
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

saveInitiator() {
  const ele = document.getElementById('monerisFrame');
  if (this.state.enableNewCard) {
    if(ele) {
      const monFrame = ele.contentWindow;
      monFrame.postMessage('', this.state.iFrameSubmitUrl);
    }
    if (window.addEventListener) {
      window.addEventListener('message', this.responseMessage.bind(this), false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', this.responseMessage.bind(this));
    }
  } else {
    this.sendRequestToMoneris('');
  }
}

  responseMessage(e) {
    if((this.state.enableNewCard)){
      const ele = document.getElementById('monerisFrame');
      ele.removeEventListener('message', this.responseMessage.bind(this), false);
      ele.removeEventListener('onmessage', this.responseMessage.bind(this));
      if (JSON.parse(e.data).responseCode.indexOf('001') === -1) {
        this.setState({
          monerisError: true,
        });
      } else {
        this.setState({
          monerisError: false,
          dataKey: JSON.parse(e.data).dataKey,
          skipMonerisFlow: false
        });
        this.sendRequestToMoneris(e);
      }
    }
  }

  sendRequestToMoneris(e) {
    const monerisDetails = {
      monerisError: false,
      dataKey: e ? JSON.parse(e.data).dataKey : this.props.dataKey,
      skipMonerisFlow: false
    };
    const request = {
      monerisDetails,
      isNewCard: this.state.enableNewCard,
      saveCard: this.state.saveCard
    }
    this.setState({
      enableNewCard: false,
    });
    this.props.saveMonerisDetails(request);
    this.props.history.push('/paymentSubmit');
  }

  amountChange(e) {
    const { value } = e.target;
    this.setState({
      amount: value,
    });
  }

  handleChange(e) {
    const newState = this.state;
    newState.enableNewCard = e.target.name !== 'savedCard';
    this.setState({
      newState
    });
  }

  handleSaveCard(e) {
    const newState = this.state;
    newState.saveCard = e.target.checked;
    this.setState({
      newState
    });
  }

  componentDidUpdate() {
    if (this.props.currentLoadingPage) {
        this.props.history.push(this.props.currentLoadingPage);
        this.props.storeCurrentPage('');
    }
  }

  render() {
    // const userDetails = this.props.userDetails ? this.props.userDetails : null;
    const cardNumber = this.props.cardDetails ? this.props.cardDetails.cardNo : null;
    const expiredOn = this.props.cardDetails ? this.props.cardDetails.expiryDate : null;
    const currentUser = this.props.currentUser ? this.props.currentUser : null;
    const { t } = this.props;
    return (<>
    <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
          <Jumbotron className="col-lg-10 jumbotron_usage_summary">
          {this.props.currentUser.roleType === "Client Admin" || this.props.currentUser.roleType === 'TMX Admin' || this.props.currentUser.roleType === 'TMX BA Admin' ? <Link className="payment_console_back_link" to='/payment'>{t('summary.back_button')}</Link> : ''}
              <div className="col-lg-12 col-md-12 col-sm-12 sol-xs-12">
              <div className="row payment_header_panel">
                <div className="payment_heading1">{t('paymentCards.make_payment')}</div>
              </div>
                <div className="wrapper summary-card-main">
                  <div className="pay_now_cards">
                    <div>
                      <p>
                      {t('paymentCards.main_statment')}
                      </p>
                    </div>
                  </div>
                  <div className="pay_now_cards">
                    <div>
                      <div className="pay_card_header">
                      {t('paymentCards.address_header')}
                      </div>
                      <div className="pay_card_address_panel">
                        <span>{currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : null},</span>
                        <span>{currentUser && currentUser.addressLine1 !== null && currentUser.addressLine1 !== 'null' && currentUser.addressLine1 !== '' ? `${currentUser.addressLine1},` : ''} {currentUser && currentUser.addressLine2 !== null && currentUser.addressLine2 !== 'null' && currentUser.addressLine2 !== '' ? `${currentUser.addressLine2},` : ''}</span>
                        <span>{currentUser && currentUser.city !== null && currentUser.city !== 'null' && currentUser.city !== '' ? `${currentUser.city},` : ''} {currentUser && currentUser.provinceState !== null && currentUser.provinceState !== 'null' && currentUser.provinceState !== '' ? `${currentUser.provinceState},` : ''}</span>
                        <span>{currentUser && currentUser.country !== null && currentUser.country !== 'null' && currentUser.country !== '' ? `${currentUser.country}` : ''} {currentUser && currentUser.postalCode !== null && currentUser.postalCode !== 'null' && currentUser.postalCode !== '' ? `- ${currentUser.postalCode}` : ''}.</span>
                      </div>
                      <div className="payments_cards_panel">
                        <div></div>
                        <div>
                          <div>
                              <input type="radio" id="savedCard" name="savedCard" value="savedCard" checked={!this.state.enableNewCard} onChange={this.handleChange}/>
                                <label className="card_options" htmlFor="savedCard">{t('paymentCards.cards_radio_option1')}</label><br></br>
                                <div className="card_details_flex">
                                  <div>{t('paymentCards.cards_number_label')} {cardNumber || ''}</div>
                                  <div>{t('paymentCards.cards_expires_on')} {expiredOn || ''}</div>
                                </div>
                              <input type="radio" id="newCard" name="newCard" value="newCard" checked={this.state.enableNewCard} onChange={this.handleChange}/>
                                <label className="card_options" htmlFor="newCard">{t('paymentCards.cards_radio_option2')}</label><br></br>
                          </div>
                               {this.state.enableNewCard
                                 ? 
                              <form className="col-lg-6 col-md-10 new_card_details_body">
                                <div className="form-row">
                                <div className="form-row header_firstrow_space">
                                  <div className="form-group col-md-12 col-lg-12 col-sm-12 left_labels_alignment">
                                  <Iframe src={this.state.iFrameUrl} id="monerisFrame" scrolling="no" class="moneris" webkitallowfullscreen mozallowfullscreen allowfullscreen width="100%" height="270" frameBorder="0" marginHeight="0" marginWidth="0"/>
                                  </div>
                                  <input type="checkbox" id="saveCard" name="saveCard" className="save-card" onChange={this.handleSaveCard}/>&nbsp;<label className="card_optionss">{t('paymentCards.save_this_card')}</label>
                                  <div className="text-danger-alert">{this.state.monerisError && 'Invalid Data'}</div>
                                </div>
                                </div>
                              </form>
                                 : ''}
                        </div>
                      </div>
                    </div>
                    <div className="payment-submit-button">
                      <button
                        className="btn pay_now_cont_btn"
                        type="button"
                        disabled={this.state.amount}
                      >
                        <Link
                          className="pay_buttons"
                          onClick={() => this.saveInitiator()}
                          amount={this.props.amount}
                        >
                        {t('paymentCards.paynow_continue_btn')}
                        </Link>
                      </button>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </Jumbotron>
          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.crud.userDetails,
  cardNumber: state.crud.card_number,
  dataKey: state.crud.dataKey,
  currentLoadingPage: state.crud.currentLoadingPage,
  currentUser: state.crud.currentUser,
  cardDetails: state.crud.cardDetails,
});

const mapDispatchToProps = (dispatch) => ({
  storeCurrentPage: (details) => dispatch(storeCurrentPage(details)),
  saveMonerisDetails: (details) => dispatch(saveMonerisDetails(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(PaymentCards));
