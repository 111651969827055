/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import '../../i18n';
import Jumbotron from 'react-bootstrap/Jumbotron';
import ReactHtmlParser from 'react-html-parser';
import { subscriberDetails } from '../../../actions/index';
import { store } from '../../../utils/httpUtil';
import apiUrl from '../../../utils/apiUrl';
import ErrorMessage from '../common/errorMessage';

class Subscriber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMSAagreed: true,
      agreement: '',
      agreedOn: new Date(),
      resultMessage: '',
      isAlertNotRequired: true
    };
    this.removeScrollNote = this.removeScrollNote.bind(this);
    this.closeAgreement = this.closeAgreement.bind(this);
    this.saveMSAChecked = this.saveMSAChecked.bind(this);
    this.getAgreement();
    if (this.props.signUpDetailsSaved && !this.props.signUpDetailsSaved.first_name) {
      this.props.history.push(this.props.defaultPage);
    }
  }

  getAgreement() {
    let isGrapevine = false;
    if (this.props.productsDetails && this.props.productsDetails.length) {
      for (let i = 0; i < this.props.productsDetails.length; i++) {
        if (this.props.productsDetails[i].subCategory.toLowerCase() === 'tmx grapevine') {
          isGrapevine = true;
          break;
        }
      }
    }
    trackPromise(store(apiUrl.getAgreement, { type: isGrapevine ? 'grapevine' : 'others' }).then((agreementList) => {
      if (agreementList && agreementList.data) {
        this.setState({
          agreement: agreementList.data.data,
        });
      }
    }).catch((err) => {
      this.setState({ resultMessage: err.message, isAlertNotRequired: false });
      window.scrollTo(0, 0);
    }));
  }

  componentDidMount() {
    if (this.props.subscriberDetailss) {
      this.setState({
        isMSAagreed: this.props.subscriberDetailss.isMSAagreed,
        agreedOn: new Date()
      });
    }
  }

  closeAgreement() {
    const agreed = true;
    this.setState({
      isMSAagreed: agreed,
      agreedOn: new Date()
    });
    this.props.subscriberDetails({ isMSAagreed: false });
  }

  removeScrollNote() {
    const elmnt = document.getElementById('subscriber_agreement');
    const a = elmnt.scrollHeight - elmnt.scrollTop === elmnt.clientHeight;
    this.setState({
      isNoteDisabled: a
    });
  }

  saveMSAChecked() {
    const agreed = true;
    this.setState({
      isAgreed: agreed
    });
    this.props.subscriberDetails({ isMSAagreed: true, agreedOn: new Date() });
  }

  saveInitiator() {
    const subscriber = this.state;
    this.props.subscriberDetails({ isMSAagreed: subscriber });
  }

  render() {
    const { t } = this.props;
    return (
      <>
      <ErrorMessage message={this.state.resultMessage} shouldNotDisplay={this.state.isAlertNotRequired} />
      <div>
      <div className="container-fluid">
        <div className="row">
        <div className="col-lg-1 col-md-1"></div>
        <Jumbotron className="col-lg-10 col-mb-10 col-sm-12 col-xs-12 jumbotron_subscriber">
          <div className="subscriber-card-main">
            <div className="subscriber-head">
              <center><h3>{t('subscriber.subscriber_header')}</h3></center>
              <Link to="/signup-summary"><h2><button className="cross-button" onClick={this.closeAgreement}>x</button></h2></Link>
            </div>
            <div className="subscriber-content scrollbar scrollbar-primary" id="subscriber_agreement" onScroll={() => this.removeScrollNote()}>
               {ReactHtmlParser(this.state.agreement)}
              <div className="mas-agree-container">
                <Link to="/signup-summary"><button className="btn msa-agree-button" onClick={this.saveMSAChecked}>
                {t('subscriber.iagree_button')}
              </button></Link>
              </div>
            </div>
          </div>
        </Jumbotron>
        <div className="col-lg-1 col-md-1"></div>
        </div>
        <div className="row">
        <div className="col-lg-1 col-md-1"></div>
        <div className="subscriber-note" hidden={this.state.isNoteDisabled}>{t('subscriber.subscriber_note')}</div>
        <div className="col-lg-1 col-md-1"></div>
        </div>
        <br></br><br></br><br></br><br></br>
      </div>
      </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  subscriberDetails: (details) => dispatch(subscriberDetails(details)),
});

const mapStateToProps = (state) => ({
  subscriberDetailss: state.crud.subscriber,
  productsDetails: state.crud.products,
  signUpDetailsSaved: state.crud.signUpDetails,
  defaultPage: state.crud.defaultPage
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Subscriber));
