/* eslint-disable no-undef */
import { trackPromise } from 'react-promise-tracker';
import apiUrl from '../utils/apiUrl';
import { store } from '../utils/httpUtil';
import { setLocalStorage, getLocalStorage } from '../utils/storageUtil';

export const getCurrentUserDetails = (payload) => ({
  type: 'GET_CURRENT_USER_DETAILS',
  data: payload
});

export const saveSelectedProduct = (payload) => ({
  type: 'SELECTED_PRODUCTS_INITIATE',
  data: payload
});

export const getCurrentBillingDetails = (payload) => ({
  type: 'GET_CURRENT_BILLING_DETAILS',
  data: payload
});

const getCardDetails = (payload) => ({
  type: 'GET_CARD_DETAILS',
  data: payload
});

export const isFromBillingUpdate = (payload) => ({
  type: 'FROM_BILLING_UPDATE',
  data: payload
});

const getPaymentDetails = (payload) => ({
  type: 'GET_PAYMENT_DETAILS',
  data: payload
});

export const getConfiguredProducts = (payload) => ({
  type: 'GET_CONFIGURED_PRODUCTS',
  data: payload
});

export const getCurrentAdminDetails = (payload) => ({
  type: 'GET_CURRENT_ADMIN_DETAILS',
  data: payload
});

export const userListUpdated = (payload) => ({
  type: 'USER_LIST_UPDATED',
  data: payload
});

export const taxAndPaymentUpdate = (payload) => ({
  type: 'TAX_AND_PAYMENT_UPDATED',
  data: payload
})

const getAuthorizationToken = (payload) => ({
  type: 'GET_AUTH_TOKEN',
  data: payload
});

export const updatePayment = (payload) => ({
  type: 'UPDATE_PAYMENT',
  data: payload
});

export const getAdminConsoleDetails = (payload) => ({
  type: 'GET_ADMIN_CONSOLE_DETAILS',
  data: payload
});

export const getApiProductsList = (payload) => ({
  type: 'GET_API_PRODUCTS_LIST',
  data: payload
});

export const roleAccessDetails = (payload) => ({
  type: 'GET_ROLE_ACCESS_DETAILS',
  data: payload
});

export const storeCurrentPage = (payload) => ({
  type: 'STORE_CURRENT_PAGE',
  data: payload
});

export const saveMonerisDetails = (payload) => ({
  type: 'MONERIS_DETAILS',
  data: payload
});

export const saveSignUpMonerisDetails = (payload) => ({
  type: 'SIGN_UP_MONERIS_DETAILS',
  data: payload
});

export const changeClientId = (payload) => ({
  type: 'UPDATE_CLIENT_ID',
  data: payload
});

export const removeloginerrormsg = () => ({
  type: 'REMOVE_LOGIN_ERRORMSG'
});

export const taxRateDetails = (payload) => ({
  type: 'TAX_RATE_DETAILS_ADMIN',
  data: payload
});

export const billingDetails = (details) => async (dispatch) => {
  const access_token = getLocalStorage('access_token');
  details.token = access_token;
  trackPromise(store(apiUrl.getCurrentBillingDetails, details).then((res) => {
    if (res && res.data && res.data.data) {
      dispatch(getCurrentBillingDetails(res.data.data));
    }
  }).catch((err) => {
    console.log(err);
  }));
}

export const paymentDetails = (details) => async (dispatch) => {
  trackPromise(store(apiUrl.getPaymentDetails, details).then((res) => {
    if (res && res.data && res.data.data) {
      dispatch(getPaymentDetails(res.data.data));
    }
  }).catch((err) => {
    console.log(err);
  }));
};

export const cardDetails = (details) => async (dispatch) => {
  const access_token = getLocalStorage('access_token');
  details.token = access_token;
  trackPromise(store(apiUrl.getCardDetails, details).then((res) => {
    if (res && res.data && res.data.data) {
      dispatch(getCardDetails(res.data.data));
    }else{
      dispatch(getCardDetails(undefined));
    }
  }).catch((err) => {
    console.log(err);
  }));
};


export const productConfiguration = (request) => async (dispatch) => {
  trackPromise(store(apiUrl.getConfiguredProducts, request).then((res) => {
    if (res && res.data && res.data.data) {
      dispatch(getConfiguredProducts(res.data.data));
    }
  }).catch((err) => {
    console.log(err);
  }));
};

export const authToken = (details) => async (dispatch) => {
  trackPromise(store(apiUrl.getAuthToken, details).then((res) => {
    if (res && res.data && res.data.data) {
      dispatch(getAuthorizationToken(res.data.data));
    }
  }).catch((err) => {
    console.log(err);
  }));
};

export const adminDetails = (details) => async (dispatch) => {
  const access_token = getLocalStorage('access_token');
  details.token = access_token;
  trackPromise(store(apiUrl.getCurrentAdminDetails, details).then((res) => {
    if (res && res.data && res.data.data) {
      dispatch(userListUpdated(true));
      dispatch(getCurrentAdminDetails(res.data.data));
    }
  }).catch((err) => {
    console.log(err);
  }));
};

export const productsDetails = (payload) => ({
  type: 'PRODUCTS_DETAILS_INITIATE',
  data: payload
});

export const loginFunction = (request) => async (dispatch) => {
  await trackPromise(store(apiUrl.login, request).then((res) => {
    setLocalStorage('access_token', res.data.data[0].access_token);
    if (res && res.data && res.data.data && (res.data.status === 1)) {
      dispatch(roleAccessDetails(res.data.data));
    }
  }).catch((err) => {
    dispatch(roleAccessDetails(err));
    console.log(err);
  }));
};

export const subscriberDetails = (payload) => ({
  type: 'SUBSCRIBER_DETAILS',
  data: payload
});

export const storeAssignProducts = (payload) => ({
  type: 'ASSIGN_PRODUCTS',
  data: payload
});

export const updateSelectedClient = (payload) => ({
  type: 'UPDATE_SELECTED_CLIENT',
  data: payload
});

export const getAdminSummary = (request) => async (dispatch) => {
  request.access_token = getLocalStorage('access_token');
  await trackPromise(store(apiUrl.getAdminSummary, request).then((res) => {
    if (res && res.data && res.data.data) {
      dispatch(userListUpdated(false));
      dispatch(adminSummaryDetails(res.data.data));
      dispatch(adminPageCount(res.data.data.pagination));
    }
  }).catch((err) => {
    console.log(err);
  }));
};

export const adminSummaryDetails = (payload) => ({
  type: 'ADMIN_SUMMARY_DETAILS',
  data: payload
});

export const adminPageCount = (payload) => ({
type: 'ADMIN_USERS_COUNT',
data: payload
});

export const clearAllAdminSummaryDetails = () => ({
  type: 'CLEAR_ALL_ADMIN_SUMMARY_DETAILS',
});

export const updateClientDetails = (payload) => ({
  type: 'UPDATE_CLIENT_DETAILS',
  data: payload
});

export const updateProfileDetails = (payload) => ({
  type: 'UPDATE_PROFILE_DETAILS',
  data: payload
});

export const updateClientBillingDetails = (payload) => ({
  type: 'UPDATE_CLIENT_DETAILS',
  data: payload
})

export const updateNewclientdetails = (payload) => ({
  type: 'UPDATE_NEW_CLIENT_DETAILS',
  data: payload
});

export const updateTeamClientName = (payload) => ({
  type: 'UPDATE_CLIENT_TEAM_NAME',
  data: payload
})

export const savesignUpDetails = (payload) => ({
  type: 'SIGN_UP_INITIATE',
  data: payload
});

export const saveBillingDetails = (payload) => ({
  type: 'BILLING_DETAILS_INITIATE',
  data: payload
});

export const saveSummaryDetails = (payload) => ({
  type: 'SUMMARY_DETAILS_INITIATE',
  data: payload
});

export const userDetails = (payload) => ({
  type: 'USER_DETAILS_INITIATE',
  data: payload
});

export const recieveAllDetails = () => ({
  type: 'RECIEVE_ALL_DETAILS'
});

export const taxRateDetailsSignUp = (payload) => ({
  type: 'TAX_RATE_DETAILS',
  data: payload
});

export const removeAllSignUpDetails = () => ({
  type: 'REMOVE_ALL_SIGNUP_DETAILS'
});
