import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from '../locales/i18n-en.json';
import translationFR from '../locales/i18n-fr.json';

const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};

i18n
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    resources,
    // lng: 'en',
    fallbackLng: 'en',
    // keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
