/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import '../../i18n';

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'Pagination',
      currentIndex: this.props.currentIndex,
      pages: [
        'products',
        'signup',
        'billing',
        'signup-payment',        
        'signup-summary'
      ],
      nextPage: this.props.isComesFromBillingPage ? this.props.currentIndex + 3 : this.props.currentIndex + 1,
      previousPage: this.props.currentIndex - 1
    };
  }

  render() {
    const { t } = this.props;
    const activeClass = (index) => `${this.props.currentIndex === index ? 'activeCurrentPage' : ''}`;
    let buttonLabel;
    if (this.props.isNext) {
      buttonLabel = 'pagination.next';
    } else if (this.props.isContinue) {
      buttonLabel = 'pagination.continue';
    } else if (this.props.isSummary) {
      buttonLabel = 'pagination.summary';
    }
    const nextButton = <button className="btn btn-info next_button" disabled={this.props.currentIndex === this.state.pages.length - 1 || this.props.isDisable}>{t(buttonLabel)}</button>;
    return (
      <div>
        <div className="pagenumber_padding">
        {this.props.showPaginationNumber
          ? <>
          <span className="textNone">{((this.props.currentIndex >= 2) && (this.state.nextPage >= 1 || !this.props.isDisable)) ? <Link to="/signup" className={`textNone ${activeClass(1)}`}>1</Link> : <a className={`textNone ${activeClass(1)}`}>1</a> }</span>
          <span className="textNone">{((this.props.currentIndex >= 3) && (this.state.nextPage >= 2 || !this.props.isDisable)) ? <Link to="/billing" className={`textNone ${activeClass(2)}`}>2</Link> : <a className={`textNone ${activeClass(2)}`}>2</a> }</span>
          <span className="textNone">{((this.props.currentIndex >= 4) && (this.state.nextPage >= 3 || !this.props.isDisable)) ? <Link to="/signup-payment" className={`textNone ${activeClass(3)}`}>3</Link> : <a className={`textNone ${activeClass(3)}`}>3</a> }</span>
          <span className="textNone">{((this.props.currentIndex >= 5) && (this.state.nextPage >= 4 || !this.props.isDisable)) ? <Link to="/signup-summary" className={`textNone ${activeClass(4)}`}>4</Link> : <a className={`textNone ${activeClass(4)}`}>4</a> }</span>
        </>
          : '' }
        </div>
        <div>
        {!this.props.isNoButton ? 
        !this.props.isDisable && !this.props.blockNavigation 
          ? <Link to={(this.props.redirectTo && !this.props.isComesFromBillingPage) ? `${this.props.redirectTo}` : `/${this.state.pages[this.state.nextPage]}`}>
            {nextButton}
          </Link>
          : <a>
            {nextButton}
        </a>
        : '' } 
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Pagination);
