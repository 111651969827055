/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import "../../i18n";
import Cryptr from "cryptr";
import Navbar from "react-bootstrap/Navbar";
import { store } from "../../../utils/httpUtil";
import { trackPromise } from "react-promise-tracker";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { connect } from "react-redux";
import apiUrl from "../../../utils/apiUrl";
import TMXLogo from "../../../assets/img/TMX-Analytics-Logo.png";
import { getCurrentUserDetails } from "../../../actions/index";
import { clearLocalStorage } from "../../../utils/storageUtil";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminDropDownOptions: [],
      isAlertRequired: true,
      isPasswordsChanged: true,
      isAPIError: true,
      isAPIErrorMessage: "",
      isDisabled: true,
      isDisableUpdated: false,
      disableFunctions: true,
    };
    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.routePage = this.routePage.bind(this);
    this.logoutFunction = this.logoutFunction.bind(this);
    this.closeEditUser = this.closeEditUser.bind(this);
    this.clearCurrentPasswordErrorMessage =
      this.clearCurrentPasswordErrorMessage.bind(this);
    this.changePasswordDialog = this.changePasswordDialog.bind(this);
    this.checknewandconfirm = this.checknewandconfirm.bind(this);
  }

  checknewandconfirm() {
    this.setState({
      isDisabled: true,
    });
    let isAlertRequired, isDisabled;
    var newpassword = document.getElementById("newpassword").value;
    var confirmpassword = document.getElementById("confirmpassword").value;

    if (confirmpassword && newpassword) {
      if (confirmpassword !== newpassword) {
        isAlertRequired = false;
        isDisabled = true;
      }
      if (confirmpassword === newpassword) {
        isAlertRequired = true;
        isDisabled = false;
      }
      this.setState({
        isAlertRequired,
        isDisabled,
      });
    }
  }

  async encryptPassword(oldPassword, newPassword, confirmPassword) {
    const cryptr = new Cryptr("myTotalySecretKey");
    let encryptedoldPassword = await cryptr.encrypt(oldPassword);
    let encryptednewPassword = await cryptr.encrypt(newPassword);
    let encryptedconfirmPassword = await cryptr.encrypt(confirmPassword);
    const saveRequest = {
      encryptedoldPassword,
      encryptednewPassword,
      encryptedconfirmPassword,
      email: this.props.currentUserDetails.email,
    };
    this.changePassword(saveRequest);
  }

  saveChangedPassword() {
    var oldPassword = document.getElementById("oldpassword").value;
    var newPassword = document.getElementById("newpassword").value;
    var confirmPassword = document.getElementById("confirmpassword").value;
    this.encryptPassword(oldPassword, newPassword, confirmPassword);
  }

  async changePassword(saveRequest) {
    let isPasswordsChanged;
    await trackPromise(
      store(apiUrl.changePassword, saveRequest)
        .then((savedUser) => {
          if (savedUser.data && savedUser.data.status == -1) {
            this.setState({
              isAPIError: false,
              isAPIErrorMessage: savedUser.data.data,
            });
          } else if (
            savedUser.data &&
            savedUser.data.data &&
            savedUser.data.data.statusCode &&
            savedUser.data.data.statusCode === 200
          ) {
            isPasswordsChanged = false;
            this.setState({
              isPasswordsChanged,
              isAPIError: true,
              isAPIErrorMessage: savedUser.data.data.message,
            });
          } else {
            if (
              savedUser.data &&
              savedUser.data.data &&
              savedUser.data.data.message
            ) {
              const message = JSON.parse(savedUser.data.data.message);
              if (message && message[0] && message[0].errorSummary) {
                this.setState({
                  isAPIError: false,
                  isAPIErrorMessage: message[0].errorSummary,
                });
              }
            }
            if (
              savedUser.data &&
              savedUser.data.data &&
              savedUser.data.data.error
            ) {
              const message = JSON.parse(savedUser.data.data.error);
              if (message && message.message) {
                this.setState({
                  isAPIError: false,
                  isAPIErrorMessage: message.message,
                });
              }
            }
          }
        })
        .catch(() => {
          window.scrollTo(0, 0);
        })
    );
  }

  openNav() {
    if (document.getElementById("mySidenav").style.width === "250px") {
      document.getElementById("mySidenav").style.width = "0px";
    } else {
      document.getElementById("mySidenav").style.width = "250px";
    }
  }

  closeNav(e) {
    e.preventDefault();
    document.getElementById("mySidenav").style.width = "0";
  }

  routePage(item) {
    if (item.isExternal) {
      window.location.href = window.location.origin + item.path;
    } else {
      this.props.routeInitiate(item.path);
    }
  }

  changePasswordDialog() {
    var modal = document.getElementById("changePasswordDiv");
    modal.style.display = "block";
    this.setState({
      isAlertRequired: true,
      isPasswordsChanged: true,
      isAPIError: true,
      isAPIErrorMessage: "",
    });
    document.getElementById("oldpassword").value = "";
    document.getElementById("newpassword").value = "";
    document.getElementById("confirmpassword").value = "";
  }

  closeEditUser() {
    document.getElementById("changePasswordDiv").style.display = "none";
  }

  logoutFunction() {
    const saveRequest = {
      token: this.props.currentUserDetails.access_token,
      type: "access_token",
      email: this.props.currentUserDetails.email,
    };
    this.logout(saveRequest);
  }

  async logout(request) {
    await trackPromise(
      store(apiUrl.logout, request).then((logoutResponse) => {
        if (logoutResponse.data && logoutResponse.data.data) {
          clearLocalStorage("state");
          clearLocalStorage("access_token");
          sessionStorage.clear();
          window.location.href = "/login";
        }
      })
    );
  }

  clearCurrentPasswordErrorMessage() {
    this.setState({
      isAPIError: true,
      isAPIErrorMessage: "",
    });
  }

  componentDidMount() {
    let sessionExpired = sessionStorage.getItem("sessionExpired");
    sessionExpired = sessionExpired === "true";
    if (
      this.props.pageAccess &&
      this.props.pageAccess.length > 0 &&
      this.props.currentUserDetails.roleType !== "TMX Admin" &&
      this.props.currentUserDetails.roleType !== "TMX BA Admin" &&
      !sessionExpired
    ) {
      const isApiProductsPresent =
        this.props.currentUserDetails.selectedProducts.filter(
          (products) => products.productCategory === "API"
        );
      let apiDropDownOption = this.props.pageAccess
        ? this.props.pageAccess
        : [];
      apiDropDownOption = apiDropDownOption.filter(
        (dropDown) => dropDown.path === "/usage"
      );
      apiDropDownOption[0].isSideMenu =
        isApiProductsPresent && isApiProductsPresent.length > 0 ? true : false;
    }

    if (
      !this.state.isDisableUpdated &&
      this.props.pageAccess &&
      this.props.pageAccess.length > 0 &&
      !sessionExpired
    ) {
      let disableFunctions = !(
        this.props.updateNewClientDetails &&
        this.props.updateNewClientDetails.status &&
        this.props.updateNewClientDetails.status === "pending approval"
      );
      if (
        this.props.currentSubscribedProducts &&
        this.props.currentUserDetails.selectedProducts.length > 0 &&
        this.props.currentUserDetails.roleType === "Client Admin"
      ) {
        const activeClients = this.props.currentSubscribedProducts.filter(
          (products) => products.clientSubsStatus === "active"
        );
        if (activeClients && activeClients.length > 0) {
          disableFunctions = true;
        } else {
          disableFunctions = false;
        }
      }
      if (
        this.props.currentUserDetails &&
        this.props.currentUserDetails.selectedProducts &&
        this.props.currentUserDetails.selectedProducts.length > 0 &&
        this.props.currentUserDetails.roleType === "User"
      ) {
        const productsArray = [],
          resultArray = [];
        this.props.currentUserDetails.selectedProducts.map((products) =>
          productsArray.push(products)
        );
        productsArray.map((product) => resultArray.push(product.users));
        resultArray.forEach((result) => {
          const activeClients = result.filter(
            (innerResult) => innerResult.user_subs_status === "active"
          );
          if (activeClients && activeClients.length > 0) {
            disableFunctions = true;
          } else {
            disableFunctions = false;
          }
        });
      }
      this.setState({
        disableFunctions,
        isDisableUpdated: true,
      });
    }
  }

  render() {
    const { t } = this.props;
    const currentUserDetails = this.props.currentUserDetails
      ? this.props.currentUserDetails
      : null;
    /*const subscribedProducts = this.props.currentUserDetails.selectedProducts ? this.props.currentUserDetails.selectedProducts : [];*/
    const subscribedProducts = this.props.currentSubscribedProducts
      ? this.props.currentSubscribedProducts
      : [];

    const subscribedProductsMenuItem = subscribedProducts.filter(
      (item) => (item && item.isVisibleInUI == 1) || false
    );

    let adminDropDownOptions = this.props.pageAccess
      ? this.props.pageAccess
      : [];
    adminDropDownOptions = adminDropDownOptions.filter(
      (dropDown) => dropDown.isSideMenu
    );
    let sessionExpired = sessionStorage.getItem("sessionExpired");
    sessionExpired = sessionExpired === "true";
    return (
      <>
        {this.props.pageAccess &&
        this.props.pageAccess.length > 0 &&
        !sessionExpired ? (
          <div className="header-style-bar">
            {" "}
            <Navbar
              variant="dark"
              className="navbar_color navbar-font sticky-top"
            >
              {this.props.currentUserDetails.roleType !== "TMX Admin" &&
              this.props.currentUserDetails.roleType !== "TMX BA Admin" ? (
                <Nav.Link onClick={this.openNav} className="hamburger">
                  &#9776;
                </Nav.Link>
              ) : (
                ""
              )}
              <Navbar.Brand to="/admin">
                <img src={TMXLogo} height="50" width="240" />
              </Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto"></Nav>
                <Nav>
                  <NavDropdown
                    className="header-name-wrapper"
                    title={`${
                      currentUserDetails.firstName
                        ? currentUserDetails.firstName
                        : ""
                    } ${
                      currentUserDetails.lastName
                        ? currentUserDetails.lastName
                        : ""
                    }`}
                  >
                    <NavDropdown.Item
                      disabled
                      className="email-text-wrapper"
                    >{`${currentUserDetails.email}`}</NavDropdown.Item>
                    {adminDropDownOptions && adminDropDownOptions.length > 0 ? (
                      <NavDropdown.Divider />
                    ) : (
                      ""
                    )}
                    {adminDropDownOptions.map((item, i) => (
                      <NavDropdown.Item
                        key={i}
                        onClick={() => this.routePage(item)}
                      >
                        {item.name}
                      </NavDropdown.Item>
                    ))}
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      onClick={() => this.changePasswordDialog()}
                    >
                      {t("header.changepassword")}
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={this.logoutFunction}>
                      {t("header.logoutlink")}
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        ) : (
          <div className="header-style-bar">
            <Navbar
              collapseOnSelect
              expand="lg"
              variant="dark"
              className="navbar_color navbar-font sticky-top"
            >
              <Navbar.Brand href="/signup">
                <img src={TMXLogo} height="50" width="240" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link
                    target="_blank"
                    eventKey={t("header.analytics_home_link")}
                    href={t("header.analytics_home_link")}
                  >
                    {t("header.homelink")}
                  </Nav.Link>
                  <Nav.Link
                    target="_blank"
                    eventKey={t("header.products_pricing_link")}
                    href={t("header.products_pricing_link")}
                  >
                    {t("header.products_pricing")}
                  </Nav.Link>
                </Nav>
                <Nav>
                  <Nav.Link eventKey={t("header.signup")} href="/signup">
                    {t("header.signup")}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        )}
        <div onMouseLeave={this.closeNav}>
          <div id="mySidenav" className="sidenav">
            {this.state.disableFunctions === false
              ? subscribedProductsMenuItem.map((item, i) => (
                  <a
                    className="text-justify align-navitems"
                    id="align-navitems"
                    key={i}
                  >
                    {item.productName}
                  </a>
                ))
              : subscribedProductsMenuItem.map((item, i) => (
                  <a
                    href={
                      item.productCategory !== "" &&
                      item.productCategory !== "API"
                        ? item.grapevineUrl
                          ? item.grapevineUrl
                          : item.productSigninUrl
                        : "/usage"
                    }
                    // target={(item.productCategory !== '' && item.productCategory !== 'API') ? '_blank' : '_self'}
                    className="text-justify align-navitems"
                    id="align-navitems"
                    key={i}
                  >
                    {item.productName}
                  </a>
                ))}
          </div>
        </div>

        <div id="changePasswordDiv" className="modal changePasswordDiv">
          <div
            id="changepassword-modal-content"
            className="changepassword-modal-content"
          >
            <div className="container-fluid">
              <div className="row">
                <span className="close" onClick={this.closeEditUser}>
                  &times;
                </span>
              </div>
              <div className="row">
                <h5 className="edituser-popup-header">
                  {t("header.changepassword")}
                </h5>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-12 col-md-12">
                  <label htmlFor="oldpassword">
                    {t("header.oldpassword")}
                    <span className="required-flag">{"*"}</span>
                  </label>
                  <input
                    type="password"
                    onChange={this.clearCurrentPasswordErrorMessage}
                    name="oldpassword"
                    id="oldpassword"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-12 col-md-12">
                  <label htmlFor="newpassword">
                    {t("header.newpassword")}
                    <span className="required-flag">{"*"}</span>
                  </label>
                  <input
                    type="password"
                    name="newpassword"
                    onChange={this.checknewandconfirm}
                    id="newpassword"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-row edituser-popup-header">
                <div className="form-group col-lg-12 col-md-12">
                  <label htmlFor="confirmpassword">
                    {t("header.confirmpassword")}
                    <span className="required-flag">{"*"}</span>
                  </label>
                  <input
                    type="password"
                    name="confirmpassword"
                    id="confirmpassword"
                    className="form-control"
                    onChange={this.checknewandconfirm}
                  />
                </div>
                <div
                  className="text-danger-alert"
                  hidden={this.state.isAlertRequired}
                >
                  New Password and Confirm Password doesn&apos;t match
                </div>
              </div>
              <div className="form-row">
                <div
                  className="text-success-alert"
                  hidden={this.state.isPasswordsChanged}
                >
                  {this.state.isAPIErrorMessage}
                </div>
              </div>
              <div className="text-danger-alert" hidden={this.state.isAPIError}>
                {this.state.isAPIErrorMessage}
              </div>
            </div>
            <div className="form-row">
              <button
                className="btn btn-info changepassword-save-button"
                onClick={() => this.saveChangedPassword()}
                hidden={!this.state.isPasswordsChanged}
                disabled={this.state.isDisabled}
              >
                {t("header.submit")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCurrentUserDetails: (details) => dispatch(getCurrentUserDetails(details)),
});

const mapStateToProps = (state) => ({
  currentUserDetails: state.crud.currentUser,
  currentSubscribedProducts: state.crud.currentUserProducts,
  pageAccess: state.crud.pageAccess,
  currentUser: state.crud.currentUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Header));
